import { observable, configure, action, computed, toJS } from 'mobx';
import util from 'preact-util';

class TableStore {
    @observable rows = [];
    @observable selectedCell = { rowIndex: 0, cellIndex: 0 };
    @observable isEditing = false;

    @action
    setSelectedCell(rowIndex, cellIndex) {
        this.selectedCell = { rowIndex, cellIndex };
    }

    @action
    setIsEditing(isEditing) {
        this.isEditing = isEditing;
    }

    @action
    updateCellData(rowIndex, cellIndex, newData) {
        this.rows[rowIndex].cells[cellIndex].value = newData;
    }
}
const store = new TableStore();
export default store;