import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewPayment extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('payment'),
        };
    }

    loadAll = async () => {
        const { idx, id, isNew } = this.props;
        const { paymentStore } = this.props.stores;
        if (!isNew) {
            paymentStore.adminSetFromIdx(idx);
            // TODO: Do not overwrite paymentStore.payment!
            if (id) {
                const idInt = parseInt(id, 10);
                const response = await paymentStore.adminLoad({ id: idInt }, { skipUpdate: true });
                const adminList = response?.data;
                const obj = adminList[0];
                paymentStore.updateKeyValue('payment', obj);
            }
        }
    }

    createPayment = async () => {
        const { callback = () => {} } = this.props;
        const { paymentStore } = this.props.stores;
        const { newPayment } = paymentStore;
        const response = await paymentStore.adminCreate(newPayment);
        if (response.status === 201) {
            callback();
        }
    }

    componentDidMount() {
        this.loadAll();
        // const { idx, isNew } = this.props;
        // const { paymentStore } = this.props.stores;
        // if (!isNew) {
        //     paymentStore.adminSetFromIdx(idx);
        // }
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections } = this.state;
        const { userStore, paymentStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newPayment } = paymentStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <h5 class='ml-3 mb-3'>
                        {isNew ? <Text id='payment.new'>New payment</Text> : <Text id='payment.edit'>Edit payment</Text>}
                    </h5>
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields stores={this.props.stores} sections={sections} holdingStore={paymentStore} objectName={isNew ? 'newPayment' : 'payment'} isNew={isNew} drawerLevel={drawerLevel} isAdmin={true} />
                    </div>
                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createPayment}>
                            {isNew ? <Text id='payment.create'>Create payment</Text> : <Text id='payment.save'>Save payment</Text>}
                        </button>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default NewPayment;
