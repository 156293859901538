import { h } from 'preact';

const TablePlaceholder = ({ rows = 5, columns = 3 }) => {
    const skeletonRows = [];

    for (let i = 0; i < rows; i++) {
        const cells = [];

        for (let j = 0; j < columns; j++) {
            cells.push(
                <div key={j} class='skeleton-cell table-placeholder' />
            );
        }

        skeletonRows.push(
            <div key={i} class='skeleton-row'>
                {cells}
            </div>
        );
    }

    return (
        <div>
            {skeletonRows}
        </div>
    );
};

export default TablePlaceholder;