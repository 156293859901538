import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class ViewGpsPosition extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('raceClass'),
            sectionsRace: fields.get('race'),
        };
    }

    createFile = async () => {
        const { callback = () => {} } = this.props;
        const { fileStore } = this.props.stores;
        const { newFile } = fileStore;
        const response = await fileStore.adminCreate(newFile);
        if (response.status === 201) {
            callback();
        }
    }

    // componentDidMount() {
        // const { idx, id, isNew } = this.props;
        // const { fileStore } = this.props.stores;
    //     if (!isNew) {
    //         fileStore.adminSetFromIdx(idx);
    // submittedApplicationStore.adminSetFromId(id);
    //     }
    // }

    render() {
        const { infoList, resultList, adminList, sections, sectionsRace } = this.state;
        const { pos, drawerLevel } = this.props;
        const { userStore, raceStore, raceClassStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <xmp>{JSON.stringify(pos, null, 4)}</xmp>
                        {/* <FormFields stores={this.props.stores} sections={sections} holdingStore={fileStore} objectName={isNew ? 'newFile' : 'file'} isNew={isNew} drawerLevel={drawerLevel} isAdmin={true} /> */}
                        <h5>RaceClass</h5>
                        <div class='bg-light d-flex flex-column justify-content-start h-100'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                fields={[
                                    'checkpoints', 'waypoints', 'sponsors'
                                ]}
                                holdingStore={raceClassStore}
                                objectName={'raceClass'}
                                isNew={false}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                // lookupArray={lookupArray}
                                // rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar parts ({foundList.length})</span> : null}
                                // rightHeaderClick={this.toggleSimilar}
                            />
                        </div>

                        <h5 class='mt-5'>Race</h5>
                        <div class='bg-light d-flex flex-column justify-content-start h-100'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sectionsRace}
                                fields={[
                                    'waypoints', 'sponsors'
                                ]}
                                holdingStore={raceStore}
                                objectName={'race'}
                                isNew={false}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                // lookupArray={lookupArray}
                                // rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar parts ({foundList.length})</span> : null}
                                // rightHeaderClick={this.toggleSimilar}
                            />
                        </div>



                    </div>
                </div>
            </div>
        </>);
    }
}

export default ViewGpsPosition;
