import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class UsageStore extends LocalModel {
    constructor() {
        super('usage', {
            namePlural: 'usages',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/usages/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/usages/',
                    params: {},
                },
                save: {
                    url: '/api/usages/',
                    params: {},
                },
                delete: {
                    url: '/api/usages/',
                    params: {},
                },
            },
        });
    }

    @observable newUsage = {
    };

    @observable usage = {};

    @observable usages = [];

    @observable parts = [];

    @observable installations = [];

}

const store = new UsageStore();
export default store;
