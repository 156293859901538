import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import GuiInfoBox from '../../components/gui/infoBox';

import localUtil from '../../lib/util';

const MARKDOWN_OPTIONS = {
	pedantic: false,
	gfm: true,
	breaks: true,
	sanitize: false,
	smartLists: true,
	smartypants: true,
	xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

@withText(props => ({
    amountText: <Text id='race-class.signup.amount-title'>Amount to pay</Text>,
}))
@observer
class PaymentBank extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    payment = () => {
        const { type } = this.props;
        const { paymentInProgress } = this.state;
        const { raceClassContestantStore, raceClassStore, raceStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        const { race } = raceStore;
        const { raceClass } = raceClassStore;
        const { bankAccountNumber, bankCompany, bankIBAN, bankSWIFT, bankOtherInfo } = race;

        return (<>
            {[
                'bankCompany',
                'bankAccountNumber',
                'bankMarkPayment',
                'bankDueDate',
            ].map(key => {
                const field = fields.getField('race', key);
                if (field.isInternalField && !isInternal) {
                    return undefined;
                }
                const displayValue = field.displayValue || (value => value);
                return (<>
                    {race[key] && <div class='d-flex flex-row'>
                        <span class='text-muted font-weight-lighter'>
                            {field.icon && <><i class={field.icon} /> {field.title}</>}
                        </span>
                        <span class='ml-2'>{displayValue(race[key])}</span>
                    </div>}
                </>);
            })}

            {bankIBAN && bankSWIFT && <div class='d-flex flex-row justify-content-center align-items-center mt-0'>
                <Text id='race-class.signup.payment-from-abroad'>Paying from another country than Norway? Use </Text>
                IBAN: {bankIBAN}, BIC/SWIFT: {bankSWIFT}
            </div>}

            {bankOtherInfo && <>
                <div class='d-flex justify-content-start align-items-center mt-5'>
                    <Markdown markdown={bankOtherInfo} markedOpts={MARKDOWN_OPTIONS} />
                </div>
                <div class='d-flex flex-column justify-content-start align-items-start mt-3'>
                    <div class=''><Text id='race.bank-mark-payment'>Mark payment with:</Text></div>
                    <strong>"{race.title} - {raceClass.title}: {raceClassContestant.firstname} {raceClassContestant.lastname}"</strong>
                </div>
            </>}
        </>);
    }

    receipt = () => {
        const { type } = this.props;
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;

        let amount = 0;
        let currency = '';
        let paidDate = null;
        switch (type) {
            case 'fee':
                amount = raceClassContestant.signupfeeAmount;
                paidDate = raceClassContestant.paidSignupfee;
                break;
            case 'signup':
                amount = raceClassContestant.signuppaymentAmount;
                paidDate = raceClassContestant.paidSignup;
                break;
            case 'rest':
                amount = raceClassContestant.restPaymentAmount;
                paidDate = raceClassContestant.paidRest;
                break;
            default:
                break;
        }

        return (<>
             <div class='d-flex flex-row justify-content-center align-items-center mt-0'>
                <Text id='race-class.paid-via-bank'>Paid via Bank</Text>
            </div>
            <div class='d-flex flex-row justify-content-center align-items-center mt-0'>
                <div>
                    <i class='fa-regular fa-circle-check text-success' style='font-size: 4.0em;' />
                </div>
                <div class='d-flex flex-column ml-3'>
                    {/* paymentMethod: {raceClassContestant.paymentMethod}<br /> */}
                    {/* {util.formatDate(raceClassContestant.paidSignupfee, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}<br /> */}
                    {/* vippsSignupfeeReference: {raceClassContestant.vippsSignupfeeReference}<br /> */}
                    {/* vippsSignupfeeStatus: {raceClassContestant.vippsSignupfeeStatus}<br /> */}
                    {/* vippsSignupfeeAmountCurrency: {raceClassContestant.vippsSignupfeeAmountCurrency}<br /> */}
                    {/* vippsSignupfeeAmountValue: {util.format(raceClassContestant.vippsSignupfeeAmountValue / 100, 0)}<br /> */}

                    <GuiInfoBox
                        stores={this.props.stores}
                        nameMiddle={util.formatDate(paidDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                        value={util.format(amount / 100, 0)}
                        postfix={currency}
                        styles={`font-size: 2.5em; line-height: 1.0em;`}
                    />
                </div>
            </div>
        </>);
    }

    render() {
        const { paymentInProgress } = this.state;
        const {
            amountText,
        } = this.props;
        const { type } = this.props;
        const { appState, userStore, raceClassContestantStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { raceClassContestant } = raceClassContestantStore;

        let amount = 0;
        let currency = '';
        let paidDate = null;
        switch (type) {
            case 'fee':
                amount = raceClassContestant.signupfeeAmount;
                paidDate = raceClassContestant.paidSignupfee;
                break;
            case 'signup':
                amount = raceClassContestant.signuppaymentAmount;
                paidDate = raceClassContestant.paidSignup;
                break;
            case 'rest':
                amount = raceClassContestant.restPaymentAmount;
                paidDate = raceClassContestant.paidRest;
                break;
            default:
                break;
        }
        // const amount = type === 'fee' ? raceClassContestant.signupfeeAmount : raceClassContestant.signuppaymentAmount;
        // const paidDate = type === 'fee' ? raceClassContestant.paidSignupfee : raceClassContestant.paidSignup;

        return (<>

            {paidDate ? <>
                {this.receipt()}
            </> : <>
                <div class='d-flex flex-row justify-content-center mt-0'>
                    <GuiInfoBox
                        stores={this.props.stores}
                        nameMiddle={amountText}
                        value={util.format(amount, 0)}
                        postfix={'NOK'}
                        styles={`font-size: 2.5em; line-height: 1.0em;`}
                    />
                </div>
                <div class='d-flex flex-column justify-content-center mt-0'>
                    {this.payment()}
                </div>
            </>}

        </>);
    }
}

export default PaymentBank;
