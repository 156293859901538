import { h, Component, createRef } from 'preact';
import { observer } from 'mobx-preact';
import util from 'preact-util';
import { Text, Localizer, withText } from 'preact-i18n';

const debug = false;
const debugName = 'Images';
const MAX_WIDTH = 2048;
const MAX_HEIGHT = 2048;
const MAX_FILES = 20;

function filenameSafe(filename) {
    return filename.replace(/[^a-z0-9]/gi, '_').replace(/_+/g, '_').toLowerCase();
}

@observer
class DragNDropFileupload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drag: false,
            loadingProgress: 0,
            uploadedFiles: [],
            uploadedFilesData: {},
            error: null,
            uploadQueue: [],
            totalUploading: 0,
        };
        this.uploadQueue = [];
    }

    dropRef = createRef();

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({ drag: true });
        }
    }

    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({ drag: false });
        }
    }

    handleDrop = (e) => {
        if (debug) {
            console.log(`${debugName}.handleDrop: event`, e);
        }
        e.preventDefault();
        e.stopPropagation();
        this.setState({ drag: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            // this.props.handleDrop(e.dataTransfer.files);
            // e.dataTransfer.clearData();
            for (let i = 0, l = e.dataTransfer.files.length; i < l; i += 1) {
                const photo = e.dataTransfer.files[i];
                // if (photo.type.match('image.*')) {
                this.readLocalFile(photo);
                // }
            }
            this.dragCounter = 0;
        }
    }

    reportError = error => {
        try{
            const { workoutStore, userStore } = this.props.stores;
            const { user } = userStore;
            // const {
            //     // currentTeam, totalTime = 0, currentSpeed = 0, totalDistance = 0,
            //     isRunning, saved, workout, newWorkout, cordovaAuthorizationStatus, powerSaveStatus,
            //     providerState, geoLocationState,
            //     cordovaActiveTrackingParams,
            //     activeTrackingWorkoutId, activeTrackingTeamId,
            //     battery, activity, coords, bgGeo,
            //     pluginState,
            // } = workoutStore;

            const data = {
                timestamp: Math.floor(new Date().getTime() / 1000),
                error: {
                    name: 'ImageUpload',
                    message: 'Problem with image upload',
                    stack: `Status:
user.email: ${JSON.stringify(user.email, null, 4)}
user.deviceInfo: ${JSON.stringify(user.deviceInfo, null, 4)}
user.platformId: ${JSON.stringify(user.platformId, null, 4)}
user.platformVersion: ${JSON.stringify(user.platformVersion, null, 4)}

error:
${JSON.stringify(error, null, 4)}
                    `,
                },
                location: window.location,
            };
            util.fetchApi('/api/errors/', { method: 'POST' }, data);
        } catch (err) {
            console.log(err);
        }
    }

    // Upload
    handleAddFiles = event => {
        const el = event.target;
// console.log('handleAddFiles', el);
        try {
            // console.log('handleAddFiles', el);
            if (!el || !el.files || el.files.length === 0) {
                return this.reportError({
                    file: 'dragNdropFileupload.js',
                    function: 'handleAddFiles',
                    error: 'No files selected',
                    el,
                });
            }
            if (el.files.length > MAX_FILES) {
                this.setState({ error: `Max ${MAX_FILES} files in each upload...` });
            } else {
                this.setState({ error: null });
                if (debug) {
                    console.log(`${debugName}.handleAddFiles: event, el`, event, el);
                }
                for (let i = 0, l = el.files.length; i < l; i += 1) {
                    const photo = el.files[i];
                    // console.log('photo', photo);
                    // if (photo.type.match('image.*')) {
                    this.readLocalFile(photo);
                    // }
                }
            }
        } catch(err) {
            this.reportError({
                file: 'dragNdropFileupload.js',
                function: 'handleAddFiles',
                error: err,
            });
        }
    }

    handleEvent = (e, fileObject) => {
        if (debug) {
            console.log(`${debugName}.handleEvent[${fileObject.name}]: ${e.type}: `
                + `${e.loaded} bytes transferred of ${e.total}. Is lengthComputable: ${e.lengthComputable}: `
                + `${JSON.stringify(e)}`);
        }
    }

    handleUpload = async (fileObject, maxWidth, maxHeight) => {
        if (debug) {
            console.log(`${debugName}.handleUpload[${fileObject.name}]`);
        }
        try {
            const { uploadStatus = () => {}, before = () => {}, after = () => {} } = this.props;
            uploadStatus(false);

            if (util.isFunction(before)) {
                before();
            }

            const formData = new FormData();

            // Don't resize images, you loose exif data..!
            const isImage = false;//fileObject?.type.match(/image\//); // .match(/(jpg|jpeg|png|gif|heic|heif|svg|webp|tif)/i);
            if (isImage) {
                // Load and resize image
                const resizedImage = await this.localLoadAndResizeImage(fileObject, maxWidth, maxHeight);
                // Append attributes from fileObject to formData
                for (const prop in fileObject) {
                    if (fileObject.hasOwnProperty(prop) && typeof fileObject[prop] !== 'function') {
                        formData.append(prop, fileObject[prop]);
                    }
                }
                // Check if resizedImage is a Blob object
                if (resizedImage instanceof Blob) {
                    // Append resized image to formData
                    formData.append('files[]', resizedImage, fileObject.name);
                } else {
                    // Handle the case where resizedImage is not a Blob object
                    console.error('Resized image is not a Blob object:', resizedImage);
                }
            } else {
                // Upload original fileObject:
                formData.append('files[]', fileObject, encodeURIComponent(fileObject.name));
            }

            const uploadMeta = {
                progress: 1,
            };

            uploadMeta.xhr = new XMLHttpRequest();

            uploadMeta.xhr.upload.addEventListener('progress', (event) => {
                const fileObj = fileObject;
                this.updateProgress(event, fileObj);
            });
            uploadMeta.xhr.addEventListener('loadstart', (event) => {
                const fileObj = fileObject;
                this.handleEvent(event, fileObj);
            });
            uploadMeta.xhr.addEventListener('load', (event) => {
                const fileObj = fileObject;
                this.handleEvent(event, fileObj);
            });
            uploadMeta.xhr.addEventListener('loadend', (event) => {
                const fileObj = fileObject;
                if (util.isFunction(after)) {
                    after();
                    this.setState({ totalUploading: this.state.totalUploading - 1 }, () => {
                        this.handleUploadQueue();
                    });
                }
                this.handleEvent(event, fileObj);
            });
            uploadMeta.xhr.addEventListener('progress', (event) => {
                const fileObj = fileObject;
                this.handleEvent(event, fileObj);
            });
            uploadMeta.xhr.addEventListener('error', (event) => {
                const fileObj = fileObject;
                this.handleEvent(event, fileObj);
            });
            uploadMeta.xhr.addEventListener('abort', (event) => {
                const fileObj = fileObject;
                this.handleEvent(event, fileObj);
            });
            uploadMeta.xhr.addEventListener('readystatechange', (event) => {
                const fileObj = fileObject;
                this.uploadDone(event, fileObj);
            });

            uploadMeta.xhr.open('POST', `${util.getApiServer()}${this.props.apiUrl}`);
            uploadMeta.xhr.setRequestHeader('Authorization', `Bearer ${util.getJwtToken()}`);
            uploadMeta.xhr.send(formData);

            const filename = filenameSafe(fileObject.name);
            const uploadedFilesData = this.state.uploadedFilesData;
            if (!uploadedFilesData[filename]) {
                uploadedFilesData[filename] = {};
            }
            uploadedFilesData[filename].uploadMeta = uploadMeta;
            uploadedFilesData[filename].imageNum = this.state.imageNum;
            this.setState({ uploadedFilesData });
        } catch(err) {
            if (debug) {
                console.log(`${debugName}.handleUpload[${fileObject.name}]: error`, err);
            }
            this.reportError({
                file: 'dragNdropFileupload.js',
                function: 'handleUpload',
                error: err,
            });
        }
    }

    resizeImage = (inputImage, maxWidth = 256, maxHeight = 256, isBlob) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                let newWidth = image.width;
                let newHeight = image.height;

                // Calculate new dimensions while maintaining aspect ratio
                if (newWidth > maxWidth) {
                    const ratio = maxWidth / newWidth;
                    newWidth = maxWidth;
                    newHeight = newHeight * ratio;
                }
                if (newHeight > maxHeight) {
                    const ratio = maxHeight / newHeight;
                    newHeight = maxHeight;
                    newWidth = newWidth * ratio;
                }

                // Set canvas dimensions
                canvas.width = newWidth;
                canvas.height = newHeight;

                // Draw image on canvas
                ctx.drawImage(image, 0, 0, newWidth, newHeight);

                if (isBlob) {
                    // Convert canvas content to blob
                    canvas.toBlob((blob) => {
                        resolve(blob);
                    });
                } else {
                    // Convert canvas content to base64 image
                    resolve(canvas.toDataURL('image/jpeg'));
                }
            });
            image.src = inputImage;
        });
    }

    localLoadAndResizeImage = (fileObject, maxWidth = 256, maxHeight = 256) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('error', (error) => {
                if (debug) {
                    console.log(`${debugName}.FileReader: Error occurred reading file: ${fileObject.name}: ${error}`);
                    this.reportError({
                        file: 'dragNdropFileupload.js',
                        function: 'localLoadAndResizeImage.reader.addEventListener',
                        error,
                    });
                }
                reject(error);
            });

            reader.addEventListener('load', async (event) => {
                if (debug) {
                    console.log(`${debugName}.FileReader: File: ${fileObject.name} read successfully: `
                        + `${JSON.stringify(event)}`);
                }
                const resizedImage = await this.resizeImage(event.target.result, maxWidth, maxHeight, true);
                resolve(resizedImage);
            });

            reader.readAsDataURL(fileObject);
        });
    }

    // eslint-disable-next-line
    readLocalFile = (fileObject, maxWidth = 256, maxHeight = 256) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            // Get the original real FileReader. The polyfill saves a reference to it.
            const realFileReader = reader._realReader;
            // Make sure we were able to get the original FileReader
            if (realFileReader) {
                // Swap out the polyfill instance for the original instance.
                reader = realFileReader;
            }

            reader.addEventListener('error', (error) => {
                if (debug) {
                    console.log(`${debugName}.FileReader: Error occurred reading file: ${fileObject.name}: ${error}`);
                    this.reportError({
                        file: 'dragNdropFileupload.js',
                        function: 'readLocalFile.reader.addEventListener',
                        error,
                    });
                }
                reject(error);
            });

            reader.addEventListener('load', async (event) => {
                if (debug) {
                    console.log(`${debugName}.FileReader: File: ${fileObject.name} read successfully: `
                        + `${JSON.stringify(event)}`);
                }
                const isImage = fileObject?.type.match(/image\//); // .match(/(jpg|jpeg|png|gif|heic|heif|svg|webp|tif)/i);
                const photo = fileObject;
                const filename = filenameSafe(photo.name);
                const uploadedFilesData = this.state.uploadedFilesData;
                if (!uploadedFilesData[filename]) {
                    uploadedFilesData[filename] = {};
                }
                uploadedFilesData[filename].event = event;
                if (isImage) {
                    const resizedImage = await this.resizeImage(event.target.result, maxWidth, maxHeight);
                    uploadedFilesData[filename].resizedSrc = resizedImage;
                }
                this.setState({ uploadedFilesData });
                this.addToUploadQueue(photo);
                // this.handleUpload(photo, MAX_WIDTH, MAX_HEIGHT);
                resolve(event);
            });

            reader.readAsDataURL(fileObject);
        });
    }

    addToUploadQueue = (photo) => {
        // const { uploadQueue = [] } = this.state;
        // uploadQueue.push(photo);
        // this.setState({
        //     uploadQueue,
        // }, () => {
        //     this.handleUploadQueue();
        // });
        this.uploadQueue.push(photo);
        this.handleUploadQueue();
    }

    handleUploadQueue = () => {
        // const { uploadQueue = [], totalUploading } = this.state;
        const { totalUploading } = this.state;
        console.log('handleUploadQueue.totalUploading', totalUploading)
        if (this.uploadQueue.length > 0 && totalUploading < 1) {
            const photo = this.uploadQueue.shift();
            this.setState({
                totalUploading: totalUploading + 1,
            });
            this.handleUpload(photo, MAX_WIDTH, MAX_HEIGHT);
        }
    }

    // handleUploadQueue should only run one upload at a time.


    updateProgress = (event, fileObject) => {
        if (event.lengthComputable) {
            const filename = filenameSafe(fileObject.name);
            const uploadedFilesData = this.state.uploadedFilesData;
            const percentComplete = (event.loaded / event.total) * 100;
            if (debug) {
                console.log(`${debugName}.updateProgress[${filename}].progress`, percentComplete);
            }
            uploadedFilesData[filename].uploadMeta.progress = percentComplete;
            this.setState({ uploadedFilesData });
        }
    }

    uploadDone = (event, fileObject) => {
        const filename = filenameSafe(fileObject.name);
        const uploadedFilesData = this.state.uploadedFilesData;
        const uploadMeta = uploadedFilesData[filename].uploadMeta;
        if (debug) {
            console.log(`${debugName}.uploadDone[${filename}].uploadedFilesData`, uploadedFilesData);
            console.log(`${debugName}.uploadDone[${filename}].uploadMeta`, uploadMeta);
            console.log(`${debugName}.uploadDone.event`, event);
        }

        if (uploadMeta && uploadMeta.xhr.readyState === 4 && uploadMeta.xhr.status === 201) {
            const response = JSON.parse(uploadMeta.xhr.responseText);
            const files = response.data.files;
            for (let i = 0; i < files.length; i += 1) {
                const file = files[i];
                this.addFileToUpload(file);
                if (this.fileInput) {
                    this.fileInput.value = '';
                }
            }
        }
    }

    addFileToUpload(file) {
        const filename = filenameSafe(file.name);
        if (debug) {
            console.log(`${debugName}.addFileToUpload[${filename}]`);
        }
        const { uploadedFilesData } = this.state;
        const { handleAddImage, uploadStatus = () => {} } = this.props;

        delete uploadedFilesData[filename];
        this.setState({ uploadedFilesData });
        handleAddImage(file);
        uploadStatus(true);
    }
    // /Upload

    componentDidMount() {
        const div = this.dropRef.current;
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);

        const { autoOpen } = this.props;

        if (autoOpen) {
            setTimeout(() => {
                // console.log('[setTimeout]');
                this.fileInput.click();
            }, 10);
        }
    }

    componentWillUnmount() {
        const div = this.dropRef.current;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
    }

    render() {
        const { uploadedFilesData, error } = this.state;
        const {
            accept = 'image/*',
            text,
            icon,
            width = '115px',
            height = '115px',
            typeOfFile = 'image',
            field,
        } = this.props;

        return (<>
            <div class='my-2 p-0 position-relative' style={`width: ${width};`} ref={this.dropRef}>
                {this.state.drag && <>
                    <div style={{
                        border: 'dashed grey 4px',
                        backgroundColor: 'rgba(255,255,255,.8)',
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 9999,
                    }}>
                        <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                left: 0,
                                textAlign: 'center',
                                color: 'grey',
                                fontSize: 36,
                            }}
                        >
                            <div>
                                <Text id='imageupload.drag-files-here'>drop your files here :)</Text>
                            </div>
                        </div>
                    </div>
                </>}

                {error && <>
                    <div class='alert alert-danger' role='alert'>
                        {error}
                    </div>
                </>}
                <div>
                    <div class='input-group w-100' style=''>
                        <div class='custom-file clearfix text-center' style={`height: ${height};`}>
                            <input
                                type='file'
                                accept={accept}
                                class='custom-file-input'
                                multiple
                                id={`image-file-${field}`}
                                onChange={this.handleAddFiles}
                                ref={c => {
                                    this.fileInput = c;
                                }}
                            />
                            <label
                                class='d-flex flex-column align-items-center justify-content-center custom-file-label bg-light border-secondary clearfix'
                                for={`image-file-${field}`}
                                style='font-size: 1.1em; height: 100%;'
                            >
                                {Object.keys(uploadedFilesData).length === 0 ? <>
                                    <i class='fa-regular fa-square-plus text-primary' /><br />
                                    {text && <small class='font-weight-light'>{text}</small>}
                                    {icon && <i class={icon} />}
                                    {/* <i class='fa-solid fa-image display-4' /><br /> */}
                                    {/* <Text id='imageupload.upload-new-images'>Upload one or more images</Text> */}
                                </> : <>
                                    <div>
                                        <div class='spinner-grow text-primary' role='status'>
                                            <span class='sr-only'>Loading...</span>
                                        </div>
                                        <br />
                                        {typeOfFile === 'image' ? <>
                                            <Text id='imageupload.uploading-images'>Uploading images...</Text>
                                        </> : <>
                                            <Text id='imageupload.uploading-files'>Uploading files...</Text>
                                        </>}
                                    </div>
                                </>}
                            </label>
                        </div>
                    </div>
                </div>

                {this.props.children}
            </div>

            <div class='col-12'>
                {/* {Object.keys(uploadedFilesData).length > 0 && <>
                    <h3><Text id='imageupload.upload-images'>Uploaded images</Text></h3>
                </>} */}
                <ul class='list-group'>
                    {Object.keys(uploadedFilesData).map(key => {
                        const { uploadMeta = {} } = uploadedFilesData[key];
                        const isImage = key.match(/(jpg|jpeg|png|gif|heic|heif|svg|webp|tif)/i);
                        return (
                            <li class='list-group-item list-group-item-action flex-column align-items-start' key={key}>
                                <div class='d-flex w-100 justify-content-between'>
                                    {isImage && <img class='img-fluid mr-3'
                                        src={uploadedFilesData[key].resizedSrc || uploadedFilesData[key].event.target.result}
                                        style='max-height: 50px;'
                                    />}
                                    <small>{uploadedFilesData[key].event.uploadDone}</small>
                                    {typeOfFile === 'image' ? <>
                                        <Text id='imageupload.uploading-images'>Uploading images...</Text>
                                    </> : <>
                                        <Text id='imageupload.uploading-files'>Uploading files...</Text>
                                    </>}
                                    <small>{util.formatBytes(uploadedFilesData[key].event.total, 2)}</small>
                                </div>
                                <div class='progress mt-1' style='height: 4px;'>
                                    <div
                                        class='progress-bar progress-bar-striped progress-bar-animated bg-success'
                                        role='progressbar'
                                        style={`width: ${uploadMeta.progress}%; height: 4px;`}
                                        aria-valuenow={uploadMeta.progress}
                                        aria-valuemin='0'
                                        aria-valuemax='100'
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>);
    }
}

export default DragNDropFileupload;
