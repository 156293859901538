import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    titleText: <Text id='contact.title'>Contact</Text>,
}))
@observer
class ContactList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            filterByTags: [],
        };
    }

    viewContact = e => {
        const { view } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('viewContact', {
            id,
            title,
            height: drawerHeightMedium,
            editFunction: this.editContactViaView,
            callback: () => {
                // appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.contacts?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.contacts?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-contact' /> <span class='font-weight-lighter'> x {obj.contacts.length}</span>
                        </span>
                    </>}
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    toggleTagFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { filterByTags: filterByTagsProps } = this.props;
        if (filterByTagsProps?.length > 0) {
            return;
        }
        const { tag } = e.target.closest('span').dataset;
        const { filterByTags } = this.state;
        if (filterByTags.includes(tag)) {
            const index = filterByTags.indexOf(tag);
            filterByTags.splice(index, 1);
        } else {
            filterByTags.push(tag);
        }
        this.setState({ filterByTags });
    }

    filterByTags = (obj) => {
        const { filterByTags, hideChildren } = this.state;
        const { filterByTags: filterByTagsProps } = this.props;
        if (hideChildren && obj.parent) {
            return false;
        }
        if (filterByTagsProps?.length > 0) {
            return obj.tags?.some(tag => filterByTagsProps.includes(tag.name));
        }
        if (filterByTags.length === 0) {
            return true;
        }
        // console.log('filterByTags', { obj, filterByTags })
        return obj.tags?.some(tag => filterByTags.includes(tag.name));
    }

    filter = () => {
        const { showTagsOnTop = true } = this.props;
        if (showTagsOnTop) {
            return '';
        }
        const { filterByTags } = this.state;
        if (filterByTags.length > 0) {
            return (<>
                {filterByTags.map(tag => {
                    return (<>
                        <span
                            class='badge badge-pill badge-secondary mr-1'
                            style='font-size: 1.0rem;'
                            onClick={this.toggleTagFilter}
                            data-tag={tag}
                        ><i class="fa-solid fa-circle-xmark"></i> {tag}</span>
                    </>);
                })}
            </>);
        }
    }

    editContact = e => {
        const { id, title } = e.target.closest('.box-line').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editContact', {
            id,
			height: drawerHeightMedium,
            callback: this.closeAddContact,
		}, drawerLevel + 2);
	}

    editContactViaView = e => {
        const { id, title } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editContact', {
            id,
			height: drawerHeightMedium,
            callback: this.closeAddContact,
		}, drawerLevel + 2);
	}

    loadAll = async () => {
        const { contactStore } = this.props.stores;
        contactStore.load({
            query: {},
            skipUpdate: false,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { appState, userStore, contactStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            classNames = '',
            hideEmpty,
            title = titleText,
            viewTags = this.viewTags,
            editElement,
            help,
            children,
            showTagsOnTop = true,
        } = this.props;
        const { filterByTags } = this.state;
        const { tags, contacts } = contactStore;

        return (<>
            {showTagsOnTop && tags?.length > 0 && <>
                <div class='font-weight-lighter px-3 mt-3 box-header'>
                    <Text id='contact.tags-title'>All tags</Text>
                </div>
                <div class={`d-flex flex-column mt-1 mx-3 px-3 py-3 mb-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                    {tags?.length > 0 && <div class='d-flex flex-wrap'>
                        {tags && tags.map(tag => tag.name).map((tag, idx) => {
                            const isActive = filterByTags.includes(tag);
                            const inActiveClass = darkmode ? 'dark' : 'light';
                            return (<>
                                <span
                                    class={`badge badge-pill badge-${isActive ? 'success' : `${inActiveClass} font-weight-normal`} mr-1 mt-1`}
                                    onClick={this.toggleTagFilter}
                                    data-tag={tag}
                                >#{tag}</span>
                            </>);
                        })}
                    </div>}

                    {filterByTags?.length > 0 && <>
                        <button type='button' class='btn btn-sm btn-outline-secondary rounded-pill mt-3' onClick={() => this.setState({ filterByTags: [] })}>
                            <Text id='contacts.clear-tag-filter'>Clear tag filter</Text> ({filterByTags.length})
                        </button>
                    </>}
                </div>
            </>}

            <GuiList
                stores={this.props.stores}
                holdingStore={contactStore}
                classNames={classNames}
                view={this.viewContact}
                title={title}
                filter={this.filter}
                list={contacts.filter(this.filterByTags)}
                elTitle={e => e.title || 'No title'}
                elInfo1={e => e.whenIsTheContactRecorded ? util.formatDate(e.whenIsTheContactRecorded, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' }) : util.formatDate(e.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                elInfo2={e => e.images.length > 0 && <>{e.images?.length === 1 ? <i class='fa-solid fa-image' /> : <i class='fa-solid fa-images' />}</>}

                // elInfo2={e => e.whoIsInTheContact}
                // elInfo3={e => e.whatIsInTheContact}
                viewTags={viewTags}
                hideEmpty={hideEmpty}
                editElement={this.editContact}
                help={help || children}
            />
        </>);
    }
}

export default ContactList;
