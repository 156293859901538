import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import Pagination from '../../components/gui/pagination';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalAllSalesTable from '../../components/dyrejournal/allVisitsTable';

const LIMIT = 100;

@withText(props => ({
    saleTitle: <Text id='dyrejournal.sale-title'>Search for sale</Text>,
    searchTitle: <Text id='dyrejournal.search-title'>Search for sale</Text>,
    deleteAreYouSure: <Text id='dyrejournal.sale-delete-are-you-sure'>Are you sure you want to delete this sale?</Text>,
}))
@observer
class AllVisitsPrint extends Component {
	constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
        };
    }

    render() {
        const { search, currentPage = 0, cutoffDate } = this.state;
        const {
            saleTitle,
            searchTitle,
            activeStatus,
            formatting,
        } = this.props;
        const { userStore, saleStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { sales, allPayments, searchResults, totalSales } = saleStore;
        // const finalSales = searchResults && searchResults.length > 0 ? searchResults : sales;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 0px; padding-bottom: 0px;'>
                    <DyrejournalAllSalesTable
                        stores={this.props.stores}
                        showDelete={true}
                        formatting={formatting}
                        activeStatus={activeStatus}
                    />
                    {/* <Pagination total={totalSales} limit={LIMIT} current={currentPage} onPageChange={this.setPage} stores={this.props.stores} /> */}
                </div>
            </div>
        );
    }
}

export default AllVisitsPrint;
