import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';
import ImageScroller from '../../components/imagescroller';
import MiniGraph from '../../components/lineGraph/mini';
import FileList from '../../components/fileViewer/list';
import UrlList from '../../components/urlViewer/list';
import GradientRight from '../../components/gui/gradientRight';
import HelpText from '../../components/gui/helpText';
import StarRating from '../../components/gui/starRating';
import Input from '../../components/form/input';

const MARKDOWN_OPTIONS = {
	pedantic: false,
	gfm: true,
	breaks: true,
	sanitize: false,
	smartLists: true,
	smartypants: true,
	xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function productImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}


@withText(props => ({
    installationListHelpText: <Text id='product.installation-list-help'>List of all the places where this product is currently located.</Text>,
    productUsageListHelpText: <Text id='product.usage-list-help'>All logged usage of this product. This can be service of a car, boat trips, shooting practice, etc.</Text>,
    productLoanListHelpText: <Text id='product.loan-list-help'>All loan outs of this product. You can see if it's still out or if it has been returned. You can also see if you have sent any reminders.</Text>,
    titleChildrenProductsText: <Text id='product.children-list-title'>Connected products</Text>,
    titleParentProductsText: <Text id='product.product-list-title'>Parent product</Text>,
}))
@observer
class Product extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, skipUpdate, callback = () => {} } = props;
        const { productStore, installationStore } = this.props.stores;
        const { installation } = installationStore;

        if (emptyFirst && !skipUpdate) {
            productStore.updateKeyValue('product', {});
        }
        // await productStore.load(id, false, { query:{ installationId: installation.id }, addData: ['installations'] });
        const localProduct = await productStore.load(id, false, { query:{}, addData: ['installations', 'existingTags'], skipUpdate });
        this.setState({ localProduct });
        callback();
    }

    editProduct = () => {
		const { id, drawerLevel = 1, skipUpdate } = this.props;
		const { appState, installationStore } = this.props.stores;
        const { installation } = installationStore;

		const { drawerHeightMedium } = appState;
		appState.openDrawer('editProduct', {
			height: drawerHeightMedium,
			id,
            installationId: installation.id,
            skipUpdate,
		}, drawerLevel + 1);
	}

    editProductLoan = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: loanId, qty = 1 } = e.target.closest('.box-line').dataset;
        const { id, drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
        const { installation } = installationStore;

		appState.openDrawer('editLoan', {
			height: drawerHeightMedium,
            id,
            loanId,
            callback: this.closeAddProduct,
		}, drawerLevel + 1);
    }

    editProductUsage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: usageId, qty = 1 } = e.target.closest('.box-line').dataset;
        const { id, drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
        const { installation } = installationStore;

		appState.openDrawer('editUsage', {
			height: drawerHeightMedium,
            id,
            usageId,
            callback: () => {
                this.closeAddProduct;
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    viewProduct = e => {
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState, installationStore } = this.props.stores;
        const { installation } = installationStore;
        // appState.toggleDrawer();

        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMediumLarge } = appState;
        appState.openDrawer('viewProduct', {
            id,
            // title,
            height: drawerHeightMediumLarge,
            callback: () => {
                // appState.toggleDrawer();
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
            skipUpdate: true,
            installation,
        }, drawerLevel + 1);
    }

    viewProductLoan = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: loanId, qty = 1 } = e.target.closest('.box-line').dataset;
        const { id, drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { installation } = installationStore;

		appState.openDrawer('viewLoan', {
			height: drawerHeightMediumLarge,
            id,
            loanId,
		}, drawerLevel + 1);
    }

    viewProductUsage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: usageId, qty = 1 } = e.target.closest('.box-line').dataset;
        const { id, drawerLevel = 1 } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { installation } = installationStore;

		appState.openDrawer('viewUsage', {
			height: drawerHeightMediumLarge,
            id,
            usageId,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    closeAddProduct = () => {
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        appState.closeDrawer(false, drawerLevel + 2);
        // this.loadAll(this.props, false);
    }

    addToInstallation = e => {
        const { id: productId, drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('addProductToInstallation', {
			height: drawerHeightMedium,
			productId,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    loanToFriend = e => {
        const { id: productId, drawerLevel = 1, installation = {} } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;

		appState.openDrawer('loanProductToFriend', {
			height: drawerHeightMedium,
			productId,
            installationId: installation.id,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    addUsageData = e => {
        const { id, drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;

		appState.openDrawer('productAddUsageData', {
			height: drawerHeightMedium,
			id,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    deleteProductFromInstallation = async (e) => {
        const { id: productId, drawerLevel = 1, installation = {} } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('deleteProductFromInstallation', {
            height: drawerHeightSmall,
            installationId: installation.id,
            productId,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
        }, drawerLevel + 1);
    }

    addTag = e => {
        const { tagInput = '' } = this.state;
        const { productStore } = this.props.stores;
        const value = {
            name: tagInput.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const field = 'tags';
        const object = productStore.product;
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            productStore.saveField(object.id, field, value);
            productStore.updateField(object.id, field, [...localValue, value]);
        }
        this.setState({ tagInput: '' });
    }

    checkForEnter = (e) => {
        if (e.key === 'Enter') {
            this.addTag();
        }
    }

    editProductQty = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel = 1 } = this.props;
        const { id: productId, qty = 1 } = e.target.closest('div').dataset;
        const { id } = this.props;
        const { appState, installationStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
        const { installation } = installationStore;

		appState.openDrawer('editInstallationProduct', {
			height: drawerHeightMedium,
            id: installation.id,
            productId,
            callback: this.closeDrawerCallback,
		}, drawerLevel + 1);
    }

    sendOwnerReminder = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = this.props;
        const { productStore } = this.props.stores;
        await productStore.sendOwnerReminder(id);
        this.setState({ ownerReminderSent: true });
        this.loadAll(this.props, false);
    }

    sendOwnerExcuse = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = this.props;
        const { productStore } = this.props.stores;
        await productStore.sendOwnerExcuse(id);
        this.setState({ ownerExcuseSent: true });
        this.loadAll(this.props, false);
    }

    updateProductUrl = async (e, idx = 1) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ [`updatingProductUrl${idx}`]: true });
        const { id } = this.props;
        const { productStore } = this.props.stores;
        await productStore.updateProductUrl(id, idx);
        await this.loadAll(this.props, false);
        this.setState({ [`updatingProductUrl${idx}`]: false });
    }

    updateProductUrl2 = async (e) => {
        this.updateProductUrl(e, 2);
    }

    updateProductUrl3 = async (e) => {
        this.updateProductUrl(e, 3);
    }

    updateProductUrl4 = async (e) => {
        this.updateProductUrl(e, 4);
    }

    updateProductUrl5 = async (e) => {
        this.updateProductUrl(e, 5);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            installationListHelpText,
            productUsageListHelpText,
            productLoanListHelpText,
            titleChildrenProductsText,
            titleParentProductsText,
            drawerLevel = 1,
        } = this.props;
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            id,
            installation,
        } = this.props;
        const {
            sessionid,
            showMenu,
            tagInput,
            ownerReminderSent,
            ownerExcuseSent,
            localProduct,
            updatingProductUrl1,
            updatingProductUrl2,
            updatingProductUrl3,
            updatingProductUrl4,
            updatingProductUrl5,
        } = this.state;
        const { appState, userStore, installationStore, inspectionStore, productStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path, opts = {} } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { inspections } = inspectionStore;
        const { product: currentProduct, installations } = productStore;
        const product = Array.isArray(localProduct) && localProduct[0] ? localProduct[0] : currentProduct;

        const endDate = product.loaner?.endDate ? new Date(product.loaner?.endDate) : new Date();
        const {
            seconds,
            minutes,
            hours,
            days,
            weeks,
            months,
            years,
        } = util.dateDiff(product.loaner?.startDate, endDate);
        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    {isAdmin && <div class={`float-right mr-5`}>
                        <button onClick={this.editProduct} class={`btn btn-sm btn-outline-secondary rounded-pill mr-2`}>
                            <i class={`fa-regular fa-pen mr-2`} />
                            <Text id='product.edit'>Edit product</Text>
                        </button>
                    </div>}

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='product.view'>View product</Text>
                    </div>
                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex px-3'>
                                <ImageScroller images={product.images} stores={this.props.stores} showImg={img => productImg(img, this.props, '1920x')} />
                            </div>
                            <div class={`d-flex flex-row mt-3 mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div
                                    class='box-line-qty text-right'
                                    onClick={this.editProductQty}
                                    data-id={product.id}
                                    data-qty={product.qty}
                                >
                                    {product.qty}
                                </div>
                                <div class={`flex-fill d-flex flex-column px-3 py-3 text-overflow position-relative`}>
                                    <GradientRight stores={this.props.stores} styles={'border-radius: 0 1.0rem 1.0rem 0 !important;'} />

                                    {product.name && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.name'>Name</Text>
                                        </span>
                                        <span class='ml-2'>{product.name}</span>
                                    </div>}

                                    {['sold', 'brand', 'model', 'productNumber', 'serialNumber', 'regNumber', 'description', 'purchaseDate', 'serviceDate', 'totalDistance',
                                        'totalHours', 'currency', 'price', 'latestPrice', 'sku', 'mpn', 'gtin13', 'commodityCode', 'category'].map(key => {
                                        if (!product[key]) {
                                            return '';
                                        }
                                        const field = fields.getField('product', key);
                                        const displayValue = field.displayValue || (value => value);
                                        return (<>
                                            <div class='d-flex flex-row'>
                                                <span class='font-weight-lighter text-muted'>
                                                    {field.title}
                                                </span>
                                                {field.type === 'textarea' ? <>
                                                    <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                        <Markdown markdown={displayValue(product[key])} markedOpts={MARKDOWN_OPTIONS} />
                                                    </div>
                                                </> : <>
                                                    <span class='ml-2'>
                                                        {displayValue(product[key])}
                                                    </span>
                                                </>}
                                            </div>
                                        </>);
                                    })}

                                    {product.url && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`product.url`}>URL</Text>
                                        </span>
                                        <span class='ml-2'>
                                            <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingProductUrl1) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updateProductUrl}>
                                                <i class={`fa-solid ${updatingProductUrl1 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[product.url]} drawerLevel={drawerLevel} />
                                        </span>
                                    </div>}

                                    {product.name2 && <div class='d-flex flex-row mt-3'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.name'>Name</Text> 2
                                        </span>
                                        <span class='ml-2'>{product.name2}</span>
                                    </div>}
                                    {product.url2 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`product.url2`}>URL 2</Text>
                                        </span>
                                        <span class='ml-2'>
                                        <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingProductUrl2) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updateProductUrl2}>
                                                <i class={`fa-solid ${updatingProductUrl2 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[product.url2]} />
                                        </span>
                                    </div>}
                                    {product.description2 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.description'>Description</Text> 2
                                        </span>
                                        <span class='ml-2'>
                                            <Markdown markdown={product.description2} markedOpts={MARKDOWN_OPTIONS} />
                                        </span>
                                    </div>}


                                    {product.name3 && <div class='d-flex flex-row mt-3'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.name'>Name</Text> 3
                                        </span>
                                        <span class='ml-2'>{product.name3}</span>
                                    </div>}
                                    {product.url3 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`product.url3`}>URL 3</Text>
                                        </span>
                                        <span class='ml-2'>
                                        <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingProductUrl3) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updateProductUrl3}>
                                                <i class={`fa-solid ${updatingProductUrl3 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[product.url3]} />
                                        </span>
                                    </div>}
                                    {product.description3 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.description'>Description</Text> 3
                                        </span>
                                        <span class='ml-2'>
                                            <Markdown markdown={product.description3} markedOpts={MARKDOWN_OPTIONS} />
                                        </span>
                                    </div>}

                                    {product.name4 && <div class='d-flex flex-row mt-3'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.name'>Name</Text> 4
                                        </span>
                                        <span class='ml-2'>{product.name4}</span>
                                    </div>}
                                    {product.url4 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`product.url4`}>URL 4</Text>
                                        </span>
                                        <span class='ml-2'>
                                        <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingProductUrl4) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updateProductUrl4}>
                                                <i class={`fa-solid ${updatingProductUrl4 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[product.url4]} />
                                        </span>
                                    </div>}
                                    {product.description4 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.description'>Description</Text> 4
                                        </span>
                                        <span class='ml-2'>
                                            <Markdown markdown={product.description4} markedOpts={MARKDOWN_OPTIONS} />
                                        </span>
                                    </div>}

                                    {product.name5 && <div class='d-flex flex-row mt-3'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.name'>Name</Text> 5
                                        </span>
                                        <span class='ml-2'>{product.name5}</span>
                                    </div>}
                                    {product.url5 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id={`product.url5`}>URL 5</Text>
                                        </span>
                                        <span class='ml-2'>
                                        <button type='button' class={`btn btn-sm btn-${util.isDefined(updatingProductUrl5) ? 'success' : 'secondary'} rounded-pill`} onClick={this.updateProductUrl5}>
                                                <i class={`fa-solid ${updatingProductUrl5 ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                            </button>
                                        </span>
                                        <span class='ml-2'>
                                            <UrlList stores={this.props.stores} urls={[product.url5]} />
                                        </span>
                                    </div>}
                                    {product.description5 && <div class='d-flex flex-row'>
                                        <span class='font-weight-lighter text-muted'>
                                            <Text id='product.description'>Description</Text> 5
                                        </span>
                                        <span class='ml-2'>
                                            <Markdown markdown={product.description5} markedOpts={MARKDOWN_OPTIONS} />
                                        </span>
                                    </div>}

                                    {(product.rating && product.rating) > 0 && <div class='d-flex flex-row mt-3'>
                                        <StarRating rating={product.rating} />
                                        <span class='ml-2'>
                                            {product.rating}
                                        </span>
                                        <span class='ml-2'>
                                            ({product.ratingCount})
                                        </span>
                                    </div>}

                                    <div class='d-flex flex-row justify-content-center'>
                                        <a href={`https://www.google.com/search?q=${product.name}`} target='_blank' rel='noopener noreferrer' class='btn btn-primary rounded-pill'>
                                            <i class='fa-brands fa-google' />
                                        </a>
                                        <a href={`https://www.youtube.com/results?search_query=${product.name}`} target='_blank' rel='noopener noreferrer' class='ml-2 btn btn-danger rounded-pill'>
                                            <i class='fa-brands fa-youtube' />
                                        </a>
                                    </div>

                                </div>
                            </div>

                            {product.product?.review?.length > 0 && <>
                                <div class='font-weight-lighter px-3 mt-4 box-header'>
                                    <Text id='product.reviews'>Product reviews</Text>
                                </div>
                                <div class={`d-flex flex-column mt-1 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <div class='d-flex flex-column w-100'  style='max-height: 25vh; overflow: auto;'>
                                    {product.product?.review?.map(review => {
                                        return (<>
                                            <div class='d-flex flex-row mb-3'>
                                                <span class='font-weight-lighter text-muted w-20'>
                                                    <div dangerouslySetInnerHTML={{ __html: review.author.name }} />
                                                    <div class=''>
                                                        <small>
                                                            {util.isoDate(review.datePublished)}
                                                        </small>
                                                    </div>
                                                </span>
                                                <span class='ml-2 w-80'>
                                                    <strong dangerouslySetInnerHTML={{ __html: review.name }} />
                                                    <div dangerouslySetInnerHTML={{ __html: review.reviewBody }} />
                                                    <div class='d-flex flex-row mt-1'>
                                                        <span>
                                                            <Text id='product.rating'>Rating</Text>: <StarRating rating={review.reviewRating.ratingValue} />
                                                        </span>
                                                    </div>

                                                    {/* <xmp>{JSON.stringify(review, null, 4)}</xmp> */}
                                                </span>
                                            </div>
                                        </>);
                                    })}
                                    </div>
                                </div>
                            </>}

                            <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                {product.files?.length > 0 && <div class='d-flex flex-column'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='product.documentation'>Documentation</Text>
                                    </span>
                                    <div class={`d-flex flex-column`}>
                                        <FileList files={product.files} stores={this.props.stores} />
                                    </div>
                                </div>}

                                <Input
                                    stores={this.props.stores}
                                    holdingStore={productStore}
                                    field='tags'
                                    object={product}
                                    objectName='product'
                                    type='tags'
                                    lookupArray={'existingTags'}
                                />

                            </div>
                            <HelpText>
                                <Text id='product.tag-help'>Add tags to this product to make it easier to find and organize. Tip: Use general tags like: photo, fishing, car, boat...</Text>
                            </HelpText>

                                {/*
                                    TODO: New features:
                                    - Show history for product
                                    - Show loan status for product
                                    - Track usage of product: Hours, distance, position, etc.
                                */}

                            {product.isConsumable && installation?.productsHistory?.length > 0 && <>
                                <div class={`mt-3 d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='product.history'>History</Text>
                                    </span>
                                    {/* <div>
                                        <small>
                                            {installation.productsHistory.filter(p => p.product === product.id).map(history => {
                                                return (<>
                                                    <div class='d-flex flex-row'>
                                                        {util.isoDate(history.date)}: {history.qty}
                                                    </div>
                                                </>);
                                            })}
                                        </small>
                                    </div> */}
                                    <div>
                                        <MiniGraph stores={this.props.stores}
                                            fieldsLeft={[]}
                                            legendLeft={[]}
                                            dataLeft={[...installation.productsHistory.filter(p => p.product === product.id)]}
                                            fieldsRight={[]}
                                            legendRight={[]}
                                            key={`product-history-${product.id}`}
                                        />
                                    </div>
                                </div>
                            </>}

                            {product.status === 'loanedOut' && <>
                                <div class={`mt-3 d-flex flex-column mx-3 rounded-lg px-4 py-3`}>
                                    <span class={`badge badge-pill badge-warning mr-1 mt-2`}>
                                        <Text id='product.loaned-out-to'>Loaned out to</Text> {product.loaner?.firstname} {product.loaner?.lastname} for <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                                    </span>
                                </div>
                            </>}

                        </div>
                    </div>


                </div>
            </div>
        </>);
    }
}

export default Product;
