import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';
import FormFields from '../../components/form/fields';
import PartList from '../../components/part/list';
import PartUsageList from '../../components/part/usageList';
import GradientRight from '../../components/gui/gradientRight';
import HelpText from '../../components/gui/helpText';
import Input from '../../components/form/input';
import FileList from '../../components/fileViewer/list';
import ShootingTarget from '../../components/part/shootingTarget';

function partImg(img, props, size = 's3MediumLink') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = img[size];
    return imgSrc;
}

@withText(props => ({
    titleOtherUsagesByUser: <Text id='part.other-usages-by-user'>Other usages by this user</Text>,

}))
@observer
class ViewUsage extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('usage'),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { usageId } = props;
        const { usageStore } = this.props.stores;
        if (emptyFirst) {
            usageStore.updateKeyValue('usage', {});
        }
        await usageStore.load(usageId, false, { addData: ['parts', 'installations', 'otherPartUsages', 'otherUsages'] });
    }

    editPartUsage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { usageId, drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;

		appState.openDrawer('editUsage', {
			height: drawerHeightMediumLarge,
            usageId,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    addTag = e => {
        const { tagInput = '' } = this.state;
        const { usageStore } = this.props.stores;
        const value = {
            name: tagInput.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const field = 'tags';
        const object = usageStore.usage;
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            usageStore.saveField(object.id, field, value);
            usageStore.updateField(object.id, field, [...localValue, value]);
        }
        this.setState({ tagInput: '' });
    }

    checkForEnter = (e) => {
        if (e.key === 'Enter') {
            this.addTag();
        }
    }

    onImageClick = e => {
        e.preventDefault();
        e.stopPropagation();
        const { appState } = this.props.stores;
        const { src } = e.target.closest('img');
        const { md5, image, largeimage } = e.target.closest('img').dataset;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('imageFullscreen', {
            src:  largeimage || src,
            md5,
            height: drawerHeightLarge,
            showCloseButton: true,
            // showBackButton: true,
            // backButtonText: 'Back',
            imageDetail: this.imageDetail,
        }, drawerLevel + 1);
    }

    imageDetail = (md5) => {
        const { drawerLevel = 1 } = this.props;
        const { sections } = this.state;
        const { usageStore } = this.props.stores;
        const { usage = {} } = usageStore;
        const { images = [] } = usage;
        const image = images.find(img => img.md5 === md5);

        if (!image) {
            return '';
        }

        return (<>
            <div class='px-3 py-2 d-flex flex-column'>

                <div class='d-flex flex-row justify-content-between'>
                    <FormFields
                        stores={this.props.stores}
                        sections={sections}
                        holdingStore={usageStore}
                        objectName={'usage'}
                        isNew={false}
                        drawerLevel={drawerLevel + 1}
                        isAdmin={false}
                        fields={['shotAnalysis', 'shotStrings']}
                        skipSurroundingDivs={true}
                    />
                </div>

                <div class='d-flex flex-row justify-content-between'>
                    {image.format && <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-format'>Format:</Text></span> {image.format}
                    </div>}
                    <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-name'>Name:</Text></span> {image.name}
                    </div>
                    <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-orgsize'>Org size:</Text></span> {image.width} x {image.height} px
                    </div>
                    <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-colorspace'>Colorspace:</Text></span> {image.space}
                    </div>
                    <div class='mx-1'>
                        <span class='font-weight-lighter'><Text id='usage.viewimage-filesize'>Filesize:</Text></span> {util.formatBytes(image.bytes)}
                    </div>
                </div>
            </div>
        </>);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.partId !== this.props.partId) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            drawerLevel = 1,
            titleOtherUsagesByUser,
        } = this.props;
        const { sessionid, showMenu, sections, isNew = false, reminderSent, tagInput = '' } = this.state;
        const { appState, userStore, installationStore, partStore, usageStore } = this.props.stores;
        const { user = {}, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { usage = {}, parts, installations, otherPartUsages, otherUsages, foundList, foundListKeys } = usageStore;

        const endDate = usage.endDate ? new Date(usage.endDate) : new Date();
        const {
            seconds,
            minutes,
            hours,
            days,
            weeks,
            months,
            years,
        } = util.dateDiff(usage.startDate, endDate);

        // Check if any of these fields have data:
        const hasShootingData = ['distanceToTarget', 'totalRounds', 'shootingPosition', 'gunAirPressure',
            'ammunition', 'ammunitionLot', 'ammunitionWeight',
            'shotVelocity', 'shotPlacementX', 'shotPlacementY', 'shotGroup', 'shotAnalysis',
        ].some(key => {
            if (usage[key]) {
                return true;
            }
            return false;
        });

        const hasWeatherData = ['windDirection', 'windSpeed', 'airTemp', 'airPressure', 'airHumidity'].some(key => {
            if (usage[key]) {
                return true;
            }
            return false;
        });

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {isAdmin && <div class={`float-right mr-5`} >
                        <button onClick={this.editPartUsage} class={`btn btn-sm btn-outline-secondary rounded-pill mr-2`}>
                            <i class={`fa-regular fa-pen mr-2`} />
                            <Text id='usage.edit'>Edit usage</Text>
                        </button>
                    </div>}

                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        <Text id='usage.view'>View Usage</Text>
                    </div>
                    <div class={`d-flex flex-column mx-3 p-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex'>
                                {/* {JSON.stringify(usage.} */}
                                <ImageScroller
                                    images={usage.images}
                                    stores={this.props.stores}
                                    showImg={img => partImg(img, this.props, 's3MediumLink')}
                                    showLargeImage={img => partImg(img, this.props, 's3XXLargeLink')}
                                    onImageClick={this.onImageClick}
                                />
                            </div>
                            <div class={`d-flex flex-column rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                                <GradientRight stores={this.props.stores} styles={'border-radius: 0 1.0rem 1.0rem 0 !important;'} />
                                {['title', 'hours', 'distanceMeters', 'comment'].map(key => {
                                    if (!usage[key]) {
                                        return '';
                                    }
                                    const field = fields.getField('usage', key);
                                    const displayValue = field.displayValue || (value => value);
                                    return (<>
                                        <div class='d-flex flex-row'>
                                            <span class='font-weight-lighter text-muted'>
                                                <Text id={`usage.${key}`}>{key}</Text>
                                            </span>
                                            <span class='ml-2'>{displayValue(usage[key])}</span>
                                        </div>
                                    </>);
                                })}
                            </div>
                        </div>
                    </div>

                    <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        {usage.files?.length > 0 && <div class='d-flex flex-column'>
                            <span class='font-weight-lighter text-muted'>
                                <Text id='usage.documentation'>Documentation</Text>
                            </span>
                            <FileList files={usage.files} stores={this.props.stores} />
                        </div>}

                        <Input
                            stores={this.props.stores}
                            holdingStore={usageStore}
                            field='tags'
                            object={usage}
                            objectName='usage'
                            type='tags'
                            lookupArray={'existingTags'}
                        />

                    </div>
                    <HelpText>
                        <Text id='part.usage-tag-help'>Add tags to this part usage to make it easier to find and organize. Tip: Use general tags like: service, trip, hiking...</Text>
                    </HelpText>

                    {hasShootingData && <>
                        <div class='font-weight-lighter px-3 box-header mt-4'>
                            <Text id='usage.shooting-details'>Shooting details</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 p-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <div class='px-0 box-header-info d-flex flex-column'>
                                {/* <div class='d-flex'>
                                    <ImageScroller images={usage.images} stores={this.props.stores} showImg={img => partImg(img, this.props, '1920x')} />
                                </div> */}
                                <div class={`d-flex flex-column rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                                    <GradientRight stores={this.props.stores} styles={'border-radius: 0 1.0rem 1.0rem 0 !important;'} />
                                    {['distanceToTarget', 'totalRounds', 'shootingPosition', 'gunAirPressure',
                                        'ammunition', 'ammunitionLot', 'ammunitionWeight',
                                        'shotVelocity', 'shotPlacementX', 'shotPlacementY', 'shotGroup'].map(key => {
                                        if (!usage[key]) {
                                            return '';
                                        }
                                        const field = fields.getField('usage', key);
                                        const displayValue = field.displayValue || (value => value);
                                        return (<>
                                            <div class='d-flex flex-row'>
                                                <span class='font-weight-lighter text-muted'>
                                                    <Text id={`usage.${key}`}>{key}</Text>
                                                </span>
                                                <span class='ml-2'>{displayValue(usage[key])}</span>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                        <HelpText>
                            <Text id='part.usage-shooting-help'>Data related to shooting precision. Log as much data as you can. We're working on an AI to help you analyze the results.</Text>
                        </HelpText>
                    </>}

                    {usage.shotAnalysis && usage.shotAnalysis.length > 0 && <>
                        <div class='font-weight-lighter px-3 box-header mt-4'>
                            <Text id='usage.shooting-analysis'>Shooting analysis</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 py-3 px-1 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <div class='px-0 box-header-info d-flex flex-column justify-content-center align-items-center'>

                                <ShootingTarget
                                    size={400}
                                    factor={0.16}
                                    shots={usage.shotAnalysis.map(shot => ({
                                        round: shot.round,
                                        type: shot.roundType,
                                        angle: shot.groupPlacement,
                                        score: shot.centerScore,
                                    }))}
                                />

                            </div>
                        </div>
                        <HelpText>
                            <Text id='part.usage-shooting-analysis-help'>Manual shooting analysis. Go through all pictures from your sessions and mark the center hit point for each group. This illustration will display center hit point for all you groups.</Text>
                        </HelpText>
                    </>}


                    {usage.shotStrings && usage.shotStrings.length > 0 && <>
                        <div class='font-weight-lighter px-3 box-header mt-4'>
                            <Text id='usage.shooting-strings'>Shoot Strings</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 py-3 px-1 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <div class='px-0 box-header-info d-flex flex-column justify-content-center align-items-center'>

                                {usage.shotStrings.map(obj => {
                                    return (<>
                                        <div class='d-flex flex-row justify-content-between'>
                                            {obj.round && <><span class='font-weight-lighter ml-2'>Round:</span> {obj.round}<br /></>}
                                            {obj.roundType && <><span class='font-weight-lighter ml-2'>Type:</span> {obj.roundType}<br /></>}
                                            {obj.score && <><span class='font-weight-lighter ml-2'>score:</span> {obj.score}<br /></>}
                                            {obj.shotCount && <><span class='font-weight-lighter ml-2'>shotCount:</span> {obj.shotCount}<br /></>}
                                            {obj.speedHigh && <><span class='font-weight-lighter ml-2'>speedHigh:</span> {obj.speedHigh}<br /></>}
                                            {obj.speedLow && <><span class='font-weight-lighter ml-2'>speedLow:</span> {obj.speedLow}<br /></>}
                                            {obj.speedAvg && <><span class='font-weight-lighter ml-2'>speedAvg:</span> {obj.speedAvg}<br /></>}
                                            {obj.spread && <><span class='font-weight-lighter ml-2'>spread:</span> {obj.spread}<br /></>}
                                            {obj.stdDev && <><span class='font-weight-lighter ml-2'>stdDev:</span> {obj.stdDev}<br /></>}
                                            {obj.groupSize && <><span class='font-weight-lighter ml-2'>groupSize:</span> {obj.groupSize}<br /></>}
                                        </div>
                                    </>);
                                })}

                            </div>
                        </div>
                        <HelpText>
                            <Text id='part.usage-shooting-analysis-help'>Manual shooting analysis. Go through all pictures from your sessions and mark the center hit point for each group. This illustration will display center hit point for all you groups.</Text>
                        </HelpText>
                    </>}

                    {hasWeatherData && <>
                        <div class='font-weight-lighter px-3 box-header mt-4'>
                            <Text id='usage.weather-details'>Weather details</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 p-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <div class='px-0 box-header-info d-flex flex-column'>
                                <div class={`d-flex flex-column rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                                    <GradientRight stores={this.props.stores} styles={'border-radius: 0 1.0rem 1.0rem 0 !important;'} />
                                    {['windDirection', 'windSpeed', 'airTemp', 'airPressure', 'airHumidity'].map(key => {
                                        if (!usage[key]) {
                                            return '';
                                        }
                                        const field = fields.getField('usage', key);
                                        const displayValue = field.displayValue || (value => value);
                                        return (<>
                                            <div class='d-flex flex-row'>
                                                <span class='font-weight-lighter text-muted'>
                                                    <Text id={`usage.${key}`}>{key}</Text>
                                                </span>
                                                <span class='ml-2'>{displayValue(usage[key])}</span>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                        <HelpText>
                            <Text id='part.usage-weather-help'>Data related to weather when this part was used. Log as much data as you can. We're working on an AI to help you analyze the results.</Text>
                        </HelpText>
                    </>}

                    {/* <PartUsageList
                        stores={this.props.stores}
                        title={titleOtherUsagesByUser}
                        usages={otherUsages}
                        classNames='mt-5'
                        hideEmpty={true}
                        view={this.viewPartUsage}
                        callback={this.loadAll}
                        editQty={this.editPartUsage}
                    >
                        <Text id='part.usage-weather-help'>Data related to weather when this part was used. Log as much data as you can. We're working on an AI to help you analyze the results.</Text>
                    </PartUsageList> */}

                </div>
            </div>
        </>);
    }
}

export default ViewUsage;
