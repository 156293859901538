import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import util from 'preact-util';
import { Text, Localizer } from 'preact-i18n';
import linkstate from 'linkstate';

@observer
class AddArrayObject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newObject: {},
            search: '',
            foundList: [],
        };
    }

    addNew = () => {
        const { newObject } = this.state;
        const {
            after = () => {},
            drawerLevel,
        } = this.props;
        const { appState } = this.props.stores;
        after(newObject);
        this.setState({ newObject: {} });
        appState.toggleDrawer(false, drawerLevel);
    };

    selectElement = (e) => {
        const { holdingStore } = this.props;
        const { newObject } = holdingStore;
        const resultObject = { ...newObject };
        const { foundList } = this.state;
        const { idx } = e.target.closest('li').dataset;
        const element = foundList[idx];
        if (element) {
            const keys = Object.keys(element);
            keys.forEach((key) => {
                resultObject[key] = element[key];
            });
            holdingStore.updateKeyValue('newObject', resultObject);
            this.setState({
                search: '',
                foundList: [],
            });
        }
    }

    searchInput = (e) => {
        const search = e.target.value;
        const { newObject } = this.state;
        const {
            fieldObject,
            holdingStore,
        } = this.props;

        const listName = fieldObject.lookupArray;
        let foundListKeys = [];
        let foundList = [];
        if (listName) {
            const list = holdingStore[listName];
            foundListKeys = list[0] ? Object.keys(list[0]) : [];
            const matchKeys = (item, regExp) => {
                return foundListKeys.some((key) => {
                    // console.log('key', key, item[key], regExp.test(item[key]));
                    if (regExp.test(item[key])) {
                        return true;
                    }
                });
            };
            if (list && search) {
                const regExp = new RegExp(search, 'i');
                foundList = list.filter(item => matchKeys(item, regExp)).map(item => {
                    return item;
                });
            }
        }
        // console.log('foundList', foundList)
        this.setState({ search, foundList, foundListKeys });
    }

    render() {
        const { newObject, search, foundList, foundListKeys } = this.state;
        const {
            before = () => {},
            after = () => {},
            isNew,
            field,
            fieldObject,
            type,
            min,
            maxValue,
            step,
            elementtype,
            datalist,
            object,
            holdingStore,
        } = this.props;

        const newValue = parseInt(newObject[field] || newObject.id, 10);
        const listName = fieldObject.lookupArray;
        let found;
        let foundValue;
        if (listName) {
            const list = holdingStore[listName];
            if (list) {
                found = list.find((item) => item.id === newValue);
                foundValue = fieldObject.displayValue && found ? fieldObject.displayValue(found) : JSON.stringify(found, null, 2);
            }
        }
        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 20px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(datalist, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(holdingStore, null, 4)}</xmp> */}
                    <div class={`d-flex justify-content-start flex-row`}>
                        <div>
                            <h5 class='ml-3 mb-3'><Text id='form.add'>Add</Text> {field}</h5>
                        </div>
                        <div class='ml-3'>
                            <input
                                class='inputForm form-control-plaintext py-0 w-100'
                                type='text'
                                placeholder={'search'}
                                onInput={this.searchInput}
                            />
                        </div>
                    </div>
{/* fieldObject: <xmp>{JSON.stringify(fieldObject, null, 4)}</xmp>
newValue: <xmp>{JSON.stringify(newValue, null, 4)}</xmp>
foundValue: <xmp>{JSON.stringify(foundValue, null, 4)}</xmp> */}

                    <div class={`bg-light form-group d-flex flex-column my-0 py-1 px-3`}>
                        <div
                            class='rounded rounded-lg position-relative px-2 py-1 flex-fill'
                            style='border-radius: 10px !important;'
                        >
                            {foundList && foundList.length > 0 && <>
                                <ul>
                                    {foundList.map((el, idx) => (<>
                                        <li data-idx={idx} onClick={this.selectElement}>
                                            {foundListKeys && foundListKeys.length > 0 && foundListKeys.map(field => (<>
                                                <span class='mr-2'><span class='font-weight-lighter text-muted'>{field}:</span> {el[field]}</span>
                                            </>))}
                                        </li>
                                    </>))}
                                </ul>
                            </>}

                            {datalist && datalist.length > 0 ? datalist.map(el => (<>
                                <input
                                    type={type}
                                    class={`inputForm form-control-plaintext py-1 ${type === 'number' || type === 'date' || type === 'time' ? 'text-center' : ''} `}
                                    id={`${field}Input`}
                                    aria-describedby={`${field}Help`}
                                    min={min}
                                    max={maxValue}
                                    step={step}
                                    name={field}
                                    value={newObject[el]}
                                    data-id={object.id}
                                    data-field={field}
                                    data-type={type}
                                    data-elementtype={elementtype}
                                    onInput={linkstate(this, `newObject.${el}`)}
                                    placeholder={el}
                                />
                            </>)) : <>
                                <input
                                    type={type}
                                    class={`inputForm form-control-plaintext py-1 ${type === 'number' || type === 'date' || type === 'time' ? 'text-center' : ''} `}
                                    id={`${field}Input`}
                                    aria-describedby={`${field}Help`}
                                    min={min}
                                    max={maxValue}
                                    step={step}
                                    name={field}
                                    value={newObject[field]}
                                    data-id={object.id}
                                    data-field={field}
                                    data-type={type}
                                    data-elementtype={elementtype}
                                    onInput={linkstate(this, `newObject.${field}`)}
                                    placeholder={field}
                                />
                            </>}
                        </div>
                        <div class='text-muted'>
                            <small>
                                {foundValue}
                            </small>
                        </div>
                    </div>

                    <div class='w-100 bg-light d-flex flex-column justify-content-start py-3 px-3'>
                        <button type='button' class='btn btn-primary' onClick={this.addNew}><Text id='form.add'>Add</Text> {field}</button>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default AddArrayObject;
