import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class MailSentStore extends LocalModel {
    constructor() {
        super('mailSent', {
            namePlural: 'mailSentList',
            namePluralReal: 'mailSent',
            sort: '-createdDate',
            limit: 500,
            api: {
                search: {
                    url: '/api/mailsent/',
                    params: {
                        limit: 500,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/mailsent/',
                    params: {},
                },
                save: {
                    url: '/api/mailsent/',
                    params: {},
                },
                delete: {
                    url: '/api/mailsent/',
                    params: {},
                },
            },
        });
    }

    @observable newMailSent = {};

    @observable mailSent = {};

    @observable mailSentList = [];

    @observable users = [];
}

const store = new MailSentStore();
export default store;
