import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Versions from '../../components/versions';

@withText(props => ({
    firstname: <Text id='input.firstname-help'>Firstname</Text>,
    lastname: <Text id='input.lastname-help'>Lastname</Text>,
    email: <Text id='input.email-help'>Email</Text>,
    'settings.darkmode': <Text id='input.settings.darkmode-help'>Use darkmode</Text>,
}))
@observer
class NewUser extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('user'),
        };
    }

    createUser = async () => {
        const { callback = () => {} } = this.props;
        const { userStore } = this.props.stores;
        const { newUser } = userStore;
        const response = await userStore.adminCreate(newUser);
        if (response.status === 201) {
            callback(response?.data?.user);
        }
    }

    loadAll = async () => {
        const { idx, id, isNew } = this.props;
        const { userStore } = this.props.stores;
        if (!isNew) {
            // userStore.adminSetFromIdx(idx);
            // userStore.adminSetFromId(id);
            // TODO: Do not overwrite userStore.user!
            if (id) {
                const idInt = parseInt(id, 10);
                const response = await userStore.adminLoad({ id: idInt }, { skipUpdate: true });
                const adminList = response?.data;
                const obj = adminList[0];
                userStore.updateKeyValue('adminUser', obj);
            }
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections } = this.state;
        const { userStore } = this.props.stores;
        // const { user, isAdmin, newUser } = userStore;
        // const darkmode= util.getNestedValue(user, 'settings.darkmode');
        // const image = localUtil.getImage({ user });
        // const name = localUtil.displayName(user, true);

        const { user } = userStore;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <Versions stores={this.props.stores} type='user' id={user.id} key={`admin-user-${user.id}`} />

                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newUser, null, 4)}</xmp> */}
                    <h5 class='ml-3 mb-3'>
                        {isNew ? <Text id='user.new'>New user</Text> : <Text id='user.edit'>Edit user</Text>}
                    </h5>
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields stores={this.props.stores} sections={sections} holdingStore={userStore} objectName={isNew ? 'newUser' : 'adminUser'} isNew={isNew} drawerLevel={drawerLevel} isAdmin={true} />
                    </div>
                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createUser}>
                            {isNew ? <Text id='user.create'>Create user</Text> : <Text id='user.save'>Save user</Text>}
                        </button>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default NewUser;
