import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class GpsDeviceStore extends LocalModel {
    constructor() {
        super('gpsDevice', {
            namePlural: 'gpsDevices',
            namePluralReal: 'gpsDevices',
            sort: 'timestamp',
            limit: 500,
            api: {
                search: {
                    url: '/api/gpsdevices/',
                    params: {
                        limit: 15,
                        sort: 'timestamp',
                    },
                },
                load: {
                    url: '/api/gpsdevices/',
                    params: {},
                },
                save: {
                    url: '/api/gpsdevices/',
                    params: {},
                },
                delete: {
                    url: '/api/gpsdevices/',
                    params: {},
                },
            },
        });
    }

    @observable newGpsDevice = {};

    @observable gpsDevice = {};

    @observable gpsDevices = [];

}

const store = new GpsDeviceStore();
export default store;
