import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';

function installationImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
}))
@observer
class InspectInstallation extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('installation'),
        };
    }

    createInspection = async () => {
        const { callback = () => {}, id } = this.props;
        const { appState, installationStore, inspectionStore } = this.props.stores;
        const { newObject } = inspectionStore;
        const response = await installationStore.addInspection({
            id,
            newInspection: newObject,
        });
        inspectionStore.updateKeyValue('newObject', {});
        appState.toggleDrawer();
    }

    componentDidMount() {
        const { idx, isNew } = this.props;
        const { installationStore } = this.props.stores;
        if (!isNew) {
            installationStore.adminSetFromIdx(idx);
        }
    }

    onInput = e => {
        const { inspectionStore } = this.props.stores;
        const { newObject } = inspectionStore;
        const { name, value } = e.target;
        inspectionStore.updateObjectKeyValue('newObject', name, value);
    }

    onCheckboxInput = (e) => {
        const { value, name, description } = e.target.closest('button').dataset;
        const { inspectionStore } = this.props.stores;
        const { newObject } = inspectionStore;
        const inspections = newObject.inspections ? [...newObject.inspections] : [];

        // Pull out existing inspection with same name
        const idx = inspections.findIndex(e => e.name === name);
        if (idx > -1) {
            inspections.splice(idx, 1);
        }

        inspections.push({
            date: new Date(),
            md5: md5(`${name}-${value}`).toString(),
            name,
            description,
            value,
        });
        inspectionStore.updateObjectKeyValue('newObject', 'inspections', inspections);
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections } = this.state;
        const { appState, userStore, installationStore, inspectionStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const { installation, requiredInspections } = installationStore;
        const { newObject } = inspectionStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newObject, null, 4)}</xmp> */}

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-4'>

                            {requiredInspections && requiredInspections.length > 0 && <div class='w-100 d-flex flex-column mt-0'>
                                <div class='font-weight-lighter px-3 box-header'>
                                    <Text id='inspection.inspection-points'>Inspection points</Text>
                                </div>
                                <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    {requiredInspections?.length === 0 && <>
                                        <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                                            <div class='w-100 d-flex flex-row justify-content-center'>
                                                <div class='box-line-add'>
                                                    <i class='fa-solid fa-circle-plus' />
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                    {requiredInspections?.map((line, lineIdx) => {
                                        return (<>
                                            <div class='w-100 d-flex flex-column border-bottom pb-4'>
                                                <div class='mb-3'>
                                                    {line.images && line.images[0] && <>
                                                        <div class='box-line-image-large position-relative'>
                                                            <img src={`${line.images[0].s3LargeLink}`} class='rounded-lg img-fluid' />
                                                        </div>
                                                    </>}
                                                </div>
                                                {[...Array(5)].map((x, i) => {
                                                    const name = `${line.partNumber}-${lineIdx}-${i}`;
                                                    const value = newObject.inspections?.find(x => x.name === name)?.value;
                                                    const description = line[`check${i + 1}`];
                                                    if (line[`check${i + 1}`]) {
                                                        return (<>
                                                            <div class='w-100 d-flex flex-row mx-3 my-1'>
                                                                <button
                                                                    class={`btn btn-sm ${value === 'ok' ? 'btn-success' : 'btn-outline-secondary'}`}
                                                                    data-name={name}
                                                                    data-value={'ok'}
                                                                    data-description={description}
                                                                    onClick={this.onCheckboxInput}
                                                                >
                                                                    <i class='fa-solid fa-check' />
                                                                </button>
                                                                <button
                                                                    class={`btn btn-sm ${value === 'not ok' ? 'btn-danger' : 'btn-outline-secondary'} ml-2`}
                                                                    data-name={name}
                                                                    data-value={'not ok'}
                                                                    data-description={description}
                                                                    onClick={this.onCheckboxInput}
                                                                    >
                                                                        <i class='fa-solid fa-xmark' />
                                                                    </button>
                                                                <div class='ml-2'>
                                                                    {line[`check${i + 1}`]}
                                                                </div>

                                                                {/* <div class='form-check'>
                                                                    <input
                                                                        class='form-check-input'
                                                                        type='checkbox'
                                                                        value={line[`check${i + 1}`]}
                                                                        id={`partNumber-${line.partNumber}-${lineIdx}-${i}`}
                                                                        name={`${line.partNumber}-${lineIdx}-${i}`}
                                                                        onInput={this.onCheckboxInput}
                                                                    />
                                                                    <label class='form-check-label' for={`partNumber-${line.partNumber}-${lineIdx}-${i}`}>
                                                                        {line[`check${i + 1}`]}
                                                                    </label>
                                                                </div> */}
                                                            </div>
                                                        </>);
                                                    }
                                                })}
                                                {/* <xmp>{JSON.stringify(line, null, 4)}</xmp> */}
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>}

                            <div class='font-weight-lighter px-3 box-header mt-5'>
                                <Text id='inspection.inspection-images'>Inspection images</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <Input
                                    stores={this.props.stores}
                                    holdingStore={inspectionStore}
                                    objectName={'newObject'}
                                    object={newObject}
                                    type={'image'}
                                    field={'image'}
                                    name={'images'}
                                    value={newObject['images']}
                                    title={'Image'}
                                    placeholder={'Image'}
                                    onInput={this.onInput}
                                />
                            </div>

                            <div class='font-weight-lighter px-3 box-header mt-5'>
                                <Text id='inspection.inspection-notes'>Inspection notes</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <Input
                                    stores={this.props.stores}
                                    holdingStore={inspectionStore}
                                    objectName={'newObject'}
                                    object={newObject}
                                    type={'textarea'}
                                    field={'description'}
                                    name={'description'}
                                    value={newObject['description']}
                                    title={'Notes'}
                                    placeholder={'notes from the inspection'}
                                    onInput={this.onInput}
                                />
                            </div>

                        </div>
                    </div>

                    <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-lg btn-primary rounded-pill' onClick={this.createInspection}>
                            <Text id='inspection.save'>Save inspection</Text>
                        </button>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default InspectInstallation;
