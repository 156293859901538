import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class CalendarAvailabilityStore extends LocalModel {
    constructor() {
        super('calendarAvailability', {
            namePlural: 'calendarAvailabilities',
            namePluralReal: 'calendarAvailabilities',
            sort: '-createdDate',
            limit: 10000,
            api: {
                search: {
                    url: '/api/calendaravailabilities/',
                    params: {
                        limit: 10000,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/calendaravailabilities/',
                    params: {},
                },
                save: {
                    url: '/api/calendaravailabilities/',
                    params: {},
                },
                delete: {
                    url: '/api/calendaravailabilities/',
                    params: {},
                },
            },
        });
    }

    @observable newCalendarAvailability = {};

    @observable calendarAvailability = {};

    @observable calendarAvailabilities = [];

    @observable calendars = [];

    @observable animals = [];

    @observable visitors = [];

    @observable clientHours = [];

    @observable adminAnimals = [];

    @observable totalHours = [];

    @observable animalBookings = [];

    getEvents(date, calendar) {
        const { calendarAvailabilities } = this;
        const start = new Date(date);
        const end = new Date(date);
        // Set end to 59 minutes and 59 seconds
        end.setMinutes(end.getMinutes() + 59);
        end.setSeconds(end.getSeconds() + 59);

        const events = calendarAvailabilities
            .filter(e => calendar === e.calendar)
            .filter(e => {
                const eventStart = new Date(e.start);
                // const eventEnd = new Date(e.end);
                return eventStart >= start && eventStart <= end;
            });
        return events;
    }

    getEventsForCalendar(date, calendar) {
        const { calendarAvailabilities } = this;
        const start = new Date(date);
        const end = new Date(date);
        // Set end to 59 minutes and 59 seconds
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        const events = calendarAvailabilities
            .filter(e => calendar === e.calendar)
            .filter(e => {
                const eventStart = new Date(e.start);
                // const eventEnd = new Date(e.end);
                return eventStart >= start && eventStart <= end;
            });
        return events;
    }

    getAvailableHours(date, calendar) {
        const { calendarAvailabilities } = this;
        const start = new Date(date);
        const end = new Date(date);
        // Set end to 59 minutes and 59 seconds
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);

        const events = calendarAvailabilities
            .filter(e => calendar === e.calendar)
            .filter(e => {
                const eventStart = new Date(e.start);
                // const eventEnd = new Date(e.end);
                return eventStart >= start && eventStart <= end;
            });

        let startHour = null;
        let endHour = null;
        events.forEach(e => {
            const eventStart = new Date(e.start);
            const eventEnd = new Date(e.end);
            if (startHour === null || eventStart < startHour) {
                startHour = eventStart;
            }
            if (endHour === null || eventEnd > endHour) {
                endHour = eventEnd;
            }
        });
        return {
            startHour: startHour ? startHour.getHours() : null,
            startMinute: startHour ? startHour.getMinutes() : null,
            endHour: endHour ? endHour.getHours() : null,
            endMinute: endHour ? endHour.getMinutes() : null,
        };
    }

    @action
    isOverlapping() {
        const { calendarAvailabilities } = this;
        calendarAvailabilities.forEach(e => {
            e.overlapping = false;
            const start = new Date(e.start);
            const end = new Date(e.end);
            const events = calendarAvailabilities
                .filter(e2 => e.calendar === e2.calendar)
                .filter(e2 => e2.id !== e.id)
                .filter(e2 => {
                    // Find overlapping events for this event
                    const eventStart = new Date(e2.start);
                    const eventEnd = new Date(e2.end);
                    const isOverlapping = (start >= eventStart && start <= eventEnd) || (end >= eventStart && end <= eventEnd);
                    return isOverlapping;
                });
            if (events.length > 0) {
                e.overlapping = true;
            }
        });
    }
}

const store = new CalendarAvailabilityStore();
export default store;
