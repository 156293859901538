import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
}))
@observer
class EditInstallationPart extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('installationPart'),
        };
    }

    loadAll = () => {
        const { installationStore } = this.props.stores;
        const { installation } = installationStore;
        const { id, partId } = this.props;

        const installationPart = installation.parts.find(p => p.id === parseInt(partId, 10));
        installationStore.updateKeyValue('installationPart', {
            ...installationPart,
        });
    }

    editPart = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { id, partId, callback = () => {} } = this.props;
		const { installationStore } = this.props.stores;
        const { installationPart } = installationStore;

        await installationStore.editPart({
            id: parseInt(id, 10),
            partId: parseInt(partId, 10),
            data: {
                ...installationPart,
            }
        });
        callback();
    }

    componentDidMount() {
        // const { idx, isNew, qty } = this.props;
        // this.setState({ qty });
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, lookupArray } = this.props;
        const { sections, qty } = this.state;
        const { userStore, installationStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { installationPart } = installationStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={installationStore}
                            objectName={'installationPart'}
                            isNew={true}
                            drawerLevel={drawerLevel}
                            lookupArray={lookupArray}
                        />
                    </div>

                    <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.editPart}>
                            <Text id='installation.save-part'>Save part</Text>
                        </button>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default EditInstallationPart;
