import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

const initialState = {};

@observer
class GradientRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    render() {
        const {
            height = '100%',
            width = '40px',
            styles = '',
        } = this.props;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        let colorFrom = 'rgba(255,255,255,0)';
        let colorTo = 'rgba(255,255,255,1)';
        if (darkmode) {
            colorFrom = 'rgba(25, 29, 33,0)';
            colorTo = 'rgba(25, 29, 33,1)';
        }

        return (
            <div
                class='position-absolute'
                style={`
                    top: 0;
                    right: 0;
                    height: ${height};
                    width: ${width};
                    background-image: linear-gradient(to right, ${colorFrom}, ${colorTo});
                    max-height: 100%;
                    ${styles}
                `}
            >
                &nbsp;
            </div>
        );
    }
}

export default GradientRight;
