import { h, Component } from 'preact';
import { observer } from 'mobx-preact';

import PubSub, { topics } from '../../lib/pubsub';

import style from '../header/style.css';

@observer
class Progress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            display: 'none',
        };
        this.subscriptions();
    }

    subscriptions() {
        PubSub.subscribe(topics.LOADING_PROGRESS, (progress) => {
            // console.log('progress', progress)
            if (progress === 100) {
                // Hide bar white we go back to 0;
                clearTimeout(this.timerAnimateId);
                this.timerAnimateId = setTimeout(() => {
                    this.setState({ display: 'none' });
                }, 700);
                // Set bar to go to 0:
                clearTimeout(this.timerResetId);
                this.timerResetId = setTimeout(() => {
                    this.setState({
                        progress: 0,
                        display: 'block',
                    });
                }, 1000);
            }
            if (progress === 0) {
                this.setState({ display: 'block' });
            }
            this.setState({
                progress,
            });
        });
    }

    render() {
        const { scrolledDown } = this.props;
        const { progress, display } = this.state;

        const { appState } = this.props.stores;
        const { darkmode, viewmode, path } = appState;

		const regexWorkoutTracking = /^\/workouts\/tracking/;
        if (regexWorkoutTracking.test(path)) {
			return <></>;
		}

        return (
            <div
                class='progress fixed-top '
                style={`
                    height: 2px;
                    margin-top: ${scrolledDown > 0 ? style.scrolledDown : style.scrolledUp};
                    z-index: 9999999;
                    transition: all 0.3s ease-in-out;
                    background-color: transparent;
                `}
            >
                <div
                    class='progress-bar bg-primary'
                    role='progressbar'
                    aria-valuenow={progress}
                    aria-valuemin='0'
                    aria-valuemax='100'
                    style={`
                        width: ${progress}%;
                        display: ${display};
                    `}
                />
            </div>
        );
    }
}

export default Progress;
