import { h, Component } from 'preact';
import util from 'preact-util';
import { observer, createRef } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import Hls from 'hls.js';

import localUtil from '../../lib/util';

import VideoStreamingPlayer from '../../routes/videostreaming/player';
import ImageScroller from '../../components/imagescroller';


function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

@observer
class VideoStreamingLoggedIn extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
        this.playerRef = null;
        this.hls = null;
    }

    loadAll = async () => {
        const { limit = 25, active = 1, frontpage, raceId } = this.props;
        const { appState, userStore, streamStore, raceStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const { isRaceAdmin, isMediaWorker } = raceStore;
        const isWorkingWithMedia = isAdmin || isRaceAdmin || isMediaWorker;
        await streamStore.load({
            query: {
                race: raceId,
                active,
                frontpage,
            },
            url: isWorkingWithMedia ? null : '/api/streams/public/',
            limit,
        });
        const { streams } = streamStore;
        const currentStream = streams[0] || {};

        this.setState({
            currentStream,
            videoStream: currentStream.id,
            videoStreamUrl: currentStream.streamUrl,
        });
    }

    setStream = async (e) => {
        const { id } = e.target.closest('.video-box').dataset;
        const idInt = parseInt(id, 10);
        const { streamStore } = this.props.stores;
        const { streams } = streamStore;
        const currentStream = streams.find((stream) => stream.id === idInt);

        this.setState({
            currentStream,
            videoStream: idInt,
            videoStreamUrl: currentStream.streamUrl,
        });
    }

    goToStream = (e) => {
        const { id } = e.target.closest('.video-box').dataset;
        const { raceId } = this.props;
        route(`/race/${raceId}/streaming/${id}`);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { showPadding = true, classNames, header } = this.props;
        const { videoStreamUrl, videoStream, currentStream = {} } = this.state;
        const { appState, userStore, streamStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { streams } = streamStore;
        const startDate = new Date(currentStream.start);
        const endDate = new Date(currentStream.end);
        const now = new Date();
        const isStreaming = currentStream.start && currentStream.end && startDate < now && endDate > now;

        if (streams.length === 0) {
            return <></>;
        }

        return (<>
            <div class={`w-100 d-flex flex-column justify-content-center ${classNames}`}>

                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                    {header}
                </div>

                <div class='w-100 h-100'>

                    {currentStream?.id && <div class={`w-100 d-flex flex-column ${showPadding ? 'mt-2' : ''}`}>
                        <div
                            class='px-0 box-header-info d-flex flex-column video-box'
                            onClick={this.goToStream}
                            data-id={currentStream.id}
                        >
                            <div class={`d-flex ${showPadding ? 'px-3 py-3' : 'pb-1'} rounded-lg`}>
                                {currentStream.images && currentStream.images.length > 0 ? <>
                                    <ImageScroller images={currentStream.images} stores={this.props.stores} showImg={img => partImg(img, this.props, '1920x')} />
                                </> : <>
                                    <div class='d-flex flex-column justify-content-center align-items-center w-100 border rounded-lg' style='aspect-ratio: 21 / 9;'>
                                        <i class='fa-solid fa-film text-muted fa-5x' />
                                    </div>
                                </>}
                            </div>
                            <div class={`d-flex flex-column ${showPadding ? 'mx-3' : ''} rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='d-flex flex-column mb-0 font-weight-bold'>
                                    {currentStream.name}
                                </div>
                                <div class='d-flex flex-column mb-1 font-weight-normal'>
                                    {util.formatDate(currentStream.start, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })} - {util.formatDate(currentStream.end, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                                </div>
                                {currentStream.description && <div class='d-flex flex-column'>
                                    <Markdown markdown={localUtil.replaceImages(currentStream.description, currentStream.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                </div>}
                            </div>
                        </div>
                    </div>}

                    {streams && streams.length > 1 && <div class='w-100 d-flex flex-wrap px-3 mt-2'>
                        {streams.map((stream, idx) => {
                            const sd = new Date(stream.start);
                            const ed = new Date(stream.end);
                            const isLive = stream.start && stream.end && sd < now && ed > now;
                            return <>
                                <div
                                    class={`d-flex flex-column mb-3 mr-3 px-2 py-2 ${videoStream === stream.id ? 'border' : ''} ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg video-box`}
                                    style={`width: 295px; height: 200px;`}
                                    onClick={this.goToStream}
                                    data-id={stream.id}
                                >
                                    <div class='d-flex flex-row mb-2 rounded-lg bg-light overflow-hidden' style='height: 150px;'>
                                        {stream.images && stream.images.length > 0 ? <>
                                            <ImageScroller images={stream.images} stores={this.props.stores} showImg={img => partImg(img, this.props, '400x')} />
                                        </> : <>
                                            <div class='d-flex flex-column justify-content-center align-items-center w-100'>
                                                <i class='fa-solid fa-film text-muted fa-3x' />
                                            </div>
                                        </>}
                                    </div>
                                    <div>
                                        {isLive && <>
                                            <div class='spinner-grow spinner-grow-sm text-danger mr-2' role='status'>
                                                <span class='sr-only'>Live</span>
                                            </div>
                                        </>}
                                        {stream.name}
                                    </div>
                                    <small class='text-muted'>
                                        {util.formatDate(stream.start, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                                    </small>
                                </div>
                            </>;
                        })}
                    </div>}

                </div>
            </div>
        </>);
    }
}

export default VideoStreamingLoggedIn;
