import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ShotTrainerStore extends LocalModel {
    constructor() {
        super('calendar', {
            namePlural: 'calendars',
            namePluralReal: 'calendars',
            sort: 'name',
            limit: 100,
            api: {
                search: {
                    url: '/api/calendars/',
                    params: {
                        limit: 15,
                        sort: 'name',
                    },
                },
                load: {
                    url: '/api/calendars/',
                    params: {},
                },
                save: {
                    url: '/api/calendars/',
                    params: {},
                },
                delete: {
                    url: '/api/calendars/',
                    params: {},
                },
            },
        });
        this.audioContext = null;
        this.soundsLoaded = {};
        this.sounds = {
            loadingSound: '../../assets/sounds/loading-1sec.m4a',
            shooterReadySound: '../../assets/sounds/shooter-ready.m4a',
            readySound: '../../assets/sounds/ready.m4a',
            notReadySound: '../../assets/sounds/not-ready.m4a',
            fireSound: '../../assets/sounds/fire.m4a',
            stopShootingSound: '../../assets/sounds/stop-shooting.m4a',
            emptyGunSound: '../../assets/sounds/empty-gun-ready-for-inspection.m4a',
            emptyGunCheckEachOtherSound: '../../assets/sounds/empty-gun-check-each-other.m4a',
            secureGunSound: '../../assets/sounds/secure-gun.m4a',
            nextStageSound: '../../assets/sounds/next-stage.m4a',
            inspectTargetSound: '../../assets/sounds/inspect-targets.m4a',
            getReadyForShooting: '../../assets/sounds/get-ready-for-shooting.m4a',
            loadAmmo: '../../assets/sounds/load-ammo.m4a',
            silence: '../../assets/sounds/silence.m4a',
        };
    }

    @action
    initializeAudioContext = () => {
        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
            Object.keys(this.sounds).forEach(key => {
                if (!this.soundsLoaded[key]) {
                    const sound = new Audio(this.sounds[key]);
                    sound.load();
                    this.soundsLoaded[key] = sound;
                }
            });
        }
    }

    playSound = (sound) => {
        if (this.audioContext && sound) {
            sound.play().catch((error) => {
                console.error('Error playing sound:', error);
            });
        }
    }

    playSilentAudio = () => {
        console.log('playSilentAudio')
        this.playSound(this.soundsLoaded.silence);
    }

    startSilentAudio = () => {
        if (this.silentAudioActive) {
            return;
        }
        this.silentAudioActive = true;
        this.keepConnectionAliveInterval = setInterval(this.playSilentAudio, 10000); // Adjust the interval as needed
    }

    stopSilentAudio = () => {
        this.silentAudioActive = false;
        clearInterval(this.keepConnectionAliveInterval);
    }

    @observable fieldExercise = {
        shootingTime: 12,
        loadingTime: 60,
        readyTime: 5,
        fireTime: 5,
        stopTime: 2,
        norgesFelt: false,
        interval: 0,
        repeat: 0,
        name: '',
    };

    // @action
    // calculate(fieldToCalculate) {
    //     const { shotCalculation } = this;
    //     const { distance, mil, sizeOfTarget } = shotCalculation;
    //     if (fieldToCalculate === 'sizeOfTarget') {
    //         shotCalculation.sizeOfTarget = 100 * (distance * mil) / 1000;
    //     }
    //     if (fieldToCalculate === 'mil') {
    //         shotCalculation.mil = 1000 * (sizeOfTarget / 100) / distance;
    //     }
    //     if (fieldToCalculate === 'distance') {
    //         shotCalculation.distance = 1000 * (sizeOfTarget / 100) / mil;
    //     }
    // }

    @observable loadingTime = null;
    @observable fireTime = null;
    @observable readyTime = null;
    @observable shootingTime = null;
    @observable series = 1;

    @observable timerRef = null;

    @observable shootingInProgress = false;
    @observable shootingDone = false;
    @observable commands = {
        startExercise: false,
        emptyGun: false,
        secureGun: false,
        inspectTargets: false,
        emptyGunCheckEachOther: false,
        nextStage: false,
    };
    @observable useShooterReady = true;

    @observable useFireSound = true;
    @observable useStopShootingSound = true;

    @action
    updateLoadingTime(value) {
        this.loadingTime = value;
    }

    @action
    updateShootingTime(value) {
        this.shootingTime = value;
    }

    @action
    updateSeriesTime(value) {
        this.series = value;
    }

    @action
    updateReadyTime(value) {
        this.readyTime = value;
    }

    @action
    updateFireTime(value) {
        this.fireTime = value;
    }

    @action
    setShootingTime({ time, interval, repeat, name }) {
        this.updateObjectKeyValue('fieldExercise', 'shootingTime', parseInt(time, 10));
        this.updateObjectKeyValue('fieldExercise', 'interval', parseInt(interval, 10));
        this.updateObjectKeyValue('fieldExercise', 'fireTime', parseInt(interval, 10));
        this.updateObjectKeyValue('fieldExercise', 'repeat', parseInt(repeat, 10));
        this.updateObjectKeyValue('fieldExercise', 'name', name);
        this.useShooterReady = false;
        this.useFireSound = false;
        this.useStopShootingSound = false;
    }

    getReadyForShooting() {
        this.playSound(this.soundsLoaded.getReadyForShooting);
        this.updateObjectKeyValue('commands', 'getReadyForShooting', true);
    }

    loadAmmo() {
        this.playSound(this.soundsLoaded.loadAmmo);
        this.updateObjectKeyValue('commands', 'loadAmmo', true);
    }

    emptyGun() {
        this.playSound(this.soundsLoaded.emptyGunSound);
        this.updateObjectKeyValue('commands', 'emptyGun', true);
    }

    emptyGunInspectEachOther() {
        this.playSound(this.soundsLoaded.emptyGunCheckEachOtherSound);
        this.updateObjectKeyValue('commands', 'emptyGunCheckEachOther', true);
    }

    secureGun() {
        this.playSound(this.soundsLoaded.secureGunSound);
        this.updateObjectKeyValue('commands', 'secureGun', true);
    }

    nextStage() {
        this.playSound(this.soundsLoaded.nextStageSound);
        this.updateObjectKeyValue('commands', 'nextStage', true);
    }

    inspectTargets() {
        this.playSound(this.soundsLoaded.inspectTargetSound);
        this.updateObjectKeyValue('commands', 'inspectTargets', true);
    }

    @action
    prepareExercise() {
        this.initializeAudioContext();

        this.timerRef = setTimeout(() => {
            this.getReadyForShooting();
            this.startSilentAudio();
        }, 500);
    }

    @action
    getReadyForExercise() {
        this.initializeAudioContext();

        this.timerRef = setTimeout(() => {
            this.loadAmmo();
            this.startSilentAudio();
        }, 500);
    }

    @action
    startExercise() {
        this.updateObjectKeyValue('commands', 'startExercise', true);
        this.initializeAudioContext();

        this.timerRef = setTimeout(() => {
            this.startLoadingTimer();
            this.startSilentAudio();
        }, 500);
    }

    @action
    startLoadingTimer() {
        const { fieldExercise } = this;
        const { loadingTime } = fieldExercise;
        this.shootingInProgress = true;

        let time = loadingTime;
        this.updateLoadingTime(time);
        this.playSound(this.soundsLoaded.loadingSound);
        this.timerRef = setInterval(() => {
            time--;
            this.updateLoadingTime(time);
            if (time === 0) {
                clearInterval(this.timerRef);
                if (this.useShooterReady) {
                    this.startReadyTimer();
                } else {
                    this.startFireTimer();
                }
                // PubSub.publish(topics.shotTrainer.loadingTimeOver);
            }
        }, 1000);
    }

    @action
    startReadyTimer(playSound = true) {
        const { fieldExercise } = this;
        const { readyTime } = fieldExercise;

        let time = readyTime;
        this.updateReadyTime(time);
        if (playSound) {
            this.playSound(this.soundsLoaded.shooterReadySound);
        }
        this.timerRef = setInterval(() => {
            time--;
            this.updateReadyTime(time);
            if (time === 0) {
                clearInterval(this.timerRef);
                this.startFireTimer();
            }
        }, 1000);
    }

    @action
    notReady() {
        clearInterval(this.timerRef);
        this.playSound(this.soundsLoaded.notReadySound);
        this.updateReadyTime(null);
        this.updateObjectKeyValue('fieldExercise', 'readyTime', 15);
        this.startReadyTimer(false);
    }

    @action
    startFireTimer(useFireSound = true) {
        const { fieldExercise } = this;
        const { fireTime } = fieldExercise;

        let time = fireTime;
        this.updateFireTime(time);
        if (useFireSound) {
            this.playSound(this.soundsLoaded.readySound);
        }
        this.timerRef = setInterval(() => {
            time--;
            this.updateFireTime(time);
            if (time === 0) {
                clearInterval(this.timerRef);
                this.startShootingTimer();
            }
        }, 1000);
    }

    @action
    startShootingTimer() {
        const { fieldExercise } = this;
        const { shootingTime, stopTime, repeat } = fieldExercise;

        let time = shootingTime;
        this.updateShootingTime(time);
        if (this.useFireSound) {
            this.playSound(this.soundsLoaded.fireSound);
        }
        this.timerRef = setInterval(() => {
            time--;
            this.updateShootingTime(time);
            if (time === 0 && this.series < repeat) {
                clearInterval(this.timerRef);
                this.updateShootingTime(null);
                this.updateSeriesTime(this.series + 1);
                this.startFireTimer(false);
            } else if (time === 0) {
                clearInterval(this.timerRef);
                this.updateKeyValue('shootingDone', true);
            }
            if (time === stopTime && this.useStopShootingSound) {
                this.playSound(this.soundsLoaded.stopShootingSound);
            }
        }, 1000);
    }

    @action
    stopExercise() {
        clearInterval(this.timerRef);
        this.updateLoadingTime(null);
        this.updateShootingTime(null);
        this.updateReadyTime(null);
        this.updateFireTime(null);
        this.updateSeriesTime(1);
        this.updateKeyValue('shootingDone', true);
    }

    @action
    finishExercise() {
        clearInterval(this.timerRef);
        this.updateLoadingTime(null);
        this.updateShootingTime(null);
        this.updateReadyTime(null);
        this.updateFireTime(null);
        this.updateSeriesTime(1);
        this.updateObjectKeyValue('fieldExercise', 'readyTime', 5);
        this.shootingInProgress = false;
        this.shootingDone = false;
        this.commands = {
            startExercise: false,
            emptyGun: false,
            secureGun: false,
            inspectTargets: false,
            emptyGunCheckEachOther: false,
            nextStage: false,
            loadAmmo: false,
            getReadyForShooting: false,
        };
        this.stopSilentAudio();
    }
}

const store = new ShotTrainerStore();
export default store;

// Named export for the class itself
export { ShotTrainerStore };
