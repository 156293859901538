import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

import DyrejournalJournalListMini from '../../components/dyrejournal/listJournalMini';
import Versions from '../../components/versions';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
    searchTitle: <Text id='input.search-for-visitor'>Search for visitor</Text>,
    searchAnimal: <Text id='input.search-for-animal'>Search for animal</Text>,
    mergeInfoAnimal: <Text id='dyrejournal.merge-info-animal'>This is what is going to happend: 1. All visits, journals and prescriptions is going to be transferred to this animal. 2. Animal you choose to merge is deleted.</Text>
}))
@observer
class NewAnimal extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('animal'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { animalStore } = this.props.stores;
        const { id, isNew } = props;
        animalStore.updateKeyValue('animal', {});
        if (!isNew) {
            await animalStore.load(id);
        }
        const { animal } = animalStore;
        await this.loadJournals(id);
        await this.loadSpecies();
        await this.loadBreeds(animal?.speciesId);
    }

    loadSpecies = async () => {
        const { animalStore } = this.props.stores;
        await animalStore.loadSpecies();
    }

    loadBreeds = async speciesId => {
        const { animalStore } = this.props.stores;
        await animalStore.loadBreeds(speciesId);
    }

    loadJournals = async animalIds => {
        const { journalStore, saleStore } = this.props.stores;
        journalStore.updateKeyValue('journals', []);
        if (animalIds) {
            const response = await journalStore.load({
                query: {
                    animal: animalIds,
                },
            });
        }
    }

    createAnimal = async () => {
        this.setState({
            loading: true,
        });
        const { callback = () => {} } = this.props;
        const { animalStore } = this.props.stores;
        const { newAnimal } = animalStore;
        const response = await animalStore.insert(newAnimal);
        const animal = response?.data?.animal;
        animalStore.updateKeyValue('newAnimal', {});
        if (response.status === 201) {
            callback(animal);
        }
        this.setState({
            loading: false,
        });
    }

    dyreIdGetChipInfo = async (chipId) => {
        const { animalStore } = this.props.stores;
        const { animal } = animalStore;
        const response = await animalStore.dyreIdGetChipInfo(chipId);
        const { data } = response;
        console.log({ data });
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        const { visitorStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await visitorStore.searchVisitors({ search });
        } else {
            visitorStore.localUpdateField('searchResult', []);
        }
    }

    clearSearch = () => {
        this.setState({ search: '' });
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    changeOwnerOfAnimal = async e => {
        const { id } = e.target.closest('button').dataset;
        const { animalStore, visitorStore } = this.props.stores;
        const { animal } = animalStore;
        const { searchResult } = visitorStore;
        const visitor = searchResult.find(visitor => visitor.id === parseInt(id, 10));
        if (!confirm(`Change owner to ${localUtil.displayName(visitor)}?`)) {
            return;
        }
        // console.log('Changing owner of animal to visitor:')
        // console.log({ visitor })
        await animalStore.save({
            id: animal.id,
            owner: visitor.id,
        });

        this.setState({
            search: '',
            ownerChanged: true,
            newOwner: visitor,
        });
        // await conventionStore.newParticipant(visitor);
        await this.loadAll();
    }

    componentDidMount() {
        const { id, isNew, visitorId } = this.props;
        const { animalStore } = this.props.stores;
        if (isNew) {
            animalStore.updateObjectKeyValue('newAnimal', 'owner', visitorId);
        }
        this.loadAll();
    }

    searchInputAnimal = (e) => {
        const searchAnimal = e.target.value;
        this.setState({ searchAnimal });

        clearTimeout(this.searchTimer);
        if (searchAnimal.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearchAnimal(searchAnimal);
            }, 300);
        }
    }

    async doSearchAnimal(searchAnimal) {
        const { animalStore } = this.props.stores;
        const { animal } = animalStore;
        if (searchAnimal) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await animalStore.searchOwnerAnimals({ search: searchAnimal, owner: animal.owner });
        } else {
            animalStore.localUpdateField('searchResult', []);
        }
    }

    clearSearchAnimal = () => {
        this.setState({ searchAnimal: '' });
        this.doSearch('');
    }

    handleKeyDownAnimal = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearchAnimal(this.state.searchAnimal);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    mergeAnimal = async e => {
        const { id } = e.target.closest('button').dataset;
        const { animalStore } = this.props.stores;
        const { animal } = animalStore;
        const { searchResult: searchResultAnimal } = animalStore;
        const mergeAnimal = searchResultAnimal.find(a => a.id === parseInt(id, 10));
        if (!confirm(`Merge animal with ${mergeAnimal.name}?`)) {
            return;
        }
        // console.log('Merging animal with:')
        // console.log({ mergeAnimal })
        await animalStore.mergeAnimals({
            currentAnimal: animal.id,
            mergeWith: mergeAnimal.id,
        });
        this.setState({
            searchAnimal: '',
            animalMerged: true,
            mergeAnimal,
        });
        await this.loadAll();
    }

    render() {
        const {
            searchTitle,
            mergeInfoAnimal,
        } = this.props;
        const {
            search,
            ownerChanged,
            newOwner,
            searchAnimal = '',
            animalMerged,
            mergeAnimal,
        } = this.state;
        const { week, isNew, hideAdmin, drawerLevel, ownerName } = this.props;
        const { sections, loading } = this.state;
        const { userStore, animalStore, journalStore, visitorStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { searchResult } = visitorStore;
        const { searchResult: searchResultAnimal } = animalStore;
        const { newAnimal, animal } = animalStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { journals } = journalStore;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <Versions stores={this.props.stores} type='animal' id={animal?.id} key={`animal-${animal.id}`} />

                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='animal.new'>New animal</Text> : <Text id='animal.edit'>Edit animal</Text>}
                    </div>

                    {isNew ? <>
                        <div class={`mt-2 mx-4 font-weight-lighter px-3 py-1 text-muted`}>
                            <small class='text-uppercase'>
                                <Text id='form.owner'>Owner</Text>
                            </small>
                        </div>
                        <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-wrap py-3 px-3 box-container`}>
                            {ownerName}
                        </div>
                    </> : <>
                        {/* <DyrejournalJournalListMini
                            classNames='mx-1'
                            stores={this.props.stores}
                            journals={journals}
                            drawerLevel={drawerLevel}
                            collapse={true}
                        /> */}
                    </>}

                    {/* {animal && animal.chipId && <>
                        <button
                            type='button'
                            class={`btn btn-info rounded-pill`}
                            onClick={this.dyreIdGetChipInfo}
                        >
                            <Text id='animal.getChipInfo'>Get chip info</Text>
                        </button>
                    </>} */}

                    {/* {isAdmin && <>
                        <button
                            type='button'
                            class={`btn btn-info rounded-pill`}
                            onClick={() => animalStore.dyreIdSearchOwnerPet('90169741')}
                        >
                            Admin: <Text id='animal.dyreIdSearchOwnerPet'>dyreIdSearchOwnerPet</Text>
                        </button>
                    </>} */}

                    <div class='d-flex flex-column justify-content-start pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={animalStore}
                            objectName={isNew ? 'newAnimal' : 'animal'}
                            isNew={isNew}
                            drawerLevel={drawerLevel}
                            hideAdmin={hideAdmin}
                        />
                    </div>
                    {isNew ? <>
                        <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                            <button type='button' class='btn btn-primary rounded-pill' onClick={this.createAnimal} disabled={loading}>
                                {isNew ? <Text id='animal.create'>Create animal</Text> : <Text id='animal.save'>Save animal</Text>}
                                {loading && <i class='fa-solid fa-spinner-third fa-spin ml-2' />}
                            </button>
                        </div>
                    </> : <>
                        <div class={`mt-5 mx-4 font-weight-lighter px-3 py-1 text-muted`}>
                            <small class='text-uppercase'>
                                <Text id='form.change-owner'>Change Owner</Text>
                            </small>
                        </div>
                        <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-wrap py-3 px-3 box-container`}>
                            {ownerChanged && newOwner ? <>
                                <div class='d-flex flex-wrap justify-content-center w-100'>
                                    <div class='alert alert-success rounded-lg w-100'>
                                        <Text id='animal.owner-changed'>Owner changed to</Text>: {localUtil.displayName(newOwner)}
                                    </div>
                                </div>
                            </> : <>
                                <div class='flex-fill input-group'>
                                    <div class='input-group-prepend'>
                                        <span class='input-group-text rounded-pill-left'>
                                            <i class='fa-regular fa-magnifying-glass'></i>
                                        </span>
                                    </div>
                                    <input
                                        class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                        type='text'
                                        value={search}
                                        placeholder={`${searchTitle}`}
                                        onInput={this.searchInput}
                                        onKeyDown={this.handleKeyDown}
                                    />
                                    {search && <div class='input-group-append'>
                                        <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                            <i class='fa-solid fa-circle-xmark'></i>
                                        </button>
                                    </div>}
                                </div>
                                {searchResult && searchResult.length ? <>
                                    <div class='d-flex flex-wrap justify-content-center'>
                                        {searchResult.map(user => {
                                            return (<>
                                                <button
                                                    class={`btn btn-sm btn-outline-primary rounded-pill mx-1 my-1`}
                                                    onClick={this.changeOwnerOfAnimal}
                                                    data-id={user.id}
                                                >
                                                    <i class='fa-duotone fa-plus' /> {localUtil.displayName(user)} {user.cellphone}
                                                </button>
                                            </>);
                                        })}
                                    </div>
                                </> : <></>}
                            </>}
                        </div>


                        <div class={`mt-5 mx-4 font-weight-lighter px-3 py-1 text-muted`}>
                            <small class='text-uppercase'>
                                <Text id='form.merge-animals'>Merge animals</Text>
                            </small>
                        </div>
                        <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-wrap py-3 px-3 mb-5 box-container`}>
                            {animalMerged && mergeAnimal ? <>
                                <div class='d-flex flex-wrap justify-content-center w-100'>
                                    <div class='alert alert-success rounded-lg w-100'>
                                        <Text id='dyrejournal.animal-merged'>Animal merged with</Text>: {mergeAnimal.name}
                                    </div>
                                </div>
                            </> : <>
                                <div class='flex-fill input-group'>
                                    <div class='input-group-prepend'>
                                        <span class='input-group-text rounded-pill-left'>
                                            <i class='fa-regular fa-magnifying-glass'></i>
                                        </span>
                                    </div>
                                    <input
                                        class={`form-control ${!searchAnimal ? 'rounded-pill-right' : ''}`}
                                        type='text'
                                        value={searchAnimal}
                                        placeholder={`${searchAnimal}`}
                                        onInput={this.searchInputAnimal}
                                        onKeyDown={this.handleKeyDownAnimal}
                                    />
                                    {search && <div class='input-group-append'>
                                        <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearchAnimal}>
                                            <i class='fa-solid fa-circle-xmark'></i>
                                        </button>
                                    </div>}
                                </div>
                                {searchResultAnimal && searchResultAnimal.length > 0 ? <>
                                    <div class='d-flex flex-wrap justify-content-center'>
                                        {searchResultAnimal.map(a => {
                                            const isCurrent = a.id === animal.id;
                                            return (<>
                                                <button
                                                    disabled={isCurrent}
                                                    class={`btn btn-sm btn-outline-${isCurrent ? 'secondary text-strikethrough' : 'primary'} rounded-pill mx-1 my-1`}
                                                    onClick={this.mergeAnimal}
                                                    data-id={a.id}
                                                >
                                                    <i class={`fa-duotone fa-plus`} /> {a.name}
                                                </button>
                                            </>);
                                        })}
                                    </div>
                                </> : <></>}
                            </>}
                            <div class='d-flex flex-column'>
                                <small class='text-danger'>
                                    <i class='fa-duotone fa-exclamation-triangle mr-2' style='font-size: 1.5em;' />
                                    <Text id='dyrejournal.merge-animal'>Search for animal you want to merge with this animal. This feature is Experimental! All execution is at your own risk!</Text>
                                </small>
                                <small class='text-muted'>
                                    <i class='fa-duotone fa-info-circle mr-2' style='font-size: 1.5em;' /> {mergeInfoAnimal}
                                </small>
                            </div>
                        </div>

                    </>}
                </div>
            </div>
        </>);
    }
}

export default NewAnimal;
