import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';
import FileList from '../../components/fileViewer/list';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@observer
class Inspection extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    toggleMenu = () => {
        const { showMenu } = this.state;
        this.setState({
            showMenu: !showMenu,
        });
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { inspectionStore } = this.props.stores;
        inspectionStore.updateKeyValue('inspection', {});
        await inspectionStore.load(id, false, { addData: ['installations'] });
    }

    editInspection = () => {
        const { id, drawerLevel } = this.props;
        // console.log('editInspection', { drawerLevel });
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editInspection', {
			height: drawerHeightMedium,
			id,
		}, drawerLevel + 1);
	}

    viewInstallation = e => {
        const { id } = e.currentTarget.dataset;
        const { appState } = this.props.stores;
        appState.toggleDrawer();
        route(`/installations/${id}`);
    };

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
        } = this.props;
        const { sessionid, showMenu } = this.state;
        const { appState, userStore, installationStore, inspectionStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { inspection, installations } = inspectionStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    {isAdmin && <div class={`d-flex justify-content-start flex-row w-100 pl-3 mb-1`}>
                        <button onClick={this.editInspection} class={`btn btn-sm btn-outline-secondary rounded-pill mr-2`}>
                            <i class={`fa-regular fa-pen mr-2`} />
                            <Text id='inspection.edit'>Edit inspection</Text>
                        </button>
                    </div>}

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex'>
                                {/* {JSON.stringify(part)} */}
                                <ImageScroller images={inspection.images} stores={this.props.stores} showImg={img => partImg(img, this.props, '1920x')} />
                            </div>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='inspection.date'>Date</Text>
                                    </span>
                                    <span class='ml-2'>{util.isoDate(inspection.createdDate)}</span>
                                </div>
                                {inspection.inspectorName && <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='inspection.inspector'>Inspector</Text>
                                    </span>
                                    <span class='ml-2'>{inspection.inspectorName}</span>
                                </div>}
                                {/* <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>Score</span>
                                    <span class='ml-2'>{inspection.score}</span>
                                </div> */}
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='inspection.description'>Description</Text>
                                    </span>
                                    <span class='ml-2'>{inspection.description}</span>
                                </div>

                                {inspection.files && inspection.files.length > 0 && <div class='d-flex flex-column mt-5'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='inspection.documentation'>Documentation</Text>
                                    </span>
                                    <FileList files={inspection.files} stores={this.props.stores} />
                                </div>}
                            </div>
                        </div>
                    </div>

                    <div class='w-100 d-flex flex-column mt-5'>
                        <div class='font-weight-lighter px-3 box-header'>
                            <Text id='inspection.inspected-points'>Inspected points</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                            {(!inspection.inspections || inspection.inspections?.length === 0) && <>
                                <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                                    <div class='w-100 d-flex flex-row justify-content-center'>
                                        <div class='box-line-add'>
                                            <i class='fa-solid fa-circle-question' />
                                        </div>
                                    </div>
                                </div>
                            </>}
                            {inspection.inspections && inspection.inspections.map((insp, idx) => {
                                return (<>
                                    <div class='d-flex flex-row my-2'>
                                        <div class='d-flex align-items-start  pr-2' style='font-size: 1.5em;'>
                                            {insp.value === 'ok' && <i class='fa-solid fa-circle-check text-success' />}
                                            {insp.value === 'not ok' && <i class='fa-solid fa-circle-xmark text-danger' />}
                                        </div>
                                        <div class='flex-fill-1 ml-1'>
                                            {/* <span class='font-weight-normal'>{insp.name}</span><br /> */}
                                            <span class='font-weight-normal'>{insp.description}</span><br />
                                            <small class='font-weight-lighter text-muted'>{util.isoDate(insp.date)}</small>
                                        </div>
                                        {/* <span class='ml-2'>{JSON.stringify(insp, null, 4)}</span> */}
                                    </div>
                                </>);
                            })}
                        </div>
                    </div>

                    <InstallationList stores={this.props.stores} installations={installations} classNames={'mt-5'} hideEmpty={true} />

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default Inspection;
