import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class TextTemplateStore extends LocalModel {
    constructor() {
        super('textTemplate', {
            namePlural: 'textTemplates',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/textTemplates/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/textTemplates/',
                    params: {},
                },
                save: {
                    url: '/api/textTemplates/',
                    params: {},
                },
                delete: {
                    url: '/api/textTemplates/',
                    params: {},
                },
            },
        });
    }

    @observable newTextTemplate = {};

    @observable textTemplate = {};

    @observable textTemplates = [];

    @observable sections = [];

}

const store = new TextTemplateStore();
export default store;
