import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

import Input from '../../components/form/input';

const GITHUB_TOKEN = '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';

@observer
class BugReport extends Component {
    reportBug = async (e) => {
        this.setState({
            bugReportInProgress: true,
        });
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const {
            newBugReport = {},

            isDevelopment,
            isCordova,
            path,
            previousPath,
            wantedPage,
            userEmail,
            currentEmail,
            apiServer,
            darkmode,

        } = appState;
        // curl -L \
        //     -X POST \
        //     -H "Accept: application/vnd.github+json" \
        //     -H "Authorization: Bearer <YOUR-TOKEN>" \
        //     -H "X-GitHub-Api-Version: 2022-11-28" \
        //     https://api.github.com/repos/OWNER/REPO/issues \
        //     -d '{"title":"Found a bug","body":"I'\''m having a problem with this.","assignees":["octocat"],"milestone":1,"labels":["bug"]}'
        const url = 'https://api.github.com/repos/5orenso/keepspot/issues';
        const body = {
            title: newBugReport.title,
            body: `### Techincal info
isDevelopment: ${isDevelopment}
isCordova: ${isCordova}
apiServer: ${apiServer}
darkmode: ${darkmode}
path: ${path}
previousPath: ${previousPath}
wantedPage: ${wantedPage}
userEmail: ${userEmail}
currentEmail: ${currentEmail}

### Summary:
${newBugReport.summary}

### Description:
${newBugReport.description}

### Steps:
${newBugReport.steps}

### Expected Behavior:
${newBugReport.expectedBehavior}

### Actual Behavior:
${newBugReport.actualBehavior}

### Additional Info:
${newBugReport.additionalInfo}

`,
            labels: ['bug'],
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/vnd.github.v3+json',
                Authorization: `token ${GITHUB_TOKEN}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const json = await response.json();
        // console.log({ json });
        // {
        //     "url": "https://api.github.com/repos/5orenso/keepspot/issues/77",
        //     "repository_url": "https://api.github.com/repos/5orenso/keepspot",
        //     "labels_url": "https://api.github.com/repos/5orenso/keepspot/issues/77/labels{/name}",
        //     "comments_url": "https://api.github.com/repos/5orenso/keepspot/issues/77/comments",
        //     "events_url": "https://api.github.com/repos/5orenso/keepspot/issues/77/events",
        //     "html_url": "https://github.com/5orenso/keepspot/issues/77",
        //     "id": 1891235083,
        //     "node_id": "I_kwDOJJ2vFc5wufUL",
        //     "number": 77,
        //     "title": "test",
        //     "user": {
        //       "login": "5orenso",
        //       "id": 2414307,
        //       "node_id": "MDQ6VXNlcjI0MTQzMDc=",
        //       "avatar_url": "https://avatars.githubusercontent.com/u/2414307?v=4",
        //       "gravatar_id": "",
        //       "url": "https://api.github.com/users/5orenso",
        //       "html_url": "https://github.com/5orenso",
        //       "followers_url": "https://api.github.com/users/5orenso/followers",
        //       "following_url": "https://api.github.com/users/5orenso/following{/other_user}",
        //       "gists_url": "https://api.github.com/users/5orenso/gists{/gist_id}",
        //       "starred_url": "https://api.github.com/users/5orenso/starred{/owner}{/repo}",
        //       "subscriptions_url": "https://api.github.com/users/5orenso/subscriptions",
        //       "organizations_url": "https://api.github.com/users/5orenso/orgs",
        //       "repos_url": "https://api.github.com/users/5orenso/repos",
        //       "events_url": "https://api.github.com/users/5orenso/events{/privacy}",
        //       "received_events_url": "https://api.github.com/users/5orenso/received_events",
        //       "type": "User",
        //       "site_admin": false
        //     },
        //     "labels": [
        //       {
        //         "id": 5268576846,
        //         "node_id": "LA_kwDOJJ2vFc8AAAABOggaTg",
        //         "url": "https://api.github.com/repos/5orenso/keepspot/labels/bug",
        //         "name": "bug",
        //         "color": "d73a4a",
        //         "default": true,
        //         "description": "Something isn't working"
        //       }
        //     ],
        //     "state": "open",
        //     "locked": false,
        //     "assignee": null,
        //     "assignees": [

        //     ],
        //     "milestone": null,
        //     "comments": 0,
        //     "created_at": "2023-09-11T20:42:33Z",
        //     "updated_at": "2023-09-11T20:42:33Z",
        //     "closed_at": null,
        //     "author_association": "OWNER",
        //     "active_lock_reason": null,
        //     "body": "test",
        //     "closed_by": null,
        //     "reactions": {
        //       "url": "https://api.github.com/repos/5orenso/keepspot/issues/77/reactions",
        //       "total_count": 0,
        //       "+1": 0,
        //       "-1": 0,
        //       "laugh": 0,
        //       "hooray": 0,
        //       "confused": 0,
        //       "heart": 0,
        //       "rocket": 0,
        //       "eyes": 0
        //     },
        //     "timeline_url": "https://api.github.com/repos/5orenso/keepspot/issues/77/timeline",
        //     "performed_via_github_app": null,
        //     "state_reason": null
        //   }

        if (json.id) {
            appState.updateKeyValue('newBugReport', {});
            this.setState({
                message: 'Bug reported successfully. Thank you!',
                bugReportInProgress: false,
            });
            setTimeout(() => {
                appState.toggleDrawer();
            }, 1000);
        } else {
            this.setState({
                error: 'Error! Could not report bug. Please try again later.',
                bugReportInProgress: false,
            });
        }
    }

    render() {
        const { error, message, bugReportInProgress } = this.state;
        const { appState } = this.props.stores;
        const { newBugReport = {} } = appState;
        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='main.report-bug'>Report Bug</Text>
                    </div>
                    <div class='w-100 d-flex flex-column'>
                        <Localizer>
                            <Input
                                classNames='form-control rounded-lg'
                                title={<Text id='input.title'>Title</Text>}
                                placeholder={<Text id='input.title'>Title</Text>}
                                stores={this.props.stores}
                                holdingStore={appState}
                                object={newBugReport}
                                objectName={'newBugReport'}
                                type={'text'}
                                field={'title'}
                                extraDataset={{
                                    'data-isnew': true,
                                }}
                            />
                        </Localizer>
                        <Localizer>
                            <Input
                                classNames='form-control rounded-lg'
                                title={<Text id='input.summary'>Summary</Text>}
                                placeholder={<Text id='input.summary'>Summary</Text>}
                                help={<Text id='input.summary-help'>Briefly describe the issue you are experiencing. (Example: "The application crashes when I click the 'Save' button.")</Text>}
                                stores={this.props.stores}
                                holdingStore={appState}
                                object={newBugReport}
                                objectName={'newBugReport'}
                                type={'textarea'}
                                field={'summary'}
                                extraDataset={{
                                    'data-isnew': true,
                                }}
                            />
                        </Localizer>
                        <Localizer>
                            <Input
                                classNames='form-control rounded-lg'
                                title={<Text id='input.description'>Detailed Description</Text>}
                                placeholder={<Text id='input.description'>Detailed Description</Text>}
                                help={<Text id='input.description-help'>Please provide more details about the issue. Try to be as descriptive as possible.</Text>}
                                stores={this.props.stores}
                                holdingStore={appState}
                                object={newBugReport}
                                objectName={'newBugReport'}
                                type={'textarea'}
                                field={'description'}
                                extraDataset={{
                                    'data-isnew': true,
                                }}
                            />
                        </Localizer>
                        <Localizer>
                            <Input
                                classNames='form-control rounded-lg'
                                title={<Text id='input.steps'>Steps to Reproduce</Text>}
                                placeholder={<Text id='input.steps'>Steps to Reproduce</Text>}
                                help={<Text id='input.steps-help'>Please provide a list of actions to reproduce the bug.</Text>}
                                stores={this.props.stores}
                                holdingStore={appState}
                                object={newBugReport}
                                objectName={'newBugReport'}
                                type={'textarea'}
                                field={'steps'}
                                extraDataset={{
                                    'data-isnew': true,
                                }}
                            />
                        </Localizer>
                        <Localizer>
                            <Input
                                classNames='form-control rounded-lg'
                                title={<Text id='input.expected-behavior'>Expected Behavior</Text>}
                                placeholder={<Text id='input.expected-behavior'>Expected Behavior</Text>}
                                help={<Text id='input.expected-behavior-help'>Describe what you expected to happen.</Text>}
                                stores={this.props.stores}
                                holdingStore={appState}
                                object={newBugReport}
                                objectName={'newBugReport'}
                                type={'textarea'}
                                field={'expectedBehavior'}
                                extraDataset={{
                                    'data-isnew': true,
                                }}
                            />
                        </Localizer>
                        <Localizer>
                            <Input
                                classNames='form-control rounded-lg'
                                title={<Text id='input.actual-behavior'>Actual Behavior</Text>}
                                placeholder={<Text id='input.actual-behavior'>Actual Behavior</Text>}
                                help={<Text id='input.actual-behavior-help'>Describe what actually happened.</Text>}
                                stores={this.props.stores}
                                holdingStore={appState}
                                object={newBugReport}
                                objectName={'newBugReport'}
                                type={'textarea'}
                                field={'actualBehavior'}
                                extraDataset={{
                                    'data-isnew': true,
                                }}
                            />
                        </Localizer>
                        <Localizer>
                            <Input
                                classNames='form-control rounded-lg'
                                title={<Text id='input.additional-info'>Additional Information</Text>}
                                placeholder={<Text id='input.additional-info'>Additional Information</Text>}
                                help={<Text id='input.additional-info-help'>Any other information that might be helpful.</Text>}
                                stores={this.props.stores}
                                holdingStore={appState}
                                object={newBugReport}
                                objectName={'newBugReport'}
                                type={'textarea'}
                                field={'additionalInfo'}
                                extraDataset={{
                                    'data-isnew': true,
                                }}
                            />
                        </Localizer>

                        <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                            <button
                                class='btn btn-primary btn-block rounded-pill'
                                onClick={this.reportBug}
                                disabled={error || message}
                            >
                                <Text id='action.report-bug'>Report Bug</Text>
                                {bugReportInProgress && <>
                                    <span class='spinner-border spinner-border-sm ml-2' role='status' aria-hidden='true'></span>
                                </>}
                            </button>
                        </div>

                        {error && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                            <div class='alert alert-danger' role='alert'>
                                {error}
                            </div>
                        </div>}

                        {message && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                            <div class='alert alert-success' role='alert'>
                                {message}
                            </div>
                        </div>}

                    </div>
                </div>
            </div>
        </>);
    }
}

export default BugReport;
