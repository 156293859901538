import { observable, configure, action, computed } from 'mobx';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class VippsStore {
    constructor() {

    }

    @action
    updateKeyValue(key, value) {
        this[key] = value;
    }

    @action
    updateObjectKeyValue(obj, key, value) {
        this[obj][key] = value;
    }

    @observable redirectUrl = null;

    async requestPayment(params = {}) {
        if (!params.amount || !params.title) {
            console.error('vippsStore.requestPayment', 'Missing amount and/or title');
            return;
        }
        const response = await util.fetchApi(`/api/vipps/request-payment`, { method: 'POST' }, {
            ...params,
        });
        switch (response.status) {
            case 200:
                // console.log('vippsStore.requestPayment', response);
                this.updateKeyValue('redirectUrl', response.data.redirectUrl);
                return response;
            default:
                console.error('vippsStore.requestPayment', response);
                return response;
        }
    }

    async getPaymentStatus(uuidv4) {
        const response = await util.fetchApi(`/api/vipps/payment-status/${uuidv4}`);
        switch (response.status) {
            case 200:
                // console.log('vippsStore.getPaymentStatus', response);
                return response;
            default:
                console.error('vippsStore.getPaymentStatus', response);
                return response;
        }
    }

    async paymentCapture(uuidv4) {
        const response = await util.fetchApi(`/api/vipps/payment-capture/${uuidv4}`);
        switch (response.status) {
            case 200:
                // console.log('vippsStore.paymentCapture', response);
                return response;
            default:
                console.error('vippsStore.paymentCapture', response);
                return response;
        }
    }

    async paymentCancel(uuidv4) {
        const response = await util.fetchApi(`/api/vipps/payment-cancel/${uuidv4}`);
        switch (response.status) {
            case 200:
                // console.log('vippsStore.paymentCancel', response);
                return response;
            default:
                console.error('vippsStore.paymentCancel', response);
                return response;
        }
    }
}

const store = new VippsStore();
export default store;
