import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalAnimalListMini from '../../components/dyrejournal/listAnimalMini';
import DyrejournalPrescriptionListMini from '../../components/dyrejournal/listPrescriptionMini';
import DyrejournalSalePrescription from '../../components/dyrejournal/salePrescription';

@withText(props => ({
    emailTitle: <Text id='dyrejournal.email-title'>Email address</Text>,
}))
@observer
class ViewPrescription extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal', 'Journal basics'),
            sectionsMedical: fields.get('journal', 'Journal medical'),
            sectionsClinical: fields.get('journal', 'Journal clinical'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { prescriptionStore } = this.props.stores;
        await this.loadPrescription(id);
        await prescriptionStore.loadVeterinaries();
    }

    loadPrescription = async id => {
        const { prescriptionStore, animalStore } = this.props.stores;
        await prescriptionStore.load(id);
        const { animal } = prescriptionStore;
        if (animal) {
            await animalStore.load(animal);
        }
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        if (search.length >= 3) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 300);
        }
    }

    async doSearch(search) {
        // return;
        const { contactStore } = this.props.stores;
        if (search) {
            // console.log('doUpdateField.saveField', id, field, value);
            const result = await contactStore.load({ query: { search }, limit: 500, skipUpdate: false });
        }
    }

    clearSearch = () => {
        const { contactStore } = this.props.stores;
        this.setState({ search: '' });
        contactStore.updateKeyValue('contacts', []);
        this.doSearch('');
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.doSearch(this.state.search);
        } else if (e.key === 'Escape') {
            e.preventDefault();
            this.clearSearch();
        }
    }

    onClickEmailPrescription = async e => {
        const { prescriptionStore } = this.props.stores;
        const { prescription } = this.props;
        const { search } = this.state;
        this.setState({ sending: true });
        await prescriptionStore.emailPrescription(prescription.id, search);
        // console.log('onClickEmailPrescription', prescription.id, search);
        this.setState({ sending: false, emailSent: true });
    }

    onClickContact = e => {
        const { contactStore } = this.props.stores;
        const {
            email,
            name = '',
            address = '',
            postalcode = '',
            postalplace = '',
        } = e.target.closest('tr').dataset;
        this.setState({
            search: email,
            farmacy: `${name}\n${address}\n${postalcode} ${postalplace}`,
        }, () => {
            contactStore.updateKeyValue('contacts', []);
        });
    }

    onClickEditContact = e => {
        e.preventDefault();
        e.stopPropagation();
        const {
            id,
        } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, contactStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('editContact', {
            height: drawerHeightMedium,
            id,
            callback: () => {
                appState.toggleDrawer(false, drawerLevel + 1);
            },
        }, drawerLevel + 1);
    }

    onClickSendSms = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, saleStore, userStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        const { customer } = userStore;
        const { newSale } = saleStore;
        const { visitors = [] } = newSale;
        const { farmacy = '' } = this.state;
        console.log('visitors', visitors);
        appState.openDrawer('sendSms', {
            height: drawerHeightMedium,
            users: visitors,
            message: `Hei,

Vi har sendt resepten på e-post til apoteket.

Vennligst hent medisinene dine hos:
${farmacy || 'apoteket ditt'}.

Mvh,
${customer.name}
`,

            callback: () => {
                appState.toggleDrawer(false, drawerLevel + 1);
            },
        }, drawerLevel + 1);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            week,
            isNew,
            hideAdmin = true,
            drawerLevel = 1,
            emailTitle,
            prescription,
        } = this.props;
        const {
            search,
            sending,
            emailSent,
        } = this.state;
        const { userStore, contactStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { contacts } = contactStore;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='dyrejournal.prescription.email'>Email Prescription</Text>
                    </div>

                    <DyrejournalPrescriptionListMini
                        classNames='mx-3'
                        stores={this.props.stores}
                        prescriptions={[prescription]}
                        saleIsLocked={true}
                        drawerLevel={drawerLevel}
                        showButtons={false}
                    />

                    <div class={`d-flex flex-column mt-4 mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                        <div class='d-flex flex-row justify-content-between'>
                            <div class='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text rounded-pill-left'>
                                        <i class='fa-regular fa-at'></i>
                                    </span>
                                </div>
                                <input
                                    class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                                    type='text'
                                    value={search}
                                    placeholder={`${emailTitle}`}
                                    onInput={this.searchInput}
                                    onKeyDown={this.handleKeyDown}
                                />
                                {search && <div class='input-group-append'>
                                    <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.clearSearch}>
                                        <i class='fa-solid fa-circle-xmark'></i>
                                    </button>
                                </div>}
                            </div>
                        </div>

                        {contacts && contacts.length > 0 && <>
                            <div class='d-flex w-100'>
                                <div class='table-responsive'>
                                    <table class={`table table-sm table-hover ${darkmode ? 'table-dark' : 'table-light'}`}>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th><Text id='input.name'>Name</Text></th>
                                                <th><Text id='input.email'>Email</Text></th>
                                                <th><Text id='input.cellphone'>Cellphone</Text></th>
                                                <th><Text id='input.description'>Description</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contacts.map(contact => {
                                                return <tr
                                                    data-email={contact.email}
                                                    data-name={contact.name}
                                                    data-address={contact.address}
                                                    data-postalcode={contact.postalcode}
                                                    data-postalplace={contact.postalplace}
                                                    data-id={contact.id}
                                                    onClick={this.onClickContact}
                                                >
                                                    <td class='d-none d-sm-table-cell' onClick={this.onClickEditContact}>
                                                        <i class='fa-solid fa-pen' />
                                                    </td>
                                                    <td>{contact.name ? `${contact.name}` : `${contact.firstname} ${contact.lastname}`}</td>
                                                    <td>{contact.email}</td>
                                                    <td>{contact.cellphone}</td>
                                                    <td>{contact.description}</td>
                                                </tr>;
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>}

                        <button
                            disabled={sending}
                            class={`btn btn-primary ml-2 rounded-pill mt-2`}
                            onClick={this.onClickEmailPrescription}
                        >
                            {sending && <i class='fa-solid fa-circle-notch fa-spin mr-2' />}
                            <i class='fa-solid fa-paper-plane' /> <Text id='dyrejournal.email-prescription'>Email prescription</Text>
                        </button>

                        {emailSent && <>
                            <div class='alert alert-success mt-2'>
                                <Text id='dyrejournal.email-sent'>Email sent</Text>
                            </div>
                            <button
                                disabled={sending}
                                class={`btn btn-outline-primary ml-2 rounded-pill mt-2`}
                                onClick={this.onClickSendSms}
                            >
                                {sending && <i class='fa-solid fa-circle-notch fa-spin mr-2' />}
                                <i class='fa-solid fa-phone' /> <Text id='dyrejournal.send-sms'>Send SMS to customer</Text>
                            </button>
                        </>}
                    </div>
                </div>

            </div>
        );
    }
}

export default ViewPrescription;
