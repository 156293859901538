import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

@withText(props => ({
    titleText: <Text id='part.loan-title'>Loans</Text>,
}))
@observer
class LoanPartList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewTags = loan => {
        const endDate = loan.endDate ? new Date(loan.endDate) : new Date();
        const {
            seconds,
            minutes,
            hours,
            days,
            weeks,
            months,
            years,
        } = util.dateDiff(loan.startDate, endDate);
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';
        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })
        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-wrap'>
                    <span class={`badge badge-pill badge-${loan.endDate ? 'success' : 'warning'} mr-1 mt-1`}>
                        <Text id='part.loaned-out-to'>Loaned out</Text> <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                    </span>
                    {/* {loan.reminders?.length > 0 && <div class='d-flex flex-nowrap'>
                        {loan.reminders && loan.reminders.reverse().map((reminder, idx) => {
                            return (<>
                                <span class='badge badge-pill badge-success mr-1 mt-1'>{reminder.type} {util.isoDate(reminder.date)}</span>
                            </>);
                        })}
                    </div>} */}

                    {loan.tags?.length > 0 && loan.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    viewPeriode = obj => {
        return (<>
            {util.formatDate(obj.startDate, { locale: 'nb-NO' })} - {obj.endDate ? util.formatDate(obj.endDate, { locale: 'nb-NO' }) : <Text id='part.loan.ongoing'>ongoing</Text>}
        </>);
    }

    returnLoan = async e => {
        const { id: loanId } = e.target.closest('.list-button').dataset;
        const { appState, userStore, partStore } = this.props.stores;
        // console.log('returnLoan', { loanId, e });

        await partStore.loanReturn({ loanId });
        const { callback = () => {} } = this.props;
        callback();
    }

    showCheckElement = obj => {
        if (obj.endDate) {
            return false;
        }
        return true;
    }

    toggleTagFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { tag } = e.target.closest('span').dataset;
        const { filterByTags = [] } = this.state;
        if (filterByTags.includes(tag)) {
            const index = filterByTags.indexOf(tag);
            filterByTags.splice(index, 1);
        } else {
            filterByTags.push(tag);
        }
        this.setState({ filterByTags });
    }

    filterByTags = (part) => {
        const { filterByTags } = this.state;
        if (filterByTags.length === 0) {
            return true;
        }
        // console.log('filterByTags', { part, filterByTags })
        return part.tags?.some(tag => filterByTags.includes(tag.name));
    }

    filter = () => {
        const { filterByTags } = this.state;
        if (filterByTags.length > 0) {
            return (<>
                {filterByTags.map(tag => {
                    return (<>
                        <span
                            class='badge badge-pill badge-secondary mr-1'
                            style='font-size: 1.0rem;'
                            onClick={this.toggleTagFilter}
                            data-tag={tag}
                        ><i class="fa-solid fa-circle-xmark"></i> {tag}</span>
                    </>);
                })}
            </>);
        }
    }

    render() {
        const { appState, userStore, partStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            loans,
            title = titleText,
            classNames = '',
            hideEmpty,
            addItem,
            inc,
            dec,
            remove,
            editQty,
            view,
            viewTags = this.viewTags,
            deleteElement,
            rentOutElement,
            moveElement,
            editElement,
            help,
            children,
        } = this.props;

        return (<>
            <GuiList
                stores={this.props.stores}
                holdingStore={partStore}
                classNames={classNames}
                view={view}
                title={title}
                list={loans}
                // elHeader={this.viewPeriode}
                elHeader={e => ''}
                elTitle={e => e.name}
                elDescription={e => ''}
                elInfo1={e => <>
                    {`${e.firstname || 'noname'}`} {`${e.lastname || 'noname'}`} <i class='fa-regular fa-mobile ml-2' /> {`${e.cellphone}`}
                </>}
                elInfo2={e => ''}
                elInfo3={e => ''}
                viewTags={viewTags}
                hideEmpty={hideEmpty}
                addItem={addItem}
                inc={inc}
                dec={dec}
                remove={remove}
                editQty={editQty}
                deleteElement={deleteElement}
                rentOutElement={rentOutElement}
                moveElement={moveElement}
                editElement={editElement}
                checkElement={this.returnLoan}

                showCheckElement={this.showCheckElement}
                help={help || children}
            />
        </>);
    }
}

export default LoanPartList;
