import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import localUtil from '../../lib/util';

@observer
class Pagination extends Component {
  	constructor(props) {
        super(props);
        this.state = {};
    }

    renderPageNumbers(total, limit, currentPage) {
        const totalPages = Math.ceil(total / limit);
        let pages = [];
        const startPage = Math.max(1, currentPage - 2);
        const maxPages = Math.min(10, totalPages);
        const endPage = Math.min(startPage + maxPages - 1, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li className={`page-item ${i === currentPage ? 'active' : ''}`} key={i}>
                    <button type='button' class="page-link" onClick={() => this.props.onPageChange(i)}>
                        {i}
                    </button>
                </li>
            );
        }
        return pages;
    }

    render() {
        const { raceClassId, raceClasses } = this.state;
        const { id } = this.props;
        const { userStore, raceStore, raceClassStore, raceClassContestantStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { race, isRaceAdmin } = raceStore;
        const { raceClass } = raceClassStore;
        const { raceClassContestants } = raceClassContestantStore;

        const { total, limit, current } = this.props;

        return (<>
            <div class='d-flex flex-column justify-content-center align-items-center mt-3'>
                <nav aria-label='Page navigation'>
                    <ul className='pagination'>
                        {this.renderPageNumbers(total, limit, current)}
                    </ul>
                </nav>
                <small class='text-muted'>
                    <Text id='pagination.page'>Page</Text> {current} <Text id='pagination.of'>of</Text> {Math.ceil(total / limit)}
                </small>
            </div>
        </>);
    }
}

export default Pagination;
