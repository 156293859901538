import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

@observer
class UrlViewer extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            url,
        } = this.props;

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center align-items-center pt-5'>
                <iframe class='w-100 h-100' src={url} allowfullscreen></iframe>
            </div>
        </>);
    }
}

export default UrlViewer;
