import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';
import FormFields from '../../components/form/fields';
import PartList from '../../components/part/list';
import PartLoanList from '../../components/part/loanList';
import GradientRight from '../../components/gui/gradientRight';
import HelpText from '../../components/gui/helpText';
import Input from '../../components/form/input';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    titleLoanedPart: <Text id='part.loaned-part-title'>Loaned out part</Text>,
    titleFromInstallation: <Text id='part.loaned-from-installation'>Loaned out from</Text>,
    titleOtherLoansByUser: <Text id='part.other-loans-by-user'>Other loans by this user</Text>,

}))
@observer
class ViewLoan extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('loan'),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { loanId } = props;
        const { loanStore } = this.props.stores;
        if (emptyFirst) {
            loanStore.updateKeyValue('loan', {});
        }
        await loanStore.load(loanId, false, { addData: ['parts', 'installations', 'otherPartLoans', 'otherLoans'] });
    }

    editPartLoan = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { loanId, drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;

		appState.openDrawer('editLoan', {
			height: drawerHeightMedium,
            loanId,
		}, drawerLevel + 1);
    }

    sendReminder = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { loanId } = this.props;
        const { loanStore } = this.props.stores;
        await loanStore.sendReminder(loanId);
        this.setState({ reminderSent: true });
        this.loadAll(this.props, false);
    }

    addTag = e => {
        const { tagInput = '' } = this.state;
        const { loanStore } = this.props.stores;
        const value = {
            name: tagInput.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const field = 'tags';
        const object = loanStore.loan;
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            loanStore.saveField(object.id, field, value);
            loanStore.updateField(object.id, field, [...localValue, value]);
        }
        this.setState({ tagInput: '' });
    }

    checkForEnter = (e) => {
        if (e.key === 'Enter') {
            this.addTag();
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.partId !== this.props.partId) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            drawerLevel = 1,
            titleLoanedPart,
            titleFromInstallation,
            titleOtherLoansByUser,
        } = this.props;
        const { sessionid, showMenu, sections, isNew = false, reminderSent, tagInput = '' } = this.state;
        const { appState, userStore, installationStore, partStore, loanStore } = this.props.stores;
        const { user = {}, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { loan = {}, parts, installations, otherPartLoans, otherLoans, foundList, foundListKeys } = loanStore;

        const endDate = loan.endDate ? new Date(loan.endDate) : new Date();
        const {
            seconds,
            minutes,
            hours,
            days,
            weeks,
            months,
            years,
        } = util.dateDiff(loan.startDate, endDate);

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {isAdmin && <div class={`float-right mr-5`} >
                        <button onClick={this.editPartLoan} class={`btn btn-sm btn-outline-secondary rounded-pill mr-2`}>
                            <i class={`fa-regular fa-pen mr-2`} />
                            <Text id='loan.edit'>Edit loan</Text>
                        </button>
                    </div>}

                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        <Text id='loan.view'>View Loan</Text>
                    </div>
                    <div class={`d-flex flex-column`}>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex'>
                                {/* {JSON.stringify(loan.} */}
                                <ImageScroller images={loan.images} stores={this.props.stores} showImg={img => partImg(img, this.props, '1920x')} />
                            </div>
                            <div class={`d-flex flex-column mt-2 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                                <GradientRight stores={this.props.stores} styles={'border-radius: 0 1.0rem 1.0rem 0 !important;'} />
                                {['name', 'qty', 'firstname', 'lastname', 'cellphone', 'email', 'description', 'url'].map(key => {
                                    if (!loan[key]) {
                                        return '';
                                    }
                                    const field = fields.getField('loan', key);
                                    const displayValue = field.displayValue || (value => value);
                                    return (<>
                                        <div class='d-flex flex-row'>
                                            <span class='font-weight-lighter text-muted'>
                                                <Text id={`loan.${key}`}>{key}</Text>
                                            </span>
                                            <span class='ml-2'>{displayValue(loan[key])}</span>
                                        </div>
                                    </>);
                                })}

                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='loan.start'>Start</Text>
                                    </span>
                                    <span class='ml-2'>
                                        {util.formatDate(loan.startDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                    </span>
                                </div>
                                {loan.endDate && <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='loan.end'>End</Text>
                                    </span>
                                    <span class='ml-2'>
                                        {util.formatDate(loan.endDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                    </span>
                                </div>}
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='loan.duration'>Duration</Text>
                                    </span>
                                    <span class='ml-2'>
                                        {days > 0 ? <>{days}d</> : <>{hours}h</>}
                                    </span>
                                </div>
                            </div>

                            <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                {loan.files?.length > 0 && <div class='d-flex flex-column'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='loan.documentation'>Documentation</Text>
                                    </span>
                                    <FileList files={loan.files} stores={this.props.stores} />
                                </div>}

                                <Input
                                    stores={this.props.stores}
                                    holdingStore={loanStore}
                                    field='tags'
                                    object={loan}
                                    objectName='loan'
                                    type='tags'
                                    lookupArray={'existingTags'}
                                />

                            </div>
                            <HelpText>
                                <Text id='part.loan-tag-help'>Add tags to this part loan to make it easier to find and organize. Tip: Use tags like: family, goodfriend, neighbour, stranger, risky...</Text>
                            </HelpText>
                        </div>
                    </div>

                    <div class='font-weight-lighter px-3 box-header mt-5'>
                        <Text id='loan.reminders'>Reminders</Text>
                    </div>
                    <div class={`d-flex flex-column mt-0 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                        {loan.reminders?.length > 0 ? <>
                            <div class='mx-0 d-flex flex-column'>
                                {loan.reminders && loan.reminders.map((reminder, idx) => {
                                    return (<>
                                        <div class={`d-flex flex-row text-danger`}>
                                            <div class='text-center' style='width: 40px;'>
                                                <i class='fa-solid fa-alarm-clock mr-1' />
                                            </div>
                                            <div class='flex-fill'>
                                                {reminder.type}: {util.formatDate(reminder.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                                            </div>
                                        </div>
                                    </>);
                                })}
                            </div>
                        </> : <>
                            <div class='d-flex flex-row justify-content-center align-items-center mt-3 px-3 text-muted'>
                                <i class='fa-solid fa-alarm-clock' /> <span class='ml-2'><Text id='part.loan-no-reminders'>No reminders sent</Text></span>
                            </div>
                        </>}

                    </div>
                    {!loan.endDate && <>
                        <div class='d-flex flex-row justify-content-center mt-2 px-3 swipe-container text-center'>
                            {reminderSent ? <>
                                <div
                                    class='bg-light text-success d-flex flex-column justify-content-center align-items-center flex-fill list-button py-3'
                                >
                                    <i class='fa-solid fa-circle-check' />
                                    <small class='mt-2' style='line-height: 1.0em;'>
                                        <Text id='part.loan-reminder-sent'>Reminder sent</Text>
                                    </small>
                                </div>
                            </> : <>
                                <div
                                    class='bg-danger text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button py-3'
                                    onClick={this.sendReminder}
                                    data-id={loan.id}
                                >
                                    <i class='fa-solid fa-alarm-clock' />
                                    <small class='mt-2' style='line-height: 1.0em;'>
                                    <Text id='part.loan-send-reminder'>Send reminder</Text>
                                    </small>
                                </div>
                            </>}
                        </div>
                    </>}
                    <HelpText>
                        <Text id='part.loan-reminder-help'>If your part has been loaned out for too long, you can send a friendly SMS reminder.</Text>
                    </HelpText>

                    <PartList
                        stores={this.props.stores}
                        title={titleLoanedPart}
                        parts={parts}
                        classNames={'mt-5'}
                        hideEmpty={false}
                        view={() => {}}
                    >
                        <Text id='part.loan-part-list-help'>The part that has been loaned out.</Text>
                    </PartList>

                    <InstallationList
                        stores={this.props.stores}
                        title={titleFromInstallation}
                        installations={installations}
                        classNames={'mt-5'}
                        hideEmpty={false}
                        view={() => {}}
                    >
                        <Text id='part.loan-installation-list-help'>The installation where the part has been loaned out from.</Text>
                    </InstallationList>

                    {/* <PartList
                        stores={this.props.stores}
                        title={'Other loans by this user'}
                        parts={otherPartLoans}
                        classNames={'mt-5'}
                        hideEmpty={false}
                        view={() => {}}
                    /> */}

                    <PartLoanList
                        stores={this.props.stores}
                        title={titleOtherLoansByUser}
                        loans={otherLoans}
                        classNames='mt-5'
                        hideEmpty={true}
                        view={this.viewPartLoan}
                        callback={this.loadAll}
                        editQty={this.editPartLoan}
                    >
                        <Text id='part.loan-loan-list-help'>Other parts that this user is currently loaning from you.</Text>
                    </PartLoanList>

                </div>
            </div>
        </>);
    }
}

export default ViewLoan;
