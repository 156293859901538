import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';
import ShootingTarget from '../../components/part/shootingTarget';

@withText(props => ({
    titleText: <Text id='part.usage-title'>Usage log</Text>,
}))
@observer
class LoanPartList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            filterByTags: [],
        };
    }

    viewPeriode = obj => {
        return (<>
            {util.formatDate(obj.date, { locale: 'nb-NO' })}
        </>);
    }

    showCheckElement = obj => {
        if (obj.endDate) {
            return false;
        }
        return true;
    }

    toggleTagFilter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { tag } = e.target.closest('span').dataset;
        const { filterByTags } = this.state;
        if (filterByTags.includes(tag)) {
            const index = filterByTags.indexOf(tag);
            filterByTags.splice(index, 1);
        } else {
            filterByTags.push(tag);
        }
        this.setState({ filterByTags });
    }

    viewTags = obj => {
        if (obj.tags?.length === 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';
        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    filterByTags = (part) => {
        const { filterByTags } = this.state;
        if (filterByTags.length === 0) {
            return true;
        }
        // console.log('filterByTags', { part, filterByTags })
        return part.tags?.some(tag => filterByTags.includes(tag.name));
    }

    filter = () => {
        const { filterByTags } = this.state;
        if (filterByTags.length > 0) {
            return (<>
                {filterByTags.map(tag => {
                    return (<>
                        <span
                            class='badge badge-pill badge-secondary mr-1'
                            style='font-size: 1.0rem;'
                            onClick={this.toggleTagFilter}
                            data-tag={tag}
                        ><i class="fa-solid fa-circle-xmark"></i> {tag}</span>
                    </>);
                })}
            </>);
        }
    }

    render() {
        const { appState, userStore, partStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            usages = [],
            classNames = '',
            hideEmpty,
            addItem,
            inc,
            dec,
            remove,
            editQty,
            view,
            deleteElement,
            rentOutElement,
            moveElement,
            title = titleText,
            editElement,
            viewTags = this.viewTags,
            help,
            children,
        } = this.props;

        return (<>
            <GuiList
                stores={this.props.stores}
                holdingStore={partStore}
                classNames={classNames}
                view={view}
                title={title}
                filter={this.filter}
                list={usages.filter(this.filterByTags)}
                elHeader={this.viewPeriode}
                elTitle={e => e.title || <><Text id='usage.title'>Usage log</Text>: {util.formatDate(e.date, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</>}
                // elDescription={e => e.comment || <Text id='part.loan.no-comment'>no comment</Text>}
                elDescription={e => ''}
                elInfo1={e => e.hours ? <>
                    <span class='mr-2'><i class='fa-solid fa-clock' /> {`${e.hours} hours`}</span>
                    <span class='mr-2'>{e.shotCount ? <><i class='fa-duotone fa-gun' /> {e.shotCount} <span class='font-weight-light'>shots</span></> : ''}</span>
                </> : (e.totalRounds || e.distanceToTarget || e.gunAirPressure || e.ammunitionWeight || e.shotPlacementX || e.shotPlacementY ? <>
                    <span class='mr-2'>{e.shotCount ? <><i class='fa-duotone fa-gun' /> {e.shotCount} <span class='font-weight-light'>shots</span></> : ''}</span>
                    <span class='mr-2'>{e.distanceToTarget ? <>{e.distanceToTarget}<span class='font-weight-light'>m</span></> : ''}</span>
                    <span class='mr-2'>{e.gunAirPressure ? <>{e.gunAirPressure}<span class='font-weight-light'>bar</span></> : ''}</span>
                    <span class='mr-2'>{e.ammunitionWeight ? <>{e.ammunitionWeight}<span class='font-weight-light'>mg</span></> : ''}</span>
                    <span class='mr-2'>{e.shotPlacementX || e.shotPlacementY ? <>{e.shotPlacementX},{e.shotPlacementY}<span class='font-weight-light'>cm</span></> : ''}</span>

                </> : '')}
                elInfo2={e => e.distanceMeters ? <>
                    {/* <i class='fa-solid fa-road' /> {`${e.distanceMeters} meters`} */}
                    {/* <span class='mr-1'>{e.shotCount ? <><i class='fa-duotone fa-gun' /> {e.shotCount} <span class='font-weight-light'>shots</span></> : ''}</span> */}
                </> : ''}
                elInfo3={e => <>
                    {e.shotStrings && <>
                        {e.shotStrings.map(ss => {
                            return (<>
                                <span class='ml-2'>{ss.roundType}: {ss.score}</span>
                            </>);
                        })}
                    </>}
                </>}

                rightFunc={e => <>
                    {e.shotAnalysis && <>
                        <div class='mt-3 mr-2'>
                            <ShootingTarget size={75} factor={0.16} shots={e.shotAnalysis.map(shot => ({
                                round: shot.round,
                                type: shot.roundType,
                                angle: shot.groupPlacement,
                                score: shot.centerScore,
                            }))} />
                        </div>
                    </>}
                </>}
                viewTags={viewTags}
                hideEmpty={hideEmpty}
                addItem={addItem}
                inc={inc}
                dec={dec}
                remove={remove}
                editQty={editQty}
                deleteElement={deleteElement}
                editElement={editElement}
                rentOutElement={rentOutElement}
                moveElement={moveElement}
                help={help || children}
            />
        </>);
    }
}

export default LoanPartList;
