import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

@observer
class UrlList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewUrl = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { url, title } = e.currentTarget.closest('button').dataset;
        const { drawerLevel = 2 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightLarge } = appState;
        appState.openDrawerRight('urlViewer', {
            height: drawerHeightLarge,
            title,
            url,
        }, drawerLevel + 1);
    }

    render() {
        const {
            urls,
        } = this.props;

        return (<>
            {urls && urls.map((url, idx) => {
                return (<>
                    <a href={url} rel='noopener noreferrer' target='_blank'>
                        <i class='fa-duotone fa-link' />
                    </a>
                    <button
                        type='button'
                        class='btn btn-link ml-2 p-0'
                        onClick={this.viewUrl}
                        data-url={url}
                    >
                        {url}
                    </button>
                </>);
            })}
        </>);
    }
}

export default UrlList;
