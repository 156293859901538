import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Input from '../../components/form/input';
import GuiList from '../../components/gui/list';

const initialState = {};

const toIcalDate = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
};

@observer
class AddToCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    generateIcalFile = () => {
        const { events = [] } = this.props;

        const icsEvents = events.map(event => {
            const { title, startTime, endTime, location, description, url } = event;
            return [
                'BEGIN:VEVENT',
                `SUMMARY:${title}`,
                `DTSTART:${toIcalDate(new Date(startTime))}`,
                `DTEND:${toIcalDate(new Date(endTime))}`,
                `LOCATION:${location}`,
                `DESCRIPTION:${description}`,
                `URL:${url}`,
                'END:VEVENT'
            ].join('\n');
        }).join('\n');

        const icsMSG = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            icsEvents,
            'END:VCALENDAR'
        ].join('\n');

        const blob = new Blob([icsMSG], { type: 'text/calendar' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = 'MultipleEvents.ics';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    render() {
        const { events = [], classNames } = this.props;
        const { raceStore } = this.props.stores;

        if (events?.length === 0) return (<></>);

        return (<>
            <div class={`d-flex flex-column justify-content-start overflow-auto ${classNames ? classNames : 'mb-5 mt-3 p-4'}`}>
                {/* <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                    <Text id='action.add-to-calendar.title'>Add to calendar</Text>
                </div> */}

                {/* <div class='d-flex flex-column justify-content-start align-items-center'>
                    {events.map(event => {
                        return (<>
                            <xmp>{JSON.stringify(event, null, 4)}</xmp>
                        </>);
                    })}
                </div> */}

                <div class='w-100 d-flex flex-sm-row flex-column mt-0'>
                    <div class='w-100 d-flex flex-column'>
                        <Localizer>
                            <GuiList
                                title={<Text id='action.add-to-calendar.title'>Add to calendar</Text>}
                                stores={this.props.stores}
                                holdingStore={raceStore}
                                list={events}
                                elTitle={e => e.title}
                                elInfo1={e => {
                                    return (<>
                                        {util.formatDate(e.startTime, {
                                            month: 'short',
                                            day: 'numeric',
                                            year: 'numeric',
                                            hour12: false,
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            locale: 'nb-NO',
                                        }, true)}
                                        {e.startTime !== e.endTime && <>
                                            - {util.formatDate(e.endTime, {
                                                month: 'short',
                                                day: 'numeric',
                                                year: 'numeric',
                                                hour12: false,
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                locale: 'nb-NO',
                                            }, true)}
                                        </>}
                                    </>);
                                }}
                                elInfo2={e => ''}
                                elInfo3={e => ''}
                                hideEmpty={true}
                                showEmptyImage={false}
                                showFilterBox={false}
                                showCevron={false}
                                rightBoxField={'startTime'}
                                rightBoxFieldValue={e => util.formatDate(e, {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                    locale: 'nb-NO',
                                }, true)}

                                // editElement={editElement}
                            />
                        </Localizer>
                    </div>
                </div>


                {events?.length > 0 && <div class='w-100 d-flex flex-sm-row flex-column mt-5 px-3'>
                    <button
                        type='button'
                        class='btn btn-primary btn-lg btn-block rounded-pill'
                        onClick={this.generateIcalFile}
                    >
                        <Text id='action.download-ical-file'>Download iCal File</Text>
                    </button>
                </div>}

            </div>
        </>);
    }
}

export default AddToCalendar;
