import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class CompetitionResultStore extends LocalModel {
    constructor() {
        super('competitionResult', {
            namePlural: 'competitionResults',
            namePluralReal: 'competitionResults',
            sort: '-date',
            limit: 2000,
            api: {
                search: {
                    url: '/api/competitionresults/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/competitionresults/',
                    params: {},
                },
                save: {
                    url: '/api/competitionresults/',
                    params: {},
                },
                delete: {
                    url: '/api/competitionresults/',
                    params: {},
                },
            },
        });
    }

    @observable newCompetitionResult = {};

    @observable competitionResult = {};

    @observable competitionResults = [];

    @observable tags = [];
}

const store = new CompetitionResultStore();
export default store;
