import { h } from 'preact';

const StarRating = ({ rating }) => {
  // Create an array of 5 elements for 5 stars
  const stars = new Array(5).fill(0);

  return (
    <span>
        {stars.map((_, index) => {
            // Full stars for ratings equal or greater than index + 1
            if (rating >= index + 1) {
                return <i class='fa-solid fa-star'></i>;
            }
            // Half star if rating is between index and index + 1
            else if (rating > index && rating < index + 1) {
                return <i class='fa-solid fa-star-half-stroke'></i>;
            }
            // Empty stars for the rest
            else {
                return <i class='fa-regular fa-star'></i>;
            }
        })}
    </span>
  );
};

export default StarRating;