import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class RaceClassStore extends LocalModel {
    constructor() {
        super('raceClass', {
            namePlural: 'raceClasses',
            namePluralReal: 'raceClasses',
            sort: '-distance',
            limit: 500,
            api: {
                search: {
                    url: '/api/raceclasses/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/raceclasses/',
                    params: {},
                },
                save: {
                    url: '/api/raceclasses/',
                    params: {},
                },
                delete: {
                    url: '/api/raceclasses/',
                    params: {},
                },
            },
        });
    }

    @observable newRaceClass = {};

    @observable raceClass = {};

    @observable checkpoint = {};

    @observable prevCp = {};

    @observable raceClassContestants = [];

    @observable raceClasses = [];

    @observable adminRaces = [];

    @observable races = [];

    @observable events = [];

    @observable startorder = [];

    @observable geoJSON = [];

    @observable hasStarted = false;

    @action
    checkRaceClass = () => {
        try {
            if (new Date(this.raceClass?.startDate) < new Date()) {
                this.hasStarted = true;
            }
        } catch(e) {
            this.reportError(e);
        }
    }

    drawStartOrder = async (id, input) => {
        const response = await util.fetchApi(`/api/raceclasses/${id}/drawstartorder`, { publish: true, method: 'POST' }, { ...input });
        switch (response.status) {
            case 202:
                this.updateKeyValue('startorder', response.data?.startorder);
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async loadPublic(opt = { query: {} }) {
        const response = await util.fetchApi(`/api/raceclasses/public`, {  method: 'GET' }, { ...opt.query, limit: opt.limit || 1000 });
        switch (response.status) {
            case 200:
                this.updateKeyValue('raceClasses', response.data);
                return true;
                break;
            default:
                return false;
                break;
        }
    }
}

const store = new RaceClassStore();
export default store;
