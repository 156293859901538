import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    contactNumber: <Text id='input.contactNumber-help'>contactNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewContact extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('contact'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { contactStore } = this.props.stores;
        if (emptyFirst) {
            contactStore.updateKeyValue('contact', {});
        }
        await contactStore.load(id, false, { addData: ['tags'], query: {} });
    }

    createContact = async () => {
        const { callback = () => {} } = this.props;
        const { contactStore } = this.props.stores;
        const { newContact } = contactStore;

        const response = await contactStore.insert(newContact);

        contactStore.updateKeyValue('newContact', {});
        callback();
    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { contactStore } = this.props.stores;
        const { foundList } = contactStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        contactStore.updateKeyValue('newContact', {
            ...item,
        });
        contactStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, installation, lookupArray } = this.props;
        const { sections, showSimilar } = this.state;
        const { userStore, contactStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newContact, foundList, foundListKeys } = contactStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(installation, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='contact.new'>New contact</Text> : <Text id='contact.edit'>Edit contact</Text>}
                    </div>

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={contactStore}
                                objectName={isNew ? 'newContact' : 'contact'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                hideAdmin={true}
                                lookupArray={lookupArray}
                                rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar contacts ({foundList.length})</span> : null}
                                rightHeaderClick={this.toggleSimilar}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createContact}>
                    {isNew ? <Text id='contact.create'>Create contact</Text> : <Text id='contact.save'>Save contact</Text>}
                </button>
            </div>}
        </>);
    }
}

export default NewContact;
