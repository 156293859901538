import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import mu from '../../lib/util';

import LineGraph from '../../components/lineGraph';

@withText((props) => ({
    week: <Text id='workout.graph.week' />,
    distance: <Text id='workout.graph.distance' />,
    ascend: <Text id='workout.graph.ascend' />,
    plan: <Text id='workout.graph.plan' />,
}))
@observer
class MiniGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    setDays = () => {
        const { month, year, dataLeft } = this.props;

        const now = new Date();
        const priorDate = new Date(new Date().setDate(now.getDate() - 30));
        const currentMonth = parseInt(month || priorDate.getMonth() + 1, 10);
        const currentYear = parseInt(year || priorDate.getFullYear(), 10);
        const currentDay = priorDate.getDate();
        const today = util.isoDate(new Date(), false, false, true);
        util.padDate();
        const hours = util.hourRange(`${currentYear}-${util.padDate(currentMonth)}-${util.padDate(currentDay)}`, `${today}`);
        const inputData = [];
        dataLeft.forEach((data) => {
            const d = new Date(data.date);
            inputData.push({
                year: d.getFullYear(),
                month: d.getMonth() + 1,
                day: d.getDate(),
                hour: d.getHours(),
                qty: data.qty,
            });
        });

        const data = [];
        let lastVal = 0;
        hours.forEach((date, idx) => {
            const yyyy = date.year;
            const mm = date.month;
            const dd = date.day;
            const hh = date.hour;
            const hour = inputData.findLast(d => d.year === yyyy && d.month === mm && d.day === dd && d.hour === hh);
            lastVal = hour ? hour.qty : lastVal;
            const obj = {
                epoch: new Date(`${yyyy}-${mm}-${dd} ${hh}:00:00`).getTime(),
                x: idx,
                y: lastVal,
            };
            data.push(obj);
        });

        this.setState({ dataLeft: [data] });
    }


    componentDidMount() {
        this.setDays();
    }

    render() {
        const {
            fieldsLeft = [],
            legendLeft = [],
            fieldsRight = [],
            legendRight = [],
            showDebug,
            xTicksVal = val => util.isoDate(val, false, false, true),
            xTicksCnt = 5,
            yTicksLeftVal = val => val,
            yTicksRightVal = val => val,
        } = this.props;
        const {
            dataLeft = [],
            dataRight = [],
        } = this.state;

        const firstData = dataLeft && dataLeft[0] ? dataLeft[0] : [];
        const xTicks = [];
        if (firstData.length) {
            const partSize = Math.ceil(firstData.length / (xTicksCnt - 1));
            const parts = Math.floor(firstData.length / partSize);
            if (showDebug) {
                console.log({ firstData, parts, partSize });
            }
            for (let i = 0, l = firstData.length; i < l; i += partSize) {
                xTicks.push(firstData[i].epoch);
            }
            xTicks.push(firstData[firstData.length - 1].epoch);
        }
        return (
            <>
                {/* <xmp>{JSON.stringify(dataLeft, null, 4)}</xmp> */}
                <div class='w-100 mt-0 mb-0'>
                    {/* <xmp>graphWorkoutSummaryWeekDistanceCurrent: {JSON.stringify(graphWorkoutSummaryWeekDistanceCurrent, null, 4)}</xmp>
                    <xmp>graphWorkoutSummaryWeekDistancePrev: {JSON.stringify(graphWorkoutSummaryWeekDistancePrev, null, 4)}</xmp> */}
                    <LineGraph
                        stores={this.props.stores}
                        width={1500}
                        height={200}
                        showXTicks={1}
                        xTicksVal={xTicksVal}
                        xTicks={xTicks}
                        paddingTop={30}
                        paddingLeft={50}
                        yTicksLeftWidth={'50px'}
                        lineFromXzero={false}
                        lineFromXmax={false}
                        // xTicksLink={'/workouts/week/'}

                        dataLeft={dataLeft}
                        legendLeft={legendLeft}
                        // yMaxLeft={30}
                        // yMinLeft={0}
                        showYTicksLeft={1}
                        // yTicksLeftPostfix={'km'}
                        // unitLeft={['km', 'km', 'km']}
                        yTicksLeftVal={yTicksLeftVal}
                        avgWindowLeft={[2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]}
                        smoothLeft={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}

                        dataRight={dataRight}
                        legendRight={legendRight}
                        // dataRight={[graphWorkoutSummaryWeekElevationCurrent, graphWorkoutSummaryWeekElevationPrev]}
                        // legendRight={[`${seasonStart} ${ascend}`, `${seasonStart - 1} ${ascend}`]}
                        showYTicksRight={dataRight.length > 0 ? 1 : 0}
                        // yTicksRightPostfix={'m'}
                        // unitRight={['m', 'm']}
                        yTicksRightVal={yTicksRightVal}
                        avgWindowRight={[0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
                        smoothRight={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
                        scrollToRight={true}

                        // marker={week}
                    />
                </div>

            </>
        );
    }
}

export default MiniGraph;
