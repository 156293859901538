import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    customerNumber: <Text id='input.customerNumber-help'>customerNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class CustomerEdit extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('customer'),
        };
    }

    createCustomer = async () => {
        const { callback = () => {} } = this.props;
        const { customerStore } = this.props.stores;
        const { newCustomer } = customerStore;
        const response = await customerStore.adminCreate(newCustomer);
        if (response.status === 201) {
            callback();
        }
    }

    componentDidMount() {
        const { idx, isNew } = this.props;
        const { customerStore } = this.props.stores;
        if (!isNew) {
            customerStore.adminSetFromIdx(idx);
        }
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections } = this.state;
        const { userStore, customerStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newCustomer } = customerStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='customer.new'>New customer</Text> : <Text id='customer.edit'>Edit customer</Text>}
                    </div>
                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-4'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                fields={[
                                    'name',
                                    'orgNumber',
                                    'address',
                                    'postalcode',
                                    'place',
                                    'country',
                                    'phone',
                                    'fax',
                                    'email',
                                    'url',
                                    'facebook',
                                    'instagram',
                                    'emailSender',
                                    'smsSender',
                                    // 'smsTextBooking',
                                    // 'smsTextNotification',
                                    'invoiceAccount',
                                    'invoiceInfo',
                                    'invoiceDueDays',
                                    'calendarStartHour',
                                    'calendarEndHour',
                                    // 'dyreIdClinicId',
                                ]}
                                holdingStore={customerStore}
                                objectName={isNew ? 'newCustomer' : 'customer'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                            />
                        </div>
                    </div>
                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createCustomer}>
                            {isNew ? <Text id='customer.create'>Create customer</Text> : <Text id='customer.save'>Save customer</Text>}
                        </button>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default CustomerEdit;
