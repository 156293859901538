import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ConventionResultStore extends LocalModel {
    constructor() {
        super('conventionResult', {
            namePlural: 'conventionResults',
            namePluralReal: 'conventionResults',
            sort: '-createdDate',
            limit: 2000,
            api: {
                search: {
                    url: '/api/conventionresults/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/conventionresults/',
                    params: {},
                },
                save: {
                    url: '/api/conventionresults/',
                    params: {},
                },
                delete: {
                    url: '/api/conventionresults/',
                    params: {},
                },
            },
        });
    }

    @observable newConventionResult = {};

    @observable conventionResult = {};

    @observable conventionResults = [];

    @observable tags = [];

    generateArray(X) {
        const myNewShinyArray = [...Array(parseInt(X, 10) + 1).keys()];
        myNewShinyArray.shift();
        return myNewShinyArray;
    }

    getParticipantFields(convention) {
        if (convention.type === 'standard') {
            return ['150s', '150s', '150s', '150s', '20s', '20s', '20s', '20s', '10s', '10s', '10s', '10s'];
        }
        if (convention.type === 'hurtig') {
            return ['10s', '10s', '10s', '10s', '8s', '8s', '8s', '8s', '6s', '6s', '6s', '6s'];
        }
        if (convention.type === 'nais') {
            return ['150s', '150s', '5x3s', '5x3s', '20s', '10s'];
        }
        if (convention.type === 'norgesfelt') {
            return ['Treff', 'Skvr', 'iX', 'Trekk'];
        }
        if (convention.type === 'felt') {
            return ['Treff', 'Skvr', 'iX', 'Trekk'];
        }
        return [];
    }

    totalScore({ participant, convention, stage, isFieldShooting }) {
        const participantResults = this.conventionResults.filter(result => result.participant === participant.id
            && result.stage === stage.md5
            && result.convention === convention.id
        );
        let totalScore = 0;
        let totalIx = 0;
        let standScore = 0;

        if (isFieldShooting) {
            this.generateArray(stage.stages || 10).map(stand => {
                standScore = 0;
                const targetResult = participantResults.find(result => result.stand === stand);
                standScore += targetResult?.scoreHits || 0;
                standScore += targetResult?.scoreTargets || 0;
                if (targetResult?.scorePenalty) {
                    standScore -= targetResult.scorePenalty;
                }
                totalScore += standScore;
                totalIx += targetResult?.scoreInner || 0;
            });
        } else {
            this.getParticipantFields(stage).map((field, index) => {
                const targetResult = participantResults.find(result => result.targetIndex === index);
                if (targetResult) {
                    totalScore += targetResult.scoreTotal;
                }
            });
        }

        return { totalScore, totalIx };
    }
}

const store = new ConventionResultStore();
export default store;
