import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalAnimalListMini from '../../components/dyrejournal/listAnimalMini';
import DyrejournalPrescriptionListMini from '../../components/dyrejournal/listPrescriptionMini';
import DyrejournalJournalListMini from '../../components/dyrejournal/listJournalMini';
import Versions from '../../components/versions';

@observer
class ViewAnimal extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('animal', 'Animal basics'),
            sectionsInsurance: fields.get('animal', 'Animal insurance'),
            sectionsMedia: fields.get('animal', 'Animal media'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { journalStore, animalStore } = this.props.stores;
        await this.loadAnimal(id);

        const { animal } = animalStore;
        await this.loadSpecies();
        await this.loadBreeds(animal?.speciesId);
    }

    loadSpecies = async () => {
        const { animalStore } = this.props.stores;
        await animalStore.loadSpecies();
    }

    loadBreeds = async speciesId => {
        const { animalStore } = this.props.stores;
        await animalStore.loadBreeds(speciesId);
    }

    loadAnimal = async id => {
        const { animalStore } = this.props.stores;
        await animalStore.load(id, false, { addData: ['owners'] });
        const animalIds = [id];
        await this.loadJournals(animalIds);
    }

    loadJournals = async animalIds => {
        const { journalStore, saleStore } = this.props.stores;
        journalStore.updateKeyValue('journals', []);
        if (animalIds) {
            const response = await journalStore.load({
                query: {
                    animal: animalIds,
                },
            });
        }
    }

    createNewVisit = () => {
        const { drawerLevel } = this.props;
        const { appState, userStore, animalStore, saleStore } = this.props.stores;
        const { animal, owners } = animalStore;
        const owner = owners.find(e => e.id === animal.owner);

        saleStore.updateObjectKeyValue('newSale', 'visitors', [owner]);
        saleStore.updateObjectKeyValue('newSale', 'animals', [animal]);
        route(`/dyrejournal-sale/`);
        appState.toggleDrawer(drawerLevel);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            week,
            isNew,
            hideAdmin = true,
            drawerLevel = 1,
            showJournals = true,
        } = this.props;
        const {
            sections,
            sectionsInsurance,
            sectionsMedia,
        } = this.state;
        const { userStore, journalStore, animalStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { animal = {} } = animalStore;

        const { journals } = journalStore;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <Versions stores={this.props.stores} type='animal' id={animal?.id} key={`animal-${animal.id}`} />

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='dyrejournal.animal.view'>View Animal</Text> {animal?.id && <>: {animal.name} ({animal.species}/{animal.breed}) {animal.birth ? util.age(animal.birth) : ''}</>}
                    </div>

                    <DyrejournalJournalListMini
                        classNames='mx-1'
                        stores={this.props.stores}
                        journals={journals}
                        drawerLevel={drawerLevel}
                        collapse={true}
                    />

                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={animalStore}
                            objectName={isNew ? 'newAnimal' : 'animal'}
                            isNew={isNew}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                        />
                    </div>

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sectionsInsurance}
                            holdingStore={animalStore}
                            objectName={isNew ? 'newAnimal' : 'animal'}
                            isNew={false}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                        />
                    </div>

                    <div class='bg-light d-flex flex-column justify-content-start mt-4'>
                        <ViewFields
                            stores={this.props.stores}
                            sections={sectionsMedia}
                            holdingStore={animalStore}
                            objectName={isNew ? 'newAnimal' : 'animal'}
                            isNew={false}
                            hideAdmin={hideAdmin}
                            drawerLevel={drawerLevel}
                        />
                    </div>

                    <div class='w-100 d-flex flex-row justify-content-center mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill mx-2' onClick={this.createNewVisit}>
                            <Text id='dyrejournal.create-new-visit'>Create new visit</Text>
                        </button>
                    </div>

                </div>
            </div>
        );
    }
}

export default ViewAnimal;
