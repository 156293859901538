import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalSalePrescription from '../../components/dyrejournal/salePrescription';

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

@observer
class PrescriptionListMini extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    saveJournal = async () => {
        const { saveJournal = () => {} } = this.props;
        saveJournal();
    }

    onClickCreatePrescription = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { drawerLevel = 1, saleIsLocked = false } = this.props;

        // console.log('onClickCreatePrescription', e, drawerLevel);
        const { appState, saleStore, animalStore } = this.props.stores;
        const { drawerHeightMediumLarge } = appState;

        const { journalStore } = this.props.stores;
        await this.saveJournal();
        const { newJournal } = journalStore;

        appState.openDrawer('addPrescription', {
            height: drawerHeightMediumLarge,
            isNew: true,
            hideAdmin: true,
            saleIsLocked,
            callback: async prescription => {
                appState.toggleDrawer(false, drawerLevel + 1);
                if (prescription) {
                    // console.log('onClickCreatePrescription callback', prescription);
                    const { newJournal } = journalStore;
                    const { prescriptions } = newJournal;
                    const isFound = prescriptions.find(a => a.id === prescription.id);
                    if (!isFound) {
                        journalStore.updateObjectKeyValue('newJournal', 'prescriptions', [...prescriptions, prescription]);
                    }
                }
                await this.saveJournal();
            },
        }, drawerLevel + 1);
    }

    onClickRemovePrescription = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { journal, id, md5 } = e.target.closest('.prescription').dataset;

        // console.log('onClickRemovePrescription', { journal, id, md5 });
        const { journalStore } = this.props.stores;
        const { newJournal } = journalStore;
        journalStore.removeArrayObject({ field: 'prescriptions', id: journal, md5 });
        const { prescriptions } = newJournal;
        const newPrescriptions = prescriptions.filter(p => p.md5 !== md5);
        journalStore.updateObjectKeyValue('newJournal', 'prescriptions', newPrescriptions);
        await this.saveJournal();
    }

    onClickPrescription = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.prescription').dataset;
        // console.log('onClickPrescription', id);
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMediumLarge } = appState;
        const { journalStore } = this.props.stores;

        appState.openDrawer('viewPrescription', {
            height: drawerHeightMediumLarge,
            id: parseInt(id, 10),
            hideAdmin: true,
        }, drawerLevel + 1);
    }

    onClickPrintPrescription = async (e, prescription) => {
        e.stopPropagation();
        // console.log('onClickPrintPrescription', e);
        // Render Prescription component with data
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'no' } = user;
        const languageDef = countryMap[language || 'default'];

        // Load current animal and visitor
        const { animalStore, visitorStore, journalStore } = this.props.stores;
        const { animal } = prescription;
        const { newJournal } = journalStore;
        const { animal: journalAnimal } = newJournal;

        const animalId = animal || journalAnimal;
        if (!animalId) {
            alert('Ingen dyr funnet for denne resepten.');
            return;
        }

        const currentAnimals = await animalStore.load({ query: { id: animalId }, skipUpdate: true });
        const currentAnimal = currentAnimals[0];
        const currentVisitors = await visitorStore.load({ query: { id: currentAnimal.owner }, skipUpdate: true });
        const currentVisitor = currentVisitors[0];

        const prescriptionComponent = <IntlProvider definition={languageDef}>
            <DyrejournalSalePrescription
                stores={this.props.stores}
                prescription={prescription}
                currentAnimal={currentAnimal}
                currentVisitor={currentVisitor}
            />;
        </IntlProvider>;
        // Append to document
        const printDiv = document.createElement('div');
        printDiv.className = 'printPrescription';
        document.body.appendChild(printDiv);
        render(prescriptionComponent, printDiv);
        // Trigger print dialog
        window.print();
        // Remove the appended div
        document.body.removeChild(printDiv);
        // Re-render the receipt component to reset it
        render(null, printDiv);
    }

    onClickEmailPrescription = async (e, prescription) => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.prescription').dataset;
        // console.log('onClickPrescription', id);
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMediumLarge } = appState;

        appState.openDrawer('emailPrescription', {
            height: drawerHeightMediumLarge,
            prescription,
        }, drawerLevel + 1);
    }

    render() {
        const {
            prescriptions = [],
            saleIsLocked = false,
            journal,
            showButtons = true,
            classNames = 'mx-4',
        } = this.props;
        const { userStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
           {(prescriptions && prescriptions.length > 0) && <>
                {prescriptions.filter(p => p && (p.id || p.uuidv4)).map(prescription => <>
                    <div
                        class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} ${classNames} d-flex flex-column box-container mt-2 p-3 prescription`}
                        onClick={this.onClickPrescription}
                        data-journal={journal}
                        data-id={prescription.id}
                        data-md5={prescription.md5}
                    >
                        <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                            {prescription.date && <div class='flex-fill'>
                                {util.formatDate(prescription.date, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                            </div>}
                            {prescription.endDate && <div class='ml-2'>
                                {util.formatDate(prescription.endDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                            </div>}
                            {!saleIsLocked && <div class='ml-2' onClick={this.onClickRemovePrescription} data-id={prescription.id} style='font-size: 1.4em;'>
                                <i class='fa-solid fa-circle-xmark text-danger' />
                            </div>}
                            {/* <button
                                class={`btn btn-link ml-2 rounded-pill`}
                                onClick={(e) => this.onClickDeletePrescription(e, prescription)}
                            >
                                <i class='fa-solid fa-trash' /> <Text id='dyrejournal.delete-prescription'>Delete prescription</Text>
                            </button> */}
                        </div>
                        {prescription.drugs && prescription.drugs.length > 0 && <>
                            {prescription.drugs.map(drug => <>
                                <div class='d-flex flex-column border box-container mx-0 mt-2 bg-light p-3'>
                                    <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted'>
                                            <Text id='dyrejournal.prescription.drug'>Rp.</Text>
                                        </div>
                                        <div class='flex-fill ml-2'>
                                            {drug.navnFormStyrke} ({drug.pakningsinfoMengde} {drug.pakningsinfoEnhetPakningV})
                                            <br />
                                            <small class='text-muted'>
                                                {drug.preparattypeDn && <>Type: {drug.preparattypeDn}</>}
                                                {drug.atcDn && <>, <span class='font-weight-lighter'>ATC:</span> {drug.atcDn}</>}
                                                {drug.reseptgruppeV && <>, <span class='font-weight-lighter'>Grp:</span> {drug.reseptgruppeV}</>}
                                                {drug.smakDn && <>, <span class='font-weight-lighter'>Smak:</span> {drug.smakDn}</>}
                                                {drug.administrasjonsveiDn && <>, <span class='font-weight-lighter'>Adm:</span> {drug.administrasjonsveiDn}</>}
                                            </small>
                                        </div>
                                    </div>
                                    <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted'>
                                            <Text id='dyrejournal.prescription.dose'>Dssn.</Text>
                                        </div>
                                        <div class='flex-fill ml-2'>
                                            {drug.dosage && <>
                                                <Markdown markdown={drug.dosage} markedOpts={localUtil.getMarkdownOptions()} />
                                            </>}
                                            {drug.frequency && <>
                                                {/* {drug.frequency}<br /> */}
                                                Frekvens: <Markdown markdown={drug.frequency} markedOpts={localUtil.getMarkdownOptions()} />
                                            </>}
                                            <small class='text-muted'>
                                                {drug.forhandsregelInntakDn && <>
                                                    {/* Merk: {drug.forhandsregelInntakDn} */}
                                                    Merk:
                                                    <Markdown markdown={drug.forhandsregelInntakDn} markedOpts={localUtil.getMarkdownOptions()} />
                                                </>}
                                            </small>
                                            {drug.description && <>
                                                <small class='text-muted'>
                                                    {/* {drug.description} */}
                                                    <Markdown markdown={drug.description} markedOpts={localUtil.getMarkdownOptions()} />
                                                </small>
                                            </>}
                                            {drug.additionalNotes && <>
                                                <small class='text-muted'>
                                                    {/* {drug.description} */}
                                                    <Markdown markdown={drug.additionalNotes} markedOpts={localUtil.getMarkdownOptions()} />
                                                </small>
                                            </>}
                                        </div>
                                    </div>
                                    {drug.reit && <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted'>
                                            <Text id='dyrejournal.prescription.reit'>Reit</Text>
                                        </div>
                                        <div class='flex-fill ml-2'>
                                            {drug.reit}
                                        </div>
                                    </div>}
                                    {/* {drug.preparatomtaleavsnittLenke && <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                        <div class='text-muted'>
                                            <Text id='dyrejournal.prescription.about'>About</Text>
                                        </div>
                                        <div class='flex-fill ml-2'>
                                            <a href={drug.preparatomtaleavsnittLenke} native target='_blank'>{drug.preparatomtaleavsnittAvsnittoverskriftDn}</a>
                                        </div>
                                    </div>} */}
                                </div>
                            </>)}
                        </>}

                        {showButtons && <div class='d-flex flex-row justify-content-between px-3 py-1'>
                            <button
                                class={`btn btn-danger ml-2 rounded-pill mt-2`}
                                onClick={(e) => this.onClickPrintPrescription(e, prescription)}
                            >
                                <i class='fa-solid fa-print' /> <Text id='dyrejournal.print-prescription'>Print prescription</Text>
                            </button>
                            <button
                                class={`btn btn-primary ml-2 rounded-pill mt-2`}
                                onClick={(e) => this.onClickEmailPrescription(e, prescription)}
                            >
                                <i class='fa-solid fa-paper-plane' /> <Text id='dyrejournal.email-prescription'>Email prescription</Text>
                            </button>
                        </div>}

                    </div>
                </>)}
            </>}

            {!(prescriptions && prescriptions.length > 0) && <>
                <div class={`d-flex flex-column justify-content-center w-100 my-1 text-muted ${classNames}`}>
                    <div class='w-100 text-center' style='font-size: 1.5em;'>
                        <i class='fa-solid fa-prescription' />
                    </div>
                    <div class='w-100 text-center' style='font-size: 1.0em;'>
                        <Text id='dyrejournal.prescription.no-prescription'>No prescription for this journal</Text>
                    </div>
                </div>
            </>}

            {!saleIsLocked && <div class={`d-flex flex-row justify-content-center mt-3 px-3 ${classNames}`}>
                <button class='btn btn-sm btn-outline-primary rounded-pill' onClick={this.onClickCreatePrescription}>
                    <i class='fa-solid fa-prescription mr-2' /> <Text id='dyrejournal.prescription.new'>New prescription</Text>
                </button>
            </div>}

        </>);
    }
}

export default PrescriptionListMini;
