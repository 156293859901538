import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

@observer
class InspectionStatus extends Component {
    render() {
        const {
            installation
        } = this.props;

        return (<>
            {installation.inspectionStatus === 'success' && <i class={`fa-solid fa-face-smile text-${installation.inspectionStatus}`} />}
            {installation.inspectionStatus === 'warning' && <i class={`fa-solid fa-face-meh text-${installation.inspectionStatus}`} />}
            {installation.inspectionStatus === 'danger' && <i class={`fa-solid fa-face-frown text-${installation.inspectionStatus}`} />}
            {installation.inspectionStatus === 'secondary' && <i class={`fa-solid fa-face-scream text-${installation.inspectionStatus}`} />}
        </>);
    }
}

export default InspectionStatus;
