import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import md5 from 'crypto-js/md5';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import CalendarAvailability from '../../components/calendar/availability';

function getNextQuarterHour(addMinutes = 0) {
    const now = new Date();
    let minutes = now.getMinutes();
    let quarterHours = Math.ceil(minutes / 15) * 15;
    if (quarterHours === 60) { // If it's on the hour, move to the next hour
        now.setHours(now.getHours() + 1);
        quarterHours = 0;
    }
    now.setMinutes(quarterHours);
    now.setSeconds(0);
    now.setMilliseconds(0);

    if (addMinutes) {
        now.setMinutes(now.getMinutes() + addMinutes);
    }
    return util.isoDate(now);
}

function formatDate(date) {
    return new Intl.DateTimeFormat('nb-NO', { year: 'numeric', month: 'long' }).format(date);
}

@withText(props => ({
    calendarAvailabilityNumber: <Text id='input.calendarAvailabilityNumber-help'>calendarAvailabilityNumber</Text>,
    onlySameWeek: <Text id='calendarAvailabilityNumber.only-same-week'>You can only select days in the same week if you want to duplicate.</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewCalendarAvailability extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('calendar'),
            daysInMonth: [],
            selectedDays: [],
            hourRange: [],
            startTime: '08:00',
            endTime: '16:00',
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { calendarAvailabilityStore, calendarStore } = this.props.stores;
        const { startHour = 6, endHour = 20 } = calendarStore;
        const hourRange = util.range(startHour, endHour);
        // console.log({ hourRange });
        this.setState({
            hourRange,
        });
        if (emptyFirst) {
            calendarAvailabilityStore.updateKeyValue('calendarAvailability', []);
        }
        await this.loadCalendars(props);
        await this.loadCalendarAvailability(props);
        this.insertDates();
    }

    loadCalendarAvailability = async (props = this.props) => {
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        const { calendarDate } = calendarStore;
        const daysInMonth = calendarStore.daysInMonth();
        // console.log({ daysInMonth })
        await calendarAvailabilityStore.load({
            query: {
                start: util.isoDate(daysInMonth[0].date, false, false, true),
                end: util.isoDate(daysInMonth[daysInMonth.length - 1].date, false, false, true),
            },
        });
    }

    loadCalendars = async (props = this.props) => {
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        const calendarList = await calendarStore.load({ query: { type: 'booking' }, skipUpdate: true });

        calendarStore.updateKeyValue('availabilityCalendars', calendarList);
        const daysInMonth = calendarStore.daysInMonth();
        this.setState({
            daysInMonth,
        });
    }

    insertDates = (props = this.props) => {
        const { startDate = getNextQuarterHour(), endDate = getNextQuarterHour(15), calendarType } = props;
        const { calendarAvailabilityStore } = this.props.stores;

        let oneYearFromNow;
        if (calendarType === 'notification') {
            oneYearFromNow = util.addDays(startDate, 365);
            const oneYearFromNowPluss1Hour = util.addSeconds(oneYearFromNow, 900);
            calendarAvailabilityStore.updateObjectKeyValue('newCalendarAvailability', 'start', oneYearFromNow);
            calendarAvailabilityStore.updateObjectKeyValue('newCalendarAvailability', 'end', oneYearFromNowPluss1Hour);
        } else {
            calendarAvailabilityStore.updateObjectKeyValue('newCalendarAvailability', 'start', startDate);
            calendarAvailabilityStore.updateObjectKeyValue('newCalendarAvailability', 'end', endDate);
        }
    }

    createCalendarAvailability = async () => {
        const { callback = () => {}, installation, onlySameWeek } = this.props;
        const { calendarAvailabilityStore, installationStore } = this.props.stores;
        // const { newCalendarAvailability } = calendarAvailabilityStore;
        // const response = await calendarAvailabilityStore.insert(newCalendarAvailability);
        // const calendarAvailability = response?.data?.calendarAvailability;
        // calendarAvailabilityStore.updateKeyValue('newCalendarAvailability', {});
        // callback();

        const { selectedDays, startTime, endTime, repeatWeeks = 1 } = this.state;
        // console.log({ selectedDays, startTime, endTime, repeatWeeks });
        // Check if all selected days are in same week
        const firstDay = selectedDays[0];
        const firstDate = new Date(firstDay.date);
        const firstWeek = util.getWeek(firstDate);
        const isSameWeek = selectedDays.every(day => {
            const date = new Date(day.date);
            const week = util.getWeek(date);
            return week === firstWeek;
        });
        if (repeatWeeks > 1 && !isSameWeek) {
            alert(onlySameWeek);
            return;
        }

        const objects = [];
        for (let i = 0; i < repeatWeeks; i += 1) {
            selectedDays.forEach(day => {
                const currentDate = new Date(day.date);
                currentDate.setDate(currentDate.getDate() + (i * 7));
                const start = `${util.isoDate(currentDate, false, false, true)}T${startTime}:00`;
                const end = `${util.isoDate(currentDate, false, false, true)}T${endTime}:00`;
                objects.push({
                    start,
                    end,
                    calendar: day.calendar,
                    title: `${startTime} - ${endTime}`,
                });
            });
        }
        // console.log({ objects });
        await calendarAvailabilityStore.insertMany(objects);
        this.setState({
            selectedDays: [],
        });
        this.loadCalendarAvailability();
    }

    toggleDate = (e) => {
        const { selectedDays } = this.state;
        const { date, calendar } = e.target.closest('td').dataset;

        const calendarInt = parseInt(calendar, 10);

        const isSelected = selectedDays.find(day => day.date === date && day.calendar === calendarInt);
        if (isSelected) {
            this.setState({
                selectedDays: selectedDays.filter(day => day.date !== date || day.calendar !== calendarInt),
            });
        } else {
            this.setState({
                selectedDays: [...selectedDays, { date, calendar: calendarInt }],
            });
        }
        // console.log({ date, calendarInt });
    }

    viewBooking = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { id, date, calendar } = e.target.closest('td').dataset;

        const { appState, calendarStore } = this.props.stores;

        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { drawerHeightMedium } = appState;

        appState.openDrawer('viewCalendarAvailability', {
            height: drawerHeightMedium,
            id: parseInt(id, 10),
            callback: event => {
                appState.toggleDrawer(false, drawerLevel + 1);
                calendarStore.resetDragging();
                this.loadCalendarAvailability();
            },
        }, drawerLevel + 1);
    }

    clickNextMonth = e => {
        const { calendarStore } = this.props.stores;
        calendarStore.calendarNextMonth();
        this.loadAll();
    }

    clickPrevMonth = e => {
        const { calendarStore } = this.props.stores;
        calendarStore.calendarPrevMonth();
        this.loadAll();
    }

    toggleCalAvail = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { id, value } = e.target.dataset;
        const { calendarStore } = this.props.stores;
        if (value === 'true') {
            calendarStore.updateFieldByName({
                namePlural: 'availabilityCalendars',
                id,
                field: 'useCalendarAvailability',
                value: false,
            });
            await calendarStore.save({
                useCalendarAvailability: false,
            }, id);
        } else {
            calendarStore.updateFieldByName({
                namePlural: 'availabilityCalendars',
                id,
                field: 'useCalendarAvailability',
                value: true,
            });
            calendarStore.save({
                useCalendarAvailability: true,
            }, id);
        }
        const { callback = () => {} } = this.props;
        callback();
        await this.loadCalendars();
        // console.log('toggleCalAvail', { id, value });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, installation, lookupArray, id } = this.props;
        const { sections, showSimilar, daysInMonth, selectedDays, hourRange, startTime, endTime, repeatWeeks } = this.state;
        const { userStore, calendarAvailabilityStore, calendarStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { availabilityCalendars, calendarDate, calendarDateStart, calendarDateEnd, calendarHours, viewName  } = calendarStore;
        const { newCalendarAvailability, foundList, foundListKeys } = calendarAvailabilityStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div
                    class='w-100 h-100'
                    style='
                        // max-width: 960px;
                        padding-top: 50px;
                        padding-bottom: 100px;
                    '
                >
                    <div class='font-weight-lighter px-3 mt-0 box-header'>
                        <Text id='calendaravailability.title'>Rotation Schedule</Text>
                    </div>

                    <div class='d-flex justify-content-between align-items-center my-2 px-3'>
                        <div>
                            &nbsp;
                        </div>
                        <div class='d-flex flex-column w-50'>
                            <div class='d-flex flex-row w-100 justify-content-center'>
                                <button class={`btn btn-primary rounded-pill-left`} onClick={this.clickPrevMonth}>
                                    <i class='fa-duotone fa-caret-left' />
                                </button>
                                <button class={`flex-fill btn btn-outline-primary rounded-none`} disabled>
                                    {formatDate(calendarDate)}
                                </button>
                                <button class={`btn btn-primary rounded-pill-right`} onClick={this.clickNextMonth}>
                                    <i class='fa-duotone fa-caret-right' />
                                </button>
                            </div>
                        </div>
                        <div>
                            &nbsp;
                        </div>
                    </div>

                    <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3  mt-3 d-flex flex-column px-0 overflow-auto p-2`}>
                        <div class='table-responsive'>
                            <small>
                                <table class='table table-sm table-bordered table-striped'>
                                    <thead>
                                        <tr>
                                            <th class='bg-light' style={`
                                                position: sticky;
                                                left: 0;
                                                z-index: 1;
                                            `}>
                                                <Text id='calendarAvailability.calendar'>Calendar</Text>
                                            </th>
                                            {daysInMonth.map(day => {
                                                const dayOfWeek = day.dayOfWeek;
                                                const isToday = day.date.toDateString() === new Date().toDateString();
                                                return (<>
                                                    <th
                                                        class={`${(dayOfWeek === 6 || dayOfWeek === 0) ? 'text-danger' : ''}`}
                                                        style={`width: 100px !important; `}
                                                    >
                                                        <nobr>
                                                            {isToday ? <i class='fa-duotone fa-circle text-primary mr-1' /> : ''}
                                                            {calendarStore.getDayName(day.dayOfWeek)} {util.formatDate(day.date, { day: '2-digit', month: '2-digit', locale: 'nb-NO' }, true)}
                                                        </nobr>
                                                    </th>
                                                </>);
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {availabilityCalendars?.length > 0 && availabilityCalendars.map(cal => {
                                            return (<>
                                                <tr>
                                                    <td class='bg-light' style={`
                                                        position: sticky;
                                                        left: 0;
                                                        z-index: 1;
                                                    `}>
                                                        <div class='d-flex flex-row justify-content-between'>
                                                            {cal.name}
                                                            {cal.useCalendarAvailability ? <>
                                                                <i class='fa-duotone fa-circle-check ml-2 text-success' onClick={this.toggleCalAvail} data-id={cal.id} data-value={cal.useCalendarAvailability} />
                                                            </> : <>
                                                                <i class='fa-duotone fa-circle-play ml-2 text-warning' onClick={this.toggleCalAvail} data-id={cal.id} data-value={cal.useCalendarAvailability} />
                                                            </>}
                                                        </div>
                                                    </td>
                                                    {daysInMonth.map(day => {
                                                        const dayOfWeek = day.dayOfWeek;
                                                        const events = calendarAvailabilityStore.getEventsForCalendar(day.date, cal.id);
                                                        const isSelected = selectedDays.find(d => d.date ===  util.isoDate(day.date, false, false, true) && d.calendar === cal.id);
                                                        return (<>
                                                            <td
                                                                class={`${isSelected ? 'bg-primary text-white' : ''} ${events.length > 0 ? 'bg-success text-white' : ''}`}
                                                                style={`width: 100px !important; ${(dayOfWeek === 6 || dayOfWeek === 0) ? 'background-color: rgba(220, 53, 69, 0.10);' : ''}`}
                                                                onClick={events.length > 0 ? this.viewBooking : this.toggleDate}
                                                                data-date={util.isoDate(day.date, false, false, true)}
                                                                data-calendar={cal.id}
                                                                data-id={events.map(e => e.id).join(',')}
                                                            >
                                                                <nobr>
                                                                    {events.length ? events.map(event => {
                                                                        return (<> {event.title} <br /> </>);
                                                                    }) : <> &nbsp; </>}
                                                                </nobr>
                                                            </td>
                                                        </>);
                                                    })}
                                                </tr>
                                            </>);
                                        })}
                                    </tbody>
                                </table>
                            </small>
                        </div>

                        <div class='d-flex flex-row justify-content-center align-items-center mt-3 mb-3'>
                            <div class='d-flex flex-row justify-content-start align-items-center'>
                                <span class='mr-2'>
                                    <Text id='calendaravailability.start'>Start</Text>
                                </span>
                                <select
                                    class='form-control'
                                    onChange={linkstate(this, 'startTime')}
                                    value={startTime}
                                >
                                    {hourRange && hourRange.map(hour => {
                                        return (<>
                                            {[0, 15, 30, 45].map(minute => {
                                                return (<>
                                                    <option value={`${util.padDate(hour)}:${util.padDate(minute)}`}>{util.padDate(hour)}:{util.padDate(minute)}</option>
                                                </>);
                                            })}
                                        </>);
                                    })}
                                </select>
                            </div>
                            <div class='d-flex flex-row justify-content-start align-items-center ml-5'>
                                <span class='mr-2'>
                                    <Text id='calendaravailability.end'>End</Text>
                                </span>
                                <select
                                    class='form-control'
                                    onChange={linkstate(this, 'endTime')}
                                    value={endTime}
                                >
                                    {hourRange && hourRange.map(hour => {
                                        return (<>
                                            {[0, 15, 30, 45].map(minute => {
                                                return (<>
                                                    <option value={`${util.padDate(hour)}:${util.padDate(minute)}`}>{util.padDate(hour)}:{util.padDate(minute)}</option>
                                                </>);
                                            })}
                                        </>);
                                    })}
                                </select>
                            </div>
                            <div class='d-flex flex-row justify-content-start align-items-center ml-5'>
                                <span class='mr-2'>
                                    <nobr><Text id='calendaravailability.repeat-setup'>Repeat for X weeks</Text></nobr>
                                </span>
                                <input
                                    type='number'
                                    class='form-control'
                                    onChange={linkstate(this, 'repeatWeeks')}
                                    value={repeatWeeks}
                                    size={3}
                                />
                            </div>
                        </div>

                        <div class='d-flex flex-column justify-content-center align-items-center mt-3 mb-3'>
                            <small class='text-muted'>
                                <h5><Text id='calendarAvailability.help-title'>Help</Text></h5>
                                <Text id='calendarAvailability.help-intro'>To create a new rotation scheduled quickly:</Text>
                                <ol>
                                    <li><Text id='calendarAvailability.help-step1'>Choose working days.</Text></li>
                                    <li><Text id='calendarAvailability.help-step2'>Choose working time.</Text></li>
                                    <li><Text id='calendarAvailability.help-step3'>Click the create button at the bottom of the screen.</Text></li>
                                    <li>
                                        <Text id='calendarAvailability.help-step4'>Click the icon on the right of the name to enable rotation plan.</Text> <i class='fa-duotone fa-circle-play ml-2 text-warning' />
                                    </li>
                                </ol>
                            </small>

                            <small class='text-muted'>
                                <Text id='calendarAvailability.help-delete'>Delete a scheduled day:</Text>
                                <ol>
                                    <li><Text id='calendarAvailability.help-delete-step1'>Click the green day you want to delete.</Text></li>
                                    <li><Text id='calendarAvailability.help-delete-step2'>Click the delete button at the bottom of the screen.</Text></li>
                                </ol>
                            </small>

                        </div>
                    </div>

                    {/* selectedDays: <xmp>{JSON.stringify(selectedDays, null, 2)}</xmp> */}
                </div>
            </div>

            <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createCalendarAvailability}>
                    <i class='fa-solid fa-plus' /> <Text id='calendarAvailability.create'>Create rotation plan</Text>
                </button>
            </div>
        </>);
    }
}

export default NewCalendarAvailability;
