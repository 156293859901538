import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    titleText: <Text id='race-class.title'>Race Classes</Text>,
    signupsText: <Text id='race.signups'>signups</Text>,
}))
@observer
class RaceClassList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewRaceClass = e => {
        const { view } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        route(`/raceclass/${id}`);
    }

    render() {
        const { appState, userStore, raceStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const { race } = raceStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText, signupsText } = this.props;
        const {
            raceClasses,
            classNames = '',
            hideEmpty,
            title = titleText,
            editElement,
            showCevron,

            rightBoxField = 'distance',
            rightBoxFieldValue = e => e,
            rightBoxFieldPostfix = 'km',
            rightBoxFieldPostfixField = 'startDate',
            rightBoxFieldPostfixValue = e => util.formatDate(e, { year: 'numeric', locale: 'nb-NO' }, true),
            help,
        } = this.props;

        return (<>
            <GuiList
                stores={this.props.stores}
                holdingStore={raceStore}
                view={this.viewRaceClass}
                classNames={classNames}
                title={title}
                list={raceClasses}
                elTitle={e => e.title}
                elInfo1={e => e.startDate ? util.formatDate(e.startDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' }) : util.formatDate(race.startDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                elInfo2={e => ''}
                elInfo3={e => e.totalContestants ? <><i class='fa-duotone fa-user' /> {e.totalContestants}</> : ''}
                hideEmpty={hideEmpty}
                editElement={editElement}
                showEmptyImage={false}
                showCevron={showCevron}
                showFilterBox={false}
                rightBoxField={rightBoxField}
                rightBoxFieldValue={rightBoxFieldValue}
                rightBoxFieldPostfix={rightBoxFieldPostfix}
                help={help}
            />
        </>);
    }
}

export default RaceClassList;
