import { observable, configure, action, computed, toJS } from 'mobx';
import StoreModel from 'preact-storemodel';
import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';
import md5 from 'crypto-js/md5';

import localUtil from '../lib/util';

configure({ enforceActions: 'always' });

const isDevelopment = process.env.NODE_ENV === 'development';

function consoleLog(...args) {
    if (isDevelopment) {
        console.log(...args);
    }
}

class LocalModel extends StoreModel {
    @observable refreshPage = () => {};

    @observable refreshPageFunctions = {};

    @observable loadMore = () => { if (isDevelopment) console.log(`loadMore not set for ${this.name}`); };

    @observable intersectionElements = [];

    @observable intersectionHistory = [];

    @observable adminList = [];

    @observable adminInstallations = [];

    @observable adminMembers = [];

    @observable adminParts = [];

    @observable logs = [];

    @observable newObject = {};

    @observable foundList = [];

    @observable foundListKeys = [];

    @observable feedback = {};

    @observable feedbackError = {};

    @observable feedbackButtonFunc = {};

    @observable feedbackButtonTitle = {};

    @observable feedbackButtonInfo = {};

    @observable feedbackButtonProgress = {};

    @observable feedbackButtonDone = {};

    @observable searchResult = [];

    @action
    setFeedback(field, value) {
        this.updateObjectKeyValue('feedback', field, value);
    }

    @action
    setFeedbackError(field, value) {
        this.updateObjectKeyValue('feedbackError', field, value);
    }

    @action
    setFeedbackButtonInfo(field, value) {
        this.updateObjectKeyValue('feedbackButtonInfo', field, value);
    }

    @action
    setFeedbackButtonProgress(field, value) {
        this.updateObjectKeyValue('feedbackButtonProgress', field, value);
    }

    @action
    setFeedbackButtonDone(field, value) {
        this.updateObjectKeyValue('feedbackButtonDone', field, value);
    }

    @action
    setFeedbackButton(field, func, title) {
        this.updateObjectKeyValue('feedbackButtonFunc', field, func);
        this.updateObjectKeyValue('feedbackButtonTitle', field, title);
    }

    // async load($id = '', append, $opt) {
    //     this.setFeedback('loading', true);

    // NOT working!!!
    //     async load($id = '', append, $opt) {
    //         const hrStart = this.hrStart();
    // console.log('load', { $id, append, $opt })
    //         await super.load($id, append, $opt);
    //         this.addLog(hrStart, `${this.name}.load()`, 'load');
    //     }

    // afterLoad(response, opt) {
    //     console.log('afterLoad', { response, opt })
    // }

    /**
     * Updates the value of an array element in an object property.
     * @param {string} obj - The name of the object to update.
     * @param {string} key - The name of the property to update.
     * @param {number} idx - The index of the array element to update.
     * @param {*} value - The new value to set for the array element.
     * @returns {void}
     */
    @action
    updateObjectKeyArrValue(obj, key, idx, value) {
        this[obj][key][idx] = value;
    }

    /**
     * Updates the value of a field in an array element of an object property.
     * @param {string} obj - The name of the object to update.
     * @param {string} key - The name of the property to update.
     * @param {number} idx - The index of the array element to update.
     * @param {string} field - The name of the field to update.
     * @param {*} value - The new value to set for the field.
     * @returns {void}
     */
    @action
    updateObjectKeyArrFieldValue(obj, key, idx, field, value) {
        this[obj][key][idx][field] = value;
    }

    /**
     * Updates the value of an array element in the local model.
     * @param {string} key - The name of the property to update.
     * @param {number} idx - The index of the array element to update.
     * @param {*} value - The new value to set for the array element.
     * @returns {void}
     */
    @action
    updateKeyArrValue(key, idx, value) {
        this[key][idx] = value;
    }

    @action
    updateArrayKeyValue(obj, idx, key, value) {
        this[obj][idx][key][field] = value;
    }

    /**
     * Updates the value of a field in an object array element in the local model.
     * @param {string} key - The name of the property to update.
     * @param {number} idx - The index of the array element to update.
     * @param {string} field - The name of the field to update.
     * @param {*} value - The new value to set for the field.
     * @returns {void}
     **/
    @action
    updateObjectFieldByName({ objectName, namePlural = this.namePlural, id, field, value, findBy = 'id' }) {
        if (util.isObject(this[objectName]) && util.isArray(this[objectName][namePlural])) {
            const idx = this[objectName][namePlural].findIndex(e => e[findBy] === id);
            // consoleLog('updateField', id, field, value, idx, this[this.name]);
            if (idx >= 0) {
                const obj = this[objectName][namePlural][idx];
                util.setNestedValue(obj, field, value);
            }
        }
    }

    /**
     * Resets the search results in the local model.
     * @returns {void}
     */
    @action
    resetSearch() {
        this.updateKeyValue('foundList', []);
        this.updateKeyValue('foundListKeys', []);
    }

    /**
     * Loads data from the server for the admin view.
     * @param {Object} object - The object to load data for.
     * @param {Object} opt - The options for loading data.
     * @param {number} opt.offset - The offset to start loading data from.
     * @param {number} opt.limit - The maximum number of items to load.
     * @param {string} opt.sort - The field to sort the data by.
     * @param {Array<string>} opt.addData - Additional data to load.
     * @returns {Promise<Object>} A Promise that resolves with the server response.
     */
    async adminLoad(object = {}, opt = {}) {
        let hrStart = this.hrStart();

        // console.log('adminLoad', object, this.namePluralReal, this.namePlural)
        // console.log('adminLoad', { object, opt });
        let addData;
        if (Array.isArray(opt.addData) && opt.addData.length > 0) {
            addData = opt.addData.join(',');
        }

        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/admin/`, { publish: true, method: 'GET' }, util.cleanObject({
            ...object,
            addData,
            offset: opt.offset,
            limit: opt.limit,
            sort: opt.sort,
        }));
        hrStart = this.addLog(hrStart, 'await util.fetchApi', `adminLoad.${this.namePluralReal || this.namePlural}`);
        switch (response.status) {
            case 200:
                if (opt.skipUpdate) {
                    return response;
                }
                if (opt.offset > 0) {
                    // Append data to existing lists
                    const newAdminList = this.adminList.concat(response.data);
                    this.updateKeyValue('adminList', newAdminList);
                    if (Array.isArray(opt.addData)) {
                        opt.addData.forEach(dataElement => {
                            if (response[dataElement]) {
                                this.updateKeyValue(`admin${util.ucfirst(dataElement)}`, response[dataElement]);
                            } else if (response.included && response.included[dataElement]) {
                                const newAddList = this[`admin${util.ucfirst(dataElement)}`].concat(response.included[dataElement]);
                                this.updateKeyValue(`admin${util.ucfirst(dataElement)}`, newAddList);
                            }
                        });
                    }

                } else {
                    this.updateKeyValue('adminList', response.data);
                    if (Array.isArray(opt.addData)) {
                        opt.addData.forEach(dataElement => {
                            if (response[dataElement]) {
                                this.updateKeyValue(`admin${util.ucfirst(dataElement)}`, response[dataElement]);
                            } else if (response.included && response.included[dataElement]) {
                                this.updateKeyValue(`admin${util.ucfirst(dataElement)}`, response.included[dataElement]);
                            }
                        });
                    }
                }
                hrStart = this.addLog(hrStart, 'preparedContent', `adminLoad.${this.namePluralReal || this.namePlural}`);
                return response;
            case 401:
                break;
        }
    }

    /**
     * Updates a local object with new data for the admin view.
     * @param {string} id - The ID of the object to update.
     * @param {Object} object - The object containing the new data.
     * @param {string} objectName - The name of the object to update.
     * @returns {void}
     */
    adminUpdateLocal(id, object = {}, objectName) {
        if (util.isObject(object)) {
            Object.keys(object).forEach((key) => {
                this.updateField(id, key, object[key]);
                this.updateFieldByName({
                    namePlural: objectName,
                    name: `${objectName}Single`,
                    id: parseInt(id, 10),
                    field: key,
                    value: object[key],
                });
            });
        }
    }

    /**
     * Updates an object on the server for the admin view.
     * @param {string} id - The ID of the object to update.
     * @param {Object} object - The object containing the new data.
     * @param {string} objectName - The name of the object to update.
     * @returns {Promise<Object>} A Promise that resolves with the server response.
     */
    async adminUpdate(id, object = {}, objectName) {
        // console.log('adminUpdate', id, object, objectName);
        // updateFieldByName({ namePlural = this.namePlural, name = this.name, id, field, value, findBy = 'id' }) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/admin/${id}`, { publish: true, method: 'PATCH' }, {
            ...object,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                return response;
            case 401:
                break;
        }
    }

    /**
     * Creates a new object on the server for the admin view.
     * @param {Object} object - The object to create.
     * @returns {Promise<Object>} A Promise that resolves with the server response.
     */
    async adminCreate(object = {}) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/admin/`, { publish: true, method: 'POST' }, {
            ...object,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 201:
                return response;
            case 401:
                break;
        }
    }

    /**
     * Adds a new object to an array field in the local model.
     * @param {Object} options - The options for adding the object.
     * @param {Object} options.newObject - The new object to add.
     * @param {Object} options.object - The object to add the new object to.
     * @param {string} options.field - The name of the array field to add the new object to.
     * @param {string} options.objectName - The name of the object to update in the local model.
     * @returns {Promise<void>} A Promise that resolves when the object has been added.
     */
    async addArrayObject({ newObject, object, field, objectName }) {
        const localValue = object[field] ? [...object[field]] : [];
        const value = {
            ...newObject,
            md5: md5(JSON.stringify(newObject)).toString(),
            date: new Date(),
        };
        await this.saveField(object.id, field, value);
        this.updateField(object.id, field, [...localValue, value]);
        if (objectName) {
            this.updateObjectKeyValue(objectName, field, [...localValue, value]);
        }
	}

    /**
     * Sets the current object in the local model based on its index in the list.
     * @param {number} idx - The index of the object in the list.
     * @returns {void}
     */
    @action
    setFromIdx(idx) {
        // console.log('setFromIdx', idx, this[this.namePlural])
        if (idx >= 0  && this[this.namePlural] && this[this.namePlural][idx]) {
            this[this.name] = this[this.namePlural][idx];
        }
    }

    /**
     * Sets the current object in the local model based on its index in the admin list.
     * @param {number} idx - The index of the object in the admin list.
     * @returns {void}
     */
    @action
    adminSetFromIdx(idx) {
        // console.log('adminSetFromIdx', idx, this[this.namePlural])
        if (idx >= 0  && this.adminList && this.adminList[idx]) {
            this[this.name] = this.adminList[idx];
        }
    }

    /**
     * Sets the current object in the local model based on id in the admin list.
     * @param {number} id - The id of the object in the admin list.
     * @returns {void}
     */
    @action
    adminSetFromId(id) {
        // console.log('adminSetFromId', idx, this[this.namePlural])
        const idInt = parseInt(id, 10);
        if (idInt >= 0  && this.adminList) {
            const idx = this.adminList.findIndex(e => e.id === idInt);
            this[this.name] = this.adminList[idx];
        }
    }

    /**
     * Removes an image from an object's images array in the local model.
     * @param {Object} options - The options for removing the image.
     * @param {number} options.id - The ID of the object to remove the image from.
     * @param {string} options.md5 - The MD5 hash of the image to remove.
     * @returns {void}
     */
    @action
    removeImageLocal({ id, md5 }) {
        if (util.isArray(this[this.name].images)) {
            const idx = this[this.name].images?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.name].images.splice(idx, 1);
            }
        }
        const widx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this[this.namePlural][widx].images?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.namePlural][widx].images.splice(idx, 1);
            }
        }
    }

    /**
     * Removes an image from an object's images array in the local model.
     * @param {Object} options - The options for removing the image.
     * @param {number} options.id - The ID of the object to remove the image from.
     * @param {string} options.md5 - The MD5 hash of the image to remove.
     * @returns {void}
     */
    async removeImage({ id, md5 }) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'PATCH' }, {
            removeImagesByMd5: md5,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                this.removeImageLocal({ id, md5 });
                return response;
            case 401:
                break;
        }
    }

    /**
     * Removes a file from an object's files array in the local model.
     * @param {Object} options - The options for removing the file.
     * @param {number} options.id - The ID of the object to remove the file from.
     * @param {string} options.md5 - The MD5 hash of the file to remove.
     * @returns {void}
     */
    @action
    removeFileLocal({ id, md5 }) {
        if (util.isArray(this[this.name].files)) {
            const idx = this[this.name].files?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.name].files.splice(idx, 1);
            }
        }
        const widx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this[this.namePlural][widx].files?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.namePlural][widx].files.splice(idx, 1);
            }
        }
    }

    /**
     * Removes a file from an object's files array on the server and in the local model.
     * @param {Object} options - The options for removing the file.
     * @param {number} options.id - The ID of the object to remove the file from.
     * @param {string} options.md5 - The MD5 hash of the file to remove.
     * @returns {Promise<Object>} A Promise that resolves with the server response.
     */
    async removeFile({ id, md5 }) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'PATCH' }, {
            removeFilesByMd5: md5,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                this.removeFileLocal({ id, md5 });
                return response;
            case 401:
                break;
        }
    }

    @action
    removeLinkLocal({ id, md5 }) {
        if (util.isArray(this[this.name].links)) {
            const idx = this[this.name].links?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.name].links.splice(idx, 1);
            }
        }
        const widx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this[this.namePlural][widx].links?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.namePlural][widx].links.splice(idx, 1);
            }
        }
    }

    async removeLink({ id, md5 }) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'PATCH' }, {
            removeLinksByMd5: md5,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                this.removeLinkLocal({ id, md5 });
                return response;
            case 401:
                break;
        }
    }

    @action
    removeYoutubeVideoLocal({ id, md5 }) {
        if (util.isArray(this[this.name].youtubeVideos)) {
            const idx = this[this.name].youtubeVideos?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.name].youtubeVideos.splice(idx, 1);
            }
        }
        const widx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this[this.namePlural][widx].youtubeVideos?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.namePlural][widx].youtubeVideos.splice(idx, 1);
            }
        }
    }

    async removeYoutubeVideo({ id, md5 }) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'PATCH' }, {
            removeYoutubeVideosByMd5: md5,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                this.removeYoutubeVideoLocal({ id, md5 });
                return response;
            case 401:
                break;
        }
    }

    @action
    removePartsLocal({ id, md5 }) {
        if (util.isArray(this[this.name].parts)) {
            const idx = this[this.name].parts?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.name].parts.splice(idx, 1);
            }
        }
        const widx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this[this.namePlural][widx].parts?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.namePlural][widx].parts.splice(idx, 1);
            }
        }
    }

    async removeParts({ id, md5 }) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'PATCH' }, {
            removePartsByMd5: md5,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                this.removePartsLocal({ id, md5 });
                return response;
            case 401:
                break;
        }
    }

    @action
    removeInspectionsLocal({ id, md5 }) {
        if (util.isArray(this[this.name].inspections)) {
            const idx = this[this.name].inspections?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.name].inspections.splice(idx, 1);
            }
        }
        const widx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this[this.namePlural][widx].inspections?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.namePlural][widx].inspections.splice(idx, 1);
            }
        }
    }

    async removeInspections({ id, md5 }) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'PATCH' }, {
            removeInspectionsByMd5: md5,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                this.removeInspectionsLocal({ id, md5 });
                return response;
            case 401:
                break;
        }
    }

    @action
    removeMembersLocal({ id, md5 }) {
        if (util.isArray(this[this.name].members)) {
            const idx = this[this.name].members?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.name].members.splice(idx, 1);
            }
        }
        const widx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (widx > -1) {
            const idx = this[this.namePlural][widx].members?.findIndex(e => e.md5 === md5);
            if (idx > -1) {
                this[this.namePlural][widx].members.splice(idx, 1);
            }
        }
    }

    async removeMembers({ id, md5 }) {
        const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'PATCH' }, {
            removeMembersByMd5: md5,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                this.removeMembersLocal({ id, md5 });
                return response;
            case 401:
                break;
        }
    }

    // @action
    // removeArrayElementLocal({ id, field, key }) {
    //     if (util.isArray(this[this.name][field])) {
    //         const idx = this[this.name][field]?.findIndex(e => e.md5 === md5);
    //         if (idx > -1) {
    //             this[this.name][field].splice(idx, 1);
    //         }
    //     }
    //     const widx = this[this.namePlural]?.findIndex(e => e.id === id);
    //     if (widx > -1) {
    //         const idx = this[this.namePlural][widx][field]?.findIndex(e => e.md5 === md5);
    //         if (idx > -1) {
    //             this[this.namePlural][widx][field].splice(idx, 1);
    //         }
    //     }
    // }

    // async removeArrayElement({ id, key }) {
    //     const response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'PATCH' }, {
    //         removeMembersByMd5: md5,
    //         version: this.currentVersion,
    //     });
    //     switch (response.status) {
    //         case 202:
    //             this.removeMembersLocal({ id, md5 });
    //             return response;
    //         case 401:
    //             break;
    //     }
    // }

    @action
    removeArrayObjectLocal({ field, id, md5, uuidv4, objectName }) {
        // console.log({ field, id, md5, objectName });
        if (objectName && util.isArray(this[objectName][field])) {
            let idx = this[objectName][field]?.findIndex(e => e.md5 === md5);
            if (!md5) {
                idx = this[objectName][field]?.findIndex(e => e.uuidv4 === uuidv4);
            }
            if (idx > -1) {
                this[objectName][field].splice(idx, 1);
            }
        }

        if (util.isArray(this[this.name][field])) {
            let idx = this[this.name][field]?.findIndex(e => e.md5 === md5);
            if (!md5) {
                idx = this[this.name][field]?.findIndex(e => e.uuidv4 === uuidv4);
            }
            if (idx > -1) {
                this[this.name][field].splice(idx, 1);
            }
        }

        const widx = this[this.namePlural] ? this[this.namePlural]?.findIndex(e => e.id === id) : null;
        if (widx > -1) {
            let idx = this[this.namePlural][widx][field]?.findIndex(e => e.md5 === md5);
            if (!md5) {
                idx = this[this.namePlural][widx][field]?.findIndex(e => e.uuidv4 === uuidv4);
            }
            if (idx > -1) {
                this[this.namePlural][widx][field].splice(idx, 1);
            }
        }
    }

    @action
    editArrayObjectLocal({ field, key, value, id, md5, uuidv4, objectName }) {
        // console.log('editArrayObjectLocal', { field, key, value, id, md5, objectName });
        if (objectName) {
            if (this[objectName] && util.isArray(this[objectName][field])) {
                let idx = this[objectName][field]?.findIndex(e => e.md5 === md5);
                if (!md5) {
                    idx = this[objectName][field]?.findIndex(e => e.uuidv4 === uuidv4);
                }
                if (idx > -1) {
                    this[objectName][field][idx][key] = value;
                }
            }
            if (this[`${objectName}Single`] && util.isArray(this[`${objectName}Single`][field])) {
                let idx = this[`${objectName}Single`][field]?.findIndex(e => e.md5 === md5);
                if (!md5) {
                    idx = this[`${objectName}Single`][field]?.findIndex(e => e.uuidv4 === uuidv4);
                }
                if (idx > -1) {
                    this[`${objectName}Single`][field][idx][key] = value;
                }
            }
        }

        if (this[this.name] && util.isArray(this[this.name][field])) {
            let idx = this[this.name][field]?.findIndex(e => e.md5 === md5);
            if (!md5) {
                idx = this[this.name][field]?.findIndex(e => e.uuidv4 === uuidv4);
            }
            if (idx > -1) {
                // console.log('editArrayObjectLocal', { idx, field, idx, key, value }, this[this.name][field][idx][key]);
                this[this.name][field][idx][key] = value;
            }
        }

        if (this[this.namePlural] && this[this.namePlural]?.findIndex) {
            const widx = this[this.namePlural]?.findIndex(e => e.id === id);
            if (widx > -1) {
                let idx = this[this.namePlural][widx][field]?.findIndex(e => e.md5 === md5);
                if (!md5) {
                    idx = this[this.namePlural][widx][field]?.findIndex(e => e.uuidv4 === uuidv4);
                }
                if (idx > -1) {
                    this[this.namePlural][widx][field][idx][key] = value;
                }
            }
        }
    }

    async removeArrayObject({ field, id, md5, uuidv4, objectName, isAdmin }) {
        let response;
        if (!md5) {
            response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${isAdmin ? 'admin/' : ''}${id}`, { publish: true, method: 'PATCH' }, {
                [`remove${util.ucfirst(field)}ByUuidv4`]: uuidv4,
                version: this.currentVersion,
            });
        } else {
            response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${isAdmin ? 'admin/' : ''}${id}`, { publish: true, method: 'PATCH' }, {
                [`remove${util.ucfirst(field)}ByMd5`]: md5,
                version: this.currentVersion,
            });
        }
        switch (response.status) {
            case 202:
                this.removeArrayObjectLocal({ field, id, md5, uuidv4, objectName });
                return response;
            case 401:
                break;
        }
    }

    async editArrayObject({ field, key, value, type, id, md5, uuidv4, objectName, isAdmin }) {
        let response;
        if (!md5) {
            response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${isAdmin ? 'admin/' : ''}${id}`, { publish: true, method: 'PATCH' }, {
                [`edit${util.ucfirst(field)}ByUuidv4`]: uuidv4,
                [`edit${util.ucfirst(field)}Key`]: key,
                [`edit${util.ucfirst(field)}Value`]: value,
                [`edit${util.ucfirst(field)}Type`]: type,
                version: this.currentVersion,
            });
        } else {
            response = await util.fetchApi(`/api/${this.namePluralReal || this.namePlural}/${isAdmin ? 'admin/' : ''}${id}`, { publish: true, method: 'PATCH' }, {
                [`edit${util.ucfirst(field)}ByMd5`]: md5,
                [`edit${util.ucfirst(field)}Key`]: key,
                [`edit${util.ucfirst(field)}Value`]: value,
                [`edit${util.ucfirst(field)}Type`]: type,
                version: this.currentVersion,
            });
        }
        switch (response.status) {
            case 202:
                this.editArrayObjectLocal({ field, key, value, id, md5, uuidv4, objectName });
                return response;
            case 401:
                break;
        }
    }

    @action
    setRefreshPage(func) {
        if (util.isFunction(func)) {
            this.refreshPage = func;
        }
    }

    @action
    addRefreshPageFunction(func, key) {
        if (util.isFunction(func)) {
            // Check for key to avoid duplicates
            const finalKey = key || func.toString();
            this.refreshPageFunctions[finalKey] = func;
        }
    }

    runRefreshPageFunctions() {
        Object.values(this.refreshPageFunctions).forEach(func => func());
    }

    @action
    removeRefreshPageFunction(key) {
        if (key) {
            delete this.refreshPageFunctions[key];
        }
    }

    @action
    setLoadMore(func) {
        if (util.isFunction(func)) {
            this.loadMore = func;
        }
    }

    foo() {
        // console.log(`foo: ${this.constructor}`);
        console.log(`model.name: ${this.name}`);
        console.log(`model.namePlural: ${this.namePlural}`);
        console.log(`model.namePluralReal || : ${this.namePluralReal}`);
    }

    console(...args) {
        this.foo();
        console.log(...args);
    }

    @action
    reorderImages(startIndex, endIndex) {
        const [removed] = this.images.splice(startIndex, 1);
        this.images.splice(endIndex, 0, removed);
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Likes
    @action
    likeAdd({ id, data, holdingArrayName }) {
        // consoleLog({ id, data, holdingArrayName });
        if (!data) {
            return false;
        }
        if (util.isUndefined(this[this.name].likes)) {
            this[this.name].likes = [];
        }
        if (util.isArray(this[this.name].likes)) {
            this[this.name].likes.push(data);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[this.namePlural][idx].likes)) {
                this[this.namePlural][idx].likes = [];
            }
            const hasLikedObject = this[this.namePlural][idx].likes.find(e => e.user === data.user);
            if (!hasLikedObject) {
                this[this.namePlural][idx].likes.push(data);
            }
        }
        // holdingArrayName
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[holdingArrayName][idx].likes)) {
                this[holdingArrayName][idx].likes = [];
            }
            // consoleLog({ idx }, this[holdingArrayName][idx].likes);
            const hasLikedObject = this[holdingArrayName][idx].likes.find(e => e.user === data.user);
            if (!hasLikedObject) {
                this[holdingArrayName][idx].likes.push(data);
            }
        }
    }

    @action
    likeRemove({ id, data, holdingArrayName }) {
        // consoleLog({ id, data, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isUndefined(this[this.name].likes)) {
                this[this.name].likes = [];
            }
            this[this.name].likes = this[this.name].likes.filter(e => e.user !== data.user);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[this.namePlural][idx].likes)) {
                this[this.namePlural][idx].likes = [];
            }
            this[this.namePlural][idx].likes = this[this.namePlural][idx].likes.filter(e => e.user !== data.user);
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[holdingArrayName][idx].likes)) {
                this[holdingArrayName][idx].likes = [];
            }
            // consoleLog({ idx }, this[holdingArrayName][idx].likes);
            this[holdingArrayName][idx].likes = this[holdingArrayName][idx].likes.filter(e => e.user !== data.user);
            // consoleLog({ idx }, this[holdingArrayName][idx].likes);
        }
    }

    async like({ id, holdingArrayName }) {
        const response = await util.fetchApi(`/api/likes/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.likeAdd({ id, data: response.data, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    async unlike({ id, holdingArrayName }) {
        const response = await util.fetchApi(`/api/likes/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.likeRemove({ id, data: response.data, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Comments
    @action
    commentAddLike({ id, commentId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentAddLike.${this.name}:`, { id, commentId, data, holdingArrayName });
        if (util.isDefined(this[this.name].comments)) {
            const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
            const comment = this[this.name].comments[commentIdx];
            if (util.isUndefined(comment.likes)) {
                comment.likes = [];
            }
            comment.likes.push(data);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                const hasLikedObject = comment.likes.find(e => e.user === data.user);
                if (!hasLikedObject) {
                    comment.likes.push(data);
                }
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isUndefined(comment.likes)) {
                    comment.likes = [];
                }
                const hasLikedObject = comment.likes.find(e => e.user === data.user);
                if (!hasLikedObject) {
                    comment.likes.push(data);
                }
            }
        }
    }

    @action
    commentReplyAddLike({ id, commentId, replyId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyAddLike.${this.name}:`, { id, commentId, replyId, data, holdingArrayName });
        if (util.isDefined(this[this.name].comments)) {
            const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
            const comment = this[this.name].comments[commentIdx];
            if (util.isDefined(comment.comments[commentIdx])) {
                const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                const reply = comment.comments[replyIdx];
                if (util.isUndefined(reply.likes)) {
                    reply.likes = [];
                }
                reply.likes.push(data);
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    const hasLikedObject = reply.likes.find(e => e.user === data.user);
                    if (!hasLikedObject) {
                        reply.likes.push(data);
                    }
                }
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    if (util.isUndefined(reply.likes)) {
                        reply.likes = [];
                    }
                    const hasLikedObject = reply.likes.find(e => e.user === data.user);
                    if (!hasLikedObject) {
                        reply.likes.push(data);
                    }
                }
            }
        }
    }

    @action
    commentAdd({ id, data, holdingArrayName }) {
        consoleLog(`musherModel.commentAdd.${this.name}:`, { id, data, holdingArrayName });
        if (util.isArray(this[this.name].comments)) {
            this[this.name].comments.push(data);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[this.namePlural][idx].comments)) {
                this[this.namePlural][idx].comments = [];
            }
            this[this.namePlural][idx].comments.push(data);
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isUndefined(this[holdingArrayName][idx].comments)) {
                this[holdingArrayName][idx].comments = [];
            }
            this[holdingArrayName][idx].comments.push(data);
        }
    }

    @action
    commentRemove({ id, commentId, holdingArrayName }) {
        consoleLog(`musherModel.commentRemove.${this.name}:`, { id, commentId, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isDefined(this[this.name].comments)) {
                const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
                this[this.name].comments.splice([commentIdx], 1);
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                this[this.namePlural][idx].comments.splice([commentIdx], 1);
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                this[holdingArrayName][idx].comments.splice([commentIdx], 1);
            }
        }
    }

    @action
    commentEdit({ id, commentId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentEdit.${this.name}:`, { id, commentId, data, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isDefined(this[this.name].comments)) {
                const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.name].comments[commentIdx];
                comment.comment = data;
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                comment.comment = data;
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                comment.comment = data;
            }
        }
    }

    @action
    commentReplyAdd({ id, commentId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyAdd.${this.name}:`, { id, commentId, data, holdingArrayName });
        if (util.isDefined(this[this.name].comments)) {
            const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
            const comment = this[this.name].comments[commentIdx];
            if (util.isUndefined(comment.comments)) {
                comment.comments = [];
            }
            comment.comments.push(data);
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isUndefined(comment.comments)) {
                    comment.comments = [];
                }
                comment.comments.push(data);
            }
        }
    }

    @action
    commentReplyRemove({ id, commentId, replyId, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyRemove.${this.name}:`, { id, commentId, replyId, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isDefined(this[this.name].comments)) {
                const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.name].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    comment.comments.splice(replyIdx, 1)
                }
            }
        }
    }

    @action
    commentReplyEditObject({ id, commentId, replyId, data, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyEditObject.${this.name}:`, { id, commentId, replyId, data, holdingArrayName });
        if (this[this.name] && this[this.name].id === id) {
            if (util.isDefined(this[this.name].comments)) {
                const commentIdx = this[this.name].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.name].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[this.namePlural][idx].comments)) {
                const commentIdx = this[this.namePlural][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[this.namePlural][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            if (util.isDefined(this[holdingArrayName][idx].comments)) {
                const commentIdx = this[holdingArrayName][idx].comments?.findIndex(e => e.id === commentId);
                const comment = this[holdingArrayName][idx].comments[commentIdx];
                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyId);
                    const reply = comment.comments[replyIdx];
                    reply.comment = data;
                }
            }
        }
    }

    async commentLike({ id, commentId, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/like/${id}/${commentId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.commentAddLike({ id, commentId, data: response.data, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    async commentReplyLike({ id, commentId, replyId, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/like/${id}/${commentId}/${replyId}`, { publish: true, method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.commentReplyAddLike({ id, commentId, replyId, data: response.data, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    async comment({ id, comment, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/${id}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.commentAdd({ id, data: response.data, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    async commentDelete({ id, commentId, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/${id}/${commentId}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.commentRemove({ id, commentId, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    async commentReply({ id, commentId, comment, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/${id}/${commentId}`, { publish: true, method: 'POST' }, { comment });
        switch (response.status) {
            case 200:
                this.commentReplyAdd({ id, commentId, data: response.data, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    async commentReplyDelete({ id, commentId, replyId, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/${id}/${commentId}/${replyId}`, { publish: true, method: 'DELETE' }, {});
        switch (response.status) {
            case 200:
                this.commentReplyRemove({ id, commentId, data: response.data, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    async commentReplyEdit({ id, commentId, replyId, comment, holdingArrayName }) {
        const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/${id}/${commentId}/${replyId}`, { publish: true, method: 'PATCH' }, { comment });
        switch (response.status) {
            case 200:
                this.commentReplyEditObject({ id, commentId, replyId, data: comment, holdingArrayName });
                // localUtil.tapticWeakBoom();
                return response.data;
            case 401:
                break;
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Comment translate
    @action
    commentTranslateUpdate({ id, commentid, value, targetLang, field = 'comment', holdingArrayName }) {
        consoleLog(`musherModel.commentTranslateUpdate.${this.name}:`, { id, commentid, value, targetLang, holdingArrayName });
        let objectIdx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[this.namePlural][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, objectIdx, commentIdx, comment });

                const translationObj = {
                    field,
                    text: value,
                    language: targetLang,
                };

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex(e => {
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        comment.translations[translationIdx] = translationObj;
                    } else {
                        comment.translations.push(translationObj);
                    }
                } else {
                    comment.translations = [translationObj];
                }
            }
        }
        objectIdx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[holdingArrayName][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                const translationObj = {
                    field,
                    text: value,
                    language: targetLang,
                };
                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex(e => {
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        comment.translations[translationIdx] = translationObj;
                    } else {
                        comment.translations.push(translationObj);
                    }
                    comment.translations = [translationObj];
                }
            }
        }
    }

    @action
    commentReplyTranslateUpdate({ id, commentid, replyid, value, targetLang, field = 'comment', holdingArrayName }) {
        consoleLog(`musherModel.commentReplyTranslateUpdate.${this.name}:`, { id, commentid, replyid, value, targetLang, holdingArrayName });
        let objectIdx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[this.namePlural][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, objectIdx, commentIdx, comment });

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];
                    const translationObj = {
                        field,
                        text: value,
                        language: targetLang,
                    };

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            return e.language === targetLang && e.field === field;
                        });
                        reply.translations[translationIdx] = translationObj;
                    } else {
                        reply.translations = [translationObj];
                    }
                }
            }
        }
        objectIdx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[holdingArrayName][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];
                // console.log({ id, commentid, value, targetLang, objectIdx, commentIdx, comment });

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];
                    const translationObj = {
                        field,
                        text: value,
                        language: targetLang,
                    };

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            return e.language === targetLang && e.field === field;
                        });
                        reply.translations[translationIdx] = translationObj;
                    } else {
                        reply.translations = [translationObj];
                    }
                }
            }
        }
    }

    commentTranslateGet({ id, commentid, targetLang, field = 'comment', holdingArrayName, debug }) {
        if (debug) {
            consoleLog(`musherModel.commentTranslateGet.${this.name}:`, { id, commentid, targetLang, holdingArrayName });
        }
        let objectIdx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[this.namePlural][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        return comment.translations[translationIdx].text;
                    }
                }
            }
        }
        objectIdx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[holdingArrayName][objectIdx];
            if (debug) {
                consoleLog(`musherModel.commentTranslateGet.${this.name}:`, { objectIdx, object });
            }

            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.translations)) {
                    const translationIdx = comment.translations?.findIndex((e) => {
                        // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                        return e.language === targetLang && e.field === field;
                    });
                    if (translationIdx > -1) {
                        return comment.translations[translationIdx].text;
                    }
                }
            }
        }
        return null;
    }

    commentReplyTranslateGet({ id, commentid, replyid, targetLang, field = 'comment', holdingArrayName }) {
        // consoleLog(`musherModel.commentReplyTranslateGet.${this.name}:`, { id, commentid, replyid, targetLang, holdingArrayName });
        let objectIdx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[this.namePlural][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                            return e.language === targetLang && e.field === field;
                        });
                        if (translationIdx > -1) {
                            return reply.translations[translationIdx].text;
                        }
                    }
                }
            }
        }
        objectIdx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (objectIdx > -1) {
            const object = this[holdingArrayName][objectIdx];
            if (util.isDefined(object.comments)) {
                const commentIdx = object.comments?.findIndex(e => e.id === commentid);
                const comment = object.comments[commentIdx];

                if (util.isDefined(comment.comments)) {
                    const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
                    const reply = comment.comments[replyIdx];

                    if (util.isDefined(reply.translations)) {
                        const translationIdx = reply.translations?.findIndex((e) => {
                            // console.log('e.language === targetLang && e.field === field', e.language, targetLang, e.field, field)
                            return e.language === targetLang && e.field === field;
                        });
                        if (translationIdx > -1) {
                            return reply.translations[translationIdx].text;
                        }
                    }
                }
            }
        }
        return null;
    }

    async commentTranslate({ object, user = {}, commentid, holdingArrayName }) {
        consoleLog(`musherModel.commentTranslate.${this.name}:`, { object, commentid, user, holdingArrayName });
        const { id } = object;
        const { language: targetLang = 'en' } = user;

        if (!util.isDefined(object.comments)) {
            return null;
        }
        const commentIdx = object.comments?.findIndex(e => e.id === commentid);
        const comment = object.comments[commentIdx];
        const text = comment.comment;

        const sourceLang = comment.language === 'undefined' ? 'no' : comment.language;
        // const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;
        const translatedText = this.commentTranslateGet({ id: object.id, commentid, targetLang, holdingArrayName });
        if (translatedText) {
            return translatedText;
        }
        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/${id}/${commentid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        const updateObject ={
                            id: object.id,
                            commentid,
                            value: response.data.translatedText,
                            targetLang,
                            holdingArrayName,
                        };
                        this.commentTranslateUpdate(updateObject);
                        return updateObject;
                    }
                    return '';
                case 401:
                    break;
            }
        }
    }

    async commentReplyTranslate({ object, user = {}, commentid, replyid, holdingArrayName }) {
        consoleLog(`musherModel.commentReplyTranslate.${this.name}:`, { object, user, commentid, replyid, holdingArrayName });
        const { id } = object;
        const { language: targetLang = 'en' } = user;

        if (!util.isDefined(object.comments)) {
            return null;
        }
        const commentIdx = object.comments?.findIndex(e => e.id === commentid);
        const comment = object.comments[commentIdx];
        const replyIdx = comment.comments?.findIndex(e => e.id === replyid);
        const reply = comment.comments[replyIdx];
        const text = reply.comment;

        const sourceLang = reply.language === 'undefined' ? 'no' : reply.language;
        // const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = this.commentReplyTranslateGet({ id: object.id, commentid, replyid, targetLang, holdingArrayName });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(`/api/comments/${this.namePluralReal || this.namePlural}/${id}/${commentid}/${replyid}/translate/${sourceLang}/${targetLang}`, { publish: false, method: 'POST' }, { text });
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        const updateObject = {
                            id: object.id,
                            commentid,
                            replyid,
                            value: response.data.translatedText,
                            targetLang,
                            holdingArrayName,
                        };
                        this.commentReplyTranslateUpdate(updateObject);
                        return updateObject;
                    }
                    return '';
                case 401:
                    break;
            }
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Translate
    @action
    updateTranslation({ id, field, value, targetLang, holdingArrayName = 'publicFeed' }) {
        // const idx = this.publicFeed?.findIndex(e => e.id === id);
        // if (idx > -1) {
        //     this.publicFeed[idx][`${field}${targetLang}`] = value;
        // }

        if (util.isObject(this[this.name])) {
            this[this.name][`${field}${targetLang}`] = value;
        }

        let idx = this[this.namePlural]?.findIndex(e => e.id === id);
        if (idx > -1) {
            this[this.namePlural][idx][`${field}${targetLang}`] = value;
        }

        idx = this[holdingArrayName]?.findIndex(e => e.id === id);
        if (idx > -1) {
            this[holdingArrayName][idx][`${field}${targetLang}`] = value;
        }
    }

    async translate({ object, user, field = 'body', holdingArrayName }) {
        consoleLog(`musherModel.translate.${this.name}:`, { object, user, field, holdingArrayName });
        const { id, language = 'en' } = object;
        const text = object[field];
        const { language: userLanguage = 'en' } = user;

        const sourceLang = language === 'undefined' ? 'no' : language;
        const targetLang = userLanguage === 'undefined' ? 'en' : userLanguage;

        const translatedText = localUtil.getTranslation({ object, targetLang, field });
        // console.log({ sourceLang, targetLang, translatedText });
        if (translatedText) {
            return translatedText;
        }

        if (sourceLang !== targetLang) {
            const response = await util.fetchApi(
                `/api/${this.namePluralReal || this.namePlural}/${id}/translate/${sourceLang}/${targetLang}/${field}`,
                { publish: false, method: 'POST' },
                { text }
            );
            switch (response.status) {
                case 200:
                    if (response.data && response.data.translatedText) {
                        this.updateTranslation({
                            id: object.id,
                            field,
                            value: response.data.translatedText,
                            targetLang,
                            holdingArrayName,
                        });
                        return response.data.translatedText;
                    }
                    return '';
                case 401:
                    break;
            }
        }
    }

    async adminSubmitCSV(csvText) {
        const response = await util.fetchApi(
            `/api/${this.namePluralReal || this.namePlural}/admin/csv`,
            { publish: false, method: 'POST' },
            { csv: csvText }
        );
        switch (response.status) {
            case 202:
                return response;
            case 401:
                break;
        }
    }

    async adminUpdateCSV(csvText) {
        const response = await util.fetchApi(
            `/api/${this.namePluralReal || this.namePlural}/admin/csv`,
            { publish: false, method: 'PATCH' },
            { csv: csvText }
        );
        switch (response.status) {
            case 202:
                return response;
            case 401:
                break;
        }
    }

    async insertFromCSV(csvText) {
        const response = await util.fetchApi(
            `/api/${this.namePluralReal || this.namePlural}/csv`,
            { publish: false, method: 'POST' },
            { csv: csvText }
        );
        switch (response.status) {
            case 202:
                return response;
            case 401:
                break;
        }
    }

    async updateFromCSV(csvText) {
        const response = await util.fetchApi(
            `/api/${this.namePluralReal || this.namePlural}/csv`,
            { publish: false, method: 'PATCH' },
            { csv: csvText }
        );
        switch (response.status) {
            case 202:
                return response;
            case 401:
                break;
        }
    }

    async loadFromCsv(csvText) {
        const response = await util.fetchApi(
            `/api/${this.namePluralReal || this.namePlural}/csv/load`,
            { publish: false, method: 'POST' },
            { csv: csvText }
        );
        switch (response.status) {
            case 200:
                return response;
            case 401:
                break;
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // View logging
    async postScrollview(inputData) {
        const data = inputData || this.getIntersectionObserverHistory();
        if (!data || data?.length === 0) {
            return false;
        }
        const response = await util.fetchApi(`/api/scrollview/`, { publish: false, method: 'POST' }, { data });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                break;
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Performance logging
    hrStart = () => {
        return new Date().getTime();
    }

    @action
    addLog = (hrStart, message, title) => {
        this.logs.push({
            timeused: new Date().getTime() - hrStart,
            title,
            message,
        });
        return this.hrStart();
    }

    @action
    getLogs = () => {
        const logs = [...this.logs];
        this.logs = [];
        return logs;
    }

    printLogs = () => {
        this.logs.forEach((e) => {
            console.log(`${e.message}: ${e.timeused} ms`);
        });
    }

    async postLog({ type, deviceInfo, currentLocation, data }) {
        const lines = data || this.getLogs();
        const response = await util.fetchApi(`/api/logs/`, { publish: true, method: 'POST' }, {
            lines,
            deviceInfo,
            currentLocation,
            type,
        });
        switch (response.status) {
            case 201:
                return response;
            case 401:
                break;
        }
    }

    async reportError(error) {
        try {
            const data = {
                timestamp: Math.floor(new Date().getTime() / 1000),
                error: {
                    name: error.name,
                    message: error.message,
                    stack: error.stack,
                },
                location: window.location,
            };
            util.fetchApi('/api/errors/', { method: 'POST' }, data);
        } catch(e) {
            console.log('reportError error', e);
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // Intersection observer logging
    @action
    intersectionObserverCallback(entries) {
        entries.forEach((e) => {
            const { id, type, title, user, sessionid } = e.target.dataset;
            const { intersectionRatio, isIntersecting, time } = e;
            const el = this.intersectionElements[`${type}-${id}`];
            if (el && el.isIntersecting && !isIntersecting) {
                el.endTime = new Date().getTime();
                el.duration = el.endTime - el.startTime;
                const elToPush = { ...el };
                delete this.intersectionElements[`${type}-${id}`];
                this.intersectionHistory.push(elToPush);
            } else {
                this.intersectionElements[`${type}-${id}`] = {
                    startTime: new Date().getTime(),
                    elementId: parseInt(id, 10),
                    user: parseInt(user, 10),
                    type,
                    intersectionRatio,
                    isIntersecting,
                    time,
                    sessionId: sessionid,
                };
            }

            // boundingClientRect: DOMRect { x: 0, y: 336.48333740234375, width: 1191, … }
            // intersectionRatio: 1
            // intersectionRect: DOMRect { x: 0, y: 336.48333740234375, width: 1191, … }
            // isIntersecting: true
            // rootBounds: DOMRect { x: 0, y: 0, width: 1206, … }
            // target: <div class="row pt-3 pb-3 bg-light" style="">
            // time: 26522.14
            // console.log({ id, title, intersectionRatio, isIntersecting, time });
        });
    }

    @action
    getIntersectionObserverHistory() {
        const elements = this.intersectionHistory.splice(0, this.intersectionHistory.length).map(e => toJS(e));
        return elements;
    }

    async insertMany(data) {
        // const result = await postData(`${commander.server}/api/journals/admin/line/multiple`, journalPrescriptions);

        const response = await util.fetchApi(
            `/api/${this.namePluralReal || this.namePlural}/multiple`,
            { publish: false, method: 'POST' },
            { data },
        );
        switch (response.status) {
            case 202:
                return response;
            case 401:
                break;
        }
    }

    async loadIntoNew({ id, query: inputQuery = {}, fields = ['title'], allFields = false }) {
        const query = {
            ...inputQuery,
        };
        if (id) {
            query.id = id;
        }
        const previousObjects = await this.load({
            query,
            sort: '-createdDate',
            limit: 1,
            skipUpdate: true,
        });
        if (previousObjects?.length > 0) {
            const previousObject = previousObjects[0];
            const newObject = {};
            if (allFields) {
                Object.keys(previousObject).forEach((field) => {
                    newObject[field] = previousObject[field];
                });
                delete newObject.id;
                delete newObject.createdDate;
                delete newObject.updatedDate;
            } else {
                fields.forEach((field) => {
                    newObject[field] = previousObject[field];
                });
            }
            this.updateKeyValue(`new${util.ucfirst(this.name)}`, newObject);
        }
    }
}

export default LocalModel;
