import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

function isNumber(stringValue) {
    if (!isNaN(Number(stringValue))) {
        return true;
    }
    return false;
}

function parseCSV(csvString) {
    const lines = csvString.split('\n');
    const headers = lines[0].split(',');

    return lines.slice(1).map(line => {
        const data = line.split(',');
        return headers.reduce((obj, nextKey, index) => {
            obj[nextKey] = isNumber(data[index]) ? util.asNumber(data[index]) : data[index];
            return obj;
        }, {});
    });
}

const kortDoseCSV = `KodeId,Kode,Beskrivelse
6,4 som engangsdose,4  som engangsdose
41,1 annenhver dag,1  annenhver dag
47,1 annenhver måned,1  annenhver måned
48,1 annenhver uke,1  annenhver uke
49,1 bak øret 5-6 timer før avreise,1  bak øret 5-6 timer før avreise
57,1 hver 3. måned,1  hver 3. måned
58,1 hver 3. uke,1  hver 3. uke
59,1 hver 4. uke,1  hver 4. uke
63,1 hver uke på fast ukedag,1  hver uke på fast ukedag
65,1 i ett nesebor 5 min. før diegivning eller pumping,1  i ett nesebor 5 min. før diegivning eller pumping
79,1 inntil x3,1  inntil 3 ganger daglig
82,1 inntil x4,1  inntil 4 ganger daglig
116,1x1x5,1  1 gang daglig i 5 dager
129,1x1-3 inntil x4,1  1-3 ganger daglig i inntil 4 dager
213,1+0+1+0,1  morgen 1  ettermiddag
216,1+1+1,1  morgen 1  midt på dagen 1  kveld
217,1+1+1+1,1  morgen 1  formiddag 1  ettermiddag 1  kveld
218,1+1+2,1  morgen 1  midt på dagen og 2  kveld
219,10 inntil x3,10  inntil 3 ganger daglig
229,10+10+10+10,10  morgen 10  formiddag 10  ettermiddag 10  kveld
233,1-2 inntil x3,1-2  inntil 3 ganger daglig
234,1-2 inntil x4,1-2  inntil 4 ganger daglig
263,2 inntil x4,2  inntil 4 ganger daglig
292,"2,5+2,5+2,5","2,5  morgen 2,5  midt på dagen 2,5  kveld"
293,"2,5+2,5+2,5+2,5","2,5  morgen 2,5  formiddag 2,5  ettermiddag 2,5  kveld"
304,2+2+2,2  morgen 2  midt på dagen 2  kveld
305,2+2+2+2,2  morgen 2  formiddag 2  ettermiddag 2  kveld
343,5+5+5+5,5  morgen 5  formiddag 5  ettermiddag 5  kveld
349,"7,5x3","7,5  3 ganger daglig"
355,1x3x1$1x2,"1  3 ganger daglig første dag, deretter 1  2 ganger daglig"
358,Plasteret skiftes en fast dag hver uke,Plasteret skiftes en fast dag hver uke
359,Plasteret skiftes to faste dager hver uke,Plasteret skiftes to faste dager hver uke
360,Påføres 1-2 ganger ukentlig på angrepet negl,Påføres 1-2 ganger ukentlig på angrepet negl
361,Påføres hver 2. time i våken tid av døgnet i 4 dager,Påføres annenhver time i våken tid av døgnet i 4 dager
363,Påsmøres flere ganger daglig,Påsmøres flere ganger daglig
369,Settes inn i skjeden.Tas ut etter 3 uker. Ny ring settes inn etter 1 uke.,Settes inn i skjeden.Tas ut etter 3 uker. Ny ring settes inn etter 1 uke.
376,5 som engangsdose,5  som engangsdose
5323,4 daglig,4  daglig
5326,2 som engangsdose,2  som engangsdose
5328,1 som engangsdose,1  som engangsdose
5331,1 festes på huden 1 time før inngrep,1  festes på huden 1 time før inngrep
5339,1 tas 1/2-1 time før seksuell aktivitet,1  tas omtrent1 time før seksuell aktivitet
5365,2 med 1 ukes pause mellom Effekt oppnås 1 uke etter siste dose,"2  tas med minst 1 ukes pause mellom hver dose, se bruksanvisning. Effekt oppnås 1 uke etter siste dose."
5381,2 før måltidene,2  rett før måltidene
5383,1 til måltidene,1  til måltidene
5389,Et tykt lag på huden en time før inngrep. Tildekkes,Legg et tykt lag på huden en time før inngrep. Tildekkes
5397,2x0$1 etter løs avføring,2  i første dose deretter 1  etter hver løs avføring
5399,"1 på huden hver uke i 3 uker, 1 ukes pause.","1  på huden hver uke i 3 uker, så 1 ukes pause. Plasteret skiftes på fast ukedag"
5424,"1,25+1,25+1,25+1,25","1,25  morgen 1,25  formiddag 1,25  ettermiddag 1,25  kveld"
5425,"2,25+2,25+2,25+2,25","2,25  morgen 2,25  formiddag 2,25  ettermiddag 2,25  kveld"
5427,"7,5+7,5+7,5+7,5","7,5  morgen 7,5  formiddag 7,5  ettermiddag 7,5  kveld"
5429,"0,5+0,5+0,5+0,5","0,5  morgen 0,5  formiddag 0,5  ettermiddag 0,5  kveld"
5430,"1,5+1,5+1,5+1,5","1,5  morgen 1,5  formiddag 1,5  ettermiddag 1,5  kveld"
5432,3+3+3+3,3  morgen 3  formiddag 3  ettermiddag 3  kveld
5459,3 hver uke på fast ukedag,3  hver uke på fast ukedag
5460,4 hver uke på fast ukedag,4  hver uke på fast ukedag
5461,5 hver uke på fast ukedag,5  hver uke på fast ukedag
5462,6 hver uke på fast ukedag,6  hver uke på fast ukedag
5471,"2,5 inntil x3","2,5  inntil 3 ganger daglig"
5472,"7,5 inntil x3","7,5  inntil 3 ganger daglig"
5473,15 inntil x3,15  inntil 3 ganger daglig
5474,5 inntil x3,5  inntil 3 ganger daglig
5481,2 inntil x5,2  inntil 5 ganger daglig
5482,"2,5 inntil x4","2,5  inntil 4 ganger daglig"
5483,"2,5-5 inntil x4","2,5 - 5  inntil 4 ganger daglig"
5484,5 inntil x4,5  inntil 4 ganger daglig
5506,"0,5+0+0+0","0,5  morgen"
5507,1+0+0+0,1  morgen
5508,0+0+0+1,1  kveld
5514,0+0+0+2,2  kveld
5519,0+0+0+3,3  kveld
5520,0+0+0+30,30  kveld
5521,0+0+0+4,4  kveld
5522,8+0+0+0,8  morgen
5523,7+0+0+0,7  morgen
5524,6+0+0+0,6  morgen
5525,5+0+0+0,5  morgen
5526,4+0+0+0,4  morgen
5527,4+0+0+4,4  morgen 4  kveld
5529,5+0+0+5,5  morgen 5  kveld
5530,5+0+0+5x3,5  morgen 5  kveld i 3 dager
5531,6+0+0+6,6  morgen 6  kveld
5532,"7,5+0+0+7,5","7,5  morgen 7,5  kveld"
5533,3+0+0+0,3  morgen
5534,3+0+0+3,3  morgen 3  kveld
5535,3+0+0+4,3  morgen 4  kveld
5536,30+0+0+0,30  morgen
5537,15+0+0+0,15  morgen
5538,15+0+0+15,15  morgen 15  kveld
5539,"12,5+0+0+12,5","12,5  morgen 12,5  kveld"
5540,10+0+0+10,10  morgen 10  kveld
5541,10+0+0+10x3,10  morgen 10  kveld i 3 dager
5542,20+0+0+20,20  morgen 20  kveld
5543,"2,5+0+0+2,5","2,5  morgen 2,5  kveld"
5544,"3,75+0+0+3,75","3,75  morgen 3,75  kveld"
5545,2+0+0+0,2  morgen
5547,2+0+0+1,2  morgen 1  kveld
5548,2+0+0+2,2  morgen 2  kveld
5551,2+0+0+2x3,2  morgen 2  kveld i 3 dager
5552,2+0+0+3,2  morgen 3  kveld
5559,1+0+0+1,1  morgen 1  kveld
5568,1+0+0+1x10,1  morgen 1  kveld i 10 dager
5569,1+0+0+1x3,1  morgen 1  kveld i 3 dager
5571,1+0+0+2,1  morgen 2  kveld
5572,"1,25+0+0+1,25","1,25  morgen 1,25  kveld"
5573,2+0+0+0x10,2  morgen i 10 dager
5574,"0,5+0+0+0,5","0,5  morgen 0,5  kveld"
5579,1+0+0+1x5,1  morgen 1  kveld i 5 dager
5580,1+0+0+1x7,1  morgen 1  kveld i 7 dager
5581,2+0+0+2x21,2  morgen 2  kveld i 21 dager
5582,2+0+0+2x5,2  morgen 2  kveld i 5 dager
5606,2 hver uke på fast ukedag,2  hver uke på fast ukedag
5633,1+2+2,1  morgen 2  midt på dagen og 2  kveld
5634,2+2+3,2  morgen 2  midt på dagen og 3  kveld
5635,"0,5+0,5+0,5","0,5  morgen 0,5  midt på dagen og 0,5  kveld"
5637,Ved behov: 1-2 inntil 4 ganger daglig,Ved behov: 1-2  inntil 4 ganger daglig
5638,Kortdose ikke oppgitt,Kortdose ikke oppgitt
5644,2+2+0+0,2  morgen 2  formiddag
5645,10+0+0+0,10  morgen
5646,20+0+0+0,20  morgen
5648,"2,5+0+0+0","2,5  morgen"
5659,"1,25+0+0+0","1,25  morgen"
5670,1 ved anfall. Ny ved behov etter minst 4 timer. Maks 2 per døgn.,1  ved anfall. Ny  ved behov etter minst 4 timer. Maks 2  per døgn.
5671,0+0+0+1x5,1  kveld i 5 dager
5672,"0+0+0+0,5","0,5  kveld"
5674,0+0+0+10,10  kveld
5675,0+0+0+1x3,1  kveld i 3 dager
5676,0+0+0+1x6,1  kveld i 6 dager
5677,0+0+0+2x3,2  kveld i 3 dager
5678,1 ved anfall. Ny ved behov etter minst 2 timer. Maks 2 per døgn.,1  ved anfall. Ny  ved behov etter minst 2 timer. Maks 2  per døgn.
5679,1 ved anfall. Ny ved behov etter minst 1 time. Maks 2 per døgn.,1  ved anfall. Ny  ved behov etter minst 1 time. Maks 2  per døgn.
5680,1 ved anfall. Ny ved behov etter minst 2 timer. Maks 300 mg per døgn.,1  ved anfall. Ny  ved behov etter minst 2 timer. Maks 300 mg per døgn.
5681,1 hver 6. måned,1  hver 6. måned
5682,1 inntil x2,1  inntil 2 ganger daglig
5683,1 2 ganger i uken,1  2 ganger i uken
5684,1x1x14$1 2 ganger i uken,"1  daglig i 14 dager, deretter 2 ganger i uken."
5685,1 inntil x1,1  inntil 1 gang daglig
5686,2 inntil x1,2  inntil 1 gang daglig
5687,1+1+1x7,1  morgen 1  midt på dagen 1  kveld i 7 dager
5688,1+1+1x10,1  morgen 1  midt på dagen 1  kveld i 10 dager
5689,1+1+1+1x7,1  morgen 1  formiddag 1  ettermiddag 1  kveld i 7 dager
5690,1+1+1+1x10,1  morgen 1  formiddag 1  ettermiddag 1  kveld i 10 dager
5691,2+2+2+2x7,2  morgen 2  formiddag 2  ettermiddag 2  kveld i 7 dager
5692,2+2+2+2x10,2  morgen 2  formiddag 2  ettermiddag 2  kveld i 10 dager
5693,1+1+1x3,1  morgen 1  midt på dagen 1  kveld i 3 dager
5694,1+1+1x5,1  morgen 1  midt på dagen 1  kveld i 5 dager
5695,1+1+1+1x5,1  morgen 1  formiddag 1  ettermiddag 1  kveld i 5 dager
5696,1+1+1+1x14,1  morgen 1  formiddag 1  ettermiddag 1  kveld i 14 dager
5697,2+2+2+2x5,2  morgen 2  formiddag 2  ettermiddag 2  kveld i 5 dager
5698,2+2+2+2x14,2  morgen 2  formiddag 2  ettermiddag 2  kveld i 14 dager
5699,1+1+1x14,1  morgen 1  midt på dagen 1  kveld i 14 dager
5700,2+0+0+2x7,2  morgen 2  kveld i 7 dager
5701,1+1+1x21,1  morgen 1  midt på dagen 1  kveld i 21 dager
5702,2+0+0+2x10,2  morgen 2  kveld i 10 dager
5703,2+0+0+2x14,2  morgen 2  kveld i 14 dager
5704,1-2 inntil x1,1-2  inntil 1 gang daglig
5705,1+0+0+0x10,1  morgen i 10 dager
5706,1+0+0+0x7,1  morgen i 7 dager
5707,1+0+0+0x3,1  morgen i 3 dager
5708,1-2 hver 1.-2. time i 2-3 dager $ hver 4.-6.time,"1-2  hver 1.-2. time i 2-3 dager, deretter hver 4.-6. time"
5709,1+0+0+1x14,1  morgen 1  kveld i 14 dager
5710,"0,5 inntil x1","0,5  inntil 1 gang daglig"
5711,5+5+5,5  morgen 5  midt på dagen 5  kveld
5712,2+0+0+0x3,2  morgen i 3 dager
5713,3+0+0+0x3,3  morgen i 3 dager
5714,4+0+0+0x3,4  morgen i 3 dager
5715,1 3 ganger i uken,1  3 ganger i uken
5716,1+1+0+0,1  morgen 1  formiddag
5717,1+1+1+1+1,1  morgen 1  formiddag 1  midt på dagen 1  ettermiddag 1  kveld
5718,"2,5+2,5+2,5+2,5+2,5","2,5  morgen 2,5  formiddag 2,5  midt på dagen 2,5  ettermiddag 2,5  kveld"
5719,"0,25+0,25+0,25+0,25x5","0,25  morgen 0,25  formiddag 0,25  ettermiddag 0,25  kveld i 5 dager"
5720,"0,25+0,25+0,25+0,25x7","0,25  morgen 0,25  formiddag 0,25  ettermiddag 0,25  kveld i 7 dager"
5721,"0,25+0,25+0,25+0,25x10","0,25  morgen 0,25  formiddag 0,25  ettermiddag 0,25  kveld i 10 dager"
5722,"0,25+0,25+0,25+0,25x14","0,25  morgen 0,25  formiddag 0,25  ettermiddag 0,25  kveld i 14 dager"
5723,"0,5+0,5+0,5+0,5x5","0,5  morgen 0,5  formiddag 0,5  ettermiddag 0,5  kveld i 5 dager"
5724,"0,5+0,5+0,5+0,5x7","0,5  morgen 0,5  formiddag 0,5  ettermiddag 0,5  kveld i 7 dager"
5725,"0,5+0,5+0,5+0,5x10","0,5  morgen 0,5  formiddag 0,5  ettermiddag 0,5  kveld i 10 dager"
5726,"0,5+0,5+0,5+0,5x14","0,5  morgen 0,5  formiddag 0,5  ettermiddag 0,5  kveld i 14 dager"
5727,"0,75+0,75+0,75+0,75x5","0,75  morgen 0,75  formiddag 0,75  ettermiddag 0,75  kveld i 5 dager"
5728,"0,75+0,75+0,75+0,75x7","0,75  morgen 0,75  formiddag 0,75  ettermiddag 0,75  kveld i 7 dager"
5729,"0,75+0,75+0,75+0,75x10","0,75  morgen 0,75  formiddag 0,75  ettermiddag 0,75  kveld i 10 dager"
5730,"0,75+0,75+0,75+0,75x14","0,75  morgen 0,75  formiddag 0,75  ettermiddag 0,75  kveld i 14 dager"
5731,"1,5+1,5+1,5+1,5x5","1,5  morgen 1,5  formiddag 1,5  ettermiddag 1,5  kveld i 5 dager"
5732,"1,5+1,5+1,5+1,5x7","1,5  morgen 1,5  formiddag 1,5  ettermiddag 1,5  kveld i 7 dager"
5733,"1,5+1,5+1,5+1,5x10","1,5  morgen 1,5  formiddag 1,5  ettermiddag 1,5  kveld i 10 dager"
5734,"1,5+1,5+1,5+1,5x14","1,5  morgen 1,5  formiddag 1,5  ettermiddag 1,5  kveld i 14 dager"
5735,"2,5+2,5+2,5+2,5x5","2,5  morgen 2,5  formiddag 2,5  ettermiddag 2,5  kveld i 5 dager"
5736,"2,5+2,5+2,5+2,5x7","2,5  morgen 2,5  formiddag 2,5  ettermiddag 2,5  kveld i 7 dager"
5737,"2,5+2,5+2,5+2,5x10","2,5  morgen 2,5  formiddag 2,5  ettermiddag 2,5  kveld i 10 dager"
5738,"2,5+2,5+2,5+2,5x14","2,5  morgen 2,5  formiddag 2,5  ettermiddag 2,5  kveld i 14 dager"
5739,5+5+5+5x5,5  morgen 5  formiddag 5  ettermiddag 5  kveld i 5 dager
5740,5+5+5+5x7,5  morgen 5  formiddag 5  ettermiddag 5  kveld i 7 dager
5741,5+5+5+5x10,5  morgen 5  formiddag 5  ettermiddag 5  kveld i 10 dager
5742,5+5+5+5x14,5  morgen 5  formiddag 5  ettermiddag 5  kveld i 14 dager
5743,"7,5+7,5+7,5+7,5x5","7,5  morgen 7,5  formiddag 7,5  ettermiddag 7,5  kveld i 5 dager"
5744,"7,5+7,5+7,5+7,5x7","7,5  morgen 7,5  formiddag 7,5  ettermiddag 7,5  kveld i 7 dager"
5745,"7,5+7,5+7,5+7,5x10","7,5  morgen 7,5  formiddag 7,5  ettermiddag 7,5  kveld i 10 dager"
5746,"7,5+7,5+7,5+7,5x14","7,5  morgen 7,5  formiddag 7,5  ettermiddag 7,5  kveld i 14 dager"
5747,10+10+10+10x5,10  morgen 10  formiddag 10  ettermiddag 10  kveld i 5 dager
5748,10+10+10+10x7,10  morgen 10  formiddag 10  ettermiddag 10  kveld i 7 dager
5749,10+10+10+10x10,10  morgen 10  formiddag 10  ettermiddag 10  kveld i 10 dager
5750,10+10+10+10x14,10  morgen 10  formiddag 10  ettermiddag 10  kveld i 14 dager
5751,"0,5+0,5+0,5x5","0,5  morgen 0,5  midt på dagen 0,5  kveld i 5 dager"
5752,2+2+2x5,2  morgen 2  midt på dagen 2  kveld i 5 dager
5753,3+3+3x5,3  morgen 3  midt på dagen 3  kveld i 5 dager
5754,"0,5+0,5+0,5x7","0,5  morgen 0,5  midt på dagen 0,5  kveld i 7 dager"
5755,"1,5+1,5+1,5x7","1,5  morgen 1,5  midt på dagen 1,5  kveld i 7 dager"
5756,2+2+2x7,2 doser> morgen 2  midt på dagen 2  kveld i 7 dager
5758,3+3+3x14,3  morgen 3  midt på dagen 3  kveld i 14 dager
5759,2+2+2x14,2  morgen 2  midt på dagen 2  kveld i 14 dager
5760,2+2+2x21,2  morgen 2  midt på dagen 2  kveld i 21 dager
5761,3+3+3x21,3  morgen 3  midt på dagen 3  kveld i 21 dager
5762,10 som engangsdose,10  som engangsdose
5763,15 som engangsdose,15  som engangsdose
5764,5+0+0+5x7,5  morgen 5  kveld i 7 dager
5765,10+0+0+10x7,10  morgen 10  kveld i 7 dager
5766,"2,5+2,5+2,5x7","2,5  morgen 2,5  midt på dagen 2,5  kveld i 7 dager"
5767,5+5+5x7,5  morgen 5  midt på dagen 5  kveld i 7 dager
5768,"7,5+7,5+7,5x7","7,5  morgen 7,5  midt på dagen 7,5  kveld i 7 dager"
5769,3+3+3x10,3  morgen 3  midt på dagen 3  kveld i 10 dager
5770,5+5+5x10,5  morgen 5  midt på dagen 5  kveld i 10 dager
5771,10+10+10x10,10  morgen 10  midt på dagen 10  kveld i 10 dager
5772,20+20+20x10,20  morgen 20  midt på dagen 20  kveld i 10 dager
5774,30 som engangsdose,30  som engangsdose
5775,20 som engangsdose,20  som engangsdose
5776,4+0+0+4x5,4  morgen 4  kveld i 5 dager
5777,4+0+0+4x7,4  morgen 4  kveld i 7 dager
5778,4+0+0+4x10,4  morgen 4  kveld i 10 dager
5779,"7,5+0+0+7,5x5","7,5  morgen 7,5  kveld i 5 dager"
5780,"7,5+0+0+7,5x7","7,5  morgen 7,5  kveld i 7 dager"
5781,"7,5+0+0+7,5x10","7,5  morgen 7,5  kveld i 10 dager"
5782,3+3+3,3  morgen 3  midt på dagen 3  kveld
5783,1 ved anfall. Ny ved behov etter minst 2 timer. Maks 150 mg per døgn.,1  ved anfall. Ny  ved behov etter minst 2 timer. Maks 150 mg per døgn.
5784,5+0+0+0x3,5  morgen i 3 dager
5785,"7,5+0+0+0x3","7,5  morgen i 3 dager"
5786,10+0+0+0x3,10  morgen i 3 dager
5793,6+0+0+6x6,6  morgen 6  kveld i 6 dager
5794,8+0+0+8x6,8  morgen 8  kveld i 6 dager
5795,2+2+2x6,2  morgen 2  midt på dagen 2  kveld i 6 dager
5796,4+4+4x6,4  morgen 4  midt på dagen 4  kveld i 6 dager
5798,"Dag 1: 2/15.min i 6 t,$2/30.min. Dag 2: 2/ t. Dag 3-14: 2/ 4.t.","Dag 1: 2  hvert 15.min i 6 t, deretter 2  hvert 30.min. Dag 2: 2  hver time. Dag 3-14: 2  hver 4. t."
5799,2+0+0+0x7,2  morgen i 7 dager
5801,1+0+0+1x21,1  morgen 1  kveld i 21 dager
5802,1+0+0+1x28,1  morgen 1  kveld i 28 dager
5803,2+0+0+0x14,2  morgen i 14 dager
5804,2+0+0+0x21,2  morgen i 21 dager
5805,2+0+0+0x28,2  morgen i 28 dager
5808,Plasteret skiftes hver 3. dag,Plasteret skiftes hver 3. dag
5809,"0,5 hver uke på fast ukedag","0,5  hver uke på fast ukedag"
5810,1 ved anfall,1  ved anfall
5811,Plasteret skiftes hver 24. time,Plasteret skiftes hver 24. time
5812,1+0+0+0x21$1 uke pause,"1  morgen i 21 dager, deretter 1 uke pause"
5813,"5+0+0+0x1$2,5+0+0+0x13","5  morgen første dag, deretter 2,5  morgen i 13 dager"
5814,2+0+0+0x1$1+0+0+0,"2  morgen første dag, deretter 1  morgen"
5815,"1+0+0+0x1$0,5+0+0+0x4","1  morgen første dag, deretter 0,5  morgen i 4 dager"
5816,20+0+0+0x1$10+0+0+0,"20  morgen første dag, deretter 10  morgen"
5817,3+0+0+3x1$3+0+0+0,"3  morgen 3  kveld første dag, deretter 3  morgen"
5818,6+0+0+0x1$3+0+0+0x4,"6  morgen første dag, deretter 3  morgen i 4 dager"
5819,2+0+0+0x1$1+0+0+0x4,"2  morgen første dag, deretter 1  morgen i 4 dager"
5820,4+0+0+0x1$2+0+0+0x4,"4  morgen første dag, deretter 2  morgen i 4 dager"
5821,10+0+0+0x1$5+0+0+0x4,"10  morgen første dag, deretter 5  morgen i 4 dager"
5822,"15+0+0+0x1$7,5+0+0+0x4","15  morgen første dag, deretter 7,5  morgen i 4 dager"
5823,2+0+0+0x1$1+0+0+0x6,"2  morgen første dag, deretter 1  morgen i 6 dager"
5824,1+0+0+1x3$1+0+0+0,"1  morgen 1  kveld i 3 dager, deretter 1  morgen"
5825,1+1+1+1x1$1+0+0+1,"1  morgen 1  formiddag 1  ettermiddag 1  kveld første dag, deretter 1  morgen 1  kveld"
5826,10-30 inntil x1,10-30  inntil 1 gang daglig
5827,Dag 1: 1x1. Dag: 2 1x2. Dag 3: 1x3.,Dag 1: 1  1 gang daglig. Dag 2: 1  2 ganger daglig. Dag 3: 1  3 ganger daglig.
5828,10IR/ml: Dag 1: 1 Dag 2: 2 Dag 3: 3 Dag 4: 4 Dag 5: 5 300IR/ml: Dag 6: 1 Dag 7: 2 Dag 8: 3 Dag 9: 4,"10 IR/ml: Dag 1: 1 , Dag 2: 2 , Dag 3: 3 , Dag 4: 4, Dag 5: 5 , 300 IR/ml: Dag 6: 1 , Dag 7: 2 , Dag 8: 3 , Dag 9: 4"
`;

class DrugStore extends LocalModel {
    constructor() {
        super('drug', {
            namePlural: 'drugs',
            sort: 'navnFormStyrke',
            limit: 100,
            api: {
                search: {
                    url: '/api/drugs/',
                    params: {
                        limit: 15,
                        sort: 'navnFormStyrke',
                    },
                },
                load: {
                    url: '/api/drugs/',
                    params: {},
                },
                save: {
                    url: '/api/drugs/',
                    params: {},
                },
                delete: {
                    url: '/api/drugs/',
                    params: {},
                },
            },
        });

        this.kortDoseDB = parseCSV(kortDoseCSV);
        // console.log('kortDoseDB', this.kortDoseDB);
    }

    findKortDose(drugId) {
        const dose = this.kortDoseDB.find(d => d.KodeId === parseInt(drugId, 10));
        if (dose) {
            return dose.Beskrivelse;
        }
        return '';
    }

    @observable newDrug = {};

    @observable drug = {};

    @observable drugs = [];

    @observable prescriptionDrugTexts = [];
}

const store = new DrugStore();
export default store;
