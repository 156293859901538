import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ShotCalcStore extends LocalModel {
    constructor() {
        super('calendar', {
            namePlural: 'calendars',
            namePluralReal: 'calendars',
            sort: 'name',
            limit: 100,
            api: {
                search: {
                    url: '/api/calendars/',
                    params: {
                        limit: 15,
                        sort: 'name',
                    },
                },
                load: {
                    url: '/api/calendars/',
                    params: {},
                },
                save: {
                    url: '/api/calendars/',
                    params: {},
                },
                delete: {
                    url: '/api/calendars/',
                    params: {},
                },
            },
        });
    }

    @observable shotCalculation = {
        distance: 25,
        mil: 1,
    };

    @observable factorCalculationMilitary = {
        weight: 125,
        velocity: 980,
        factor: 0,
    };
    @observable factorCalculationMagnum1 = {
        weight: 158,
        velocity: 1150,
        factor: 0,
    };

    @observable factorCalculationMagnum2 = {
        weight: 240,
        velocity: 1150,
        factor: 0,
    };

    requiredFactorMilitary = 120;

    requiredFactorMagnum1 = 180;

    requiredFactorMagnum2 = 275;

    @computed get moa() {
        const { shotCalculation } = this;
        const moa = shotCalculation.mil * 3.4377467707849;
        return moa;
    }

    @action
    calculate(fieldToCalculate) {
        const { shotCalculation } = this;
        const { distance, mil, sizeOfTarget } = shotCalculation;
        if (fieldToCalculate === 'sizeOfTarget') {
            shotCalculation.sizeOfTarget = 100 * (distance * mil) / 1000;
        }
        if (fieldToCalculate === 'mil') {
            shotCalculation.mil = 1000 * (sizeOfTarget / 100) / distance;
        }
        if (fieldToCalculate === 'distance') {
            shotCalculation.distance = 1000 * (sizeOfTarget / 100) / mil;
        }
    }

    @action
    calculateFactor(type = 'military') {
        const { factorCalculationMilitary, factorCalculationMagnum1, factorCalculationMagnum2 } = this;
        if (type === 'military') {
            const { type, weight, velocity } = factorCalculationMilitary;
            factorCalculationMilitary.factor = Math.floor(weight * velocity / 1000);
        }
        if (type === 'magnum1') {
            const { type, weight, velocity } = factorCalculationMagnum1;
            factorCalculationMagnum1.factor = Math.floor(weight * velocity / 1000);
            }
        if (type === 'magnum2') {
            const { type, weight, velocity } = factorCalculationMagnum2;
            factorCalculationMagnum2.factor = Math.floor(weight * velocity / 1000);
        }
    }
}

const store = new ShotCalcStore();
export default store;

// Named export for the class itself
export { ShotCalcStore };
