import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import CalendarHourGridAvailability from './hourGridAvailability';

function formatDate(date) {
    return new Intl.DateTimeFormat('nb-NO', { year: 'numeric', month: 'long' }).format(date);
}

@observer
class CalendarAvailability extends Component {
  	constructor(props) {
        super(props);
        this.state = {};
    }

    loadAll = async (props = this.props) => {
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        const { id } = props;
        // calendarStore.updateKeyValue('availabilityCalendars', []);
        // console.log('loadAll', id)
        if (id) {
            const calendarList = await calendarStore.load({ query: { id }, skipUpdate: true });
            calendarStore.updateKeyValue('availabilityCalendars', calendarList);
            await calendarAvailabilityStore.load({ query: { calendar: id }});
        }
        // calendarAvailabilityStore.isOverlapping();
    }

    addEventCallback = async (event, calendarHtmlId) => {
        const props = this.props;
        // console.log('addEventCallback', event, calendarHtmlId);
        this.loadAll(props);
    }

    clickHeader = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.calendar-header').dataset;
        const { calendarStore, appState, saleStore } = this.props.stores;
        const { calendarSelectDateTime, calendarId } = this.props;

        const { drawerLevel = 1, callback = () => {}, skipAnimal } = this.props;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('newCalendarAvailability', {
			height: drawerHeightLarge,
            isNew: true,
            hideAdmin: true,
            id,
            callback: async booking => {
                // console.log('newCalendarEvent callback', booking);
                appState.toggleDrawer(false, drawerLevel + 1);
                callback(booking);
                calendarStore.resetDragging();
            },
		}, drawerLevel + 1);
    }


    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.ids !== this.props.calendars) {
        //     this.loadAll(nextProps);
        // }
    }

    componentWillUnmount() {
    }

    render() {
        const { calendarSelectDateTime, skipAnimal } = this.props;
        const { appState, userStore, calendarStore } = this.props.stores;
        const {
            colors,
            viewName,
            showMonth,
            calendarDate,
            calendarDateStart,
            calendarDateEnd,
            isToday,
            isTodayWeek,
        } = calendarStore;
        const { availabilityCalendars } = calendarStore;
        const currentCalendars = availabilityCalendars;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center'>

                <div class='w-100 h-100'>
                    <div class='d-flex flex-row w-100 justify-content-between mt-2'>
                        <div>
                            <button class={`btn btn-outline-primary rounded-pill-left`} onClick={() => calendarStore.calendarPrev()}>
                                <i class='fa-duotone fa-caret-left' /> <Text id='calendar.prev'>Prev</Text>
                            </button>
                            <button class={`btn btn-${isToday ? 'primary' : 'outline-primary'} rounded-none`} onClick={() => calendarStore.calendarToday()}><Text id='calendar.today'>Today</Text></button>
                            <button class={`btn btn-outline-primary rounded-pill-right`} onClick={() => calendarStore.calendarNext()}>
                                <Text id='calendar.next'>Next</Text> <i class='fa-duotone fa-caret-right' />
                            </button>
                        </div>

                        {showMonth && <>
                            <div class='d-flex flex-column w-50'>

                                <div class='d-flex flex-row w-100 justify-content-center'>
                                    <button class={`btn btn-primary rounded-pill-left`} onClick={() => calendarStore.calendarPrevMonth()}>
                                        <i class='fa-duotone fa-caret-left' />
                                    </button>
                                    <button class={`flex-fill btn btn-outline-primary rounded-none`} disabled>
                                        {formatDate(calendarDate)}
                                    </button>
                                    <button class={`btn btn-primary rounded-pill-right`} onClick={() => calendarStore.calendarNextMonth()}>
                                        <i class='fa-duotone fa-caret-right' />
                                    </button>
                                </div>

                                <div class='table-responsive mt-1'>
                                    <table class='table table-sm table-bordered table-hover'>
                                        <thead>
                                            <tr>
                                                <th class='text-center'><Text id='calendar.week'>Week</Text></th>
                                                <th class='text-center'><Text id='calendar.monday-short'>M</Text></th>
                                                <th class='text-center'><Text id='calendar.tuesday-short'>T</Text></th>
                                                <th class='text-center'><Text id='calendar.wednesday-short'>W</Text></th>
                                                <th class='text-center'><Text id='calendar.thursday-short'>T</Text></th>
                                                <th class='text-center'><Text id='calendar.friday-short'>F</Text></th>
                                                <th class='text-center'><Text id='calendar.saturday-short'>S</Text></th>
                                                <th class='text-center'><Text id='calendar.sunday-short'>S</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {calendarStore.weeksInMonth().map((obj, idx) => {
                                                if (!obj.week) {
                                                    return '';
                                                }
                                                return (<>
                                                    <tr>
                                                        <td class='text-center font-weight-lighter'>
                                                            {obj.week?.week}
                                                        </td>
                                                        {obj.week?.daysInWeek.map((day, idx) => {
                                                            const dayOfWeek = day.getDay();
                                                            const isToday = day.toDateString() === new Date().toDateString();
                                                            const currentIsToday = calendarDate.toDateString() === new Date().toDateString();
                                                            const isCurrentDay = day.toDateString() === calendarDate.toDateString() && !currentIsToday;
                                                            return (<>
                                                                <td
                                                                    class={`text-center
                                                                        ${(dayOfWeek === 6 || dayOfWeek === 0) ? 'text-danger' : ''}
                                                                        ${isToday ? 'font-weight-bolder' : ''}
                                                                        ${isCurrentDay ? 'bg-primary text-white' : ''}
                                                                    `}
                                                                    style={`
                                                                        ${isToday ? 'font-weight: 1000 !important;' : ''}
                                                                    `}
                                                                    onClick={() => calendarStore.calendarSetDate(day)}
                                                                >
                                                                    {day.getDate()}
                                                                </td>
                                                            </>);
                                                        })}
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>}
                        <div>
                            <button
                                type='button'
                                class={`btn btn-${showMonth ? 'primary' : 'outline-primary'} rounded-pill-both`}
                                onClick={() => calendarStore.calendarChangeView('showMonth')}>
                                    {showMonth ? <>
                                        <Text id='calendar.hideMonth'>Hide month</Text>
                                    </> : <>
                                        <Text id='calendar.showMonth'>Show month</Text>
                                    </>}
                                </button>
                        </div>
                    </div>

                    <div class={`d-flex flex-row w-100 justify-content-start mt-2 px-2 box-header font-weight-lighter text-muted`}>
                        {calendarDate && <>
                            <div class='d-flex flex-row w-100 align-items-center justify-content-between'>
                                <span class='mr-2'>{util.formatDate(calendarDate, {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    locale: 'nb-NO',
                                    // hour: '2-digit',
                                    // minute: '2-digit',
                                    // hour12: false,
                                })}</span>
                                <span class='mr-2'>w{util.getWeek(calendarDate)}</span>
                            </div>
                        </>}
                    </div>

                    <div class='d-flex flex-row w-100 justify-content-between mt-0 mb-5'>
                        {currentCalendars.map((c, idx) => {
                            const color = c.color ? c.color : colors[idx].color;
                            const backgroundColor = c.backgroundColor ? c.backgroundColor : colors[idx].backgroundColor;
                            return (<>
                                <div class='d-flex flex-column w-100 h-100 mt-2 mx-1 px-1'>
                                    <div
                                        class='rounded-pill my-1 px-2 py-1 flex-fill text-center calendar-header'
                                        style={`background-color: ${backgroundColor}; color: ${color}`}
                                        onClick={this.clickHeader}
                                        data-id={c.id}
                                    >
                                        {c.name}
                                    </div>
                                    <div class={`w-100 px-2 py-1 mt-2  ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                        <CalendarHourGridAvailability
                                            stores={this.props.stores}
                                            idx={idx}
                                            calendarDate={calendarDate}
                                            calendar={c.id}
                                            calendarSelectDateTime={calendarSelectDateTime}
                                            callback={this.addEventCallback}
                                            skipAnimal={skipAnimal}
                                        />
                                    </div>
                                </div>
                            </>);
                        })}
                    </div>

                </div>
            </div>
        </>);
    }
}

export default CalendarAvailability;
