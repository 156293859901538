import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import Barcode from 'react-barcode';

import localUtil from '../../lib/util';
import Input from '../../components/form/input';

function isValidDate(dateString) {
    // console.log('isValidDate', dateString);

    const date = new Date(dateString);
    // Check if the date is invalid
    if (isNaN(date)) {
        // console.log('isNaN(date)', isNaN(date));
        return false;
    }
    // Check if the year is before 1800
    if (date.getFullYear() < 0) {
        // console.log('date.getFullYear() < 0', date.getFullYear() < 0);
        return false;
    }
    // If we reach here, the date is valid
    return true;
}

@observer
class InputSectionToggle extends Component {

    render() {
        const { section, toggleSectionValues, toggleSectionFunction } = this.props;
        const key = section?.key;
        const safeKey = key.replace(/ /g, '_');
        return (<>
            <div class='position-absolute d-flex flex-column align-items-center' style='right: -70px; top: 0px;'>
                <div class='custom-control custom-switch custom-switch-lg d-flex justify-content-end'>
                    <input
                        type='checkbox'
                        class='inputForm custom-control-input'
                        id={`${safeKey}InputToggle`}
                        name={key}
                        value={(toggleSectionValues[key] === 1 || toggleSectionValues[key] === true || toggleSectionValues[key] === '1' || toggleSectionValues[key] === 'true') ? 0 : 1}
                        onInput={toggleSectionFunction}
                        checked={toggleSectionValues[key] == 1 ? 'checked' : ''}
                        autocomplete={'off'}
                    />
                    <label class='custom-control-label pl-2' for={`${safeKey}InputToggle`} />
                </div>
                <small class='text-muted'>Hidden</small>
            </div>
        </>);
    }
}

@observer
class FormFields extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            showSection: '',
            updateInProgress: {},
            textTemplateSaved: {},
            isInfocus: {},
            textTemplates: [],
            sectionCollapsed: {},
        };
        this.updateTimer = {};
        this.sectionRefs = {};
    }

    setUpdateInProgress = (field, value) => {
        const { updateInProgress } = this.state;
        this.setState({
            updateInProgress: {
                ...updateInProgress,
                [field]: value,
            },
        });
    }

    adminUpdate = (e) => {
        const { id } = e.target.closest('.inputForm').dataset;
        const { datatype } = e.target.dataset;
        const idAsInt = parseInt(id, 10);
        const { name, value: rawValue, type } = e.target;
        this.setUpdateInProgress(`${id}-${name}`, true);

        let value = rawValue;
        if (type === 'number') {
            value = value.replace(',', '.');
            value = value.replace(/[^0-9.]/g, '');
        }
        if (datatype === 'integer') {
            value = parseInt(value, 10);
        }

        const field = `${idAsInt}-${name}`;
        // console.log('adminUpdate', { id, name, field, value, type });
        const {
            holdingStore,
            isAdmin,
            objectName,
            callback = () => {},
            onInputcallback = () => {},
        } = this.props;
        if (type === 'datetime-local' && !isValidDate(value)) {
            // console.log('adminUpdate invalid date', { id, name, field, value, type })
            return;
        }
        if (type === 'date' && !isValidDate(value)) {
            // console.log('adminUpdate invalid date', { id, name, field, value, type })
            return;
        }

        // Update local values
        holdingStore.adminUpdateLocal(idAsInt, { [name]: value }, objectName);
        // holdingStore.adminUpdateLocal(idAsInt, { [name]: value }, 'adminList');
        holdingStore.updateObjectKeyValue(objectName, name, value);
        // Callback for input updates
        onInputcallback(id, name, value);

        clearTimeout(this.updateTimer[field]);
        this.updateTimer[field] = setTimeout(() => {
            this.doAdminUpdate({ id, idAsInt, name, value });
        }, 700);
    }

    updateValue = async ({ name, value, id }) => {
        const { holdingStore, objectName } = this.props;
        // console.log('updateValue', { objectName, name, value });
        holdingStore.updateObjectKeyValue(objectName, name, value);
        if (id) {
            const idAsInt = parseInt(id, 10);
            await holdingStore.saveField(idAsInt, name, value);
        }
    }

    doAdminUpdate = async ({ id, idAsInt, name, value }) => {
        // const { id } = e.target.closest('.inputForm').dataset;
        // const idAsInt = parseInt(id, 10);
        // const { name, value } = e.target;
        const {
            holdingStore,
            isAdmin,
            objectName,
            checkValue = () => {},
            callback = () => {},
        } = this.props;
        // console.log('doAdminUpdate', { id, name, value });
        if (holdingStore) {
            if (isAdmin) {
                await holdingStore.adminUpdate(idAsInt, { [name]: value }, objectName);
            } else {
                // console.log('doAdminUpdate !isAdmin', { id: idAsInt, name, value });
                await holdingStore.saveField(idAsInt, name, value);
            }
            this.setUpdateInProgress(`${id}-${name}`, false);
            callback(value);
            checkValue();
        }
    }

    displayValue = (field, val) => {
        const { holdingStore } = this.props;
        // if (field.name === 'members' && holdingStore[field.lookupArray]) {
        //     const member = holdingStore[field.lookupArray].find((m) => m.id === parseInt(val, 10));
        //     if (member) {
        //         return localUtil.displayName(member, true);
        //     }
        // }
        return val;
    };

    displayValueArrayObject = (field, val) => {
        if (field.displayArrayObject) {
            return field.displayArrayObject(val);
        }
        if (field.name === 'youtubeVideos') {
            return (<img src={localUtil.youtubeThumb(val.url)} style='max-height: 115px;' />);
        }
        if (field.name === 'parts') {
            return `${val.qty} x ${val.partNumber} ${val.name}`;
        }
        if (field.name === 'members') {
            return `${val.firstname} ${val.lastname} ${val.email}`;
        }
        if (field.name === 'tags') {
            return `${val.name}`;
        }
        if (field.name === 'inspections') {
            const values = [];
            for (let i = 1; i <= 5; i += 1) {
                if (val[`check${i}`]) {
                    values.push(val[`check${i}`]);
                }
            }
            if (val[`name`]) {
                values.push(val[`name`]);
            }
            return (<>
                <div class='d-flex w-100 flex-column'>
                    <div class='w-100'>
                        {val.images && <img src={val.images[0].s3MediumLink} style='max-height: 100px;' />}
                    </div>
                    {values.map((v, idx) => {
                        return (<>
                            <div class='w-100 d-flex flex-row text-left'>
                                <div class='mx-2'>
                                    <i class='fa-solid fa-check' />
                                </div>
                                <div class='flex-fill'>
                                    {v}
                                </div>
                            </div>
                        </>);
                    })}
                </div>
            </>);
        }
        return val?.title;
    }

    renderField(field, line) {
        const apiServer = util.getApiServer();

        if (field.showQR) {
            const value = field.getQrValue ? field.getQrValue(line) : `${apiServer}/go/${field.getValue(line)}`;
            return (<>
                <QRCodeSVG value={value} size={400} />
            </>);
        }
        if (field.showBarcode) {
            const value = field.getBarcodeValue ? field.getBarcodeValue(line) : field.getValue(line);
            if (!value) {
                return (<></>);
            }
            return (<>
                <Barcode value={value} />
            </>);
        }

        if (field.showImage) {
            return (<>
                {field.getValue(line).map((image, idx) => {
                    return (<>
                        <img src={`${image.s3MediumLink}`} style='max-width: 100px;' />
                    </>);
                })}
            </>);
        }
        if (field.showFile) {
            return (<>
                {field.getValue(line).map((file, idx) => {
                    return (<>
                        <a
                            href={file.s3Link}
                            title={file.name}
                            target='_blank'
                            native
                            style='font-size: 2.0em;'
                        ><i class='fa-solid fa-file-pdf' /></a>
                    </>);
                })}
            </>);
        }
        if (field.name === 'youtubeVideos') {
            if (!field.getValue(line) || !field.getValue(line).length) {
                return (<></>);
            }
            return (<>
                {field.getValue(line).map((video, idx) => {
                    return (<>
                        <a
                            href={`https://www.youtube.com/watch?v=${video.url}`}
                            title={video.title}
                            target='_blank'
                            native
                            style='font-size: 2.0em;'
                        >
                            <img src={localUtil.youtubeThumb(video.url)} style='max-height: 80px;' />
                        </a>
                    </>);
                })}
            </>);
        }

        return (<>
            <span class='form-label-inline text-muted'>{field.placeholder}</span>
            <div class={`pt-4 pb-2 py-2 pl-2 ${field.type === 'number' ? 'text-center' : ''}`}>
                {field.getValue(line)}
            </div>
        </>);
    }

    chooseItem = async (props) => {
        const {
            holdingStore,
            objectName,
            object: inputObject,
        } = this.props;
        const { id, item, listName, replaceKeys } = props;
        const object = inputObject || holdingStore[objectName];

        // console.log({ id, item, listName, replaceKeys });
        const updateObject = {};
        for (let i = 0, l = replaceKeys.length; i < l; i += 1) {
            const keyPair = replaceKeys[i];
            const [fromKey, toKey] = keyPair;
            const value = item[fromKey];
             // Update local values
            //  holdingStore.adminUpdateLocal(id, { [toKey]: value }, objectName);
            holdingStore.updateObjectKeyValue(objectName, toKey, value);
            updateObject[toKey] = value;
        }

        if (object?.id) {
            await holdingStore.save(updateObject, id);
        }
        holdingStore.updateKeyValue(listName, []);
    }

    setSection = (e) => {
        const { showSection } = this.state;
        const { section } = e.target.closest('span.badge').dataset;
        this.setState({
            showSection: showSection === section ? '' : section,
        });
    }

    onFocus = (e) => {
        const { name } = e.target;
        const { isInfocus } = this.state;
        isInfocus[name] = true;
        this.setState({ isInfocus });
    }

    onBlur = (e) => {
        const { name } = e.target;
        const { isInfocus } = this.state;
        setTimeout(() => {
            delete isInfocus[name];
            this.setState({ isInfocus });
        }, 2000);
    }

    handleSelectTextTemplate = async (e) => {
        const { textTemplates } = this.state;
        const { textTemplateStore } = this.props.stores;
        const { holdingStore, objectName, object, objectUpdate } = this.props;
        // const { textTemplates } = textTemplateStore;
        const { section, field } = e.target.closest('select').dataset;
        const { value } = e.target;
        const textObject = textTemplates.find(t => t.id === parseInt(value, 10));
        const currentObject = object || holdingStore[objectName];
        const currentValue = currentObject[field] || '';
        // console.log('handleSelectTextTemplate', { objectName, section, field, value, textObject, currentObject, currentValue });
        // console.log('objectUpdate', objectUpdate);
        if (objectUpdate) {
            objectUpdate({
                id: currentObject.id,
                name: field,
                value: `${currentValue}${currentValue ? '\n\n' : ''}${textObject.body}`,
                md5: currentObject.md5,
            })
        } else {
            holdingStore.updateObjectKeyValue(objectName, field, `${currentValue}${currentValue ? '\n\n' : ''}${textObject.body}`);
            const updateObject = {};
            updateObject[field] = textObject.body;
            if (currentObject?.id) {
                await holdingStore.save(updateObject, currentObject.id);
            }
        }
    }

    saveTextTemplate = async (e) => {
        const { textTemplateStore } = this.props.stores;
        const { holdingStore, objectName, object } = this.props;
        const { section, field } = e.target.closest('button').dataset;
        const currentObject = object || holdingStore[objectName];

        if (!confirm('Are you sure you want to save this text as a template for everyone?')) {
            return;
        }

        const response = await textTemplateStore.insert({
            section,
            field,
            body: currentObject[field],
        });
        if (response.status === 201) {
            const { textTemplateSaved } = this.state;
            textTemplateSaved[`${field}`] = true;
            this.setState({ textTemplateSaved });
            setTimeout(() => {
                const { textTemplateSaved } = this.state;
                delete textTemplateSaved[`${field}`];
                this.setState({ textTemplateSaved });
            }, 3000);
        }
    }

    toggleSection = (e) => {
        const { sectionCollapsed } = this.state;
        const { key } = e.target.closest('.section-button').dataset;
        sectionCollapsed[key] = !sectionCollapsed[key];
        this.setState({ sectionCollapsed });

        if (this.sectionRefs[key]) {
            this.sectionRefs[key].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }

    toggleAdminFields = () => {
        const { appState } = this.props.stores;
        const { showAdminFields } = appState;
        appState.setShowAdminFields(!showAdminFields);
    }

    loadAll = async () => {
        const { sections, holdingStore, sectionsNotCollapsed } = this.props;

        holdingStore.updateKeyValue('feedback', {});
        holdingStore.updateKeyValue('feedbackButtonFunc', {});
        holdingStore.updateKeyValue('feedbackButtonTitle', {});
        holdingStore.updateKeyValue('feedbackButtonProgress', {});
        holdingStore.updateKeyValue('feedbackButtonDone', {});
        holdingStore.updateKeyValue('feedbackButtonInfo', {});

        const sectionKeys = sections.map(s => s.key);
        if (!sectionsNotCollapsed) {
            const sectionCollapsed = {};
            for (let i = 0, l = sections.length; i < l; i += 1) {
                const section = sections[i];
                if (section.isCollapsed) {
                    sectionCollapsed[section.key] = true;
                }
            }
            this.setState({ sectionCollapsed });
        }

        // console.log('loadAll', { sections, sectionKeys });
        const { textTemplateStore } = this.props.stores;
        const tpls = await textTemplateStore.load({ query: { section: sectionKeys }, skipUpdate: 1 });

        this.setState({ textTemplates: tpls });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { userStore, textTemplateStore, appState } = this.props.stores;
        const { showAdminFields } = appState;
        const {
            showSection,
            updateInProgress = {},
            isInfocus,
            textTemplateSaved,
            textTemplates = [],
            sectionCollapsed = {},
        } = this.state;
        const {
            sections,
            fields,
            holdingStore,
            objectName,
            object: inputObject,
            isNew,
            drawerLevel,
            lookupArray,
            rightHeader,
            rightHeaderClick = () => {},
            hideAdmin = !showAdminFields,
            filter = fields ? f => fields.includes(f.name) : () => true,
            onInput,
            headerMargin = 'mx-4',
            sectionMargin = 'mx-3',
            skipSurroundingDivs = false,
            onKeyDown = () => {},
            isAdmin: isAdminProp,
            fieldsOpts,
            hideFields,
            hideSections,
            hideFilters,
            showRequiredFieldsInfo,
            sectionsNotCollapsed,
            toggleFieldFunction,
            toggleFieldValues = {},
            toggleSectionFunction,
            toggleSectionValues = {},
            openFieldSettings,
            onInputcallback = () => {},
            callback = () => {},
            drawerCallback = () => {},
            classNames,
        } = this.props;
        // const { textTemplates } = textTemplateStore;
        const { user, isAdmin, isCustomerAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const object = inputObject || holdingStore[objectName];
        // console.log('object', object);
        const finalSections = fields ? sections.filter(s => {
            return s.fields.some(f => fields.includes(f.name));
        }) : sections;
        // console.log('finalSections', finalSections);

        // return (<>
        //     <xmp>finalSections: {JSON.stringify(finalSections, null, 2)}</xmp>
        // </>);
        return (<>
            {!skipSurroundingDivs && finalSections && finalSections.length > 1 && <>
                {hideFilters ? <>
                </> : <>
                    <div class={`${headerMargin} font-weight-lighter px-3 py-1 text-muted`}>
                        <small class='text-uppercase'>
                            <Text id='form.filter-sections'>Filter</Text>
                        </small>
                    </div>
                    <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} ${sectionMargin} d-flex flex-wrap py-3 px-3 box-container`}>
                        {isAdmin && <>
                            <span
                                class={`badge badge-pill badge-${showAdminFields ? 'danger' : 'light'} font-weight-bold mr-2 mt-2`}
                                onClick={this.toggleAdminFields}
                            >Show Admin</span>
                        </>}
                        {finalSections && finalSections.filter(s => {
                            if (hideSections && hideSections.length > 0) {
                                return !hideSections.some(e => e.key === s.key);
                            }
                            return true;
                        }).map((section, idx) => {
                            return (<>
                                <span
                                    data-section={section.key}
                                    class={`badge badge-pill badge-${section.key === showSection ? 'primary' : 'light'} font-weight-normal mr-2 mt-2`}
                                    onClick={this.setSection}
                                >
                                    {section.name}
                                    <span class={`ml-1 text-${section.key === showSection ? 'white' : 'muted'}`}>
                                        ({section.fields.filter(filter).filter(f => f.getValue(object)).length}/{section.fields.filter(filter).length})
                                    </span>
                                    {section.key === showSection ? <i class='fa-solid fa-times ml-1' /> : ''}
                                </span>
                            </>);
                        })}
                    </div>
                </>}
            </>}

            {finalSections && finalSections.filter(s => {
                if (hideSections && hideSections.length > 0) {
                    return !hideSections.some(e => e.key === s.key);
                }
                if (showSection && showSection !== '') {
                    return s.key === showSection;
                }
                return true;
            }).map((section, idx) => {
                let sectionFieldIdx = 0;
                const fields = section.fields.filter(f => {
                    if (hideFields && hideFields.length > 0) {
                        return !hideFields.some(e => e.name === f.name);
                    }
                    return true;
                });
                const hasAllFields = section.hasAllRequiredFields ? section.hasAllRequiredFields(object, fields) : true;

                return (<>
                    {section.foundListName && holdingStore[section.foundListName] && holdingStore[section.foundListName].length > 0 && <>
                        <div class='w-100 d-flex flex-column justify-content-start'>
                            <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                                <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                                    <small class='text-uppercase'>
                                        {section.foundListText ? section.foundListText : <Text id='input.found-similar'>Found similar...</Text>}
                                    </small>
                                </div>
                                <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-column px-0 overflow-auto rounded-lg`} style='max-height: 35vh;'>
                                    {holdingStore[section.foundListName].map((item, idx) => {
                                        const keys = section.foundListDisplayKeys;
                                        return(<>
                                            <div class='d-flex flex-row justify-content-between align-items-center'>
                                                <div
                                                    class='flex-fill similar-item py-2 px-3'
                                                    onClick={() => this.chooseItem({
                                                        item,
                                                        listName: section.foundListName,
                                                        replaceKeys: section.foundListReplaceKeys,
                                                    })}
                                                >
                                                    {keys && keys.map(key => <>
                                                        <span class='mr-2'>{item[key]}</span>
                                                    </>)}
                                                </div>
                                                {/* {item.images[0] && <>
                                                    <div>
                                                        <div class=''>
                                                            <img src={`${item.images[0].s3MediumLink}`} class='rounded-lg img-fluid' style='max-height: 45px;' />
                                                        </div>
                                                    </div>
                                                </>} */}
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </>}

                    {!skipSurroundingDivs && <div
                        class={`${headerMargin} mt-4 font-weight-lighter px-3 py-1 text-muted position-relative`}
                        ref={c => this.sectionRefs[section.key] = c}
                    >
                        <small class='text-uppercase section-button' onClick={this.toggleSection} data-key={section.key}>
                            {showRequiredFieldsInfo && <>
                                {hasAllFields ? <>
                                    <i class='fa-duotone fa-circle-check text-success mr-2'></i>
                                </> : <>
                                    <i class='fa-duotone fa-circle-xmark text-danger mr-2'></i>
                                </>}
                            </>}
                            <Localizer>
                                {section.name}
                            </Localizer>
                            {sectionCollapsed[section.key] ? <><i class='fa-duotone fa-caret-up' /></> : <><i class='fa-duotone fa-caret-down' /></>}
                            {rightHeader && <>
                                <div class='float-right' onClick={rightHeaderClick}>
                                    {rightHeader}
                                </div>
                            </>}
                        </small>
                        {toggleSectionFunction && <InputSectionToggle
                            section={section}
                            toggleSectionValues={toggleSectionValues}
                            toggleSectionFunction={toggleSectionFunction}
                        />}
                    </div>}

                    {sectionCollapsed[section.key] ? <>
                        <div
                            class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} ${sectionMargin} d-flex flex-column box-container section-button px-3 py-3`}
                            onClick={this.toggleSection}
                            data-key={section.key}
                        >
                            {section.displayCollapsedInfo ? section.displayCollapsedInfo(object, hideFields) : <>
                                <Text id='input.click-to-view-section'>Click to view section</Text>
                            </>}
                        </div>
                    </> : <>
                        <div
                            class={!skipSurroundingDivs ? `${darkmode ? 'bg-darkmode' : 'bg-lightmode'} ${sectionMargin} d-flex flex-column box-container` : 'w-100'}
                        >
                            {section.fields && section.fields.filter(filter).map((field, idx) => {
                                if (hideFields && hideFields.some(e => e.name === field.name)) {
                                    return (<></>);
                                }
                                if (isAdmin && field.hideIfAdmin) {
                                    return (<></>);
                                }
                                let isReadOnly = isAdmin ? (field.isReadOnly && !field.adminEditAllowed) : field.isReadOnly;
                                if (field.noEdit && !isNew) {
                                    isReadOnly = isAdmin ? !field.adminEditAllowed : true;
                                }
                                if ((field.isAdminField && hideAdmin) || (field.isAdminField && !isAdmin)) {
                                    return (<></>);
                                }
                                if (isNew && field.hideOnCreate) {
                                    return (<></>);
                                }
                                if (isNew && field.defaultValue && !field.getValue(object)) {
                                    holdingStore.updateObjectKeyValue(objectName, field.name, field.defaultValue);
                                }
                                const textTpls = textTemplates.filter(t => t.section === section.key && t.field === field.name);
                                const line = field.getValue(object);

                                let datalist = field.datalist;
                                if (field.datalistName && Array.isArray(holdingStore[field.datalistName])) {
                                    datalist = holdingStore[field.datalistName].map(item => {
                                        return [
                                            field.datalistFieldKey(item),
                                            field.datalistFieldVal(item),
                                        ];
                                    });
                                }
                                sectionFieldIdx += 1;

                                if (field.conditionFunc) {
                                    if (!field.conditionFunc(object)) {
                                        return (<></>);
                                    }
                                }

                                if (fieldsOpts && fieldsOpts[`${section.key}.${field.name}`]) {
                                    field = {
                                        ...field,
                                        ...fieldsOpts[`${section.key}.${field.name}`],
                                    };
                                }

                                return (<>
                                    <div
                                        class={`d-flex flex-row align-items-center position-relative pl-2 pr-2 w-100`}
                                        style={`${sectionFieldIdx > 1 ? `border-top: 1px ${darkmode ? '#303030' : '#f0f0f0'} solid;` : ''}`}
                                    >
                                        {['image', 'file', 'arrayObject'].includes(field.type) && object[field.name]?.length > 2 ? <></> : <>
                                            {(!skipSurroundingDivs || field.type === 'image') && <div
                                                class={`d-flex justify-content-center align-items-center ${field.isAdminField && isAdmin ? 'text-danger' : 'text-muted '}`}
                                                style={`
                                                width: 44px;
                                                height: 44px;
                                                min-width: 44px;
                                                opacity: ${field.isAdminField && isAdmin ? '0.8' : '0.3'};
                                                `}
                                            >
                                                <i class={`${field.icon}`} />
                                            </div>}
                                        </>}
                                        <div class={`flex-grow-1 position-relative`}>
                                            {isReadOnly ? <>
                                                {line ? this.renderField(field, object) : <>
                                                    <span class='form-label-inline text-muted'>{field.placeholder}</span>
                                                    <div class='pt-4 pb-2 pl-2'>
                                                        <span class={`text-muted font-weight-lighter`}>
                                                            <Text id='form.field-empty'>No value for</Text> {field.name}
                                                        </span>
                                                    </div>
                                                </>}
                                            </> : <>
                                                <Localizer>
                                                    <Input
                                                        stores={this.props.stores}
                                                        isAdmin={isAdminProp}
                                                        drawerLevel={drawerLevel}
                                                        holdingStore={holdingStore}
                                                        objectName={objectName}
                                                        object={object}
                                                        type={field.type}
                                                        field={field.name}
                                                        section={section.key}
                                                        fieldObject={field}
                                                        step={field.step}
                                                        viewHtml={field.viewHtml}
                                                        min={field.minFunc ? field.minFunc(object) : field.min}
                                                        max={field.maxFunc ? field.maxFunc(object) : field.max}
                                                        value={line}
                                                        displayArrayValue={(val) => this.displayValue(field, val)}
                                                        displayArrayObject={(val) => this.displayValueArrayObject(field, val)}
                                                        datalist={datalist}
                                                        datalistHelp={field.datalistHelp}
                                                        datalistValues={field.datalistValues}
                                                        useFirstValueAsBackgroundColorForOption={field.useFirstValueAsBackgroundColorForOption}
                                                        title={field.title}
                                                        placeholder={field.placeholder}
                                                        help={field.help}
                                                        showDanger={field.showDanger}
                                                        showWarning={field.showWarning}
                                                        showFieldInfo={field.showFieldInfo}
                                                        maxLength={field.maxLength}
                                                        onInput={onInput || (isNew ? undefined: this.adminUpdate)}
                                                        callback={() => field.callback ? field.callback(object, this.updateValue, holdingStore) : null}
                                                        drawerCallback={() => field.drawerCallback ? field.drawerCallback(object, this.updateValue, holdingStore) : drawerCallback(object, this.updateValue, holdingStore)}
                                                        loadCallback={() => field.loadCallback ? field.loadCallback(object, this.updateValue, holdingStore) : null}
                                                        checkValue={() => field.checkValue ? field.checkValue(object, holdingStore) : null}
                                                        before={() => field.before ? field.before(object, holdingStore) : null}
                                                        onInputcallback={onInputcallback}
                                                        extraDataset={{
                                                            'data-isnew': isNew,
                                                            'data-id': object.id,
                                                            'data-md5': object.md5,
                                                        }}
                                                        drawerSize={field.drawerSize}
                                                        drawerName={field.drawerName}
                                                        drawerRight={field.drawerRight}
                                                        width={field.displayWidth}
                                                        height={field.displayHeight}
                                                        lookupArray={field.lookupArray || section.lookupArray || lookupArray}
                                                        foundListName={field.foundListName || section.foundListName}
                                                        foundListKeysName={field.foundListKeysName || section.foundListKeysName}
                                                        updateInProgress={updateInProgress[`${object.id}-${field.name}`]}
                                                        onFocus={this.onFocus}
                                                        onBlur={this.onBlur}
                                                        onKeyDown={onKeyDown}
                                                        skipSurroundingDivs={skipSurroundingDivs}
                                                        addToImages={field.addToImages}
                                                        usefulValues={field.usefulValues}
                                                        toggleFieldFunction={toggleFieldFunction}
                                                        toggleFieldValues={toggleFieldValues}
                                                        openFieldSettings={openFieldSettings}
                                                        isRequired={field.isRequired}
                                                        classNames={classNames}
                                                    />
                                                </Localizer>

                                                {field.hideTextTemplate ? <></> : <>
                                                    {field.type === 'textarea' && object[field.name] && isInfocus[field.name] && <>
                                                        <div class='d-flex flex-row justify-content-end align-items-end'>
                                                            {isCustomerAdmin ? <>
                                                                <button
                                                                    class='btn btn-link btn-sm text-muted'
                                                                    onClick={this.saveTextTemplate}
                                                                    data-section={section.key}
                                                                    data-field={field.name}
                                                                >
                                                                    <i class='fa-duotone fa-floppy-disk' /> <Text id='input.save-text-template'>Save as template</Text>
                                                                </button>
                                                                {textTemplateSaved[field.name] && <>
                                                                    <div class='alert alert-success' role='alert'>
                                                                        <Text id='input.text-template-saved'>Text template saved</Text>
                                                                    </div>
                                                                </>}
                                                            </> : <></>}
                                                        </div>
                                                    </>}
                                                    {textTpls && textTpls.length > 0 && <>
                                                        {/* section: {section.key} field: {field.name}<br />
                                                        <xmp>{JSON.stringify(textTpls, null, 2)}</xmp> */}

                                                        <div class='input-group mb-2'>
                                                            <div class='input-group-prepend'>
                                                                <div class='input-group-text'>
                                                                    <i class='fa-duotone fa-text-size' />
                                                                </div>
                                                            </div>
                                                            {/* <label for='exampleFormControlSelect1'>Example select</label> */}
                                                            <select
                                                                class='form-control form-control-sm'
                                                                data-section={section.key}
                                                                data-field={field.name}
                                                                onChange={this.handleSelectTextTemplate}
                                                                >
                                                                <option value=''>-- <Text id='input.choose-text-template'>Choose text template</Text> --</option>
                                                                {textTpls.map(t => {
                                                                    return (<>
                                                                        <option value={t.id}>{t.body}</option>
                                                                    </>);
                                                                })}
                                                            </select>
                                                        </div>
                                                    </>}
                                                </>}

                                            </>}
                                        </div>
                                    </div>
                                </>);
                            })}
                        </div>
                        {section.isCollapsed && !sectionsNotCollapsed && <div class='d-flex flex-row justify-content-center px-3 mt-1'>
                            <button
                                class={`btn btn-outline-success rounded-pill section-button`}
                                onClick={this.toggleSection}
                                data-key={section.key}
                            >
                                <i class='fa-duotone fa-check' /> <Text id='input.section-done'>Done with this section</Text>
                            </button>
                        </div>}

                        {!skipSurroundingDivs && section.help && <div class='mx-4 font-weight-lighter px-3 pb-1 text-muted' style='font-size: 1.0rem; line-height: 1.0rem;'>
                            <small class=''>
                                {section.help}
                            </small>
                        </div>}
                    </>}

                </>);
            })}
        </>);
    }
}

export default FormFields;
