import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class VersionStore extends LocalModel {
    constructor() {
        super('version', {
            namePlural: 'versions',
            namePluralReal: 'versions',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/versions/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/versions/',
                    params: {},
                },
                save: {
                    url: '/api/versions/',
                    params: {},
                },
                delete: {
                    url: '/api/versions/',
                    params: {},
                },
            },
        });
    }

    @observable newVersion = {};

    @observable version = {};

    @observable versions = [];

    @observable users = [];
}

const store = new VersionStore();
export default store;
