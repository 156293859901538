import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import md5 from 'crypto-js/md5';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

@withText(props => ({
    drugTitle: <Text id='dyrejournal.drug.search-title'>Search for drug</Text>,
    newDrugTitle: <Text id='dyrejournal.drug.new-title'>New drug</Text>,
}))
@observer
class AddPrescription extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('prescription'),
            sectionDrugs: fields.get('prescriptionDrugs'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, isNew } = props;
        const { drugStore, saleStore, prescriptionStore } = this.props.stores;
        const { newSale } = saleStore;
        // await drugStore.load();
        await prescriptionStore.loadVeterinaries();
        await this.loadPrescription(id, isNew);
    }

    loadPrescription = async (id, isNew) => {
        const { journalStore, prescriptionStore } = this.props.stores;
        if (isNew) {
            const { newJournal } = journalStore;
            const { veterinary, id: journal, animal, sale } = newJournal;
            prescriptionStore.updateObjectKeyValue('newPrescription', 'date', new Date());
            prescriptionStore.updateObjectKeyValue('newPrescription', 'veterinary', veterinary);
            prescriptionStore.updateObjectKeyValue('newPrescription', 'journal', journal);
            prescriptionStore.updateObjectKeyValue('newPrescription', 'animal', animal);
            prescriptionStore.updateObjectKeyValue('newPrescription', 'sale', sale);
        } else {
            const newPrescription = await prescriptionStore.load(parseInt(id, 10), false, { skipUpdate: true });
            prescriptionStore.updateKeyValue('newPrescription', newPrescription[0]);
        }
    }

    savePrescription = async e => {
        const { prescriptionStore } = this.props.stores;
        const { newPrescription } = prescriptionStore;

        if (newPrescription.id) {
            await prescriptionStore.save(newPrescription);
        } else {
            const response = await prescriptionStore.insert(newPrescription);
            // Update current newPrescription object
            const prescription = response?.data?.prescription;
            prescriptionStore.updateObjectKeyValue('newPrescription', 'id', prescription?.id);
        }
        return true;
    }

    searchDrugCallback = selectedItem => {
        const { appState, saleStore, drugStore, prescriptionStore } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { newPrescription } = prescriptionStore;
        // console.log('searchDrugCallback', selectedItem);
        const { drugs = [] } = newPrescription;

        // {e.kortDose && e.kortDose.map((v, i) => <>
        //     <tr>
        //         <td>dn</td>
        //         <td>{v.dn} ({v.v})</td>
        //         <td>{drugStore.findKortDose(v.v)}</td>
        //     </tr>
        // </>)}

        const { prescriptionDrugTexts } = drugStore;
        const currentText = prescriptionDrugTexts.find(v => v.lId === selectedItem.lId);
        // console.log('searchDrugCallback', { selectedItem, prescriptionDrugTexts, currentText });
        const texts = {};
        if (currentText) {
            texts.diagnoseText = currentText.diagnoseText;
            texts.usageText = currentText.usageText;
        }

        let dosage = '';
        if (selectedItem.kortDose && selectedItem.kortDose.length > 0) {
            dosage = selectedItem.kortDose.map(v => {
                const kortDose = drugStore.findKortDose(v.v);
                return `${kortDose}`;
            }).join(', ');
        }

        prescriptionStore.updateObjectKeyValue('newPrescription', 'drugs', [...drugs, {
            md5: md5(JSON.stringify(newPrescription)).toString(),
            ...selectedItem,
            ...texts,
            dosage,
        }]);
        // appState.toggleDrawerRight(false, drawerLevel);
    }

    onInputDrugInfo = e => {
        const { name, value } = e.target;
        const { md5 } = e.target.dataset;
        // console.log('onInputDrugInfo', { name, value, md5 });
        const { prescriptionStore } = this.props.stores;
        const { newPrescription } = prescriptionStore;
        const idx = newPrescription.drugs.findIndex(v => v.md5 === md5);
        prescriptionStore.updateObjectKeyArrFieldValue('newPrescription', 'drugs', idx, name, value);
    }

    inputDrugInfo = ({ name, value, md5 }) => {
        console.log('inputDrugInfo', { name, value, md5 });
        const { prescriptionStore } = this.props.stores;
        const { newPrescription } = prescriptionStore;
        const idx = newPrescription.drugs.findIndex(v => v.md5 === md5);
        prescriptionStore.updateObjectKeyArrFieldValue('newPrescription', 'drugs', idx, name, value);
    }

    onClickCreatePrescription = async e => {
        // console.log('onClickCreatePrescription', e);
        const { callback = () => {} } = this.props;
        const { prescriptionStore } = this.props.stores;
        await this.savePrescription();
        const { newPrescription } = prescriptionStore;
        callback({
            ...newPrescription,
            md5: md5(JSON.stringify(newPrescription)).toString(),
        });
        prescriptionStore.updateKeyValue('newPrescription', {});
    }

    onClickNewDrug = e => {
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore, animalStore } = this.props.stores;
		const { drawerHeightMedium } = appState;

		appState.openDrawer('newDrug', {
			height: drawerHeightMedium,
            isNew: true,
            hideAdmin: true,
            callback: drug => {
                appState.toggleDrawer(false, drawerLevel + 1);
            },
		}, drawerLevel + 1);
    }

    // onClickRemoveDrug = e => {
    //     // console.log('onClickRemoveDrug', e);
    //     const { id } = e.target.closest('drugs').dataset;
    //     const { prescriptionStore } = this.props.stores;
    //     const { newPrescription } = prescriptionStore;
    //     const { drugs } = newPrescription;
    //     const newDrugs = drugs.filter(v => v.id !== parseInt(id, 10));
    //     prescriptionStore.updateObjectKeyValue('newPrescription', 'drugs', newDrugs);
    // }

    onClickRemoveDrug = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { prescription, id, md5 } = e.target.closest('.drug').dataset;

        // console.log('onClickRemovePrescription', { journal, id, md5 });
        const { journalStore, prescriptionStore } = this.props.stores;
        const { newPrescription } = prescriptionStore;
        // prescriptionStore.removeArrayObject({ field: 'drugs', id: prescription, md5 });
        const { drugs } = newPrescription;
        const newDrugs = drugs.filter(p => p.md5 !== md5);
        prescriptionStore.updateObjectKeyValue('newPrescription', 'drugs', newDrugs);
        // await this.savePrescription();
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            sections,
            sectionDrugs
        } = this.state;
        const {
            newDrugTitle,
            drugTitle,
            drawerLevel = 1,
            isNew,
            hideAdmin,
            saleIsLocked,
        } = this.props;
        const { userStore, drugStore, prescriptionStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { newPrescription } = prescriptionStore;
        const { drugs } = newPrescription;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.prescription.add'>Add prescription</Text>
                            </div>

                            <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                                <FormFields
                                    stores={this.props.stores}
                                    sections={sections}
                                    holdingStore={prescriptionStore}
                                    objectName={'newPrescription'}
                                    isNew={newPrescription.id ? false : true}
                                    hideAdmin={hideAdmin}
                                    drawerLevel={drawerLevel}

                                    // filter={field => {
                                    // }}
                                />
                            </div>

                            <div class='font-weight-lighter text-muted mx-4'>
                                <small class='text-uppercase ml-3'>
                                    <Text id='dyrejournal.prescription.drugs'>Prescription drugs</Text>
                                </small>
                            </div>

                            <div class={`d-flex flex-column mx-4 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <DyrejournalSaleSearch
                                    stores={this.props.stores}
                                    key={'drugs'}
                                    addData={['prescriptionDrugTexts']}
                                    title={drugTitle}
                                    holdingStore={drugStore}
                                    searchResultList={'searchResults'}
                                    searchResultsLine={(e, idx, selectedIndex) => {
                                        return(<>
                                            <div class={`d-flex flex-row justify-content-between px-3 py-1 ${idx === selectedIndex ? 'selected' : ''}`}>
                                                <div class={`d-flex flex-row justify-content-between`}>
                                                    <div>{e.atcDn}</div>
                                                    <div>{e.legemiddelformKortDn}</div>
                                                </div>
                                                <div class={`d-flex flex-row justify-content-between ml-2`}>
                                                    <div>{e.navnFormStyrke}</div>
                                                    <div class='ml-2'>{e.pakningsinfoMengde} {e.pakningsinfoEnhetPakningV}</div>
                                                </div>
                                                {/* <div class={`d-flex flex-row justify-content-between`}>
                                                    <div>{e.pakningsinfoMengde} {e.pakningsinfoEnhetPakningV} </div>
                                                    <div>{e.pakningsinfoPakningstypeDn}</div>
                                                    <div>{e.administrasjonsveiDn}</div>
                                                    <div class='text-muted'>{e.reseptgruppeDn}</div>
                                                </div> */}
                                            </div>
                                        </>);
                                    }}
                                    searchResultsFields={e => {
                                        return(<>
                                            <div class={`table-responsive`}>
                                                <table class='table table-sm table-borderless mb-0'>
                                                    <tbody>
                                                        <tr><td class='text-muted'>virkestoffNavn</td><td>{e.virkestoffNavn}</td></tr>
                                                        <tr><td class='text-muted'>virkestoffNavnEngelsk</td><td>{e.virkestoffNavnEngelsk}</td></tr>
                                                        <tr><td class='text-muted'>administrasjonLegemiddelKortdose</td><td>
                                                            <table class='table table-sm table-borderless mb-0'>
                                                                <tbody>
                                                                    {e.administrasjonLegemiddelKortdose && e.administrasjonLegemiddelKortdose.map((v, i) => <>
                                                                        <tr>
                                                                            <td>{v.dn}</td>
                                                                        </tr>
                                                                    </>)}
                                                                </tbody>
                                                            </table>
                                                        </td></tr>
                                                        <tr><td class='text-muted'>forhandsregelInntak</td><td>
                                                            <table class='table table-sm table-borderless mb-0'>
                                                                <tbody>
                                                                    {e.forhandsregelInntak && e.forhandsregelInntak.map((v, i) => <>
                                                                        <tr>
                                                                            <td>{v.dn}</td>
                                                                        </tr>
                                                                    </>)}
                                                                </tbody>
                                                            </table>
                                                        </td></tr>
                                                        <tr><td class='text-muted'>kortDose</td><td>
                                                            <table class='table table-sm table-borderless mb-0'>
                                                                <tbody>
                                                                    {e.kortDose && e.kortDose.map((v, i) => <>
                                                                        <tr>
                                                                            <td>{v.dn} ({v.v})</td>
                                                                            <td>{drugStore.findKortDose(v.v)}</td>
                                                                        </tr>
                                                                    </>)}
                                                                </tbody>
                                                            </table>
                                                        </td></tr>
                                                        <tr><td class='text-muted'>bruksomradeEtikett</td><td>
                                                            <table class='table table-sm table-borderless mb-0'>
                                                                <tbody>
                                                                    {e.bruksomradeEtikett && e.bruksomradeEtikett.map((v, i) => <>
                                                                        <tr>
                                                                            <td>{v.dn}</td>
                                                                        </tr>
                                                                    </>)}
                                                                </tbody>
                                                            </table>
                                                        </td></tr>
                                                        <tr><td class='text-muted'>administrasjonLegemiddelForhandsregelInntakDn</td><td>{e.administrasjonLegemiddelForhandsregelInntakDn}</td></tr>
                                                        <tr><td class='text-muted'>navnFormStyrke</td><td>{e.navnFormStyrke}</td></tr>
                                                        <tr><td class='text-muted'>administrasjonLegemiddelKanKnusesDn</td><td>{e.administrasjonLegemiddelKanKnusesDn}</td></tr>
                                                        <tr><td class='text-muted'>administrasjonLegemiddelEnhetDoseringDn</td><td>{e.administrasjonLegemiddelEnhetDoseringDn}</td></tr>
                                                        <tr><td class='text-muted'>reseptgyldighetVarighet</td><td>{e.reseptgyldighetVarighet}</td></tr>

                                                        <tr><td class='text-muted'>administrasjonsveiDn</td><td>{e.administrasjonsveiDn}</td></tr>
                                                        <tr><td class='text-muted'>delingDn</td><td>{e.delingDn}</td></tr>
                                                        <tr><td class='text-muted'>enhetDoseringDn</td><td>{e.enhetDoseringDn}</td></tr>
                                                        <tr><td class='text-muted'>forhandsregelInntakDn</td><td>{e.forhandsregelInntakDn}</td></tr>
                                                        <tr><td class='text-muted'>smakDn</td><td>{e.smakDn}</td></tr>
                                                        <tr><td class='text-muted'>varenavn</td><td>{e.varenavn}</td></tr>
                                                        <tr><td class='text-muted'>atcDn</td><td>{e.atcDn}</td></tr>
                                                        <tr><td class='text-muted'>ean</td><td>{e.ean}</td></tr>
                                                        <tr><td class='text-muted'>legemiddelformKortDn</td><td>{e.legemiddelformKortDn}</td></tr>
                                                        <tr><td class='text-muted'>oppbevaringDn</td><td>{e.oppbevaringDn}</td></tr>
                                                        <tr><td class='text-muted'>pakningsinfoEnhetPakningDn</td><td>{e.pakningsinfoEnhetPakningDn}</td></tr>
                                                        <tr><td class='text-muted'>pakningsinfoMengde</td><td>{e.pakningsinfoMengde}</td></tr>
                                                        <tr><td class='text-muted'>pakningsinfoPakningsstr</td><td>{e.pakningsinfoPakningsstr}</td></tr>
                                                        <tr><td class='text-muted'>pakningsinfoPakningstypeDn</td><td>{e.pakningsinfoPakningstypeDn}</td></tr>
                                                        <tr><td class='text-muted'>preparattypeDn</td><td>{e.preparattypeDn}</td></tr>
                                                        <tr><td class='text-muted'>reseptgruppeDn</td><td>{e.reseptgruppeDn}</td></tr>
                                                        <tr><td class='text-muted'>typeSoknadSlvDn</td><td>{e.typeSoknadSlvDn}</td></tr>
                                                        <tr><td class='text-muted'>varenr</td><td>{e.varenr}</td></tr>

                                                        <tr><td class='text-muted'>virkestoffMedStyrke</td><td>
                                                            <table class='table table-sm table-borderless mb-0'>
                                                                <tbody>
                                                                    {e.virkestoffMedStyrke && e.virkestoffMedStyrke.map((v, i) => <>
                                                                        <tr><td>styrkeV</td><td>{v.styrkeV}</td></tr>
                                                                        <tr><td>styrkeU</td><td>{v.styrkeU}</td></tr>
                                                                        <tr><td>styrkeoperatorDn</td><td>{v.styrkeoperatorDn}</td></tr>
                                                                        <tr><td>atcKombipreparatDn</td><td>{v.atcKombipreparatDn}</td></tr>
                                                                    </>)}
                                                                </tbody>
                                                            </table>
                                                        </td></tr>



                                                        <tr><td class='text-muted'>pakningsinfoRefLegemiddelMerkevare</td><td>{e.pakningsinfoRefLegemiddelMerkevare}</td></tr>
                                                        <tr><td class='text-muted'>legemiddelformLang</td><td>{e.legemiddelformLang}</td></tr>
                                                        <tr><td class='text-muted'>preparatomtaleavsnittAvsnittoverskriftDn</td><td>{e.preparatomtaleavsnittAvsnittoverskriftDn}</td></tr>
                                                        <tr><td class='text-muted'>preparatomtaleavsnittLenke</td><td>{e.preparatomtaleavsnittLenke}</td></tr>
                                                        <tr><td class='text-muted'>produktInfoProdusent</td><td>{e.produktInfoProdusent}</td></tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </>);
                                    }}
                                    callback={this.searchDrugCallback}
                                    rightButton={newDrugTitle}
                                    rightButtonClassNames='btn-outline-secondary'
                                    rightButtonClick={this.onClickNewDrug}
                                />

                                {(drugs && drugs.length > 0) && <>
                                    {drugs.map(drug => <>
                                        <div class='d-flex flex-column border box-container mx-4 mt-2 bg-light p-3 position-relative' style='border-radius: 1.0em !important;'>

                                            {!saleIsLocked && <>
                                                <div
                                                    class='ml-2 drug position-absolute'
                                                    onClick={this.onClickRemoveDrug}
                                                    data-journal={newPrescription.id}
                                                    data-md5={drug.md5}
                                                    style='font-size: 1.4em; top: 10px; right: 10px; cursor: pointer;'
                                                >
                                                    <i class='fa-solid fa-circle-xmark text-danger' />
                                                </div>
                                            </>}

                                            <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                                <div class='text-muted'>
                                                    <Text id='dyrejournal.prescription.drug'>Rp.</Text>
                                                </div>
                                                <div class='flex-fill ml-2'>
                                                    {drug.qty} x {drug.navnFormStyrke} ({drug.pakningsinfoMengde} {drug.pakningsinfoEnhetPakningV})
                                                    <br />
                                                    <small class='text-muted'>
                                                        Type: {drug.preparattypeDn}
                                                        {drug.atcDn && <>, <span class='font-weight-lighter'>ATC:</span> {drug.atcDn}</>}
                                                        {drug.reseptgruppeV && <>, <span class='font-weight-lighter'>Grp:</span> {drug.reseptgruppeV}</>}
                                                        {drug.smakDn && <>, <span class='font-weight-lighter'>Smak:</span> {drug.smakDn}</>}
                                                    </small>
                                                </div>
                                            </div>
                                            <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                                <div class='text-muted'>
                                                    <Text id='dyrejournal.prescription.dose'>Dssn.</Text>
                                                </div>
                                                <div class='flex-fill ml-2'>
                                                    {drug.dosage && <>{drug.dosage}<br /></>}
                                                    {drug.frequency && <>{drug.frequency}<br /></>}
                                                    <small class='text-muted'>
                                                        {drug.administrasjonsveiDn && <>Adm: {drug.administrasjonsveiDn}<br /></>}
                                                        {drug.forhandsregelInntakDn && <>Merk: {drug.forhandsregelInntakDn}</>}
                                                    </small>
                                                </div>
                                            </div>
                                            {drug.preparatomtaleavsnittLenke && <div class={`d-flex flex-row justify-content-between px-3 py-1`}>
                                                <div class='text-muted'>
                                                    <Text id='dyrejournal.prescription.about'>About</Text>
                                                </div>
                                                <div class='flex-fill ml-2'>
                                                    <a href={drug.preparatomtaleavsnittLenke} target='_blank' rel='noopener noreferrer'>{drug.preparatomtaleavsnittLenke}</a>
                                                </div>
                                            </div>}

                                            <div class={`d-flex flex-column justify-content-between py-1`}>
                                                <FormFields
                                                    stores={this.props.stores}
                                                    sections={sectionDrugs}
                                                    holdingStore={prescriptionStore}
                                                    objectName={'newPrescription'}
                                                    object={drug}
                                                    // isNew={newPrescription.id ? false : true}
                                                    hideAdmin={hideAdmin}
                                                    drawerLevel={drawerLevel}
                                                    objectUpdate={this.inputDrugInfo}
                                                    onInput={this.onInputDrugInfo}
                                                    // filter={field => {
                                                    // }}
                                                />
                                            </div>

                                        </div>
                                    </>)}
                                </>}

                            </div>
                        </div>
                    </div>


                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.onClickCreatePrescription}>
                            {newPrescription.id ? <Text id='dyrejournal.prescription.save'>Save prescription</Text> : <Text id='dyrejournal.prescription.create'>Create prescription</Text>}
                        </button>
                    </div>}

                </div>
            </div>
        );
    }
}

export default AddPrescription;
