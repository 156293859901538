import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

@withText(props => ({
    title: <Text id='part.sortby'>Sort by</Text>,
}))
@observer
class PartSort extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            filterByTags: [],
        };
    }

    sortByField = e => {
        const { drawerLevel = 1 } = this.props;
        const { appState, partStore } = this.props.stores;
        const { field, dir } = e.target.dataset;
        const {
            sortTitle,
        } = partStore;
        const sortByField = sortTitle.replace(/^-/, '');
        let sortDir = dir;

        if (sortByField === field) {
            sortDir = sortTitle[0] === '-' ? 'desc' : 'asc';
            sortDir = sortDir === 'asc' ? 'desc' : 'asc';
            // console.log('sortByField: A', { field, sortTitle, sortByField, sortDir }, `${sortDir === 'asc' ? '' : '-'}${field}`);
        }
        // console.log('sortByField', { field, sortTitle, sortByField, sortDir }, `${sortDir === 'asc' ? '' : '-'}${field}`);
        partStore.setSortTitle(`${sortDir === 'asc' ? '' : '-'}${field}`);
        appState.toggleDrawer(drawerLevel);
    }

    render() {
        const { title } = this.props;
        const { partStore, userStore } = this.props.stores;
        const { user } = userStore;
        const {
            sortBy,
            sortTitle,
        } = partStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const sortByField = sortTitle.replace(/^-/, '');
        const sortDir = sortTitle[0] === '-' ? 'desc' : 'asc';

        return (<>
            <div class={`w-100 d-flex flex-column`}>
                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                    {title}
                </div>
                <div class={`d-flex flex-column mx-3 rounded-lg p-3 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                    {sortBy.map(field => {
                        let dir = field[0] === '-' ? 'desc' : 'asc';
                        let f = field.replace(/^-/, '');
                        const fObj = fields.getField('part', f);

                        return (<>
                            <button
                                class={`btn btn-lg ${sortByField === f ? 'btn-primary' : 'btn-outline-primary'} rounded-pill mb-2`}
                                data-field={f}
                                data-dir={dir}
                                onClick={this.sortByField}
                            >
                                <span class='mr-2'>
                                    {sortByField === f ? <>
                                        {sortDir === 'asc' ? <i class='fas fa-arrow-up-short-wide' /> : <i class='fas fa-arrow-down-wide-short' />}
                                    </> : <>
                                        {dir === 'asc' ? <i class='fas fa-arrow-down-wide-short' /> : <i class='fas fa-arrow-up-short-wide' />}
                                    </>}
                                </span>
                                {fObj ? fObj.title : f}
                            </button>
                        </>);
                    })}
                </div>
            </div>
        </>);
    }
}

export default PartSort;
