import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ProductStore extends LocalModel {
    constructor() {
        super('product', {
            namePlural: 'products',
            sort: '-createdDate',
            limit: 5000,
            api: {
                search: {
                    url: '/api/products/',
                    params: {
                        limit: 5000,
                        // sort: 'name',
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/products/',
                    params: {},
                },
                save: {
                    url: '/api/products/',
                    params: {},
                },
                delete: {
                    url: '/api/products/',
                    params: {},
                },
            },
        });
    }

    @observable newProduct = {};

    @observable product = {};

    @observable products = [];

    @observable allProducts = [];

    @observable searchResults = [];

    @observable productJournals = [];

    async updateAllPrices({ account, category, percent }) {
        const response = await util.fetchApi(`/api/products/adjustallprices`, { method: 'PATCH' }, {
            percent,
            account,
            category,
        });
        switch (response?.status) {
            case 201:
                // this.updateKeyValue('breeds', response.data);
                return response;
        }
    }
}

const store = new ProductStore();
export default store;
