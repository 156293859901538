import { h, Component } from 'preact';

class Refresh extends Component {
    render() {
        const { height } = this.props;
        const { appState } = this.props.stores;
        const {
            currentCustomer = {},
        } = appState;

        // const paddingTop = `margin-top: calc(${currentCustomer?.logoHeight || '0px'} - 45px);`;
        const paddingTop = `margin-top: 45px;`;
        const fontSize = `${Math.round(height * 0.5)}px`;

        return (
            <div
                class='d-flex flex-row justify-content-center align-items-center overflow-hidden'
                style={`
                    height: ${height}px;
                    transition: all 0.5s ease;
                    font-size: ${fontSize};
                    box-shadow: inset 0px -4px 10px -4px rgba(0, 0, 0, 0.5);
                    background-color: rgba(0, 0, 0, 0.1);
                `}
            >
                <i class='fa-duotone fa-spinner-third fa-spin' />
            </div>
        );
    }
}

export default Refresh;

