import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import FormFields from '../../components/form/fields';

@observer
class EditField extends Component {
  	constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            isNew,
            drawerLevel,
            holdingStore,
            sections,
            fields,
            objectName,
            object,
            onInputcallback,
            id,
            objectUpdate,
        } = this.props;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(animalJournals, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <h5 class='ml-3 mb-3'>
                        {isNew ? <Text id='journal.new'>New journal</Text> : <Text id='journal.edit'>Edit journal</Text>}
                    </h5> */}
                    {/* <div class='bg-light d-flex flex-column justify-content-start pt-3'>
                        sections: <xmp>{JSON.stringify(sections, null, 2)}</xmp><br />
                        fields: <xmp>{JSON.stringify(fields, null, 2)}</xmp><br />
                        objectName: <xmp>{JSON.stringify(objectName, null, 2)}</xmp><br />
                        object: <xmp>{JSON.stringify(object, null, 2)}</xmp><br />
                    </div> */}

                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            fields={fields}
                            holdingStore={holdingStore}
                            object={object}
                            isNew={isNew}
                            objectName={objectName}
                            drawerLevel={drawerLevel}
                            onInputcallback={onInputcallback}
                            id={id}
                            objectUpdate={objectUpdate}
                        />
                    </div>

                </div>
            </div>
        </>);
    }
}

export default EditField;
