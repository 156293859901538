import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

const initialState = {};

@observer
class Tooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.container = null;
    }

    onMouseOver = e => {
        let width;
        let height;
        if (this.container) {
            // Width and height of the tooltip
            width = this.container.offsetWidth;
            height = this.container.offsetHeight;
        }
        this.setState({
            show: true,
            width,
            height,
        });
    }

    onMouseOut = e => {
        this.setState({
            show: false,
        });
    }

    render() {
        const {
            styles = '',
            text,
            children,
            left = 0,
            width = 230,
        } = this.props;
        const {
            show,
            height,
        } = this.state;

        return (<>
            <span
                class={`${styles} position-relative`}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                ref={c => this.container = c}
            >
                {text}

                {show && <>
                    <div
                        class='position-absolute bg-light border font-weight-light p-4 text-left rounded-lg shadow'
                        style={`top: ${height + 5}px; left: ${left}px; width: ${width}px; z-index: 1000000;`}
                    >
                        {children}
                    </div>
                </>}
            </span>
        </>);
    }
}

export default Tooltip;
