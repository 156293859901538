import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { route } from 'preact-router';
import linkState from 'linkstate';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';

@observer
class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    goHome = () => {
        const { appState } = this.props.stores;
        const { language = 'en', wantedPage = '/' } = appState;
        route(wantedPage);
    }

    scrollToTop = () => {
		const {  refreshPage = () => {} } = this.props;
		const { appState } = this.props.stores;
		appState.appContainerScrollTop();
		refreshPage();
	}

    render() {
        const { style = 'font-size: 2.5em;' } = this.props;
        const { appState, raceStore, userStore } = this.props.stores;
        const { path } = appState;
        const { isAdmin, isRaceAdmin, isVeterinary, user = {}, register = {} } = userStore;
        const { language = 'no' } = user;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { race } = raceStore;
        const { logo, logoHeight = 100, logoUrl, css } = race;

        if (/^\/(race|raceclass)\/\d+/.test(path) && race.logo) {
            return (<></>);
        //     return (<>
        //         <a href={logoUrl}><img src={logo} style='max-height: 47px;' /></a>
        //     </>);
        }

        return (<>
            <a
                class='navbar-brand'
                href='/'
                data-path={path}
                onClick={this.scrollToTop}
            >
                {darkmode ? <>
                    <nobr>
                        <span style={style} onClick={this.goHome}>
                            <i class='fa-duotone fa-paw mr-2' /> Raske<span class='font-weight-lighter text-muted'>Poter</span>
                        </span>
                    </nobr>
                </> : <>
                    <nobr>
                        <span style={style} onClick={this.goHome}>
                            <i class='fa-duotone fa-paw mr-2' /> Raske<span class='font-weight-lighter text-muted'>Poter</span>
                        </span>
                    </nobr>
                </>}
                <span class='font-weight-bold text-danger ml-1' style='font-size: 0.5em;'>
                    BETA
                </span>
            </a>
        </>);
    }
}

export default Logo;
