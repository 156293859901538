import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class StreamStore extends LocalModel {
    constructor() {
        super('stream', {
            namePlural: 'streams',
            namePluralReal: 'streams',
            sort: 'start',
            limit: 500,
            api: {
                search: {
                    url: '/api/streams/',
                    params: {
                        limit: 15,
                        sort: 'timestamp',
                    },
                },
                load: {
                    url: '/api/streams/',
                    params: {},
                },
                save: {
                    url: '/api/streams/',
                    params: {},
                },
                delete: {
                    url: '/api/streams/',
                    params: {},
                },
            },
        });
    }

    @observable newStream = {};

    @observable stream = {};

    @observable streams = [];
}

const store = new StreamStore();
export default store;
