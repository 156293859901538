import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';
import FormFields from '../../components/form/fields';
import HelpText from '../../components/gui/helpText';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@observer
class LoanToFriend extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('partLoan'),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { partId } = props;
        const { partStore } = this.props.stores;
        partStore.updateKeyValue('part', {});
        await partStore.load(partId, false, { query: { loadAllEmptyInstallations: true }, addData: ['installations', 'loaners'] });
    }

    loanToAFriend = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { installationId, partId, callback = () => {}, drawerLevel = 1 } = this.props;
        const { partStore } = this.props.stores;
        const { newLoan } = partStore;
        // console.log('newLoan', JSON.stringify(newLoan, null, 4));
        // console.log({ installationId, partId, callback})
        await partStore.loanToFriend({
            id: partId,
            installationId,
            newLoan,
        });

        const { appState } = this.props.stores;
        appState.toggleDrawer(false, drawerLevel);
        callback();
    }

    chooseItem = (e) => {
        const { idx } = e.target.closest('.similar-item').dataset;
        const { partStore } = this.props.stores;
        const { foundList } = partStore;
        // const item = foundList.find((item) => item.id === parseInt(id, 10));
        const item = foundList[parseInt(idx, 10)];
        partStore.updateKeyValue('newLoan', {
            ...item,
        });
        partStore.resetSearch();
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.partId !== this.props.partId) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            drawerLevel = 1,
        } = this.props;
        const { sessionid, showMenu, sections, isNew = true } = this.state;
        const { appState, userStore, installationStore, inspectionStore, partStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { inspections } = inspectionStore;
        const { part, installations, foundList, foundListKeys } = partStore;

        const endDate = part.loaner?.endDate ? new Date(part.loaner?.endDate) : new Date();
        const {
            seconds,
            minutes,
            hours,
            days,
            weeks,
            months,
            years,
        } = util.dateDiff(part.loaner?.startDate, endDate);

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='part.loan-to-friend'>Loan to a friend</Text>
                    </div>

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.name'>Name</Text>
                                    </span>
                                    <span class='ml-2'>{part.name}</span>
                                </div>
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.part-number'>Part Number</Text>
                                    </span>
                                    <span class='ml-2'>{part.partNumber}</span>
                                </div>

                                {part.status === 'loanedOut' && <>
                                    <span class={`badge badge-pill badge-warning mr-1 mt-2`}>
                                        Loaned out to {part.loaner?.firstname} {part.loaner?.lastname} for <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                                    </span>
                                </>}
                            </div>
                            <HelpText>
                                <Text id='part.loan-to-friend-help'>The part you are going to loan out.</Text>
                            </HelpText>
                        </div>
                    </div>

                    <div class='font-weight-lighter px-3 box-header mt-5'>
                        <Text id='part.loan-friend'>Friend</Text>
                    </div>

                    {foundList?.length > 0 && <>
                        <div class='w-100 d-flex flex-column justify-content-start'>
                            <div class='bg-light d-flex flex-column justify-content-start h-100 pt-0 pb-2'>
                                <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                                    <small class='text-uppercase'>
                                        <Text id='part.loan-similar'>Similar loaners</Text>
                                    </small>
                                </div>
                                <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-3 d-flex flex-column px-0`} style='border-radius: 15px;'>
                                    {foundList.map((item, idx) => {
                                        return(<>
                                            <div class='d-flex flex-row justify-content-between align-items-center'>
                                                <div
                                                    class='flex-fill similar-item py-2 px-3'
                                                    data-id={item.id}
                                                    data-idx={idx}
                                                    onClick={this.chooseItem}
                                                >
                                                    <span class='font-weight-lighter text-muted mr-3'>{item.cellphone}</span>
                                                    {item.firstname} {item.lastname}
                                                </div>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </>}


                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-0'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={partStore}
                            objectName={'newLoan'}
                            isNew={isNew}
                            drawerLevel={drawerLevel}
                            isAdmin={false}
                            lookupArray={'loaners'}
                        />
                    </div>

                    <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.loanToAFriend}>
                            <Text id='part.loan-to-a-friend'>Loan to a friend</Text>
                        </button>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default LoanToFriend;
