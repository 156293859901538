import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import CustomerList from '../../components/customer/list';
import InstallationList from '../../components/installation/list';
import InspectionList from '../../components/inspection/list';
import NewsList from '../../components/news/list';
import PartList from '../../components/part/list';

import localUtil from '../../lib/util';

@observer
class Search extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
        this.searchTimer = null;
    }

    clearSearch = () => {
        this.setState({
            search: '',
        });
        const { userStore, newsStore, customerStore, installationStore, partStore, inspectionStore } = this.props.stores;
        newsStore.resetSearch();
        customerStore.resetSearch();
        installationStore.resetSearch();
        partStore.resetSearch();
        inspectionStore.resetSearch();
    }

    searchInput = (e) => {
        const search = e.target.value;
        const { holdingStore } = this.props;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.doSearch();
        }, 700);
    }

    doSearch = async () => {
        const { search } = this.state;
		const { newsStore, customerStore, installationStore, partStore, inspectionStore } = this.props.stores;

        await Promise.all([
            newsStore.search(search),
        ]);
    }

    render() {
        const { search } = this.state;
        const { drawerLevel, classNames = '' } = this.props;
        const { appState, userStore, newsStore, customerStore, installationStore, partStore, inspectionStore } = this.props.stores;
        const { isCordova } = appState;
        const { user, isAdmin, saved } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { searchResults: news } = newsStore;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class={`w-100 d-flex flex-column ${classNames}`}>
                        <div class='font-weight-lighter px-3 box-header'>
                            <Text id='search.title'>Search</Text>
                        </div>
                        <div class={`d-flex flex-column mx-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                            <div class='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text rounded-pill-left'>
                                        <i class='fa-solid fa-magnifying-glass'></i>
                                    </span>
                                </div>
                                <input
                                    class='form-control form-control-lg rounded-pill-right'
                                    type='text'
                                    value={search}
                                    placeholder={'search'}
                                    onInput={this.searchInput}
                                />
                                {search && <div class='input-group-append'>
                                    <button class='btn btn-outline-secondary' type='button' onClick={this.clearSearch}>
                                        <i class='fa-solid fa-circle-xmark'></i>
                                    </button>
                                </div>}
                            </div>

                        </div>
                    </div>

                    {news && news.length > 0 && <NewsList
                        stores={this.props.stores}
                        newsList={news}
                        classNames={'mt-5'}
                        drawerLevel={drawerLevel}
                    />}

                </div>
            </div>
        );
    }
}

export default Search;
