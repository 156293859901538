import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

@observer
class ViewImage extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            src,
            imageDetail,
            md5,
        } = this.props;
        const { userStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center position-relative'>
                <div
                    class='w-100 h-100 d-flex justify-content-center align-items-top overflow-auto'
                    style='padding-top: 45px;'
                >
                    <img src={src} class='img-fluid' />
                </div>
                {imageDetail && <>
                    <div
                        class='w-100 fixed-bottom text-white overflow-auto'
                        style='bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.6); max-height: 30vh;'
                    >
                        {imageDetail(md5)}
                    </div>
                </>}
            </div>
        </>);
    }
}

export default ViewImage;
