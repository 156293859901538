import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    competitionResultNumber: <Text id='input.competitionResultNumber-help'>competitionResultNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewCompetitionResult extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('competitionResult'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, isNew } = props;
        const { competitionResultStore } = this.props.stores;
        if (emptyFirst) {
            competitionResultStore.updateKeyValue('competitionResult', {});
        }
        await competitionResultStore.load(id, false, { addData: ['tags'], query: {} });

        if (isNew) {
            competitionResultStore.loadIntoNew({
                id: null,
                fields: ['title', 'date', 'competitionId', 'organizer', 'place', 'class', 'country', 'url', 'files'],
            });
        }
    }

    createCompetitionResult = async () => {
        const { callback = () => {} } = this.props;
        const { appState, competitionResultStore } = this.props.stores;
        const { newCompetitionResult } = competitionResultStore;

        const response = await competitionResultStore.insert(newCompetitionResult);

        competitionResultStore.updateKeyValue('newCompetitionResult', {});
        callback();
        appState.toggleDrawer();
    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { competitionResultStore } = this.props.stores;
        const { foundList } = competitionResultStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        competitionResultStore.updateKeyValue('newCompetitionResult', {
            ...item,
        });
        competitionResultStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, installation, lookupArray } = this.props;
        const { sections, showSimilar } = this.state;
        const { userStore, competitionResultStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newCompetitionResult, foundList, foundListKeys } = competitionResultStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(installation, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='competitionResult.new'>New competitionResult</Text> : <Text id='competitionResult.edit'>Edit competitionResult</Text>}
                    </div>

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={competitionResultStore}
                                objectName={isNew ? 'newCompetitionResult' : 'competitionResult'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                hideAdmin={true}
                                lookupArray={lookupArray}
                                rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar competitionResults ({foundList.length})</span> : null}
                                rightHeaderClick={this.toggleSimilar}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createCompetitionResult}>
                    {isNew ? <Text id='competitionResult.create'>Create competitionResult</Text> : <Text id='competitionResult.save'>Save competitionResult</Text>}
                </button>
            </div>}
        </>);
    }
}

export default NewCompetitionResult;
