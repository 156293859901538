import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';
import FormFields from '../../components/form/fields';

@observer
class EditUsage extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('usage'),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { usageId } = props;
        const { usageStore } = this.props.stores;
        await usageStore.load(usageId, false, { addData: ['parts', 'installations', 'usageers'] });
    }

    // chooseItem = (e) => {
    //     const { idx } = e.target.closest('.similar-item').dataset;
    //     const { partStore } = this.props.stores;
    //     const { foundList } = partStore;
    //     // const item = foundList.find((item) => item.id === parseInt(id, 10));
    //     const item = foundList[parseInt(idx, 10)];
    //     partStore.updateKeyValue('newUsage', {
    //         ...item,
    //     });
    //     partStore.resetSearch();
    // }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.partId !== this.props.partId) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            drawerLevel = 1,
        } = this.props;
        const { sessionid, showMenu, sections, isNew = false } = this.state;
        const { appState, userStore, installationStore, partStore, usageStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { part, installations, foundList, foundListKeys } = usageStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        <Text id='part.usage-edit'>Edit Usage</Text>
                    </div>

                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={usageStore}
                            objectName={'usage'}
                            isNew={isNew}
                            drawerLevel={drawerLevel}
                            isAdmin={false}
                        />
                    </div>

                </div>
            </div>
        </>);
    }
}

export default EditUsage;
