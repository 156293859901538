import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import util from 'preact-util';
import { Text, Localizer } from 'preact-i18n';
import linkstate from 'linkstate';

import Input from '../../components/form/input';

function findAllKeys(list) {
    if (!Array.isArray(list) || list.length === 0) {
        return [];
    }

    const allKeys = list.reduce((keySet, item) => {
        // Add each key from the item to the set
        Object.keys(item).forEach(key => keySet.add(key));
        return keySet;
    }, new Set());

    return Array.from(allKeys); // Convert the set of keys back into an array
}

@observer
class AddArrayObject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: '',
            foundList: [],
        };
    }

    onInput = e => {
        const { holdingStore } = this.props;
        const { newObject } = holdingStore;
        const { name, value } = e.target;
        holdingStore.updateObjectKeyValue('newObject', name, value);
    }

    addNew = () => {
        // const { newObject } = this.state;
        const { holdingStore } = this.props;
        const { newObject } = holdingStore;
        // const newObjectCombined = { ...newObject, ...newObjectStore };
        const {
            after = () => {},
            drawerLevel,
        } = this.props;
        const { appState } = this.props.stores;
        after(newObject);
        this.setState({ newObject: {} });
        holdingStore.updateKeyValue('newObject', {});
        appState.toggleDrawer(false, drawerLevel);
    };

    selectElement = (e) => {
        const { holdingStore } = this.props;
        const { newObject } = holdingStore;
        const resultObject = { ...newObject };
        const { foundList } = this.state;
        const { idx } = e.target.closest('.similar-item').dataset;
        const element = foundList[idx];
        if (element) {
            const keys = Object.keys(element);
            keys.forEach((key) => {
                resultObject[key] = element[key];
            });
            holdingStore.updateKeyValue('newObject', resultObject);
            this.setState({
                search: '',
                foundList: [],
            });
        }
    }

    searchInput = (e) => {
        const search = e.target.value;
        const { newObject } = this.state;
        const {
            fieldObject,
            holdingStore,
            lookupArray,
        } = this.props;

        const listName = lookupArray || fieldObject.lookupArray;
        let foundListKeys = [];
        let foundList = [];
        if (listName) {
            const list = holdingStore[listName];
            // console.log('searchInput', { search, listName, list })
            if (list) {
                foundListKeys = list[0] ? findAllKeys(list) : [];
            }
            const matchKeys = (item, regExp) => {
                return foundListKeys.some((key) => {
                    // console.log('key', key, item[key], regExp.test(item[key]));
                    if (regExp.test(item[key])) {
                        return true;
                    }
                });
            };
            // console.log({ foundListKeys });
            if (list && search) {
                const regExp = new RegExp(search, 'i');
                foundList = list.filter(item => matchKeys(item, regExp)).map(item => {
                    return item;
                });
            }
        }
        // console.log('foundList', foundList)
        this.setState({ search, foundList, foundListKeys });
    }

    render() {
        const { search, foundList, foundListKeys } = this.state;
        const {
            before = () => {},
            after = () => {},
            isNew,
            field,
            fieldObject,
            type,
            min,
            maxValue,
            step,
            elementtype,
            datalist,
            datalistHelp,
            datalistValues,
            object,
            holdingStore,
            lookupArray,
        } = this.props;

        const { newObject, adminMembers } = holdingStore;

        const newValue = parseInt(newObject[field] || newObject.id, 10);
        const listName = fieldObject.lookupArray;
        let found;
        let foundValue;
        if (listName) {
            const list = holdingStore[listName];
            // console.log('list', list);
            if (list) {
                found = list.find((item) => item.id === newValue);
                foundValue = fieldObject.displayValue && found ? fieldObject.displayValue(found) : JSON.stringify(found, null, 2);
            }
        }

        const { userStore } = this.props.stores;
        const { user } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 20px;'>

                    <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                        <Text id='form.add'>Add</Text> {field}
                    </div>
                    {/* lookupArray: {lookupArray} */}
                    {/* <xmp>{JSON.stringify(adminMembers, null, 4)}</xmp> */}

                    <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                        {listName && <div class='w-100 mb-3'>
                            <input
                                class='inputForm form-control form-control-lg py-0 w-100'
                                type='text'
                                placeholder={'search'}
                                onInput={this.searchInput}
                            />
                        </div>}
                    </div>

                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(datalist, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(holdingStore, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(search, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(foundList, null, 4)}</xmp> */}
                    {/* // <xmp>{JSON.stringify(newObject, null, 4)}</xmp> // */}
                    {/* fieldObject: <xmp>{JSON.stringify(fieldObject, null, 4)}</xmp> */}
                    {foundList && foundList.length > 0 && <>
                        <div class='font-weight-lighter px-3 py-1 text-muted' >
                            <small class='text-uppercase'>
                                <Text id='form.search-result'>Search results</Text>
                            </small>
                        </div>
                        <div class={`d-flex flex-column mx-3 mb-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            {foundList.map((el, idx) => (<>
                                <div
                                    class='d-flex flex-row justify-content-between align-items-center similar-item'
                                    data-idx={idx}
                                    onClick={this.selectElement}
                                >
                                    {foundListKeys && foundListKeys.length > 0 && foundListKeys.map(field => (<>
                                        <div class='flex-fill py-2 px-3'>
                                            <span class='font-weight-lighter text-muted'>{field}:</span> {el[field]}
                                        </div>
                                    </>))}
                                </div>
                            </>))}
                        </div>
                    </>}

                    <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                    {/* <div class={`bg-light form-group d-flex flex-column my-0 py-1 px-3`}> */}
                        <div
                            class='rounded rounded-lg position-relative px-2 py-1 flex-fill'
                            style='border-radius: 10px !important;'
                        >
                            {datalist && datalist.length > 0 ? datalist.map((el, idx) => (<>
                                <Input
                                    stores={this.props.stores}
                                    holdingStore={holdingStore}
                                    objectName={'newObject'}
                                    object={newObject}
                                    type={el === 'image' ? 'image' : (el === 'json' ? 'textarea' : 'text')}
                                    field={el}
                                    min={min}
                                    max={maxValue}
                                    step={step}
                                    name={field}
                                    value={newObject[el]}
                                    title={el}
                                    placeholder={el}
                                    onInput={this.onInput}
                                    help={datalistHelp && datalistHelp[idx] ? datalistHelp[idx] : ''}
                                    usefulValues={datalistValues && datalistValues[idx] ? datalistValues[idx] : []}
                                />
                            </>)) : <>
                                <Input
                                    stores={this.props.stores}
                                    holdingStore={holdingStore}
                                    objectName={'newObject'}
                                    object={newObject}
                                    type={field === 'image' ? 'image' : (field === 'json' ? 'textarea' : 'text')}
                                    field={field}
                                    min={min}
                                    max={maxValue}
                                    step={step}
                                    name={field}
                                    value={newObject[field]}
                                    title={field}
                                    placeholder={field}
                                    onInput={this.onInput}
                                />

                            </>}
                        </div>
                        {/* <div class='text-muted'>
                            <small>
                                {foundValue}
                            </small>
                        </div> */}
                    </div>

                    <div class='w-100 bg-light d-flex flex-column justify-content-start py-3 px-3'>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.addNew}><Text id='form.add'>Add</Text> {field}</button>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default AddArrayObject;
