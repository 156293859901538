import { h } from 'preact';

const colors = ['red', 'blue', 'green', 'yellow', 'orange', 'purple'];

function getColor(round) {
    const roundInt = parseInt(round, 10) - 1;
    return colors[roundInt % colors.length];
}

const ShootingTarget = ({ shots, size = 500, factor = 0.08 }) => {
    const targetSize = size;

    const centerX = targetSize / 2;
    const centerY = targetSize / 2;
    // Assuming the outermost ring represents the score of 5 and each ring step represents a score step

    // const baseRadius = 15 * 2.5; // Starting radius for the innermost circle (score 10)
    // const stepRadius = 15 * 2.5; // Radius step between each score
    const baseRadius = targetSize * factor; // Starting radius for the innermost circle (score 10)
    const stepRadius = targetSize * factor; // Radius step between each score

    const shotRadius = baseRadius / 2 < 8 ? baseRadius / 2 : 8;

    const scores = [10, 9, 8, 7, 6, 5]; // Define scores here

    // Convert score to radius
    const scoreToRadius = (score) => baseRadius + (10 - score) * stepRadius;

    // Convert polar to Cartesian coordinates, adjusting for 0/360 degrees up
    const polarToCartesian = (angle, score) => {
        const radius = scoreToRadius(score);
        const angleRad = ((angle - 90) * Math.PI) / 180;
        const x = centerX + radius * Math.cos(angleRad);
        const y = centerY + radius * Math.sin(angleRad);
        return { x, y };
    };

    return (
        <svg width={targetSize} height={targetSize} xmlns="http://www.w3.org/2000/svg" class='img-fluid rounded-lg'>
            {/* Draw scoring rings and labels */}
            <circle
                key={`ring-${5}`}
                cx={centerX}
                cy={centerY}
                r={scoreToRadius(5)}
                stroke="black"
                strokeWidth="1"
                fill="black"
            />

            {scores.map((score, index) => (
                <>
                    <circle
                        key={`ring-${score}`}
                        cx={centerX}
                        cy={centerY}
                        r={scoreToRadius(score)}
                        stroke="white"
                        strokeWidth="1"
                        fill="none"
                    />
                    {score < 10 && size > 200 && <>
                        <text
                            key={`label-${score}`}
                            x={centerX - scoreToRadius(score) + 20} // Positioning the label outside the ring
                            y={centerY + 5} // Slight adjustment to align text better
                            fill="white"
                            fontSize="12"
                            fontFamily="Arial"
                        >
                            {score}
                        </text>
                    </>}
                </>
            ))}

            {/* Draw shots */}
            {shots.map((shot, index) => {
                const { x, y } = polarToCartesian(shot.angle, shot.score);
                return (<>
                    <circle
                        key={index}
                        cx={x}
                        cy={y}
                        r={shotRadius}
                        fill={getColor(shot.round)}
                        stroke="white"
                        strokeWidth="1"
                    />
                    {size > 200 && <>
                        <text
                            key={`label-shot-${index}`}
                            x={x - (shotRadius / 2)} // Positioning the label outside the ring
                            y={y + 4} // Slight adjustment to align text better
                            fill="white"
                            fontSize="12"
                            fontFamily="Arial"
                        >
                            {shot.round}
                        </text>
                        <text
                            key={`label-shot-${index}`}
                            x={x + 10} // Positioning the label outside the ring
                            y={y + 4} // Slight adjustment to align text better
                            fill="white"
                            fontSize="12"
                            fontFamily="Arial"
                        >
                            {shot.type}
                        </text>
                    </>}
                 </>);
            })}
        </svg>
    );
};

export default ShootingTarget;