import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

// import isoCountries from 'iso-countries';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import FormFields from '../../components/form/fields';
import AddToCalendar from '../../components/actions/addToCalendar';

import localUtil from '../../lib/util';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

@observer
class RaceClassContestantViewProfile extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('raceClassSignup'),
            sessionid: new Date().getTime(),
            events: [],
        };
        this.mainContainer = null;
    }

    editSignup = e => {
        const { drawerLevel = 1, user = {} } = this.props;

		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawerRight('raceSignup', {
            raceClassId: user.raceClass,
            // raceClassContestantId: user.id,
            isNew: false,
            backButtonText: 'Signup',
            editSignup: true,
		}, drawerLevel + 2);
    }

    render() {
        const { sections } = this.state;
        const { user, raceClass = {}, markerInfo = {} } = this.props;
        const image = localUtil.getImage({ user, size: 's3MediumLink', lastImage: true });
        const name = localUtil.displayName(user, true);
        const { firstname, lastname, sportsClub, email, cellphone } = user;

        const { userStore, raceStore, raceClassStore } = this.props.stores;
        const { user: currentUser, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode = util.getNestedValue(currentUser, 'settings.darkmode');

        const { isRaceAdmin, isMediaWorker, isTimeTracker } = raceStore;
        const isInternal = isRaceAdmin || isMediaWorker || isTimeTracker;
        const { events } = raceClassStore;

        let lastUpdated;
        let lastUpdatedMinutes;
        if (markerInfo?.timestamp) {
            lastUpdated = util.dateDiff(markerInfo.timestamp, new Date());
            lastUpdatedMinutes = lastUpdated.minutes;
        }

        return (<>
            {markerInfo?.timestamp && <div class={`d-flex flex-column mt-0 mx-3 px-3 py-2 justify-content-end align-items-end text-overflow position-relative`}>
                <small class='text-muted d-flex justify-content-end'>
                    <Text id='input.updatedDate'>Last updated:</Text> {util.isoDateCompact(markerInfo.timestamp)}<br />
                </small>
                <small class='text-muted d-flex justify-content-end'>
                    {lastUpdatedMinutes} <Text id='input.minutes-ago'>minutes ago</Text>
                </small>
            </div>}

            <div class={`d-flex flex-column mt-0 mx-3 px-3 py-2 justify-content-center align-items-center text-overflow position-relative`}>
                <div
                    class={`text-center rounded-circle imageRounded imageRoundedLarge`}
                    style={`${image ? `background-image: url('${image}'); background-size: cover;` : ''} border: 2px solid #ffffff;`}
                >
                    {!image && <i class='fas fa-user text-muted mt-5' style='font-size: 50px;' />}
                </div>
                <div class='position-absolute' style='top: 110px; left: calc(50% - 110px);'>
                   {user.bib ? <span class='badge badge-dark rounded-pill mr-1 ml-1' style={`font-size: 2.0em; border: 2px solid #ffffff; ${raceClass.bibColor ? `background-color: ${raceClass.bibColor} !important;` : ''}`}>{user.bib}</span> : ''}
                </div>
                <div class='position-absolute' style='top: 110px; left: calc(50% + 40px);'>
                    {localUtil.getFlagSvg(user.country, 45, false, 'rounded-pill', 'border: 2px solid #ffffff;')}
                </div>
                <div class='d-flex flex-row' style='font-size: 2.0rem;'>
                    <span class='ml-2'>
                        {user.firstname} {user.lastname} {user.birth ? <span class='font-weight-lighter'>({util.age(user.birth)})</span> : ''}
                    </span>
                </div>
                <div class='d-flex flex-row' style='font-size: 0.9rem;'>
                    <span class='ml-2 text-muted'>
                        {user.veteranRookie === '1' ? 'Veteran' : 'Rookie'}
                    </span>
                </div>
            </div>
            <div class={`d-flex flex-column mt-2 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                {/* TODO: Fix layout of this */}
                {[
                    'email',
                    'birth',
                    'cellphone',
                    'sportsClub',
                    'licenseNumber',
                    'postalcode',
                    'postalplace',
                    'country',
                    'companionFirstname',
                    'companionLastname',
                    'companionCellphone',
                    'companionBirth',
                    'handler1Firstname',
                    'handler1Lastname',
                    'handler1Cellphone',
                    'handler2Firstname',
                    'handler2Lastname',
                    'handler2Cellphone',
                    'comment',
                ].map(key => {
                    const field = fields.getField('raceClassContestant', key);
                    if (field.isInternalField && !isInternal) {
                        return undefined;
                    }
                    const displayValue = field.displayValue || (value => value);
                    return (<>
                        {user[key] && <div class='d-flex flex-row'>
                            <span class='text-muted font-weight-lighter'>
                                {field.icon && <><i class={field.icon} /> {field.title}</>}
                            </span>
                            <span class='ml-2'>{displayValue(user[key])}</span>
                        </div>}
                    </>);
                })}
                {user.body && <>
                    <Markdown markdown={localUtil.replaceImages(user.body, user.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                </>}

                {user.user === currentUser.id && <button type='button' class='btn btn-link mt-3' onClick={this.editSignup}>
                    <i class='fa-duotone fa-pen' /> <Text id='race-class.signup-raceclass-edit'>Edit your signup</Text>
                </button>}
            </div>

        </>);
    }
}

export default RaceClassContestantViewProfile;
