import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

const replacer = (match, p1, offset, string) => {
    console.log('replacer', match, p1, offset, string)
    const stringWithoutHtml = string.replace(/<.+?>/g, '');
    const bgClass = string.findIndex(e => e.toLowerCase() === p1.toLowerCase());
    if (stringWithoutHtml.match(new RegExp(p1, 'i'))) {
        return `<span class='${bgClasses[bgClass] || 'bg-secondary'} text-white'>${p1}</span>`;
    }
    return `${p1}`;
}

const replaceAllStrings = (string) => {
    let result = string;
    for (let i = 0, l = regexpList.length; i < l; i += 1) {
        const regexp = regexpList[i];
        result = result.replace(regexp, replacer);
    }
    return result;
}

@observer
class GoogleSheet extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    onClickTab = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { idx } = e.target.closest('button').dataset;
        this.setState({ currentSheet: parseInt(idx, 10) });
    }

    onClickRow = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { idx } = e.target.closest('tr').dataset;
        this.setState({ row: parseInt(idx, 10) });
    }

    onClickPage = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { idx } = e.target.closest('button').dataset;
        this.setState({ page: parseInt(idx, 10) });
    }

    render() {
        const {
            doc,
            showDocTitle,
            showSearch,
            limit = 30,
            tableClasses = '',

        } = this.props;
        const {
            currentSheet = 0,
            searchText = '',
            page = 1,
        } = this.state;
        const { userStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const bgClasses = ['bg-success', 'bg-warning', 'bg-danger', 'bg-primary', 'bg-secondary'];
        const searchWords = searchText.trim().split(' ');
        const regexpList = searchWords.filter(w => w.length > 1).map(word => new RegExp(`(${word})`, 'i'));

        return (
            <div class='w-100 d-flex flex-column'>

                {showDocTitle && <h3>{doc.title}</h3>}

                <div class='mb-2'>
                    <ul class='nav nav-pills'>
                        {doc.sheets && doc.sheets.map((sheet, idx) => <>
                            <li class='nav-item mr-3'>
                                <button class={`btn nav-link ${currentSheet === idx ? 'active' : ''}`} data-idx={idx} onClick={this.onClickTab}>{sheet.title}</button>
                            </li>
                        </>)}
                    </ul>
                </div>
                {doc.sheets && doc.sheets.map((sheet, idx) => {
                    const sheetRows = sheet.rows;
                    let finalSheetRows = sheetRows;
                    const pageNumbers = [];
                    const totalPerPage = parseInt(limit, 10);
                    if (limit) {
                        for (let i = 1; i <= Math.ceil(sheetRows.length / totalPerPage); i++) {
                            pageNumbers.push(i);
                        }
                        // const pages = this.makePageArray(pageNumbers);
                        const startIdx = (page - 1) * totalPerPage;
                        const endIdx = page * totalPerPage;
                        finalSheetRows = sheetRows.slice(startIdx, endIdx);
                    }

                    if (currentSheet !== idx) {
                        return '';
                    }
                    return (<>
                        {showSearch && <div class='mb-2'>
                            <div class='form-group row'>
                                <div class='col-6 offset-3'>
                                    <input type='text' class='form-control form-control-lg' placeholder={`Search inside "${sheet.title}"`} onInput={onInputSearch} value={searchText} />
                                </div>
                            </div>
                        </div>}

                        <div class='table-responsive'>
                            {/* <xmp>{JSON.stringify(sheet.headers)}</xmp> */}
                            <table class={`table ${tableClasses}`} style={`table-layout: fixed;`}>
                                <thead>
                                    <tr>
                                        {sheet.headers && sheet.headers.map((col) => {
                                            const meta = sheet.headersMeta[col];
                                            const { textFormat = {}, horizontalAlignment, verticalAlignment, backgroundColor = {} } = meta.userEnteredFormat || {};
                                            const { fontSize, bold, underline, strikethrough, italic, foregroundColor = {} } = textFormat;
                                            const { red: bgRed, green: bgGreen, blue: bgBlue } = backgroundColor;
                                            const { red: fgRed, green: fgGreen, blue: fgBlue } = foregroundColor;
                                            const bgColor = (bgRed || bgGreen || bgBlue) ? `rgb(${util.normalizeBetween(bgRed || 0, 0, 1, 0, 255)}, ${util.normalizeBetween(bgGreen || 0, 0, 1, 0, 255)}, ${util.normalizeBetween(bgBlue || 0, 0, 1, 0, 255)})` : 'inherit';
                                            const fgColor = (fgRed || fgGreen || fgBlue) ? `rgb(${util.normalizeBetween(fgRed || 0, 0, 1, 0, 255)}, ${util.normalizeBetween(fgGreen || 0, 0, 1, 0, 255)}, ${util.normalizeBetween(fgBlue || 0, 0, 1, 0, 255)})` : 'inherit';
                                            const { pixelSize: columnWidth } = meta.columnMeta;

                                            return (<th style={{
                                                'color': fgColor,
                                                'background-color': bgColor,
                                                'font-size': `${fontSize ? `${fontSize}pt` : ''}`,
                                                'text-align': horizontalAlignment,
                                                'vertical-align': verticalAlignment,
                                                'font-weight': bold ? 'bold' : 'normal',
                                                'text-decoration-line': underline ? 'underline' : (strikethrough ? 'line-through' : 'none'),
                                                'font-style': italic ? 'italic' : 'none',
                                                'width': `${columnWidth}px`,
                                                // 'height': `${rowHeight}px`,
                                            }}>
                                                {col}
                                            </th>);
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {finalSheetRows && finalSheetRows.map((row, rowIdx) => {
                                        return (<>
                                            <tr onClick={this.onClickRow} data-id={row.idx}>
                                                {sheet.headers && sheet.headers.map((col) => {
                                                    const headerMeta = sheet.headersMeta[col];
                                                    const meta = sheet.meta[row.idx][col] || {};
                                                    // console.log({ meta })
                                                    // {
                                                    //     'value': 'id',
                                                    //     'valueType': 'stringValue',
                                                    //     'formattedValue': 'id',
                                                    //     'formula': null,
                                                    //     'formulaError': null,
                                                    //     'effectiveFormat': {
                                                    //         'backgroundColor': {
                                                    //             'red': 1,
                                                    //             'green': 1,
                                                    //             'blue': 1
                                                    //         },
                                                    //         'padding': {
                                                    //             'top': 2,
                                                    //             'right': 3,
                                                    //             'bottom': 2,
                                                    //             'left': 3
                                                    //         },
                                                    //         'horizontalAlignment': 'LEFT',
                                                    //         'verticalAlignment': 'BOTTOM',
                                                    //         'wrapStrategy': 'OVERFLOW_CELL',
                                                    //         'textFormat': {
                                                    //             'foregroundColor': {},
                                                    //             'fontFamily': 'Arial',
                                                    //             'fontSize': 10,
                                                    //             'bold': true,
                                                    //             'italic': false,
                                                    //             'strikethrough': false,
                                                    //             'underline': false,
                                                    //             'foregroundColorStyle': {
                                                    //                 'rgbColor': {}
                                                    //             }
                                                    //         },
                                                    //         'hyperlinkDisplayType': 'PLAIN_TEXT',
                                                    //         'backgroundColorStyle': {
                                                    //             'rgbColor': {
                                                    //                 'red': 1,
                                                    //                 'green': 1,
                                                    //                 'blue': 1
                                                    //             }
                                                    //         }
                                                    //     }
                                                    // }
                                                    const { userEnteredFormat = {}, value, valueType, formattedValue, hyperlink, props = {} } = meta;
                                                    const { textFormat = {}, horizontalAlignment, verticalAlignment, backgroundColor = {}, wrapStrategy } = userEnteredFormat;
                                                    const { fontSize, bold, underline, strikethrough, italic, foregroundColor = {} } = textFormat;
                                                    const { red: bgRed, green: bgGreen, blue: bgBlue } = backgroundColor;
                                                    const { red: fgRed, green: fgGreen, blue: fgBlue } = foregroundColor;
                                                    const bgColor = (bgRed || bgGreen || bgBlue) ? `rgb(${util.normalizeBetween(bgRed || 0, 0, 1, 0, 255)}, ${util.normalizeBetween(bgGreen || 0, 0, 1, 0, 255)}, ${util.normalizeBetween(bgBlue || 0, 0, 1, 0, 255)})` : 'inherit';
                                                    const fgColor = (fgRed || fgGreen || fgBlue) ? `rgb(${util.normalizeBetween(fgRed || 0, 0, 1, 0, 255)}, ${util.normalizeBetween(fgGreen || 0, 0, 1, 0, 255)}, ${util.normalizeBetween(fgBlue || 0, 0, 1, 0, 255)})` : 'inherit';
                                                    const cellContent = meta?.value || '';
                                                    const content = searchText !== '' ? replaceAllStrings(cellContent) : cellContent;
                                                    const { pixelSize: columnWidth } = headerMeta.columnMeta;
                                                    const { pixelSize: rowHeight } = props;

                                                    return (<td
                                                        class='py-0 px-1'
                                                        style={{
                                                            'color': fgColor,
                                                            'background-color': bgColor,
                                                            'font-size': `${fontSize ? `${fontSize}pt` : ''}`,
                                                            'text-align': horizontalAlignment,
                                                            'vertical-align': verticalAlignment,
                                                            'font-weight': bold ? 'bold' : 'normal',
                                                            'text-decoration-line': underline ? 'underline' : (strikethrough ? 'line-through' : 'none'),
                                                            'font-style': italic ? 'italic' : 'none',
                                                            'width': `${columnWidth}px`,
                                                            'height': `${rowHeight}px`,
                                                            // 'overflow': `${wrapStrategy === 'OVERFLOW_CELL' ? 'visible' : 'inherit'}`,
                                                        }}
                                                    >
                                                        {hyperlink ? <>
                                                            <Markdown markdown={`<a href='${hyperlink}' target='_blank'>${content}</a>`} markedOpts={localUtil.getMarkdownOptions()} />
                                                        </> : <>
                                                            <Markdown markdown={`${content}`} markedOpts={localUtil.getMarkdownOptions()} />
                                                        </>}
                                                    </td>);
                                                })}
                                            </tr>
                                        </>);
                                    })}
                                </tbody>
                            </table>
                        </div>

                        {limit && <div class='mt-2 d-flex justify-content-center'>
                            <nav class='text-center'>
                                <ul class='pagination pagination-lg mb-0'>
                                    {pageNumbers && pageNumbers.map(p => <>
                                        <li class={`page-item ${page === p ? 'active' : ''}`}>
                                            <button
                                                type='button'
                                                class={`btn btn-link page-link`}
                                                onClick={this.onClickPage}
                                                data-idx={p}
                                            >
                                                {p}
                                            </button>
                                        </li>
                                    </>)}
                                </ul>
                                <small class='text-muted'>
                                    Total: {sheetRows.length}
                                </small>
                            </nav>
                        </div>}

                    </>);
                })}



            </div>
        );
    }
}

export default GoogleSheet;
