import { h, Component } from 'preact';

class ClipboardCopy extends Component {
    state = {
        copied: false
    };

    copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                this.setState({ copied: true }, () => {
                    setTimeout(() => this.setState({ copied: false }), 1500); // Reset the state after 1.5 seconds
                });
                // console.log('Text copied to clipboard successfully!');
            }, (err) => {
                console.error('Failed to copy text to clipboard', err);
            });
        } else {
            // Fallback for older browsers
            const textArea = document.createElement('textarea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand('copy');
                this.setState({ copied: true }, () => {
                    setTimeout(() => this.setState({ copied: false }), 1500); // Reset the state after 1.5 seconds
                });
                // console.log('Text copied to clipboard successfully!');
            } catch (err) {
                console.error('Unable to copy', err);
            }
            document.body.removeChild(textArea);
        }
    };

    render() {
        const { text, label } = this.props;
        const { copied } = this.state;
        return (
            <span onClick={() => this.copyToClipboard(text)} style={copied ? {
                cursor: 'pointer',
                color: 'green',
                transition: 'color 0.3s',
            } : { cursor: 'pointer' }}>
                {copied ? 'Copied!' : (label || text)}
            </span>
        );
    }
}

export default ClipboardCopy;