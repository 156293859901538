import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
}))
@observer
class NewInstallation extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('installation'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { installationStore } = this.props.stores;
        if (!id) {
            return;
        }
        if (emptyFirst) {
            installationStore.updateKeyValue('installation', {});
        }
        await installationStore.load(id, false, { addData: [] });
    }

    createInstallation = async () => {
        const { callback = () => {} } = this.props;
        const { installationStore } = this.props.stores;
        const { newInstallation } = installationStore;
        // const response = await installationStore.adminCreate(newInstallation);
        const response = await installationStore.insert(newInstallation);
        if (response.status === 201) {
            callback();
        }
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, lookupArray } = this.props;
        const { sections, showSimilar } = this.state;
        const { userStore, installationStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newInstallation, foundList, foundListKeys } = installationStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='installation.new'>New installation</Text> : <Text id='installation.edit'>Edit installation</Text>}
                    </div>

                    {showSimilar && foundList?.length > 0 && <>
                        <div class='w-100 d-flex flex-column justify-content-start'>
                            <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                                <div class='mx-4 font-weight-lighter px-3 py-1 text-muted' >
                                    <small class='text-uppercase'>
                                        <Text id='installation.similar'>Similar installation</Text>
                                    </small>
                                </div>
                                <div class={`${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mx-4 d-flex flex-column px-0 overflow-auto`} style='font-size: 1.1em; border-radius: 15px; max-height: 35vh;'>
                                    {foundList.map((item, idx) => {
                                        return(<>
                                            <div class='d-flex flex-row justify-content-between align-items-center'>
                                                <div
                                                    class='flex-fill similar-item py-2 px-3'
                                                    data-id={item.id}
                                                    onClick={this.chooseItem}
                                                >
                                                    {item.name}
                                                    <span class='font-weight-lighter text-muted ml-3'>{item.partNumber}</span>
                                                </div>
                                                {/* {item.images[0] && <>
                                                    <div>
                                                        <div class=''>
                                                            <img src={`${item.images[0].s3MediumLink}`} class='rounded-lg img-fluid' style='max-height: 45px;' />
                                                        </div>
                                                    </div>
                                                </>} */}
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        </div>
                    </>}

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-4'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={installationStore}
                                objectName={isNew ? 'newInstallation' : 'installation'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                lookupArray={lookupArray}
                                rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar installation ({foundList.length})</span> : null}
                                rightHeaderClick={this.toggleSimilar}
                            />
                        </div>
                    </div>
                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createInstallation}>
                            {isNew ? <Text id='installation.create'>Create installation</Text> : <Text id='installation.save'>Save installation</Text>}
                        </button>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default NewInstallation;
