import { h } from 'preact';

const Placeholder = ({ count = 1, style = {}, height = '' }) => {
    const placeholders = [];

    for (let i = 0; i < count; i++) {
        placeholders.push(<div key={i} class='placeholder rounded-lg mr-2' style={style} />);
    }

    return (<>
        <div class={`d-flex flex-row w-100`} style={`height: ${height};`}>
            {placeholders.map(p => p)}
        </div>
    </>);
};

export default Placeholder;