import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class PaymentStore extends LocalModel {
    constructor() {
        super('payment', {
            namePlural: 'payments',
            namePluralReal: 'payments',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/payments/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/payments/',
                    params: {},
                },
                save: {
                    url: '/api/payments/',
                    params: {},
                },
                delete: {
                    url: '/api/payments/',
                    params: {},
                },
            },
        });
    }

    @observable newPayment = {};

    @observable payment = {};

    @observable payments = [];

    /*
        0 = not startet,
        1 = payment started,
        2 = payment authorized,
        3 = payment captured,
        90 = payment canceled,
        91 = payment aborted,
        99 = payment failed,
    */
    paymentStatuses = {
        0: 'Not started',
        1: 'Payment started',
        2: 'Payment authorized',
        3: 'Payment captured',
        90: 'Payment canceled',
        91: 'Payment aborted',
        99: 'Payment failed',
    };

    paymentStatus(status) {
        return this.paymentStatuses[status] || 'Unknown';
    }

}

const store = new PaymentStore();
export default store;
