import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';

import localUtil from '../../lib/util';

@observer
class MailSentView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { mailSentStore } = this.props.stores;
        mailSentStore.updateKeyValue('mailSent', {});
        await mailSentStore.load(id);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { userStore, mailSentStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { mailSent } = mailSentStore;

        if (!mailSent || !mailSent.id) {
            return null;
        }

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-column'>
                        <div class='d-flex font-weight-bold'>
                            <Text id='mailsent.from'>From</Text>: <span class='font-weight-normal ml-2'>{mailSent.from}</span>
                        </div>
                        <div class='d-flex font-weight-bold'>
                            <Text id='mailsent.subject'>Subject</Text>: <span class='font-weight-normal ml-2'>{mailSent.subject}</span>
                        </div>
                        <div class='d-flex font-weight-bold'>
                            <Text id='mailsent.date'>Date</Text>: <span class='font-weight-normal ml-2'>{util.formatDate(mailSent.createdDate)}</span>
                        </div>
                        <div class={`w-100 h-100 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg px-3 py-2`} style='padding-top: 0px; padding-bottom: 0px;'>
                            <Markdown markdown={mailSent.body} markedOpts={localUtil.getMarkdownOptions()} />
                        </div>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default MailSentView;
