import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

const initialState = {};

@observer
class HelpText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    render() {
        const {
            text,
            styles = '',
            children,
        } = this.props;

        return (<>
            <div class='w-100 d-flex justify-content-center px-3 text-center' style='font-size: 1.0rem; line-height: 1.0rem;'>
                <small class='text-muted'>
                    {text || children}
                </small>
            </div>
        </>);
    }
}

export default HelpText;
