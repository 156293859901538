import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';

import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';
import Input from '../../components/form/input';
import FileList from '../../components/fileViewer/list';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@observer
class AddToInstallation extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { partId } = props;
        const { partStore } = this.props.stores;
        partStore.updateKeyValue('part', {});
        await partStore.load(partId, false, { query: { loadAllEmptyInstallations: true }, addData: ['installationsEmpty'] });
    }

    addPartToInstallation = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const {
            callback = () => {},
        } = this.props;
        const { installationStore, partStore } = this.props.stores;
        const { part, newMovement } = partStore;

        const { id } = e.target.closest('.box-line').dataset;
        const installations = await installationStore.load(id, false, {
            skipUpdate: true,
        });
        const installation = installations[0];
        if (installation?.id) {
            const newObject = {
                qty: 1,
                id: part.id,
                name: part.name,
                partNumber: part.partNumber,
            };
            await installationStore.addArrayObject({
                newObject,
                object: installation,
                field: 'parts',
            });
            if (newMovement.movePart) {
                // Find the part in the current installation:
                const { installations: partInstallations } = partStore;
                if (partInstallations?.length === 1) {
                    const currentInstallation = partInstallations[0]
                    const currentInstallationParts = currentInstallation.parts;
                    const installationPart = currentInstallationParts.find(p => p.id === part.id);
                    await installationStore.removeArrayObject({
                        field: 'parts',
                        id: currentInstallation.id,
                        md5: installationPart.md5,
                    });
                } else {
                    console.log('Part is in multiple installations, not removing from current installation');
                }
            }
        }
        const { appState } = this.props.stores;
        appState.toggleDrawer();
        callback();
    }

    // onInput = (e) => {
    //     const { newMovement = {} } = this.state;
    //     const { id, field, type } = e.target.dataset;
    //     const { value } = e.target;
    //     newMovement[field] = parseInt(value, 10);
    //     console.log(field, value, newMovement);
    //     this.setState({ newMovement });
    // }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.partId !== this.props.partId) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            partId,
        } = this.props;
        const { sessionid, showMenu } = this.state;
        const { appState, userStore, installationStore, inspectionStore, partStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { inspections } = inspectionStore;
        const { part, installationsEmpty, newMovement } = partStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        const wrapperProps = {
            'data-part': partId,
        };

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='part.add-to-installation'>Add to installation</Text>
                    </div>
                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            {/* <div class='d-flex'>
                                <ImageScroller images={part.images} stores={this.props.stores} showImg={img => partImg(img, this.props, '1920x')} />
                            </div> */}
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='d-flex flex-row' style='font-size: 1.2em;'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.name'>Name</Text>
                                    </span>
                                    <span class='ml-2'>{part.name}</span>
                                </div>
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.part-number'>Part Number</Text>
                                    </span>
                                    <span class='ml-2'>{part.partNumber}</span>
                                </div>
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.description'>Description</Text>
                                    </span>
                                    <span class='ml-2'>{part.description}</span>
                                </div>
                                <div class='d-flex flex-column'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.documentation'>Documentation</Text>
                                    </span>
                                    <FileList files={part.files} stores={this.props.stores} />
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class='w-100 d-flex px-3 justify-content-center'>
                        <Localizer>
                            <Input
                                title={<Text id='action.move'>Move</Text>}
                                stores={this.props.stores}
                                holdingStore={partStore}
                                object={newMovement}
                                objectName={'newMovement'}
                                type={'toggle'}
                                field={'movePart'}
                                extraDataset={{
                                    'data-isnew': true,
                                }}
                            />
                        </Localizer>
                    </div>

                    <Localizer>
                        <InstallationList
                            stores={this.props.stores}
                            installations={installationsEmpty}
                            classNames='mt-5'
                            hideEmpty={true}
                            title={<Text id='part.choose-installation'>Choose Installation</Text>}
                            view={this.addPartToInstallation}
                            wrapperProps={wrapperProps}
                        />
                    </Localizer>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default AddToInstallation;
