import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

@withText(props => ({
    productTitle: <Text id='dyrejournal.product.search-title'>Search for product</Text>,
}))
@observer
class AddReferral extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { productStore } = this.props.stores;
        await productStore.load();
    }

    onClickChooseProduct = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore, productStore } = this.props.stores;
        const { newSale } = saleStore;
        const { products = [] } = newSale;
        const { products: allProducts } = productStore;
        const selectedItem = allProducts.find(p => p.id === parseInt(id, 10));
        if (selectedItem) {
            saleStore.updateObjectKeyValue('newSale', 'products', [...products, selectedItem]);
            appState.toggleDrawerRight(false, drawerLevel);
        }
    }

    onClickEditProduct = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editProduct', {
			height: drawerHeightMedium,
            hideAdmin: true,
            id,
            callback: product => {
                appState.toggleDrawer(false, drawerLevel + 1);
                if (product) {
                    const { newSale, productResults } = saleStore;
                    const { products } = newSale;
                    saleStore.updateObjectKeyValue('newSale', 'products', [...products, { ...product, qty: 1 }]);
                    setTimeout(() => {
                        this.loadAll(this.props, false);
                    }, 1000);
                }
            },
		}, drawerLevel + 1);
    }

    searchProductCallback = selectedItem => {
        const { appState, saleStore, productStore } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { newSale } = saleStore;
        const { products = [] } = newSale;
        saleStore.updateObjectKeyValue('newSale', 'products', [...products, selectedItem]);
        appState.toggleDrawerRight(false, drawerLevel);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            productTitle,
        } = this.props;
        const { userStore, productStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { products } = productStore;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.referral.add'>Add referral</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <DyrejournalSaleSearch
                                    stores={this.props.stores}
                                    key={'products'}
                                    title={productTitle}
                                    holdingStore={productStore}
                                    searchResultList={'searchResults'}
                                    searchResultsLine={(e, idx, selectedIndex) => {
                                        return(<>
                                            <div class={`d-flex flex-row justify-content-between px-3 py-1 ${idx > 0 ? 'border-top' : ''} ${idx === selectedIndex ? 'bg-secondary' : ''}`}>
                                                <div>{e.name}</div>
                                                <div>{e.vat}</div>
                                                <div>{e.price}</div>
                                            </div>
                                        </>);
                                    }}
                                    callback={this.searchProductCallback}
                                />
                                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <table class='table table-striped mb-0'>
                                        <thead>
                                            <tr>
                                                <th class='d-none d-sm-table-cell w-5'>&nbsp;</th>
                                                <th class='d-none d-sm-table-cell w-5'><Text id='dyrejournal.id'>Id</Text></th>
                                                <th><Text id='dyrejournal.product.name'>Name</Text></th>
                                                <th class='text-center w-5'><Text id='dyrejournal.product.vat'>Vat</Text></th>
                                                <th class='text-center w-10'><Text id='dyrejournal.product.price'>Price</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(products && products.length > 0) && products.map(e => {
                                                return (<>
                                                    <tr onClick={this.onClickChooseProduct} data-id={e.id}>
                                                        <td class='d-none d-sm-table-cell' onClick={this.onClickEditProduct} data-id={e.id}>
                                                            <i class='fa-solid fa-pen' />
                                                        </td>
                                                        <td class='d-none d-sm-table-cell'>{e.id}</td>
                                                        <td>{e.name}</td>
                                                        <td class='text-right'>
                                                            {util.format(e.vat, 0, ',', ' ')}%
                                                        </td>
                                                        <td class='text-right'>
                                                            {util.format(e.price, 2, ',', ' ')}
                                                        </td>
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        {/* <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.journals'>Journals</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                Report for today's journals
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        );
    }
}

export default AddReferral;
