import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../form/fields';
import Input from '../form/input';

import DyrejournalPrintForm from '../../components/dyrejournal/printForm';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class ViewForm extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('visitor'),
        };
    }

    componentDidMount() {
        const { id, isNew } = this.props;
        const { visitorStore } = this.props.stores;
        if (!isNew) {
            visitorStore.load(id);
        }
    }

    render() {
        const { id } = this.props;
        const { userStore, visitorStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class={`d-flex flex-column mt-1 mx-3 px-3 py-3 mb-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                        <DyrejournalPrintForm stores={this.props.stores} id={id} />
                    </div>

                </div>
            </div>
        </>);
    }
}

export default ViewForm;
