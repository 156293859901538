import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';

// import mu from '../../lib/musher-util';
const MARGIN_TOP = 0;
const MARGIN_BOTTOM = 0;

const initialState = {};

@observer
class Offline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    render() {
        const { goto, gotoTitle, routerName } = this.props;
        return (
			<div class='w-100 h-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style={`max-width: 960px; padding-top: 0px; padding-bottom: 150px;`}>

                    <div class='h-100 d-flex flex-column justify-content-center align-items-center'>
                        <div class='display-1 text-muted'>
                            <i class='fa-duotone  fa-user-robot-xmarks' />
                        </div>
                        <div class='font-weight-lighter mt-2' style='font-size: 2.0em;'>
                            <Text id='home.offline'>You are offline...</Text>
                        </div>

                        <div class='d-flex justify-content-center'>
                            {goto && <>
                                <a class='my-4 btn btn-primary btn-lg btn-block' native href={goto}>{gotoTitle} <i class='fas fa-angle-double-right ml-2' /></a>
                                <small class='text-muted'>{routerName}</small>
                            </>}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Offline;
