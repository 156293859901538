import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import GuiInfoBox from '../../components/gui/infoBox';

import localUtil from '../../lib/util';

@withText(props => ({
    amountText: <Text id='race-class.signup.amount-title'>Amount to pay</Text>,
}))
@observer
class PaymentVipps extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    doPayment = async () => {
        this.setState({ paymentInProgress: true });
        const { type, amount, raceId } = this.props;
        const { raceClassContestantStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { raceClassContestant } = raceClassContestantStore;

        switch (type) {
            case 'fee':
                switch (raceClassContestant.paymentMethod) {
                    case 'vipps':
                        await raceClassContestantStore.paySignupFeeByVipps(raceClassContestant.uuidv4);
                        this.checkSignupFeePayment();
                        break;
                    case 'paypal':
                        return this.paymentPaypal();
                        break;
                    default:
                        await raceClassContestantStore.paySignupFeeByVipps(raceClassContestant.uuidv4);
                        this.checkSignupFeePayment();
                        break;
                }
                break;
            case 'signup':
                switch (raceClassContestant.paymentMethod) {
                    case 'vipps':
                        await raceClassContestantStore.paySignupByVipps(raceClassContestant.uuidv4);
                        this.checkSignupPayment();
                        break;
                    case 'paypal':
                        return this.paymentPaypal();
                        break;
                    default:
                        await raceClassContestantStore.paySignupByVipps(raceClassContestant.uuidv4);
                        this.checkSignupPayment();
                        break;
                }
                break;
            case 'rest':
                switch (raceClassContestant.paymentMethod) {
                    case 'vipps':
                        await raceClassContestantStore.payRestByVipps(raceClassContestant.uuidv4);
                        this.checkRestPayment();
                        break;
                    case 'paypal':
                        return this.paymentPaypal();
                        break;
                    default:
                        await raceClassContestantStore.payRestByVipps(raceClassContestant.uuidv4);
                        this.checkRestPayment();
                        break;
                }
                break;
            case 'subscription':
                switch (user.paymentMethod) {
                    case 'vipps':
                        await userStore.paySubscriptionByVipps(user.uuidv4, raceId, amount);
                        this.checkSubscriptionPayment();
                        break;
                    case 'paypal':
                        return this.paymentPaypal();
                        break;
                    default:
                        await userStore.paySubscriptionByVipps(user.uuidv4, raceId, amount);
                        this.checkSubscriptionPayment();
                        break;
                }
                break;
            default:
                break;
        }
    }

    paySignup = async () => {
        this.setState({ paymentInProgress: true });
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.paySignupByVipps(raceClassContestant.uuidv4);
        this.checkSignup();
    }

    checkSignupFeePayment = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        if (raceClassContestant.paidSignupfee || !raceClassContestant.vippsSignupfeeReference) {
            return;
        }
        const hasPaid = await raceClassContestantStore.checkSignupFeeByVipps(raceClassContestant.uuidv4);
        setTimeout(() => {
            this.checkSignupFeePayment();
        }, 2000);
    }

    checkSignupPayment = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        if (raceClassContestant.paidSignup || !raceClassContestant.vippsSignupReference) {
            return;
        }
        const hasPaid = await raceClassContestantStore.checkSignupByVipps(raceClassContestant.uuidv4);
        setTimeout(() => {
            this.checkSignupPayment();
        }, 2000);
    }

    checkRestPayment = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        if (raceClassContestant.paidRest || !raceClassContestant.vippsRestReference) {
            return;
        }
        const hasPaid = await raceClassContestantStore.checkRestByVipps(raceClassContestant.uuidv4);
        setTimeout(() => {
            this.checkRestPayment();
        }, 2000);
    }

    checkSubscriptionPayment = async () => {
        const { userStore } = this.props.stores;
        const { user } = userStore;
        if (user.paidSubscription || !user.vippsSubscriptionReference) {
            userStore.load();
            return;
        }
        const hasPaid = await userStore.checkSubscriptionByVipps(user.uuidv4);
        setTimeout(() => {
            this.checkSubscriptionPayment();
        }, 2000);
    }

    payment = () => {
        const { type, amount } = this.props;
        const { paymentInProgress } = this.state;
        const { raceClassContestantStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { raceClassContestant } = raceClassContestantStore;

        // const vippsReference = type === 'fee' ? raceClassContestant.vippsSignupfeeReference : raceClassContestant.vippsSignupReference;
        // const vippsRedirectUrl = type === 'fee' ? raceClassContestant.vippsSignupfeeRedirectUrl : raceClassContestant.vippsSignupRedirectUrl;

        let vippsReference = null;
        let vippsRedirectUrl = null;
        switch (type) {
            case 'fee':
                vippsReference = raceClassContestant.vippsSignupfeeReference;
                vippsRedirectUrl = raceClassContestant.vippsSignupfeeRedirectUrl;
                break;
            case 'signup':
                vippsReference = raceClassContestant.vippsSignupReference;
                vippsRedirectUrl = raceClassContestant.vippsSignupRedirectUrl;
                break;
            case 'rest':
                vippsReference = raceClassContestant.vippsRestReference;
                vippsRedirectUrl = raceClassContestant.vippsRestRedirectUrl;
            case 'subscription':
                vippsReference = user.vippsSubscriptionReference;
                vippsRedirectUrl = user.vippsSubscriptionRedirectUrl;
            break;
        }

        return (<>
            {vippsReference ? <>
                <div class='d-flex flex-column'>
                    <div class='d-flex flex-row justify-content-center align-items-center mt-0'>
                        <img src={'/assets/external/Mark.png'} class='mr-2' />

                        <span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true' />
                        <Text id='race-class.signup.waiting-for-vipps'>Waiting for Vipps...</Text>
                    </div>
                    <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
                        {vippsRedirectUrl ? <>
                            <a href={vippsRedirectUrl} rel='noopener noreferrer' target='_blank' class='btn btn-primary rounded-pill mt-4'>
                                <i class='fa-duotone fa-right-from-bracket' /> <Text id='race-class.signup.goto-vipps-page'>Go to Vipps to handle the payment</Text>
                            </a>
                        </> : <>
                            {/* <small class='text-muted'>
                                <Text id='race-class.signup.waiting-for-vipps-help'>Please check your Vipps app.</Text>
                            </small> */}
                        </>}
                    </div>
                </div>
                {/* vippsSignupfeeStatus: {raceClassContestant.vippsSignupfeeStatus}<br /> */}
            </> : <>
                {paymentInProgress ? <>
                    <div class='spinner-grow spinner-grow-sm' role='status'>
                        <span class='sr-only'>Loading...</span>
                    </div>
                </> : <>
                    <img src={'/assets/external/Pay_pill - NO.png'} style='width: 180px;' onClick={this.doPayment} />
                </>}
                {/* <button type='button' class='btn btn-primary mt-4' onClick={this.paySignup}>Pay signup </button> */}
            </>}
        </>);
    }

    receipt = () => {
        const { type } = this.props;
        const { raceClassContestantStore, userStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        const { user } = userStore;

        // const amount = type === 'fee' ? raceClassContestant.vippsSignupfeeAmountValue : raceClassContestant.vippsSignupAmountValue;
        // const currency = type === 'fee' ? raceClassContestant.vippsSignupfeeAmountCurrency : raceClassContestant.vippsSignupAmountCurrency;
        // const paidDate = type === 'fee' ? raceClassContestant.paidSignupfee : raceClassContestant.paidSignup;

        let amount = 0;
        let currency = '';
        let paidDate = null;
        switch (type) {
            case 'fee':
                amount = raceClassContestant.vippsSignupfeeAmountValue;
                currency = raceClassContestant.vippsSignupfeeAmountCurrency;
                paidDate = raceClassContestant.paidSignupfee;
                break;
            case 'signup':
                amount = raceClassContestant.vippsSignupAmountValue;
                currency = raceClassContestant.vippsSignupAmountCurrency;
                paidDate = raceClassContestant.paidSignup;
                break;
            case 'rest':
                amount = raceClassContestant.vippsRestAmountValue;
                currency = raceClassContestant.vippsRestAmountCurrency;
                paidDate = raceClassContestant.paidRest;
                break;
            case 'subscription':
                amount = user.vippsSubscriptionAmountValue;
                currency = user.vippsSubscriptionAmountCurrency;
                paidDate = user.paidSubscription;
                break;
            default:
                break;
        }

        return (<>
             <div class='d-flex flex-row justify-content-center align-items-center mt-0'>
                <img src={'/assets/external/Vipps logo.png'} style='width: 64px;' />
            </div>
            <div class='d-flex flex-row justify-content-center align-items-center mt-0'>
                <div>
                    <i class='fa-regular fa-circle-check text-success' style='font-size: 4.0em;' />
                </div>
                <div class='d-flex flex-column ml-3'>
                    {/* paymentMethod: {raceClassContestant.paymentMethod}<br /> */}
                    {/* {util.formatDate(raceClassContestant.paidSignupfee, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}<br /> */}
                    {/* vippsSignupfeeReference: {raceClassContestant.vippsSignupfeeReference}<br /> */}
                    {/* vippsSignupfeeStatus: {raceClassContestant.vippsSignupfeeStatus}<br /> */}
                    {/* vippsSignupfeeAmountCurrency: {raceClassContestant.vippsSignupfeeAmountCurrency}<br /> */}
                    {/* vippsSignupfeeAmountValue: {util.format(raceClassContestant.vippsSignupfeeAmountValue / 100, 0)}<br /> */}

                    <GuiInfoBox
                        stores={this.props.stores}
                        nameMiddle={util.formatDate(paidDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                        value={util.format(amount / 100, 0)}
                        postfix={currency}
                        styles={`font-size: 2.5em; line-height: 1.0em;`}
                    />
                </div>
            </div>
        </>);
    }

    render() {
        const { paymentInProgress } = this.state;
        const {
            amountText,
        } = this.props;
        const { type } = this.props;
        const { appState, userStore, raceClassContestantStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { raceClassContestant } = raceClassContestantStore;
        // const amount = type === 'fee' ? raceClassContestant.signupfeeAmount : raceClassContestant.signuppaymentAmount;
        // const paidDate = type === 'fee' ? raceClassContestant.paidSignupfee : raceClassContestant.paidSignup;

        let amount = 0;
        let currency = '';
        let paidDate = null;
        switch (type) {
            case 'fee':
                amount = raceClassContestant.signupfeeAmount;
                paidDate = raceClassContestant.paidSignupfee;
                break;
            case 'signup':
                amount = raceClassContestant.signuppaymentAmount;
                paidDate = raceClassContestant.paidSignup;
                break;
            case 'rest':
                amount = raceClassContestant.restPaymentAmount;
                paidDate = raceClassContestant.paidRest;
                break;
            case 'subscription':
                amount = user.subscriptionPaymentAmount;
                paidDate = user.paidSubscription;
                break;
            default:
                break;
        }

        return (<>

            {paidDate ? <>
                {this.receipt()}
            </> : <>
                <div class='d-flex flex-row justify-content-center mt-0'>
                    <GuiInfoBox
                        stores={this.props.stores}
                        nameMiddle={amountText}
                        value={util.format(amount, 0)}
                        postfix={'NOK'}
                        styles={`font-size: 2.5em; line-height: 1.0em;`}
                    />
                </div>
                <div class='d-flex flex-row justify-content-center mt-0'>
                    {this.payment()}
                </div>
            </>}

        </>);
    }
}

export default PaymentVipps;
