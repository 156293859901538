import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';

function partImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@observer
class DeleteFromInstallation extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { partId } = props;
        const { partStore } = this.props.stores;
        partStore.updateKeyValue('part', {});
        await partStore.load(partId, false, { query: { loadAllEmptyInstallations: true }, addData: ['installations'] });
    }

    deletePartFromInstallation = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { installationId, partId, callback = () => {}, drawerLevel = 1 } = this.props;
        const { installationStore } = this.props.stores;
        const installations = await installationStore.load(installationId, false, {
            skipUpdate: true,
        });
        const installation = installations[0];
        if (installation?.id) {
            const part = installation.parts.find(part => part.id === parseInt(partId, 10));
            const { md5 } = part;
            await installationStore.removeArrayObject({
                field: 'parts',
                id: installationId,
                md5,
            });
        }
        const { appState } = this.props.stores;
        appState.toggleDrawer(false, drawerLevel);
        callback();
    }

    toggleDrawer = () => {
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        appState.toggleDrawer(false, drawerLevel);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.partId !== this.props.partId) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            showAnimations = true,
        } = this.props;
        const { sessionid, showMenu } = this.state;
        const { appState, userStore, installationStore, inspectionStore, partStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { inspections } = inspectionStore;
        const { part, installations } = partStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='part.delete-from-installation'>Delete from installation</Text>
                    </div>

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='d-flex flex-row' style='font-size: 1.2em;'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.name'>Name</Text>
                                    </span>
                                    <span class='ml-2'>{part.name}</span>
                                </div>
                                <div class='d-flex flex-row'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='part.part-number'>Part Number</Text>
                                    </span>
                                    <span class='ml-2'>{part.partNumber}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3'>
                        <div class='d-flex flex-row flex-wrap'>
                            <button class='btn btn-danger flex-fill rounded-pill' onClick={this.deletePartFromInstallation}><Text id='action.delete'>Delete</Text></button>
                            <button class='btn btn-secondary flex-fill rounded-pill ml-2' onClick={this.toggleDrawer}><Text id='action.cancel'>Cancel</Text></button>
                        </div>

                    </div>
                </div>
            </div>
        </>);
    }
}

export default DeleteFromInstallation;
