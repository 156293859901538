import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';

import localUtil from '../../lib/util';

@observer
class SmsView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { smsStore } = this.props.stores;
        smsStore.updateKeyValue('sms', {});
        await smsStore.load(id);
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { userStore, smsStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { sms } = smsStore;

        if (!sms || !sms.id) {
            return null;
        }

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-column'>
                        <div class='d-flex font-weight-bold'>
                            <Text id='sms.sender'>Sender</Text>: <span class='font-weight-normal ml-2'>{sms.sender}</span>
                        </div>
                        <div class='d-flex font-weight-bold'>
                            <Text id='sms.date'>Date</Text>: <span class='font-weight-normal ml-2'>{util.formatDate(sms.createdDate)}</span>
                        </div>
                        <div class={`w-100 h-100 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg px-3 py-2`} style='padding-top: 0px; padding-bottom: 0px;'>
                            <Markdown markdown={sms.message} markedOpts={localUtil.getMarkdownOptions()} />
                        </div>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default SmsView;
