import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class AnimalBreedStore extends LocalModel {
    constructor() {
        super('animalBreed', {
            namePlural: 'animalBreeds',
            sort: 'name',
            limit: 100,
            api: {
                search: {
                    url: '/api/animalbreeds/',
                    params: {
                        limit: 15,
                        sort: 'name',
                    },
                },
                load: {
                    url: '/api/animalbreeds/',
                    params: {},
                },
                save: {
                    url: '/api/animalbreeds/',
                    params: {},
                },
                delete: {
                    url: '/api/animalbreeds/',
                    params: {},
                },
            },
        });
    }

    @observable newAnimalBreed = {};

    @observable animalBreed = {};

    @observable animalBreeds = [];
}

const store = new AnimalBreedStore();
export default store;
