import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class SalePaymentStore extends LocalModel {
    constructor() {
        super('salePayment', {
            namePlural: 'salePayments',
            sort: '-paidDate -createdDate',
            limit: 300,
            api: {
                search: {
                    url: '/api/salepayments/',
                    params: {
                        limit: 300,
                        sort: '-paidDate -createdDate',
                    },
                },
                load: {
                    url: '/api/salepayments/',
                    params: {},
                },
                save: {
                    url: '/api/salepayments/',
                    params: {},
                },
                delete: {
                    url: '/api/salepayments/',
                    params: {},
                },
            },
        });
    }

    @observable newSalePayment = {};

    @observable salePayment = {};

    @observable salePayments = [];
}

const store = new SalePaymentStore();
export default store;
