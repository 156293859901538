import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class AnimalSpecieStore extends LocalModel {
    constructor() {
        super('animalSpecie', {
            namePlural: 'animalSpecies',
            sort: 'name',
            limit: 100,
            api: {
                search: {
                    url: '/api/animalspecies/',
                    params: {
                        limit: 15,
                        sort: 'name',
                    },
                },
                load: {
                    url: '/api/animalspecies/',
                    params: {},
                },
                save: {
                    url: '/api/animalspecies/',
                    params: {},
                },
                delete: {
                    url: '/api/animalspecies/',
                    params: {},
                },
            },
        });
    }

    @observable newAnimalSpecie = {};

    @observable animalSpecie = {};

    @observable animalSpecies = [];
}

const store = new AnimalSpecieStore();
export default store;
