import { h, Component } from 'preact';

import { Router, route } from 'preact-router';
import { createHashHistory } from 'history';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer } from 'preact-i18n';
import linkState from 'linkstate';
import AsyncRoute from 'preact-async-route';
import Match from 'preact-router/match';
import { Suspense, lazy } from 'preact/compat';

import util from 'preact-util';
import localUtil from './lib/util';
import PubSub, { topics } from './lib/pubsub';
import versionData from './version.json';

const SCROLLDOWN_LIMIT = 250;
const MENU_HIDE_SCROLLDOWN = 15;
const history = createHashHistory();

// import Start from './routes/start';

import RefreshSpinner from './components/refresh';
import ReadyToRefresh from './components/refresh/ready';
import Progress from './components/progress';

import appState from './stores/appState';
import tableStore from './stores/table';
import versionStore from './stores/version';
import articleStore from './stores/article';
import userStore from './stores/user';
import newsStore from './stores/news';
import mediaStore from './stores/media';
import customerStore from './stores/customer';
import installationStore from './stores/installation';
import partStore from './stores/part';
import inspectionStore from './stores/inspection';
import loanStore from './stores/loan';
import usageStore from './stores/usage';
import raceStore from './stores/race';
import raceClassStore from './stores/raceClass';
import raceClassContestantStore from './stores/raceClassContestant';
import raceResultStore from './stores/raceResult';

import journalStore from './stores/journal';
import prescriptionStore from './stores/prescription';
import drugStore from './stores/drug';
import animalStore from './stores/animal';
import animalSpecieStore from './stores/animalSpecie';
import animalBreedStore from './stores/animalBreed';
import visitorStore from './stores/visitor';
import saleStore from './stores/sale';
import salePaymentStore from './stores/salePayment';
import saleReportStore from './stores/saleReport';
import productStore from './stores/product';
import diagnoseStore from './stores/diagnose';
import fileStore from './stores/file';
import gpsPointStore from './stores/gpsPoint';
import gpsDeviceStore from './stores/gpsDevice';
import streamStore from './stores/stream';
import streamDeviceStore from './stores/streamDevice';

import conventionStore from './stores/convention';
import conventionResultStore from './stores/conventionResult';
import competitionResultStore from './stores/competitionResult';

import timeTrackerStore from './stores/timeTracker';

import contactStore from './stores/contact';

import calendarStore from './stores/calendar';
import calendarEventStore from './stores/calendarEvent';
import calendarAvailabilityStore from './stores/calendarAvailability';
import textTemplateStore from './stores/textTemplate';
import smsStore from './stores/sms';
import mailSentStore from './stores/mailSent';
import shotCalcStore from './stores/shotCalc';
import shotTrainerStore from './stores/shotTrainer';

import paymentStore from './stores/payment';
import vippsStore from './stores/vipps';
import paypalStore from './stores/paypal';

import fikenStore from './stores/fiken';
import tripletexStore from './stores/tripletex';

import definitionNo from './languages/no.json';
import definitionEn from './languages/en.json';

import LoadingSpinner from './components/loadingSpinner/';

import Header from './components/header';
import Footer from './components/footer';
import Offline from './components/offline';

function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    const language = navigator.language;

    // Basic parsing of userAgent to identify browser and OS - this can be expanded
    let browserName = userAgent.match(/firefox|fxios/i) ? 'Firefox' :
                      userAgent.match(/chrome|chromium|crios/i) ? 'Chrome' :
                      userAgent.match(/safari/i) ? 'Safari' :
                      userAgent.match(/opr\//i) ? 'Opera' :
                      userAgent.match(/edg/i) ? 'Edge' :
                      'Other';

    let osName = userAgent.match(/android/i) ? 'Android' :
                 userAgent.match(/iphone|ipad|ipod/i) ? 'iOS' :
                 userAgent.match(/win/i) ? 'Windows' :
                 userAgent.match(/mac/i) ? 'MacOS' :
                 userAgent.match(/linux/i) ? 'Linux' :
                 'Other';

    return {
        userAgent: userAgent,
        browser: browserName,
        os: osName,
        language: language
    };
}

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

const refreshRange = 150;
const readyToRefreshRange = 50;
const maxRange = 350;
const actionRange = 250;
const indicateActionRange = 50;

class Catcher extends Component {
    constructor() {
        super();
        this.state = {
            errored: false,
            errorsReported: 0,
        };
    }

    componentDidCatch(error) {
        const { errorsReported } = this.state;
        if (errorsReported < 10) {
            const data = {
                timestamp: Math.floor(new Date().getTime() / 1000),
                error: {
                    name: error.name,
                    message: error.message,
                    stack: error.stack,
                },
                location: window.location,
                browser: getBrowserInfo(),
            };
            this.setState({ errored: true, errorsReported: errorsReported + 1 });
            util.fetchApi('/api/errors/', { method: 'POST', publish: false }, data);
        }
    }

    reload = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.assign('/');
        // window.location.reload();
    }

    render(props, state) {
        if (state.errored) {
            return (
                <div class='container-fluid h-100'>
                    <div class='row h-100'>
                        <div class='col-12 text-center my-auto'>
                            <div class='display-1 text-muted'>
                                <i class='fa-duotone fa-bug' />
                            </div>
                            <h5>Uuuh, this was not supposed to happen...</h5>
                            <p>Error is automatically reported.</p>
                            <a class='mt-3 btn btn-lg btn-primary' href='#' onClick={this.reload}>Reload the app!</a>
                        </div>
                    </div>
                </div>
            );
        }
        return props.children;
    }
}

@observer
class App extends Component {
	constructor(props) {
        super(props);
		this.stores = {
            appState,
            tableStore,
            versionStore,
            articleStore,
            userStore,
            newsStore,
            mediaStore,
            customerStore,
            installationStore,
            partStore,
            inspectionStore,
            loanStore,
            usageStore,
            raceStore,
            raceClassStore,
            raceClassContestantStore,
            raceResultStore,

            journalStore,
            prescriptionStore,
            drugStore,
            animalStore,
            animalSpecieStore,
            animalBreedStore,
            visitorStore,
            saleStore,
            salePaymentStore,
            saleReportStore,
            productStore,
            diagnoseStore,
            fileStore,
            gpsPointStore,
            gpsDeviceStore,
            streamStore,
            streamDeviceStore,

            conventionStore,
            conventionResultStore,
            competitionResultStore,

            timeTrackerStore,

            contactStore,

            calendarStore,
            calendarEventStore,
            calendarAvailabilityStore,
            textTemplateStore,
            smsStore,
            mailSentStore,
            shotCalcStore,
            shotTrainerStore,

            paymentStore,
            vippsStore,
            paypalStore,

            fikenStore,
            tripletexStore,

            history,
        };
        const startUrl = window.location.hash.replace(/^#/, '');
        this.state = {
            jwtToken: util.getJwtToken(),
            refreshTime: new Date().getTime(),
            currentUrl: startUrl,
            checkCordovaCounter: 0,
            bug: {},
            inputProps: {},
            online: true,
            refreshing: false,
        };
        this.logMessages = [],
        this.subscriptions();
        const { user, isAdmin, saved } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tightmode = util.getNestedValue(user, 'settings.tightmode');
        util.toggleDarkModeClasses(darkmode);
        util.toggleBodyClasses('tight-mode', tightmode);
        this.appContainer = null;
    }

    overrideConsole() {
        const oldConsoleLog = console.log;
        const oldConsoleError = console.error;

        // console.log = (...args) => {
        //     this.logMessages.push({
        //         type: 'log',
        //         message: args,
        //         timestamp: Math.floor(new Date().getTime() / 1000),
        //         browser: getBrowserInfo(),
        //     });
        //     oldConsoleLog.apply(console, args);
        // };

        console.error = (...args) => {
            this.logMessages.push({
                type: 'error',
                message: args,
                timestamp: Math.floor(new Date().getTime() / 1000),
                browser: getBrowserInfo(),
            });
            oldConsoleError.apply(console, args);
        };
    }

    sendLogs = async () => {
        try {
            if (this.logMessages.length > 0) {
                await util.fetchApi('/api/errors/', { method: 'POST', publish: false }, { errors: this.logMessages });
                this.logMessages = []; // Clear after sending
            }
            if (appState.usageLog.length > 0) {
                await util.fetchApi('/api/usageLogs/', { method: 'POST', publish: false }, { usage: appState.usageLog });
                appState.emptyUsageLog();
            }
        } catch (err) {
            //
        }
    }

    startLogTimer = () => {
        clearInterval(this.sendLogsTimer);
        this.sendLogsTimer = setInterval(() => {
            this.sendLogs();
        }, 10000); // Every 10 seconds
    }

    postLog = async () => {
        const logs = [
            ...articleStore.getLogs(),
            ...userStore.getLogs(),
            ...newsStore.getLogs(),
            ...mediaStore.getLogs(),
            ...customerStore.getLogs(),
            ...installationStore.getLogs(),
            ...partStore.getLogs(),
            ...inspectionStore.getLogs(),
            ...loanStore.getLogs(),
            ...usageStore.getLogs(),
            ...raceStore.getLogs(),
            ...raceClassStore.getLogs(),
            ...raceClassContestantStore.getLogs(),
            ...raceResultStore.getLogs(),

            ...journalStore.getLogs(),
            ...prescriptionStore.getLogs(),
            ...drugStore.getLogs(),
            ...animalStore.getLogs(),
            ...animalSpecieStore.getLogs(),
            ...animalBreedStore.getLogs(),
            ...visitorStore.getLogs(),
            ...saleStore.getLogs(),
            ...salePaymentStore.getLogs(),
            ...saleReportStore.getLogs(),
            ...productStore.getLogs(),
            ...diagnoseStore.getLogs(),
            ...fileStore.getLogs(),
            ...gpsPointStore.getLogs(),
            ...gpsDeviceStore.getLogs(),
            ...streamStore.getLogs(),
            ...streamDeviceStore.getLogs(),

            ...conventionStore.getLogs(),
            ...conventionResultStore.getLogs(),
            ...competitionResultStore.getLogs(),

            ...timeTrackerStore.getLogs(),

            ...contactStore.getLogs(),

            ...calendarStore.getLogs(),
            ...calendarEventStore.getLogs(),
            ...calendarAvailabilityStore.getLogs(),
            ...textTemplateStore.getLogs(),
            ...smsStore.getLogs(),
            ...mailSentStore.getLogs(),
        ];
        if (logs.length > 0) {
            userStore.postLog({ data: logs });
        }
    }

    subscriptions() {
        PubSub.subscribe(topics.LOG_OUT, () => {
            // console.log('LOG_OUT');
            this.setState({
                jwtToken: undefined,
            }, () => {
                util.removeUserEmail();
                util.removeUserCellphone();
                util.removeJwtToken();
                appState.toggleDarkmode(false);
                setTimeout(() => {
                    route('/');
                    window.location.reload(true);
                }, 1000);
            });
        });

        PubSub.subscribe(topics.JWT_TOKEN_CHANGED, async (token) => {
            this.setState({
                jwtToken: token,
            }, () => {
                // window.location.reload(true);
            });
        });
    }

    redirectIfNotLoggedIn = (e) => {
        const { opts } = appState;
        if (opts.skipRedirect) {
            return false;
        }
        const { jwtToken } = this.state;
        // console.log('redirectIfNotLoggedIn', e.url, jwtToken, !/^\/(welcome|login|register|forgotten)/.test(e.url), (!jwtToken && !/^\/(welcome|login|register|forgotten)/.test(e.url)));
        if (!jwtToken && !/^\/(welcome|login|register|forgotten)/.test(e.url)) {
            // console.log('redirectIfNotLoggedIn', 'redirected');
            return route('/welcome');
        }
    }

    redirectIfNotApproved = (e) => {
        const { opts } = appState;
        if (opts.skipRedirect) {
            return false;
        }
        const { user, isAdmin } = userStore;
        if (isAdmin) {
            return;
        }
        const isUserApproved = user && user.id && user.isApproved;
        if (!isUserApproved) {
            return route('/waiting-approval');
        }
        const { currentUrl } = this.state;
        if (/^\/(waiting-approval)/.test(currentUrl)) {
            return route('/');
        }
    }

    /** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
        const { scrolledDown } = this.state;
        const { prevScroll, opts } = appState;
        const { user, isAdmin } = userStore;

        appState.addUsageLog({
            type: 'route',
            url: e.url,
            prevUrl: e.previous,
            timestamp: Math.floor(new Date().getTime() / 1000),
            browser: getBrowserInfo(),
            user: user ? user.id : null,
            isAdmin,
        });

        this.redirectIfNotLoggedIn(e);
        // console.log('handleRoute', e.url, jwtToken, !/^\/(welcome|login|register|forgotten)/.test(e.url), (!jwtToken && !/^\/(welcome|login|register|forgotten)/.test(e.url)))
        // if (!jwtToken && !/^\/(welcome|login|register|forgotten)/.test(e.url)) {
        //     route('/welcome');
        // }
        if (/^\/register/.test(e.previous)) {
            this.redirectIfNotApproved();
        }
		this.setState({ currentUrl: e.url });
        appState.setPrevPath(e.previous);
        appState.setPrevScroll(scrolledDown);
        appState.setPath(e.url);
        this.cleanupMemory(e.url, e.previous);
        const { router } = e;
        const { props = {} } = router;
        const { history = {} } = props;
        const { action = {} } = history;
        if (this.appContainer) {
            // if (prevScroll && action === 'POP') {
            //     console.log({ e, scrolledDown, prevScroll }, this.appContainer);
            //     this.scrollTimer = setTimeout(() => this.appContainer.scrollTo({ top: prevScroll }), 2000);
            // }
            if (!e.url.match(/skipScroll/)) {
                // this.appContainer.scrollTo({ top: 0 });
                localUtil.scrollTo(this.appContainer);
            }
        }
        // switch (e.url) {
        //     case '/profile':
        //         const isAuthed = await this.isAuthenticated();
        //         if (!isAuthed) route('/', true);
        //         break;
        // }
	}

    cleanupMemory = (currentUrl, prevUrl) => {
        try {
            const urlRegexpWorkout = new RegExp(`^/workouts`);
            if (urlRegexpWorkout.test(prevUrl) && !urlRegexpWorkout.test(currentUrl)) {
                // workoutStore.cleanupMemory();
            }
        } catch (err) {
            console.log(err);
        }
    }

    async loadAll() {
        const { language: appStateLanguage } = appState;

        // const { jwtToken } = this.state;
        // appState.updateField('jwtToken', jwtToken);
        // util.setJwtToken(jwtToken);
        // await appState.getInfo();
        const serverHostname = window.location.hostname;

        await appState.getFingerprint();
        await appState.getCustomerByHostname();
        const online = await userStore.getInfo({
            loadAllUsers: false,
            skipSummary: true,
            skipStaticDataLoad: false,
            language: appStateLanguage,
            hostname: serverHostname,
        });
        this.setState({ online });
        const { isAdmin, isRaceAdmin, isVeterinary, user, build, buildDate } = userStore;

        appState.setLatestVersion({
            build,
            date: buildDate,
        });
        appState.setUser(user);

        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        util.toggleDarkModeClasses(darkmode);

        const tightmode = util.getNestedValue(user, 'settings.tightmode');
        util.toggleBodyClasses('tight-mode', tightmode);

        const { opts, currentCustomer } = appState;
        this.redirectIfNotApproved();

        // If showCustomerLogo is enabled, we need to update the html title, icon and logo
        if (opts.showCustomerLogo && currentCustomer?.id) {
            const { icons, name } = currentCustomer;
            appState.storeDefaultMetaTags({ title: currentCustomer.name });
            document.title = name;
            if (currentCustomer.icons && currentCustomer.icons.length > 0) {
                const icon = localUtil.displayImg(currentCustomer.icons[0], this.props, '220x');
                const favicon = document.querySelector('link[rel="icon"]');
                favicon.href = icon;
                const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
                appleTouchIcon.href = icon;
            }
            if (currentCustomer.backgroundColor) {
                document.body.style.backgroundColor = currentCustomer.backgroundColor;
                const appDiv = document.querySelector('#app');
                appDiv.classList.remove('bg-light', 'bg-dark');
                appDiv.style.backgroundColor = currentCustomer.backgroundColor;
            }
            if (currentCustomer.logoHeight) {
                try {
                    const appDiv = document.querySelector('#app');
                    // appDiv.style.paddingTop = `calc(${currentCustomer.logoHeight} - 45px)`;
                    appDiv.style.paddingTop = `${currentCustomer.logoHeight}`;
                    // ${hasCustomerLogo ? `padding-top: calc(${currentCustomer?.logoHeight} - 45px);` : ''}
                } catch (err) {
                    console.log(err);
                }
            }
        }

        // await userStore.getInfo(false, true, false, 'en', darkmode ? 1 : 0);
        // const { isAdmin, isRaceAdmin, isVeterinary, user } = userStore;

        // Determin version of app
        const hostname = window.location.host;
        // const storedLocation = user.settings?.locationBeta ? 'beta' : 'prod';
        let appVersion;
        switch (hostname) {
            case 'keepspot.app':
                appVersion = 'prod';
                break;
            case 'dev.keepspot.app':
                appVersion = 'beta';
                break;
            case '127.0.0.1:8080':
            case 'localhost:8080':
                appVersion = 'dev';
                break;
            default:
                appVersion = 'prod';
        }

        if (/Android/i.test(navigator.userAgent)) {
            document.body.classList.add('android');
        }

        // this.websocket({
        //     id: 1000,
        //     firstname: 'anonymous',
        //     lastname: 'anonymous',
        // });

        // console.log({ appVersion });
        // if (appVersion === 'prod' && storedLocation === 'beta') {
        //     const location = 'https://dev.keepspot.app';
        //     window.location = location;
        // }
        this.performanceTimer = setInterval(() => this.postLog(), 10 * 1000);
    }

    touchStart = (e) => {
        this.setState({
            startX: e.touches[0].clientX,
            startY: e.touches[0].clientY,
        });
    }

    touchEnd = (e) => {
        const {
            refreshing,
        } = this.state;
        this.setState({
            isTouchDragging: false,
            startY: undefined,
            startX: undefined,
            readyToRefresh: false,
            // swipeDownPx: refreshing ? actionRange : 0,
            swipeDownPx: refreshing ? 0 : 0,
            swipeUpPx: 0,
        });

        const {
            swipeRight = () => {},
            swipeLeft = () => {},
            swipeUp = () => {},
            swipeDown = () => {},
            swipeRightIndicate = () => {},
            swipeLeftIndicate = () => {},
            swipeUpIndicate = () => {},
            swipeDownIndicate = () => {},
        } = appState;

        swipeRightIndicate(e, { startY: 0, startX: 0, xUp: 0, yUp: 0, xDiff: 0, yDiff: 0, absXDiff: 0, absYDiff: 0 });
        swipeLeftIndicate(e, { startY: 0, startX: 0, xUp: 0, yUp: 0, xDiff: 0, yDiff: 0, absXDiff: 0, absYDiff: 0 });
        swipeUpIndicate(e, { startY: 0, startX: 0, xUp: 0, yUp: 0, xDiff: 0, yDiff: 0, absXDiff: 0, absYDiff: 0 });
        swipeDownIndicate(e, { startY: 0, startX: 0, xUp: 0, yUp: 0, xDiff: 0, yDiff: 0, absXDiff: 0, absYDiff: 0 });
    }

    touchMove = (e) => {
        const { startY, startX, refreshing } = this.state;
        const {
            swipeRight = () => {},
            swipeLeft = () => {},
            swipeUp = () => {},
            swipeDown = () => {},
            swipeRightIndicate = () => {},
            swipeLeftIndicate = () => {},
            swipeUpIndicate = () => {},
            swipeDownIndicate = () => {},
        } = appState;
        this.setState({
            isTouchDragging: true,
        });

        const xUp = e.touches[0].clientX;
        const yUp = e.touches[0].clientY;

        const xDiff = startX - xUp;
        const yDiff = startY - yUp;
        const absXDiff = Math.abs(xDiff);
        const absYDiff = Math.abs(yDiff);
        if ( absXDiff > absYDiff ) { /*most significant*/
            if ( xDiff > 0 ) {
                /* left swipe */
                // console.log('left swipe', { startY, startX, xDiff, swipeLeft });
                // if (absXDiff > actionRange || absXDiff > indicateActionRange) {
                //     e.preventDefault();
                //     e.stopPropagation();
                // }
                if (absXDiff > actionRange) {
                    swipeLeft(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
                }
                if (absXDiff > indicateActionRange) {
                    swipeLeftIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
                }
            } else {
                /* right swipe */
                // console.log('right swipe', { e, startY, startX, xDiff, swipeRight });
                // eslint-disable-next-line no-lonely-if
                // if (absXDiff > actionRange || absXDiff > indicateActionRange) {
                //     e.preventDefault();
                //     e.stopPropagation();
                // }
                if (absXDiff > actionRange) {
                    swipeRight(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
                }
                if (absXDiff > indicateActionRange) {
                    swipeRightIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
                }
            }
        } else if ( yDiff > 0 ) {
            /* up swipe */
            const { swipeUpPx = 0 } = this.state;
            const { scrollHeight, scrollTop, clientHeight } = this.appContainer;
            const isBottom = Math.round(scrollTop + clientHeight) >= scrollHeight - swipeUpPx;
            // console.log({ isBottom, swipeUpPx, scrollHeight, scrollTop, clientHeight });
            // console.log('up swipe', { startY, startX, yDiff });
            // if (absYDiff > actionRange || absYDiff > indicateActionRange) {
            //     e.preventDefault();
            //     e.stopPropagation();
            // }
            if (isBottom && absYDiff > indicateActionRange) {
                this.setState({
                    swipeUpPx: absYDiff < maxRange ? absYDiff : maxRange,
                });
            }
            if (absYDiff > actionRange) {
                swipeUp(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
            }
            if (absYDiff > indicateActionRange) {
                swipeUpIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
            }
        } else {
            // console.log({ absXDiff, absYDiff });
            /* down swipe */
            // console.log('down swipe', { startY, startX, yDiff });
            // if (absYDiff > actionRange || absYDiff > indicateActionRange) {
            //     e.preventDefault();
            //     e.stopPropagation();
            // }

            if (absYDiff > actionRange) {
                swipeDown(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
            }
            if (absYDiff > indicateActionRange) {
                swipeDownIndicate(e, { startY, startX, xUp, yUp, xDiff, yDiff, absXDiff, absYDiff });
            }
            const skipRefresh = e.target.closest('.skipRefresh');
            if (!skipRefresh) {
                const { scrollTop } = this.appContainer;
                if (scrollTop <= 0 && absYDiff > refreshRange && !refreshing) {
                    this.setState({
                        refreshing: true,
                        readyToRefresh: false,
                        swipeDownPx: absYDiff < maxRange ? absYDiff : maxRange,
                    });
                    this.pullToRefreshPage();
                } else if (scrollTop <= 0 && absYDiff > readyToRefreshRange && !refreshing) {
                    this.setState({
                        readyToRefresh: true,
                        swipeDownPx: absYDiff < maxRange ? absYDiff : maxRange,
                    });
                } else if (scrollTop <= 0 && absYDiff > indicateActionRange) {
                    this.setState({
                        swipeDownPx: absYDiff < maxRange ? absYDiff : maxRange,
                    });
                }
            }
        }
    }

    scrollToTop = () => {
        if (this.appContainer) {
            localUtil.scrollTo(this.appContainer);
            this.refreshPage();
        }
    }

    doneRefreshing() {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = setTimeout(() => this.setState({
            startY: undefined,
            refreshing: false,
            readyToRefresh: false,
            swipeDownPx: 0,
        }), 1000);

    }

    setRefreshTime = () => {
        const refreshTime= new Date().getTime();
        this.setState({ refreshTime });
    }

    pullToRefreshPage = () => {
        const { currentUrl } = this.state;
        this.refreshPage();
        return this.doneRefreshing();
    }

    refreshPage = () => {
        const { currentUrl } = this.state;
        // console.log('refreshPage', currentUrl);
        const { user } = userStore;
        try {
            appState.addUsageLog({
                type: 'refresh',
                url: currentUrl,
                timestamp: Math.floor(new Date().getTime() / 1000),
                browser: getBrowserInfo(),
                user: user ? user.id : null,
            });
            const urlRegexpFrontpage = new RegExp(`^/$`);
            const urlRegexpStart = new RegExp(`^/start`);
            const urlRegexpInstallations = new RegExp(`^/installations`);
            const urlRegexpUsersProfile = new RegExp(`^/users/profile`);
            const urlRegexpDyrejournal = new RegExp(`^/dyrejournal`);
            const urlRegexpMedia = new RegExp(`^/media`);
            const urlRegexpArticle = new RegExp(`^/article`);
            const urlRegexpRace = new RegExp(`^/race`);
            const urlRegexpTimeTracker = new RegExp(`^/timetracker`);
            const urlRegexpContact = new RegExp(`^/contact`);
            const urlRegexpCalendar = new RegExp(`^/calendar`);
            const urlRegexpCalendarEvent = new RegExp(`^/calendarevent`);
            const urlRegexpCalendarAvailability = new RegExp(`^/calendaravailability`);
            const urlRegexpTextTemplate = new RegExp(`^/texttemplate`);
            const urlRegexpSms = new RegExp(`^/sms`);
            const urlRegexpMailSent = new RegExp(`^/mailsent`);
            const urlRegexpErrors = new RegExp(`^/errors`);
            const urlRegexpApiLogger = new RegExp(`^/apilogger`);
            const urlRegexpQueryLogger = new RegExp(`^/querylogger`);
            const urlRegexpConvention = new RegExp(`^/convention`);
            if (urlRegexpFrontpage.test(currentUrl)) {
                installationStore.refreshPage();
                newsStore.refreshPage();
                newsStore.runRefreshPageFunctions();
                raceStore.refreshPage();
                articleStore.refreshPage();
                articleStore.runRefreshPageFunctions();
                conventionStore.refreshPage();
            } else if (urlRegexpInstallations.test(currentUrl)) {
                installationStore.refreshPage();
            } else if (urlRegexpUsersProfile.test(currentUrl)) {
                userStore.refreshPage();
                articleStore.refreshPage();
                conventionStore.refreshPage();
                competitionResultStore.refreshPage();
            } else if (urlRegexpDyrejournal.test(currentUrl)) {
                saleStore.refreshPage();
                salePaymentStore.refreshPage();
                saleReportStore.refreshPage();
            } else if (urlRegexpMedia.test(currentUrl)) {
                mediaStore.refreshPage();
            } else if (urlRegexpArticle.test(currentUrl)) {
                articleStore.refreshPage();
            } else if (urlRegexpRace.test(currentUrl)) {
                raceStore.refreshPage();
            } else if (urlRegexpTimeTracker.test(currentUrl)) {
                timeTrackerStore.refreshPage();
            } else if (urlRegexpContact.test(currentUrl)) {
                contactStore.refreshPage();
            } else if (urlRegexpCalendar.test(currentUrl)) {
                calendarStore.refreshPage();
            } else if (urlRegexpCalendarEvent.test(currentUrl)) {
                calendarEventStore.refreshPage();
            } else if (urlRegexpCalendarAvailability.test(currentUrl)) {
                calendarAvailabilityStore.refreshPage();
            } else if (urlRegexpTextTemplate.test(currentUrl)) {
                textTemplateStore.refreshPage();
            } else if (urlRegexpSms.test(currentUrl)) {
                smsStore.refreshPage();
            } else if (urlRegexpMailSent.test(currentUrl)) {
                mailSentStore.refreshPage();
            } else if (urlRegexpConvention.test(currentUrl)) {
                conventionStore.refreshPage();
            } else if (urlRegexpErrors.test(currentUrl)) {
                appState.loadErrors({ limit: 100, offset: 0 });
            } else if (urlRegexpApiLogger.test(currentUrl)) {
                appState.loadApiLogs({ limit: 100, offset: 0 });
            } else if (urlRegexpQueryLogger.test(currentUrl)) {
                appState.loadQueryLogs({ limit: 100, offset: 0 });
            } else if (urlRegexpStart.test(currentUrl)) {
                this.setRefreshTime(currentUrl);
                return this.doneRefreshing();
            }
        } catch (err) {
            console.log(err);
        }
    }

    onScroll = (e) => {
        const { currentUrl } = this.state;
        const element = e.target;
        const { scrolledDown } = this.state;
        if (element) {
            if (element.scrollTop > SCROLLDOWN_LIMIT && !scrolledDown) {
            // if (element.scrollTop > SCROLLDOWN_LIMIT) {
                this.setState({
                    scrolledDown: element.scrollTop,
                });
            } else if (element.scrollTop === 0) {
                this.setState({
                    scrolledDown: 0,
                });
            }
        }

        // Check if we should hide the navbar
        const { isMobile } = appState;
        if (isMobile) {
            const currentScrollY = element.scrollTop;
            const scrollDelta = currentScrollY - this.lastScrollY;

            if (currentScrollY === 0) {
                appState.updateKeyValue('isVisibleNavbar', true);
            } else if (scrollDelta > MENU_HIDE_SCROLLDOWN) {
                appState.updateKeyValue('isVisibleNavbar', false);
            } else if (scrollDelta < -MENU_HIDE_SCROLLDOWN) {
                appState.updateKeyValue('isVisibleNavbar', true);
            }
            this.lastScrollY = currentScrollY;
        }

        // Load more content function
        const { isAtBottom } = this.state;
        const marginToBottom = element.clientHeight;
        if (element.scrollTop + element.clientHeight + marginToBottom >= element.scrollHeight) {
            if (!isAtBottom) {
                this.setState({ isAtBottom: true });
                // console.log({ currentUrl });
                const regex1 = /^\/admin\/customer$/;
                const regex2 = /^\/admin\/user$/;
                const regex3 = /^\/admin\/installation$/;
                const regex4 = /^\/admin\/part$/;
                const regex5 = /^\/admin\/inspection$/;
                const regex6 = /^\/admin\/loan$/;
                const regex7 = /^\/admin\/news$/;

                const regex8 = /^\/admin\/journal$/;
                const regex9 = /^\/admin\/prescripton$/;
                const regex10 = /^\/admin\/drug$/;
                const regex11 = /^\/admin\/animal$/;
                const regex12 = /^\/admin\/visitor$/;
                const regex13 = /^\/admin\/sale$/;
                const regex14 = /^\/admin\/product$/;
                const regex15 = /^\/admin\/diagnose$/;
                const regex16 = /^\/admin\/file$/;
                const regex17 = /^\/admin\/usage$/;

                const regex18 = /^\/admin\/media$/;
                const regex51 = /^\/admin\/article$/;
                const regex19 = /^\/admin\/race$/;
                const regex20 = /^\/admin\/raceclass$/i;
                const regex21 = /^\/admin\/raceclasscontestant$/i;
                const regex27 = /^\/admin\/raceresult$/i;

                const regex22 = /^\/admin\/timetracker$/i;

                const regex23 = /^\/admin\/calendar$/;
                const regex24 = /^\/admin\/calendarevent$/i;
                const regex28 = /^\/admin\/calendaravailability$/i;
                const regex25 = /^\/admin\/texttemplate$/i;
                const regex26 = /^\/admin\/sms$/;
                const regex29 = /^\/admin\/gpspoint$/i;
                const regex30 = /^\/admin\/salepayment$/i;
                const regex40 = /^\/admin\/animalspecie$/i;
                const regex31 = /^\/admin\/animalbreed$/i;
                const regex32 = /^\/admin\/salereport$/i;
                const regex33 = /^\/admin\/gpsdevice$/i;
                const regex34 = /^\/admin\/stream$/;
                const regex35 = /^\/admin\/streamdevice$/i;
                const regex36 = /^\/admin\/mailsent$/i;
                const regex39 = /^\/admin\/contact$/;

                const regex41 = /^\/admin\/convention$/;
                const regex42 = /^\/admin\/conventionresult$/;

                const regex43 = /^\/admin\/competitionresult$/;

                const regex50 = /^\/race.+?\/news$/;

                const regex90 = /^\/$/;

                switch (true) {
                    case regex50.test(currentUrl):
                        return newsStore.loadMore();

                    case regex1.test(currentUrl):
                        return customerStore.loadMore();
                    case regex2.test(currentUrl):
                        return userStore.loadMore();
                    case regex3.test(currentUrl):
                        return installationStore.loadMore();
                    case regex4.test(currentUrl):
                        return partStore.loadMore();
                    case regex5.test(currentUrl):
                        return inspectionStore.loadMore();
                    case regex6.test(currentUrl):
                        return loanStore.loadMore();
                    case regex7.test(currentUrl):
                        return newsStore.loadMore();

                    case regex8.test(currentUrl):
                        return journalStore.loadMore();
                    case regex9.test(currentUrl):
                        return prescriptionStore.loadMore();
                    case regex10.test(currentUrl):
                        return drugStore.loadMore();
                    case regex11.test(currentUrl):
                        return animalStore.loadMore();
                    case regex40.test(currentUrl):
                        return animalSpecieStore.loadMore();
                    case regex31.test(currentUrl):
                        return animalBreedStore.loadMore();
                    case regex12.test(currentUrl):
                        return visitorStore.loadMore();
                    case regex13.test(currentUrl):
                        return saleStore.loadMore();
                    case regex14.test(currentUrl):
                        return productStore.loadMore();
                    case regex15.test(currentUrl):
                        return diagnoseStore.loadMore();
                    case regex16.test(currentUrl):
                        return fileStore.loadMore();
                    case regex17.test(currentUrl):
                        return usageStore.loadMore();

                    case regex18.test(currentUrl):
                        return mediaStore.loadMore();
                    case regex51.test(currentUrl):
                        return articleStore.loadMore();
                    case regex19.test(currentUrl):
                        return raceStore.loadMore();
                    case regex20.test(currentUrl):
                        return raceClassStore.loadMore();
                    case regex21.test(currentUrl):
                        return raceClassContestantStore.loadMore();
                    case regex27.test(currentUrl):
                        return raceResultStore.loadMore();
                    case regex22.test(currentUrl):
                        return timeTrackerStore.loadMore();
                    case regex23.test(currentUrl):
                        return calendarStore.loadMore();
                    case regex24.test(currentUrl):
                        return calendarEventStore.loadMore();
                    case regex28.test(currentUrl):
                        return calendarAvailabilityStore.loadMore();
                    case regex25.test(currentUrl):
                        return textTemplateStore.loadMore();
                    case regex26.test(currentUrl):
                        return smsStore.loadMore();
                    case regex36.test(currentUrl):
                        return mailSentStore.loadMore();
                    case regex29.test(currentUrl):
                        return gpsPointStore.loadMore();
                    case regex30.test(currentUrl):
                        return salePaymentStore.loadMore();
                    case regex32.test(currentUrl):
                        return saleReportStore.loadMore();
                    case regex33.test(currentUrl):
                        return gpsDeviceStore.loadMore();
                    case regex34.test(currentUrl):
                        return streamStore.loadMore();
                    case regex35.test(currentUrl):
                        return streamDeviceStore.loadMore();
                    case regex39.test(currentUrl):
                        return contactStore.loadMore();
                    case regex41.test(currentUrl):
                        return conventionStore.loadMore();
                    case regex42.test(currentUrl):
                        return conventionResultStore.loadMore();
                    case regex43.test(currentUrl):
                        return competitionResultStore.loadMore();

                    case regex10.test(currentUrl):
                        return installationStore.loadMore();
                }
            }
        } else if (isAtBottom) {
            this.setState({ isAtBottom: false });
        }
        // console.log({ scrollTop: element.scrollTop, clientHeight: element.clientHeight, scrollHeight: element.scrollHeight });
    }

    checkShareApi = () => {
        appState.checkShareApi();
    }

    // componentWillMount() {

    // }

    componentWillUnmount() {
        clearInterval(this.sendLogsTimer);
        clearInterval(this.performanceTimer);
    }

    setInputProps = () => {
        const inputProps = util.collectPropsFromElement(window.document.body);
        this.setState({ inputProps });
    }

    componentDidMount() {
        // window.addEventListener("cordovacallbackerror", (event) => {
        //     // event.error contains the original error object
        //     console.log(event.error);
        // });

        const browserInfo = getBrowserInfo();
        const isMobile = browserInfo.os === 'Android' || browserInfo.os === 'iOS';
        appState.updateKeyValue('isMobile', isMobile);

        const inputProps = util.collectPropsFromElement(window.document.body);
        appState.setInputProps(inputProps);
        const apiServer = inputProps.apiServer || this.props.apiServer || `${window.location.protocol}//${window.location.host}`;

        // Parse the query string
        const queryParams = new URLSearchParams(window.location.search);
        const jwtToken = queryParams.get('jwtToken');
        // Check if apiToken exists in the query string
        if (jwtToken) {
            console.log("Found apiToken:", jwtToken);
            // You can now use this apiToken as needed
            util.setJwtToken(jwtToken);
            const { wantedPage } = appState;
            // console.log({ wantedPage });
            window.location.assign(`${window.location.protocol}//${window.location.host}/#${wantedPage}`);
        }

        util.setApiServer(apiServer);
        // this.ensureCordova();
        this.checkShareApi();
        this.loadAll();
        this.overrideConsole();
        this.startLogTimer();

        // document.addEventListener('pause', this.onPause, false);
        // document.addEventListener('resume', this.onResume, false);
        // document.addEventListener('menubutton', this.onMenubutton, false);
        // document.addEventListener('backbutton', this.onBackbutton, false);
        // document.addEventListener('searchbutton', this.onSearchbutton, false);
        appState.setAppContainer(this.appContainer);
        appState.storeDefaultMetaTags({});
        const { currentUrl } = this.state;
        appState.setPath(currentUrl);
        this.setInputProps();
        this.redirectIfNotLoggedIn({ url: currentUrl });
        const hostname = window.location.hostname;

        appState.setVersion(versionData, hostname);
        articleStore.setVersion(versionData, hostname);
        userStore.setVersion(versionData, hostname);
        newsStore.setVersion(versionData, hostname);
        mediaStore.setVersion(versionData, hostname);
        customerStore.setVersion(versionData, hostname);
        installationStore.setVersion(versionData, hostname);
        partStore.setVersion(versionData, hostname);
        inspectionStore.setVersion(versionData, hostname);
        loanStore.setVersion(versionData, hostname);
        usageStore.setVersion(versionData, hostname);
        raceStore.setVersion(versionData, hostname);
        raceClassStore.setVersion(versionData, hostname);
        raceClassContestantStore.setVersion(versionData, hostname);
        raceResultStore.setVersion(versionData, hostname);
        journalStore.setVersion(versionData, hostname);
        prescriptionStore.setVersion(versionData, hostname);
        drugStore.setVersion(versionData, hostname);
        animalStore.setVersion(versionData, hostname);
        animalSpecieStore.setVersion(versionData, hostname);
        animalBreedStore.setVersion(versionData, hostname);
        visitorStore.setVersion(versionData, hostname);
        saleStore.setVersion(versionData, hostname);
        salePaymentStore.setVersion(versionData, hostname);
        saleReportStore.setVersion(versionData, hostname);
        productStore.setVersion(versionData, hostname);
        diagnoseStore.setVersion(versionData, hostname);
        fileStore.setVersion(versionData, hostname);
        gpsPointStore.setVersion(versionData, hostname);
        gpsDeviceStore.setVersion(versionData, hostname);
        streamStore.setVersion(versionData, hostname);
        streamDeviceStore.setVersion(versionData, hostname);
        conventionStore.setVersion(versionData, hostname);
        conventionResultStore.setVersion(versionData, hostname);
        competitionResultStore.setVersion(versionData, hostname);
        timeTrackerStore.setVersion(versionData, hostname);
        contactStore.setVersion(versionData, hostname);
        calendarStore.setVersion(versionData, hostname);
        calendarEventStore.setVersion(versionData, hostname);
        calendarAvailabilityStore.setVersion(versionData, hostname);
        textTemplateStore.setVersion(versionData, hostname);
        smsStore.setVersion(versionData, hostname);
        mailSentStore.setVersion(versionData, hostname);
        // console.log('versionData', versionData);
        // window.addEventListener('scroll', ()=>{
        //     console.log(document.documentElement.scrollTop || document.body.scrollTop);
        // })
    }

    render() {
        const {
            refreshing,
            // jwtToken,
            readyToRefresh,
            swipeDownPx,
            swipeUpPx,
            currentUrl,
            // refreshTime,
            // scrolledDown,
            // bug = {},
            inputProps,
            isTouchDragging,
            online,
        } = this.state;
        const scrolledDownPlaceholder = false;
        const { path } = this.props;
        const {
            isCordova,
            showDrawer,
            language: appStateLanguage,
            currentCustomer = {},
            path: appStatePath,
        } = appState;
        const { isAdmin, isRaceAdmin, isVeterinary, user = {}, register = {} } = userStore;
        const { language = appStateLanguage } = user;

        const languageDef = countryMap[language || 'default'];
        // appDiv.style.paddingTop = `calc(${currentCustomer.logoHeight} - 45px)`;
        // const paddingTop = `padding-top: calc(${currentCustomer?.logoHeight || '0px'} - 45px);`;
        // console.log('currentCustomer?.logoHeight', currentCustomer?.logoHeight)
        let paddingTop = `padding-top: ${currentCustomer?.logoHeight || '45px'};`;
        // console.log('appStatePath', appStatePath);
        if (/^\/(welcome|login|register|forgotten|waiting-approval|window|calendarBooking|onBoarding)/.test(appStatePath)) {
            paddingTop = 'padding-top: 0px;';
        }
        // console.log('paddingTop', paddingTop)

        // ${readyToRefresh ? 'padding-top: 50px;' : ''}
        // ${refreshing ? 'padding-top: 70px;' : ''}
        // overscroll-behavior: none;
        // ${swipeDownPx ? `padding-top: ${swipeDownPx}px !important;` : paddingTop}

        return (
            <IntlProvider definition={languageDef}>
                <Catcher>
                    <div
                        id='app'
                        class='bg-light'
                        style={`
                            overflow-x: auto;
                            overflow-y: ${showDrawer ? 'hidden': 'auto'};
                            height: 100vh;
                            ${paddingTop}
                            ${swipeUpPx ? `padding-bottom: ${swipeUpPx}px !important;` : ''}
                            transition: ${isTouchDragging ? '' : 'padding 0.2s ease-in-out'};
                        `}
                        onTouchstart={this.touchStart}
                        onTouchend={this.touchEnd}
                        onTouchmove={this.touchMove}
                        onScroll={this.onScroll}
                        ref={c => this.appContainer = c}
                    >
                        <Progress stores={this.stores} scrolledDown={scrolledDownPlaceholder} />
                        {readyToRefresh ? <ReadyToRefresh stores={this.stores} height={swipeDownPx} scrolledDown={scrolledDownPlaceholder} /> : <></>}
                        {refreshing ? <RefreshSpinner stores={this.stores} height={swipeDownPx || refreshRange} scrolledDown={scrolledDownPlaceholder} /> : <></>}

                        {/* {!online ? <>
                            <Offline stores={this.stores} {...this.props} />
                        </> : <></>} */}

                        <Match>
                            {({ matches, path, url }) => <>
                                {/^\/(welcome|login|register|forgotten|waiting-approval|window|calendarBooking|onBoarding)/.test(url) ? <>
                                    {/* <pre><br /><br /><br />url: {url}<br />path: {path}</pre> */}
                                </> : <>
                                    <Header stores={this.stores} {...this.props} scrolledDown={scrolledDownPlaceholder} refreshPage={this.refreshPage} />
                                </>}
                            </>}
                        </Match>

                        <Router onChange={this.handleRoute} history={history}>
                            <AsyncRoute stores={this.stores} {...this.props} path="/waiting-approval" getComponent={() => import('./routes/waiting-approval').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} path="/welcome" getComponent={() => import('./routes/welcome').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} path="/login" getComponent={() => import('./routes/login/phone').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} path="/login/email" getComponent={() => import('./routes/login').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} path="/login/email/:email" getComponent={() => import('./routes/login').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} path="/link/login/:loginToken" getComponent={() => import('./routes/login').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} path="/register/" getComponent={() => import('./routes/register').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} path="/register/:email" getComponent={() => import('./routes/register').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} path="/forgotten/" getComponent={() => import('./routes/forgotten').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} path="/forgotten/:email" getComponent={() => import('./routes/forgotten').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} path="/logout/" getComponent={() => import('./routes/logout').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} path="/info/privacy" getComponent={() => import('./routes/info/privacy').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/onBoarding' getComponent={() => import('./routes/onBoarding').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/users/profile' getComponent={() => import('./routes/users/profile').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/help' getComponent={() => import('./routes/help').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/calendar' getComponent={() => import('./routes/calendar').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/calendar/notifications' calendar='notification' getComponent={() => import('./routes/calendar').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/calendarBooking' getComponent={() => import('./routes/calendarBooking').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/calendarBooking/:customerId' getComponent={() => import('./routes/calendarBooking').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-sale' getComponent={() => import('./routes/dyrejournal/sale').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-sale/:sale' getComponent={() => import('./routes/dyrejournal/sale').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-payments' getComponent={() => import('./routes/dyrejournal/paymentList').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-sms' getComponent={() => import('./routes/dyrejournal/smsList').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-mailsent' getComponent={() => import('./routes/dyrejournal/mailSentList').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-files' getComponent={() => import('./routes/dyrejournal/filesList').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-contacts' getComponent={() => import('./routes/dyrejournal/contactList').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-clinic' getComponent={() => import('./routes/dyrejournal/clinic').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/admin/development' getComponent={() => import('./routes/admin/development').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/admin/imports' getComponent={() => import('./routes/admin/imports').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/admin' getComponent={() => import('./routes/admin').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/admin/:store' getComponent={() => import('./routes/admin').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/errors' getComponent={() => import('./routes/admin/errors').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/statistics' getComponent={() => import('./routes/admin/statistics').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/apilogger' getComponent={() => import('./routes/admin/apilogger').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/querylogger' getComponent={() => import('./routes/admin/querylogger').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/dyrejournal-admin' getComponent={() => import('./routes/admin/dyrejournal-admin').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/installations/:id' getComponent={() => import('./routes/installations/detail').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/installations' getComponent={() => import('./routes/frontpage').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/texttemplates' getComponent={() => import('./routes/texttemplates').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/parts' getComponent={() => import('./routes/parts').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/inspections' getComponent={() => import('./routes/inspections').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/products' getComponent={() => import('./routes/products').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/news' getComponent={() => import('./routes/news').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/media' getComponent={() => import('./routes/media').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/article' getComponent={() => import('./routes/article').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/article/:uuidv4' getComponent={() => import('./routes/article').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/article/:title/:uuidv4' getComponent={() => import('./routes/article').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race' getComponent={() => import('./routes/race').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id' getComponent={() => import('./routes/race/detail').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/receipt' getComponent={() => import('./routes/race/receipt').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/results' getComponent={() => import('./routes/race/results').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/tracking' getComponent={() => import('./routes/race/tracking').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/news' getComponent={() => import('./routes/race/news').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/streaming' getComponent={() => import('./routes/race/streaming').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/streaming/:streamingId' getComponent={() => import('./routes/race/streaming').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/admin/signups' getComponent={() => import('./routes/race/admin/signups').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/admin/edit' getComponent={() => import('./routes/race/admin/edit').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/admin/raceresults' getComponent={() => import('./routes/race/admin/raceresults').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/admin/raceevent' getComponent={() => import('./routes/race/admin/raceevent').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/admin/checkinKiosk' getComponent={() => import('./routes/race/admin/checkinKiosk').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/admin/dashboard' getComponent={() => import('./routes/race/admin/dashboard').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/:raceClassId/results' getComponent={() => import('./routes/race/results').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/:raceClassId/tracking' getComponent={() => import('./routes/race/tracking').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/:raceClassId/news' getComponent={() => import('./routes/race/news').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/race/:id/:raceClassId/streaming' getComponent={() => import('./routes/race/streaming').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/raceclass/:id' getComponent={() => import('./routes/raceClass/detail').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/raceclass/:id/admin/signups' getComponent={() => import('./routes/raceClass/admin/signups').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/raceclass/:id/admin/drawStartOrder' getComponent={() => import('./routes/raceClass/admin/drawStartOrder').then(module => module.default)} loading={() => <div>loading...</div>} />
                            {/* <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/raceclass/:id/admin/raceresults' getComponent={() => import('./routes/raceClass/admin/raceresults').then(module => module.default)} /> */}
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/raceclass/:id/admin/edit' getComponent={() => import('./routes/raceClass/admin/edit').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/sms' getComponent={() => import('./routes/sms').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/mailsent' getComponent={() => import('./routes/mailsent').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/timetracker' getComponent={() => import('./routes/timetracker').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/videostreaming' getComponent={() => import('./routes/videostreaming').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/allgpsdevices' getComponent={() => import('./routes/allgpsdevices').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/shot-calc' getComponent={() => import('./routes/shotcalc').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/shot-trainer' getComponent={() => import('./routes/shottrainer').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/convention' getComponent={() => import('./routes/convention').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/convention/signup' getComponent={() => import('./routes/convention/signup').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/convention/:jwt/signup' getComponent={() => import('./routes/convention/signup').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/convention/:id' getComponent={() => import('./routes/convention/detail').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/window/close' getComponent={() => import('./routes/window/close').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/window/404' getComponent={() => import('./routes/window/404').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/' getComponent={() => import('./routes/frontpage').then(module => module.default)} loading={() => <div>loading...</div>} />
                            <AsyncRoute stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} path='/home-wall' getComponent={() => import('./routes/frontpage').then(module => module.default)} loading={() => <div>loading...</div>} />

                            <LoadingSpinner stores={this.stores} {...this.props} {...inputProps} scrolledDown={scrolledDownPlaceholder} default />

                            {/* <LoadingSpinner default goto='/' gotoTitle='Home' routerName='livecenter' /> */}
                        </Router>

                        <Match>
                            {({ matches, path, url }) => <>
                                {/^\/(welcome|login|register|forgotten|waiting-approval|window|calendarBooking|onBoarding)/.test(url) ? <>
                                </> : <>
                                    <Footer stores={this.stores} {...this.props} scrolledDown={scrolledDownPlaceholder} key={currentUrl} currentUrl={currentUrl} isCordova={isCordova} />
                                </>}
                            </>}
                        </Match>
                    </div>
                </Catcher>
            </IntlProvider>
        );
    }
}

export default App;

