import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';

import ImageScroller from '../../components/imagescroller';
import ClipboardCopy from '../../components/gui/clipboardCopy';
import GoogleSheet from '../../components/google/sheet';
import Placeholder from '../../components/gui/placeholder';
import PlaceholderTable from '../../components/gui/placeholderTable';
import VideoStreamingPlayer from '../../routes/videostreaming/player';

import localUtil from '../../lib/util';

const API_KEY = 'AIzaSyAaeWqA1TAT5sWG52KcrEPJtuA9CavtSac';

function smartReplaceLinks(text) {
    const urlPattern = /https?:\/\/[^\s]+/g;
    const coordinatePattern = /@-?\d+\.\d+,-?\d+\.\d+/g;

    return text.replace(urlPattern, url => {
        if (url.match(/\.(jpeg|jpg|gif|png)$/) !== null) {
            return `<img src='${url}' alt='Image' />`;
        }
        if (url.match(/\.(mp4|webm)$/) !== null) {
            return `<video controls><source src='${url}' type='video/mp4'></video>`;
        }
        if (url.match(/\.(mp3|ogg|wav)$/) !== null) {
            return `<audio controls><source src='${url}' type='audio/mpeg'></audio>`;
        }
        if (url.match(/\.(pdf)$/) !== null) {
            return `<object data='${url}' type='application/pdf' width='100%' height='100%'></object>`;
        }
        if (url.match(/yr\.no\/.*forecast/) !== null) {
            const urlParts = url.split('/');
            const location = urlParts[6];
            return `<iframe src='https://www.yr.no/nb/innhold/${location}/meteogram.svg' width='100%' height='400'></iframe>`;
        }
        return url;
    }).replace(coordinatePattern, coordinates => {
        const [lat, lng] = coordinates.slice(1).split(',');
        const googleMapsUrl = `https://www.google.com/maps/embed/v1/view?key=${API_KEY}&center=${lat},${lng}&zoom=14`;
        return `<iframe width='100%' height='450' style='border:0' loading='lazy' allowfullscreen src='${googleMapsUrl}'></iframe>`;
    });
}

@observer
class MarkdownDisplay extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            markdown = '',
        } = this.props;

        const finalContent = smartReplaceLinks(markdown);

        return (<>
            <Markdown markdown={finalContent} markedOpts={localUtil.getMarkdownOptions()} />
        </>);
    }
}

export default MarkdownDisplay;
