import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class PrescriptionStore extends LocalModel {
    constructor() {
        super('prescription', {
            namePlural: 'prescriptions',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/prescriptions/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/prescriptions/',
                    params: {},
                },
                save: {
                    url: '/api/prescriptions/',
                    params: {},
                },
                delete: {
                    url: '/api/prescriptions/',
                    params: {},
                },
            },
        });
    }

    @observable newPrescription = {};

    @observable prescription = {};

    @observable prescriptions = [];

    @observable veterinaries = [];

    async loadVeterinaries() {
        const response = await util.fetchApi(`/api/users/veterinaries/`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('veterinaries', response.data);
                return response;

        }
    }

    async emailPrescription(id, email) {
        const response = await util.fetchApi(`/api/prescriptions/${id}/email`, { method: 'GET' }, { email});
        switch (response.status) {
            case 200:
                return response;

        }
    }
}

const store = new PrescriptionStore();
export default store;
