import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';
import GuiListTicker from '../../components/gui/listTicker';
import GuiListLarge from '../../components/gui/listLarge';
import GuiListFrontpage from '../../components/gui/listFrontpage';

const MARKDOWN_OPTIONS = {
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: true,
highlight: function(code, lang) {
    const hljs = require('highlight.js');
    const language = hljs.getLanguage(lang) ? lang : 'plaintext';
    return hljs.highlight(code, { language }).value;
  },
langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

function mergeArrays(array1, array2) {
    const resultArray = array1.slice(); // Create a copy of array1 to avoid mutating the original

    for (let i = 0; i < array2.length; i++) {
        if (i < array1.length) {
            resultArray[i] = array2[i];
        } else {
            resultArray.push(array2[i]); // Add any additional items from array2 not present in array1
        }
    }

    return resultArray;
}

@withText(props => ({
    titleText: <Text id='news.title'>News</Text>,
}))
@observer
class NewsList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            limit: 50,
            offset: 0,
            localList: [],
        };
    }

    viewNews = e => {
        const { view, showHeader } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMediumLarge } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewNews', {
            id,
            all: 1,
            title : showHeader ? title : undefined,
            height: drawerHeightMediumLarge,
            // callback: () => {
            //     appState.toggleDrawer();
            //     // this.loadAll();
            // },
        }, drawerLevel + 1);
    }

    onClickNewNews = e => {
        e.stopPropagation();
        e.preventDefault();
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { tag } = e.target.closest('button').dataset;

		appState.openDrawer('newsNew', {
			height: drawerHeightMediumLarge,
            isNew: true,
            tag,
            callback: () => {
                this.loadAll(this.props, false, false);
            },
		}, drawerLevel + 2);
	}

    editNews = e => {
		e.stopPropagation();
        e.preventDefault();
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { id, title } = e.target.closest('.box-line').dataset;

		appState.openDrawer('newsEdit', {
			height: drawerHeightMediumLarge,
			id,
		}, drawerLevel + 1);
	}

    onClickViewAllNewss = e => {
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { category } = e.target.closest('button').dataset;

		appState.openDrawer('adminListNews', {
			height: drawerHeightMediumLarge,
            isNew: true,
            category,
            callback: () => {
                this.loadAll(this.props, false, false);
            },
		}, drawerLevel + 2);
	}

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-auto'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.ownedByOther && <>
                        <span class={`badge badge-pill badge-danger mr-1 mt-1`}>
                            <span class='font-weight-lighter'>Owner:</span> {obj.ownerFirstname} {obj.ownerLastname}
                        </span>
                    </>}
                    {obj.parents?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-code-branch' />
                        </span>
                    </>}
                    {obj.url && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-link' />
                        </span>
                    </>}
                    {/* {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>} */}
                    {obj.tags?.length > 0 && obj.tags.slice(0,3).map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                    {obj.loans?.length > 0 && obj.loans.map(loan => {
                        if (loan.endDate) {
                            return '';
                        }
                        const endDate = loan.endDate ? new Date(loan.endDate) : new Date();
                        const {
                            seconds,
                            minutes,
                            hours,
                            days,
                            weeks,
                            months,
                            years,
                        } = util.dateDiff(loan.startDate, endDate);
                        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })

                        return (<>
                            <span class={`badge badge-pill badge-${loan.endDate ? 'success' : 'warning'} mr-1 mt-1`}>
                                Loaned out <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                            </span>

                            {/* <div class='tag'>{JSON.stringify(loan)}</div> */}
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    onClickMenu = e => {
        const { id } = e.target.closest('.list-menu').dataset;
        const idInt = parseInt(id, 10);
        const { newsStore } = this.props.stores;
        const { newss } = newsStore;
        const news = newss.find(n => n.id === idInt);

        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightSmall } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('newsMenu', {
            id,
            height: drawerHeightSmall,
            news,
            // callback: () => {
            //     appState.toggleDrawer();
            //     // this.loadAll();
            // },
        }, drawerLevel + 1);
    }

    loadAll = async (props = this.props, append, emptyFirst = false) => {
        const { limit, offset } = this.state;
        const { raceId, skipUpdate, limit: propsLimit, tag } = props;
        const { newsStore, raceStore, userStore } = this.props.stores;
        const { isAdmin } = userStore;
        const { isRaceAdmin, isMediaWorker } = raceStore;
        const isWorkingWithMedia = isAdmin || isRaceAdmin || isMediaWorker;

        if (emptyFirst) {
            newsStore.updateKeyValue('newss', {});
        }

        const query = {
            all: 1,
        };
        if (tag) {
            query.tag = tag;
        }
        const localList = await newsStore.load({
            query,
            limit: propsLimit || limit,
            offset,
            skipUpdate: skipUpdate,
        }, append);
// console.log('loadAll', { raceId, tag, skipUpdate, limit, propsLimit, offset, localList })
        if (localList && localList.length > 0) {
            this.setState({ localList });
            // newsStore.updateKeyValue('newssAlt', localList);
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.category !== this.props.category || nextProps.offset !== this.props.offset) {
            if (nextProps.offset) {
                this.setState({ offset: nextProps.offset });
            }
            this.loadAll(nextProps);
        }
    }

    render() {
        const { appState, userStore, newsStore } = this.props.stores;
        const { isDevelopment, path, apiServer, currentCustomer = {} } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const {
            newsContainerClasses  = [],
            newsListClasses  = [],
            newsListImageWidth  = [],
            newsListImageLocations  = [],
        } = currentCustomer || {};

        const { newss } = newsStore;
        const { titleText, showHeader, skipUpdate, category } = this.props;
        const {
            classNames = '',
            hideEmpty,
            title = titleText,
            editElement,
            help,
            children,
            largeList,
            frontpageList,
            tickerList,
            newss: list,
            raceId,
            tag,
        } = this.props;

        const { localList } = this.state;
        const finalList = skipUpdate ? localList : list || newss;

        return (<>
            <div class={`d-flex flex-row px-4 pt-3`}>
                <h5>
                    <Text id='news.list-admin'>Admin list of News</Text>
                </h5>
            </div>
            <div class='d-flex w-100 position-relative'>
                {isAdmin && <>
                    <div class='position-absolute' style='top: 10px; right: 10px; z-index: 100000;'>
                        <button
                            type='button'
                            class='btn btn-sm btn-primary rounded-pill'
                            data-tag={tag}
                            onClick={this.onClickNewNews}
                        >
                            {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                            <i class='fa-solid fa-plus' />
                        </button>
                    </div>
                </>}
                <GuiList
                    key={`news-list-${tag}-${currentCustomer?.id}`}
                    classNames={classNames}
                    stores={this.props.stores}
                    holdingStore={newsStore}
                    view={this.editNews}
                    title={showHeader ? title : undefined}
                    list={finalList}
                    elTitle={e => <>{e.active ? <i class='fa-duotone fa-eye text-success mr-2' /> : <i class='fa-duotone fa-eye-slash text-danger mr-2' />}{e.title}</>}
                    elInfo1={e => <>
                        {util.isoDate(e.publishDate || e.date)}
                        {e.expireDate ? ` - ${util.isoDate(e.expireDate)}` : ''}
                    </>}
                    elInfo2={e => e.tag}
                    elInfo3={e => ''}
                    hideEmpty={hideEmpty}
                    editElement={editElement}
                    help={help || children}
                    showFilterBox={false}
                    maxHeight={'90vh'}
                    viewTags={this.viewTags}
                />
            </div>
        </>);

    }
}

export default NewsList;
