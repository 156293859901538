import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';

import Input from '../../components/form/input';

const initialState = {};

function isValidEmail(input) {
    // Define a regular expression pattern for a valid cellphone number
    // const pattern = /\d{8}$/; // Assumes a 8-digit number
    const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the input against the pattern
    return pattern.test(input);
}

@observer
class SendEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
    }

    sendEmail = async (e) => {
        this.setState({
            inProgress: true,
        });
        const { drawerLevel = 1, users = [], from, isPlainEmail = true } = this.props;
        const { appState } = this.props.stores;
        const { newEmail = {} } = appState;

        if (!newEmail.subject || !newEmail.body) {
            this.setState({
                errorTmp: 'Error! Please fill in all fields.',
                inProgress: false,
            });
            return;
        }

        let response;
        if (isPlainEmail) {
            response = await appState.sendEmailPlain({
                // from: '',
                from,
                to: users.filter(u => isValidEmail(u.email)).map(u => u.email),
                subject: newEmail.subject,
                body: newEmail.body,
            });
        } else {
            response = await appState.sendEmail({
                // from: '',
                from,
                to: users.filter(u => isValidEmail(u.email)).map(u => u.email),
                subject: newEmail.subject,
                body: newEmail.body,
            });
        }


        if (response.status === 200) {
            appState.updateKeyValue('newEmail', {});
            this.setState({
                message: 'Email sent!',
                inProgress: false,
            });
            setTimeout(() => {
                appState.toggleDrawer();
            }, 1000);
        } else {
            this.setState({
                error: 'Error! Could not send email. Please try again later.',
                inProgress: false,
            });
        }
    }

    componentDidMount() {
        const { appState } = this.props.stores;
        appState.updateKeyValue('newEmail', {
            subject: this.props.subject || '',
            body: this.props.body || '',
        });
    }

    render() {
        const { error, errorTmp, message, inProgress } = this.state;
        const { users = [], from } = this.props;
        const { appState } = this.props.stores;
        const { newEmail = {} } = appState;

        return (<>
            <div class='d-flex flex-column justify-content-start overflow-auto mb-5 mt-3 p-4'>
                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                    <Text id='race.admin.send-email-title'>Send Email</Text>
                </div>

                {/* <xmp>{JSON.stringify(users, null, 4)}</xmp> */}
                <div class='d-flex flex-row justify-content-start px-2'>
                    {from && <>
                        <span class='mr-2 text-muted'>
                            <Text id='input.from'>From</Text>
                        </span>
                        <span class={`badge badge-pill badge-secondary font-weight-normal mr-1 mt-1`}>
                            {from}
                        </span>
                    </>}
                </div>
                <div class='d-flex flex-wrap justify-content-start px-2'>
                    <span class='mr-2 text-muted'>
                        <Text id='input.to'>To</Text>
                    </span>
                    {users.filter(u => isValidEmail(u.email)).map(user => {
                        return (<>
                            <span class={`badge badge-pill badge-secondary font-weight-normal mr-1 mt-1`}>
                                {user.firstname} {user.lastname} &lt;{user.email}&gt;
                            </span>
                        </>);
                    })}
                </div>
                <div class='d-flex flex-wrap justify-content-start px-2'>
                    <span class='mr-2 text-muted'>
                        <Text id='input.invalid-emails'>Invalid emails</Text>
                    </span>
                    {users.filter(u => !isValidEmail(u.email)).map(user => {
                        return (<>
                            <span class={`badge badge-pill badge-danger font-weight-normal mr-1 mt-1`}>
                                {user.firstname} {user.lastname} &lt;{user.email}&gt;
                            </span>
                        </>);
                    })}
                </div>

                <Localizer>
                    <Input
                        classNames='form-control rounded-lg'
                        title={<Text id='input.subject'>Subject</Text>}
                        placeholder={<Text id='input.subject'>Subject</Text>}
                        stores={this.props.stores}
                        holdingStore={appState}
                        object={newEmail}
                        objectName={'newEmail'}
                        type={'text'}
                        field={'subject'}
                        extraDataset={{
                            'data-isnew': true,
                        }}
                    />
                </Localizer>
                <Localizer>
                    <Input
                        classNames='form-control rounded-lg'
                        title={<Text id='input.email-body'>Email content</Text>}
                        placeholder={<Text id='input.email-body'>Email content</Text>}
                        help={<Text id='input.email-body-help'>Your email content.</Text>}
                        stores={this.props.stores}
                        holdingStore={appState}
                        object={newEmail}
                        objectName={'newEmail'}
                        type={'textarea'}
                        field={'body'}
                        extraDataset={{
                            'data-isnew': true,
                        }}
                    />
                </Localizer>

                <div class='d-flex flex-column justify-content-center mt-5'>
                    <button
                        type='button'
                        class={`btn btn-block btn-primary rounded-pill`}
                        onClick={this.sendEmail}
                        disabled={error || message}
                    >
                        <i class='fa-solid fa-paper-plane mr-1' /> Send email
                        {inProgress && <>
                            <span class='spinner-border spinner-border-sm ml-2' role='status' aria-hidden='true'></span>
                        </>}
                    </button>

                    {error && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                        <div class='alert alert-danger rounded-pill' role='alert'>
                            {error}
                        </div>
                    </div>}
                    {errorTmp && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                        <div class='alert alert-danger rounded-pill' role='alert'>
                            {errorTmp}
                        </div>
                    </div>}
                    {message && <div class='w-100 d-flex px-3 mt-4 justify-content-center'>
                        <div class='alert alert-success rounded-pill' role='alert'>
                            {message}
                        </div>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default SendEmail;
