import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalAnimalListMini from '../../components/dyrejournal/listAnimalMini';

@withText(props => ({
    drugTitle: <Text id='dyrejournal.drug.search-title'>Search for drug</Text>,
    animaliaSearchTitle: <Text id='dyrejournal.journal-animalia-search-title'>Search for individuals in DHP</Text>,
    searchTitle: <Text id='dyrejournal.journal-animal-search-title'>Search for animals</Text>,

    textMarkOfOrigin: <Text id='dyrejournal.animalia-origin-mark'>Origin mark</Text>,
    textEarMark: <Text id='dyrejournal.animalia-ear-mark'>Ear mark</Text>,
    textName: <Text id='dyrejournal.animal-name'>Name</Text>,
    textBirth: <Text id='dyrejournal.birth'>Birthdate</Text>,
    textGender: <Text id='dyrejournal.gender'>Gender</Text>,
    textSpecies: <Text id='dyrejournal.species'>Species</Text>,
    textGroupSize: <Text id='dyrejournal.group-size'>Group size</Text>,
    textIsGroup: <Text id='dyrejournal.is-group'>Is group of animals</Text>,
}))
@observer
class AddAnimalOther extends Component {
	constructor(props) {
        super(props);
        this.state = {
            // sections: fields.get('journal'),
            sortBy: 'oremerke',
            sortDirection: 'asc',
            filterAnimals: true,
            newAnimal: {},
        };
        this.searchTimer = null;
        this.searchInput = null;
    }

    filterBySpecies = breed => {
        const { newAnimal } = this.state;
        if (!newAnimal.speciesId) {
            return false;
        }
        return breed.speciesId === parseInt(newAnimal.speciesId, 10);
    }

    onSearchInput = e => {
        const search = e.target.value;
        this.setState({ search });
    }

    onClearSearch = () => {
        this.setState({ search: '' });
    }

    searchFilter = animal => {
        const { search } = this.state;
        if (!search) {
            return true;
        }
        const searchLower = search.toLowerCase();
        return String(animal.name).toLowerCase().includes(searchLower) || String(animal.earMark).toLowerCase().includes(searchLower);
    }

    toggleSearch = e => {
        const { field } = e.target.closest('th').dataset;
        const { sortBy, sortDirection } = this.state;
        if (sortBy === field) {
            this.setState({ sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' });
        }
        this.setState({ sortBy: field });
    }

    sortList = (a, b) => {
        const { sortBy, sortDirection } = this.state;
        if (sortDirection === 'asc') {
            return a[sortBy] > b[sortBy] ? 1 : -1;
        }
        return a[sortBy] < b[sortBy] ? 1 : -1;
    }

    loadAll = async (props = this.props) => {
        // this.animaliaDoSearch('');
        await this.loadSpecies();
        await this.loadBreeds();
        this.searchInput.focus();
    }

    loadSpecies = async () => {
        const { animalStore } = this.props.stores;
        await animalStore.loadSpecies();
    }

    loadBreeds = async () => {
        const { animalStore } = this.props.stores;
        await animalStore.loadBreeds();
    }

    onClickChooseAnimal = async e => {
        const { addingAnimal } = this.state;
        if (addingAnimal) {
            return;
        }
        this.setState({ addingAnimal: true });
        const { id } = e.target.closest('tr').dataset;
        const idInt = parseInt(id, 10);
        const { drawerLevel = 1, saveSale = () => {}, loadAnimals = () => {}, loadJournals = () => {} } = this.props;


        const { appState, saleStore, animalStore, journalStore } = this.props.stores;
        const { newSale, visitorAnimals = [] } = saleStore;
        const { animals = [], visitors = [] } = newSale;
        const visitor = visitors[0];
        const visitorIds = visitors.map(v => v?.id);
        const animal = visitorAnimals.find(a => a.id === idInt);

        // console.log('opprinnelsesmerke', opprinnelsesmerke, 'oremerke', oremerke, 'animal', animal, 'animals', animals);
        // Check if animal is already in list for this sale
        if (animals.find(a => a.id === idInt)) {
            this.setState({ addingAnimal: false });
            return;
        }
        // console.log('animal', animal);

        const allAnimals = [...animals, {
            ...animal,
        }]
        saleStore.updateObjectKeyValue('newSale', 'animals', allAnimals);

        // // Create new journal for this animal and insert this into the sale.
        // const newJournal = {
        //     animal: currentAnimal.id,
        //     visitor: visitor.id,
        //     veterinary: newSale.veterinary,
        // };
        // const response = await journalStore.insert(newJournal);
        // const journal = response?.data?.journal;

        // // Insert this journal into the sale.
        // saleStore.updateObjectKeyValue('newSale', 'journals', [...newSale.journals, journal]);


        await saveSale({ calledFrom: 'addAnimal.onClickChooseAnimal' });

        const animalsIds = allAnimals.map(v => v?.id);
        const journalsIds = newSale.journals.map(v => v?.id);
        // console.log('animalsIds', animalsIds);
        await loadJournals(animalsIds, journalsIds);
        this.setState({ addingAnimal: false });
        // appState.toggleDrawer(false, drawerLevel);
    }

    onClickSaveNewAnimal = async () => {
        const { addingAnimal } = this.state;
        if (addingAnimal) {
            return;
        }
        this.setState({ addingAnimal: true });
        const { drawerLevel = 1, saveSale = () => {}, loadAnimals = () => {}, loadJournals = () => {} } = this.props;
        const { appState, saleStore, animalStore, journalStore } = this.props.stores;
        const { newSale, visitorAnimals = [] } = saleStore;
        const { animals = [], visitors = [] } = newSale;
        const visitor = visitors[0];
        const visitorIds = visitors.map(v => v?.id);

        const { newAnimal } = this.state;

        // Check if animal is already in list for this visitor
        const response = await animalStore.insert({
            ...newAnimal,
            owner: visitor.id,
        });
        this.setState({ newAnimal: {} });

        const currentAnimal = response?.data?.animal;
        await loadAnimals(visitorIds);

        const allAnimals = [...animals, {
            ...currentAnimal,
        }];
        saleStore.updateObjectKeyValue('newSale', 'animals', allAnimals);
        await saveSale({ calledFrom: 'addAnimal.onClickSaveNewAnimal' });

        const animalsIds = allAnimals.map(v => v?.id);
        const journalsIds = newSale.journals.map(v => v?.id);
        // console.log('animalsIds', animalsIds);
        await loadJournals(animalsIds, journalsIds);
        this.setState({ addingAnimal: false });
    }

    toggleFilterAnimals = e => {
        const { filterAnimals } = this.state;
        this.setState({
            filterAnimals: !filterAnimals,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            search,
            sortBy,
            sortDirection,
            animaliaSearch,
            animalList = [],
            addingAnimal,
            filterAnimals,
        } = this.state;
        const {
            searchTitle,
            animaliaSearchTitle,
            drawerLevel,

            textMarkOfOrigin,
            textEarMark,
            textName,
            textBirth,
            textGender,
            textSpecies,
            textGroupSize,
            textIsGroup,
        } = this.props;
        const { userStore, saleStore, animalStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { visitorAnimals = [], newSale } = saleStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            animals = [],
            visitors = [],
            journals = [],
        } = newSale;

        const diff = util.dateDiff(newSale.saleDate);
        const saleIsLocked = diff?.days > 360 || newSale.status > 0;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.add-animal-other'>Add other animal</Text>
                            </div>

                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                                <div class='d-flex flex-column'>
                                    <div class='d-flex flex-row justify-content-between'>
                                        <div class='input-group position-relative'>
                                            <div class='input-group-prepend'>
                                                <span class='input-group-text rounded-pill-left'>
                                                    <i class='fa-regular fa-magnifying-glass'></i>
                                                </span>
                                            </div>
                                            <input
                                                class={`form-control ${!animaliaSearch ? 'rounded-pill-right' : ''}`}
                                                type='text'
                                                value={search}
                                                placeholder={`${searchTitle}`}
                                                onInput={this.onSearchInput}
                                                ref={c => this.searchInput = c}
                                            />
                                            <div class='position-absolute' style='right: 55px; top: 5px; font-size: 1.1em;'>
                                                {addingAnimal && <>
                                                    <i class='fa-duotone fa-spinner fa-spin' />
                                                </>}
                                            </div>

                                            {animaliaSearch && <div class='input-group-append'>
                                                <button class='btn btn-danger rounded-pill-right' type='button' onClick={this.onClearSearch}>
                                                    <i class='fa-solid fa-circle-xmark'></i>
                                                </button>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class={`d-flex flex-column mx-3 mt-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <table class='table table-striped mb-0'>
                                        <thead>
                                            <tr>
                                                <th class='w-1'>&nbsp;</th>
                                                <th class='w-2' onClick={this.toggleSearch} data-field='markOfOrigin'>
                                                    <nobr>
                                                        <Text id='dyrejournal.animalia-origin-mark'>Origin mark</Text>
                                                        {sortBy === 'markOfOrigin' ? <i class={`fa-duotone ${sortDirection === 'asc' ? 'fa-chevron-down' : 'fa-chevron-up'} ml-2`} /> : ''}
                                                    </nobr>
                                                </th>
                                                <th class='w-2' onClick={this.toggleSearch} data-field='earMark'>
                                                   <nobr>
                                                     <Text id='dyrejournal.animalia-ear-mark'>Ear mark</Text>
                                                        {sortBy === 'earMark' ? <i class={`fa-duotone ${sortDirection === 'asc' ? 'fa-chevron-down' : 'fa-chevron-up'} ml-2`} /> : ''}
                                                    </nobr>
                                                </th>
                                                <th class='' onClick={this.toggleSearch} data-field='name'>
                                                   <nobr>
                                                     <Text id='dyrejournal.animal-name'>Name</Text>
                                                        {sortBy === 'name' ? <i class={`fa-duotone ${sortDirection === 'asc' ? 'fa-chevron-down' : 'fa-chevron-up'} ml-2`} /> : ''}
                                                    </nobr>
                                                </th>
                                                <th class='w-2 text-right' onClick={this.toggleSearch} data-field='birth'>
                                                    <nobr>
                                                        <Text id='dyrejournal.birth'>Birthdate</Text>
                                                        {sortBy === 'birth' ? <i class={`fa-duotone ${sortDirection === 'asc' ? 'fa-chevron-down' : 'fa-chevron-up'} ml-2`} /> : ''}
                                                    </nobr>
                                                </th>
                                                <th class='w-2' onClick={this.toggleSearch} data-field='gender'>
                                                    <nobr>
                                                        <Text id='dyrejournal.gender'>Gender</Text>
                                                        {sortBy === 'gender' ? <i class={`fa-duotone ${sortDirection === 'asc' ? 'fa-chevron-down' : 'fa-chevron-up'} ml-2`} /> : ''}
                                                    </nobr>
                                                </th>
                                                <th align='right' class='text-right w-10' onClick={this.toggleSearch} data-field='speciesId'>
                                                    <nobr>
                                                        <Text id='dyrejournal.race'>Race id</Text>
                                                        {sortBy === 'speciesId' ? <i class={`fa-duotone ${sortDirection === 'asc' ? 'fa-chevron-down' : 'fa-chevron-up'} ml-2`} /> : ''}
                                                    </nobr>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class='bg-primary-light'>
                                                <td>
                                                    <i class='fa-duotone fa-plus' />
                                                </td>
                                                <td>
                                                    <input type='text' class='form-control form-control-sm' placeholder={textMarkOfOrigin} onInput={linkstate(this, 'newAnimal.markOfOrigin')} />
                                                </td>
                                                <td>
                                                    <input type='text' class='form-control form-control-sm' placeholder={textEarMark} onInput={linkstate(this, 'newAnimal.earMark')} />
                                                </td>
                                                <td>
                                                    <input type='text' class='form-control form-control-sm' placeholder={textName} onInput={linkstate(this, 'newAnimal.name')} />
                                                </td>
                                                <td>
                                                    <input type='date' class='form-control form-control-sm' placeholder={textBirth} onInput={linkstate(this, 'newAnimal.birth')} />
                                                </td>
                                                <td>
                                                    <input type='text' class='form-control form-control-sm' placeholder={textGender} onInput={linkstate(this, 'newAnimal.gender')} />
                                                </td>
                                                <td class='form-inline float-right'>
                                                    <nobr>
                                                        <select class='form-control form-control-sm mr-1' onInput={linkstate(this, 'newAnimal.speciesId')}>
                                                            <option value=''><Text id='dyrejournal.choose-species'>-- Choose species --</Text></option>
                                                            {animalStore.species.map(specie => {
                                                                return (<option value={specie.id}>{specie.name}</option>);
                                                            })}
                                                        </select>
                                                        /
                                                        <select class='form-control form-control-sm ml-1' onInput={linkstate(this, 'newAnimal.breedId')}>
                                                            <option value=''><Text id='dyrejournal.choose-breed'>-- Choose breed --</Text></option>
                                                            {animalStore.breeds.filter(this.filterBySpecies).map(breed => {
                                                                return (<option value={breed.id}>{breed.name}</option>);
                                                            })}
                                                        </select>

                                                    </nobr>
                                                </td>
                                            </tr>
                                            <tr class='bg-primary-light'>
                                                <td colspan='7'>
                                                    <div class='d-flex flex-row justify-content-between'>
                                                        <div class='form-group form-check my-1'>
                                                            <input type='checkbox' class='form-check-input' id='isGroup' />
                                                            <label class='form-check-label' for='isGroup'><Text id='dyrejournal.animal-isGroup'>Animal is group</Text></label>
                                                        </div>
                                                        <div class='form-group my-1'>
                                                            <input type='number' class='form-control form-control-sm' placeholder={textGroupSize} onInput={linkstate(this, 'newAnimal.groupSize')} />
                                                        </div>

                                                        <button class='btn btn-sm btn-success rounded-pill ml-2 py-0' onClick={this.onClickSaveNewAnimal}>
                                                            <Text id='dyrejournal.save-animal'>Save animal</Text>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>

                                            {visitorAnimals && visitorAnimals.filter(this.searchFilter).sort(this.sortList).map(animal => {
                                                return (<>
                                                    <tr
                                                        onClick={this.onClickChooseAnimal}
                                                        data-id={animal.id}
                                                        class={`${animals.find(a => a.id === animal?.id) ? 'bg-success-light' : ''}`}
                                                    >
                                                        <td>
                                                            <i class={`fa-duotone ${animal?.id ? 'fa-check' : 'fa-times'}`} />
                                                        </td>
                                                        <td>
                                                            {animal.markOfOrigin}
                                                        </td>
                                                        <td>
                                                            {animal.earMark}
                                                        </td>
                                                        <td>
                                                            {animal.name}
                                                        </td>
                                                        <td class='text-right'>
                                                            {util.formatDate(animal.birth, { locale: 'no' })}
                                                        </td>
                                                        <td>
                                                            {/* {animal['kjonn-id']} / */}
                                                            {animal.gender}
                                                        </td>
                                                        <td class='text-right'>
                                                            {/* {animal['rase-kode']} /  */}
                                                            {animalStore.getSpecies(animal.speciesId, 'name')} / {animalStore.getBreed(animal.breedId, 'name')}
                                                        </td>
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>



{/* <xmp>{JSON.stringify(animalList, null, 4)}</xmp> */}
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default AddAnimalOther;
