import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

@observer
class CalendarHourGrid extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            isDragging: false,
        };
    }

    loadAll = () => {
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        calendarStore.getHoursInDay();
    }

    viewEvent = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.calendar-event').dataset;

        const { appState, calendarStore } = this.props.stores;
        const { eventId } = calendarStore
        if (eventId) {
            return;
        }

        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { drawerHeightMedium } = appState;

        appState.openDrawer('viewCalendarAvailability', {
            height: drawerHeightMedium,
            id: parseInt(id, 10),
            callback: event => {
                callback(event);
                appState.toggleDrawer(false, drawerLevel + 2);
                calendarStore.resetDragging();
            },
        }, drawerLevel + 2);
    }

    selectTime = e => {
        const { isDragging } = this.state;
        if (isDragging) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const { hour } = e.target.closest('.hour-line').dataset;
        const rect = e.currentTarget.getBoundingClientRect();
        const y = e.clientY - rect.top;  // y position within the element

        // Round to the nearest 15 minutes
        const roundedMinutes = Math.round(y / 15) * 15;
        // Zero pad minutes if needed
        let paddedMinute = roundedMinutes < 10 ? `0${roundedMinutes}` : roundedMinutes;
        let hourInt = parseInt(hour, 10);
        if (paddedMinute === 60) {
            hourInt += 1;
            paddedMinute = '00';
        }
        const paddedHour = hourInt < 10 ? `0${hourInt}` : hourInt;

        const { appState, saleStore, calendarStore } = this.props.stores;
        const { calendarDate } = calendarStore;
        // calendarStore.resetDragging();

        const startDate = new Date(`${util.isoDate(calendarDate, false, false, true)}T${paddedHour}:${paddedMinute}:00`);
        const endDate = new Date(startDate);
        endDate.setMinutes(endDate.getMinutes() + 15);

        const { calendarSelectDateTime, calendar, callback = () => {} } = this.props;

        if (calendarSelectDateTime) {
            calendarSelectDateTime({
                start: startDate,
                end: endDate,
                calendar,
            });
            return;
        }

        const { drawerLevel = 1, skipAnimal } = this.props;
        const { drawerHeightLarge } = appState;
        const { newSale } = saleStore;
        const { animals } = newSale;
        appState.openDrawer('newCalendarAvailability', {
			height: drawerHeightLarge,
            isNew: true,
            hideAdmin: true,
            calendar,
            calendarType: 'booking',
            animals,
            startDate: startDate,
            endDate: endDate,
            skipAnimal,
            callback: async booking => {
                // console.log('newCalendarAvailability callback', booking);
                appState.toggleDrawer(false, drawerLevel + 1);
                callback(booking);
                calendarStore.resetDragging();
            },
		}, drawerLevel + 1);

    }

    handleMouseDown = e => {
        // console.log('handleMouseDown');
        const  { calendar } = this.props;
        const { calendarStore } = this.props.stores;
        const { selectionStartDate, selectionEndDate, setSelectionCalendarId } = calendarStore;
        const selectionDiv = e.target.closest('.selection-calendar-event')
        const pullDownHandle = e.target.closest('.pull-down-handle');
        if (pullDownHandle) {
            return this.handleMouseDownPullDownHandle(e);
        }
        if (selectionDiv) {
            return this.clickSelection(e);
        }
        const eventDiv = e.target.closest('.calendar-event')
        if (eventDiv) {
            return this.viewEvent(e);
        }

        this.setState({
            isDragging: false,
        });

        const { hour } = e.target.closest('.hour-line').dataset;
        const rect = e.target.closest('.hour-line').getBoundingClientRect();
        const y = e.clientY - rect.top;  // y position within the element
        // Round to the nearest 15 minutes
        const roundedMinutes = Math.round(y / 15) * 15;
        // Zero pad minutes if needed
        let paddedMinute = roundedMinutes < 10 ? `0${roundedMinutes}` : roundedMinutes;
        let hourInt = parseInt(hour, 10);
        if (paddedMinute === 60) {
            hourInt += 1;
            paddedMinute = '00';
        }

        const { calendarDate } = calendarStore;
        const paddedHour = hourInt < 10 ? `0${hourInt}` : hourInt;

        // console.log('handleMouseDown', { hour, paddedHour, paddedMinute })
        const startDate = new Date(`${util.isoDate(calendarDate, false, false, true)}T${paddedHour}:${paddedMinute}:00`);
        const endDate = new Date(startDate);
        endDate.setMinutes(endDate.getMinutes() + 15);

        calendarStore.setSelectionStartDate(startDate);
        calendarStore.setSelectionEndDate(endDate);
        calendarStore.setSelectionCalendarId(calendar);
        calendarStore.setDragging(true);
        calendarStore.setStartY(roundedMinutes);
        calendarStore.setStartYAbs(e.clientY);
        calendarStore.setEndY(e.clientY);
    }

    handleMouseMove = (e) => {
        this.setState({
            isDragging: true,
        });
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        const {
            calendarDate,
            colors,
            calendarHours,
            startY,
            startYAbs,
            endY,
            selectionStartDate,
            selectionEndDate,
            dragging,
            eventId,
            eventEnd,
        } = calendarStore;


        if (dragging) {
            calendarStore.setEndY(e.clientY);
            const selectHeight = endY - startYAbs < 0 ? -Math.abs(endY - startYAbs) : Math.abs(endY - startYAbs);
            const roundedSelectHeight = Math.round(selectHeight / 15) * 15;
            const endDate = new Date(selectionStartDate);
            endDate.setMinutes(endDate.getMinutes() + roundedSelectHeight);
            calendarStore.setSelectionEndDate(endDate);

            if (eventId) {
                const event = calendarAvailabilityStore.calendarAvailabilitys.find(e => e.id === eventId);
                const end = new Date(eventEnd);
                end.setMinutes(end.getMinutes() + roundedSelectHeight);
                calendarAvailabilityStore.updateField(eventId, 'end', end);
            }
        }
    }

    handleMouseUp = e => {
        const { isDragging } = this.state;
        if (isDragging) {
            setTimeout(() => {
                this.setState({
                    isDragging: false,
                });
            }, 350);
        }
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        calendarStore.setDragging(false);

        const {
            selectionStartDate,
            selectionEndDate,
            setSelectionCalendarId,
            startYAbs,
            endY,
            eventId,
            eventEnd,
        } = calendarStore;
        const { calendarSelectDateTime, calendar } = this.props;
        if (calendarSelectDateTime) {
            calendarSelectDateTime({
                start: selectionStartDate,
                end: selectionEndDate,
                calendar: setSelectionCalendarId,
            });
            return;
        }
        if (eventId) {
            e.preventDefault();
            e.stopPropagation();
            const selectHeight = endY - startYAbs < 0 ? -Math.abs(endY - startYAbs) : Math.abs(endY - startYAbs);
            const roundedSelectHeight = Math.round(selectHeight / 15) * 15;

            const event = calendarAvailabilityStore.calendarAvailabilitys.find(e => e.id === eventId);
            const end = new Date(eventEnd);
            end.setMinutes(end.getMinutes() + roundedSelectHeight);
            calendarAvailabilityStore.save({
                end,
            }, eventId);
            calendarAvailabilityStore.updateField(eventId, 'end', end);
            setTimeout(() => {
                calendarStore.setEventId(null);
                calendarStore.setEndOfEvent(null);
            }, 500);
        }
    }

    handleMouseDownPullDownHandle = e => {
        e.stopPropagation();
        e.preventDefault();
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        // console.log('handleMouseDownPullDownHandle');
        calendarStore.setDragging(true);
        calendarStore.setStartYAbs(e.clientY);
        calendarStore.setEndY(e.clientY + 15);

        const pullDownHandle = e.target.closest('.pull-down-handle');
        const { id } = pullDownHandle.dataset;
        const eventId = parseInt(id, 10);
        calendarStore.setEventId(eventId);
        const event = calendarAvailabilityStore.calendarAvailabilitys.find(e => e.id === eventId);
        calendarStore.setEndOfEvent(event.end);
    }

    clickSelection = e => {
        e.stopPropagation();
        e.preventDefault();
        // console.log('clickSelection');
        const { calendarStore, appState, saleStore } = this.props.stores;
        const { selectionStartDate, selectionEndDate, selectionCalendarId } = calendarStore;
        const { calendarSelectDateTime } = this.props;
        if (calendarSelectDateTime) {
            calendarSelectDateTime({
                start: selectionStartDate,
                end: selectionEndDate,
                calendar: setSelectionCalendarId,
            });
            return;
        }

        const { drawerLevel = 1, callback = () => {}, skipAnimal } = this.props;
        const { drawerHeightLarge } = appState;
        const { newSale } = saleStore;
        const { animals } = newSale;
        appState.openDrawer('newCalendarAvailability', {
			height: drawerHeightLarge,
            isNew: true,
            hideAdmin: true,
            calendarType: 'booking',
            calendar: setSelectionCalendarId,
            animals,
            startDate: selectionStartDate,
            endDate: selectionEndDate,
            skipAnimal,
            callback: async booking => {
                // console.log('newCalendarAvailability callback', booking);
                appState.toggleDrawer(false, drawerLevel + 1);
                callback(booking);
                calendarStore.resetDragging();
            },
		}, drawerLevel + 1);
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.calendarDate !== this.props.calendarDate) || (nextProps.calendar !== this.props.calendar)) {
            this.loadAll();
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const  { idx, calendar } = this.props;
        const { calendarStore, calendarAvailabilityStore, userStore } = this.props.stores;
        const {
            calendarDate,
            colors,
            calendarHours,
            startY,
            startYAbs,
            endY,
            selectionStartDate,
            selectionEndDate,
            isToday,
            workhourStart,
            workhourEnd,
        } = calendarStore;

        const today = new Date();
        const todayHour = today.getHours();
        const todayMinutes = today.getMinutes();
        const todayHourStart = new Date(today);
        todayHourStart.setHours(todayHour, 0, 0, 0);

        const selectTop = Math.min(startY, endY);
        const selectHeight = endY - startYAbs < 0 ? 0 : Math.abs(endY - startYAbs);
        const roundedSelectHeight = Math.round(selectHeight / 15) * 15;
        const selectColor = colors[colors.length - 1];

        // const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        // const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const color = colors[idx].color;
        const backgroundColor = colors[idx].backgroundColor;
        // const dragBackgroundColor = colors[idx].dragBackgroundColor;
        // const borderColor = colors[idx].borderColor;
        // console.log(calendarAvailabilitys.map(e => `${e.start} - ${e.end}: ${e.title}`));

        return (<>
            <div
                class='no-select'
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
            >

{/* startY: {startY}<br />
startYAbs: {startYAbs}<br />
endY: {endY}<br />
selectHeight: {selectHeight}<br />
roundedSelectHeight: {roundedSelectHeight}<br /> */}
{/* calendar: {calendar}<br /> */}

                {calendarHours.map((hour, idx) => {
                    const hourInt = hour.getHours();
                    const events = calendarAvailabilityStore.getEvents(hour, calendar);
                    const selectionEvent = calendarStore.getSelectionEvent(hour, calendar);
                    const isInPast = hour < todayHourStart;
                    const isWorkHour = (hourInt >= workhourStart) && (hourInt < workhourEnd);

                    return (<>
                        <div class={`d-flex flex-row`}>
                            <div class='d-flex flex-row justify-content-end' style='width: 50px;'>
                                <small class='text-muted'>
                                    {util.isoTime(hour)}
                                </small>
                            </div>
                            <div
                                class='flex-fill ml-2 border-top position-relative hour-line'
                                style={`
                                    height: 60px;
                                    // opacity: ${isInPast ? 0.8 : 1};
                                    background-color: ${isWorkHour ? '#ffffff' : '#fafafa'};
                                `}
                                onClick={this.selectTime}
                                data-hour={hour.getHours()}
                            >
                                {isToday && hourInt === todayHour && <>
                                    <div
                                        class='position-absolute'
                                        style={`
                                            top: ${todayMinutes}px;
                                            left: 0;
                                            height: 2px;
                                            min-height: 2px;
                                            background-color: red;
                                            width: 100%;
                                            z-index: 1;
                                        `}
                                    ></div>
                                </>}

                                {events.map((event, eidx) => {
                                    const top = (new Date(event.start).getMinutes() / 60) * 60;
                                    const diff = new Date(event.end) - new Date(event.start);
                                    const height = (diff / 1000 / 60 / 60) * 60;
                                    return (<>
                                        <div
                                            class='position-absolute overflow-hidden pl-2 calendar-event d-flex flex-column'
                                            style={`
                                                top: ${top}px;
                                                ${event.overlapping ? ((eidx % 2 === 0) ? 'left: 0' : 'right: 0') : 'left: 0'};
                                                height: ${height}px;
                                                min-height: 15px;
                                                background-color: ${backgroundColor};
                                                color: ${color};
                                                opacity: 0.5;
                                                line-height: 0.8em;
                                                width: ${event.overlapping ? '50%' : '100%'};
                                                border-left: 3px solid ${color};
                                                border-top: 1px solid ${color};
                                                z-index: 10000;
                                            `}
                                            data-id={event.id}
                                            onClick={this.viewEvent}
                                        >
                                            <small class='font-weight-bold'>
                                                {util.isoTime(event.start)}-{util.isoTime(event.end)}: {event.title}
                                            </small>
                                            <small class='font-weight-normal'>
                                                {event.body}
                                            </small>
                                            <div
                                                class='pull-down-handle position-absolute text-center'
                                                style={`
                                                    content: "";
                                                    display: block;
                                                    margin: auto;
                                                    width: 20px;
                                                    height: 10px;
                                                    font-size: 10px;
                                                    line-height: 10px;
                                                    // background-color: #fff;
                                                    bottom: 0px;
                                                    left: 50%;
                                                    transform: translateX(-50%);
                                                    cursor: ns-resize; /* north-south resize cursor */
                                                    // border-radius: 5px 5px 0 0; /* rounded corners at the top */
                                                    // border: 1px solid ${color};
                                                `}
                                                data-id={event.id}
                                            >
                                                <i class="fas fa-grip-lines"></i>
                                            </div>
                                        </div>
                                    </>);
                                    lastOneIsOverlapping = event.overlapping;
                                })}
                                {selectionEvent && <>
                                    <div
                                        class='position-absolute overflow-hidden pl-2 selection-calendar-event'
                                        data-selection={true}
                                        style={`
                                            top: ${selectTop}px;
                                            left: 0;
                                            height: ${roundedSelectHeight}px;
                                            min-height: 15px;
                                            background-color: ${selectColor.backgroundColor};
                                            color: ${selectColor.color};
                                            opacity: 0.5;
                                            line-height: 0.8em;
                                            width: 100%;
                                            border-left: 3px solid ${selectColor.color}};
                                            border-top: 1px solid ${selectColor.color};
                                            z-index: 10000;
                                        `}
                                    >
                                        <small>
                                            {util.isoTime(selectionEvent.start)}-{util.isoTime(selectionEvent.end)}: {selectionEvent.title}
                                        </small>

                                        {/* <button type='button' class='btn btn-sm btn-success rounded-pill' onClick={this.clickSelection}>
                                            choose
                                        </button> */}

                                    </div>

                                </>}
                            </div>
                        </div>
                    </>);
                })}
            </div>
        </>);
    }
}

export default CalendarHourGrid;
