import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import InstallationList from '../../components/installation/list';
import FormFields from '../../components/form/fields';

@observer
class AddUsageData extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            sections: fields.get('usage'),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { userStore, usageStore, partStore } = this.props.stores;
        const { user } = userStore;
        const { part } = partStore;

        const lastUsages = await usageStore.load({
            query: {
                part: part.id,
            },
            sort: '-createdDate',
            limit: 1,
            skipUpdate: true,
        });
        const lastUsage = lastUsages?.[0] || {};
        delete lastUsage.id;
        delete lastUsage.uuidv4;
        delete lastUsage.createdDate;
        delete lastUsage.updatedDate;
        delete lastUsage.images;
        delete lastUsage.files;
        delete lastUsage.yrWeather;
        delete lastUsage.shotAnalysis;
        delete lastUsage.shotStrings;

        const newUsage = {
            ...lastUsage,
            customer: part.customer,
            part: part.id,
            user: user.id,
            date: util.isoDate(),
        };

        usageStore.updateKeyValue('newUsage', newUsage);
        // await usageStore.load(id, false, { addData: ['parts'] });
    }

    addUsageData = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id, callback = () => {}, drawerLevel } = this.props;
        const { appState, usageStore, partStore } = this.props.stores;
        const { newUsage } = usageStore;
        const { part } = partStore;
        // console.log('newUsage', { id, part }, JSON.stringify(newUsage, null, 4));
        const response = await usageStore.insert(newUsage);
        // const usage = response?.data?.usage;

        // if (usage?.id) {
        //     const newObject = {
        //         qty: 1,
        //         id: part.id,
        //         name: part.name,
        //         partNumber: part.partNumber,
        //     };
        //     await installationStore.addArrayObject({
        //         newObject,
        //         object: installation,
        //         field: 'parts',
        //         // objectName: 'installation',
        //     });
        // }
        usageStore.updateKeyValue('newUsage', {});
        appState.closeDrawer(false, drawerLevel);
        callback();
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.partId !== this.props.partId) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            drawerLevel = 1,
        } = this.props;
        const { sessionid, showMenu, sections, isNew = true } = this.state;
        const { appState, userStore, installationStore, partStore, usageStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { usage, installations, foundList, foundListKeys } = usageStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    <div class='font-weight-lighter px-3 box-header mt-3'>
                        <Text id='part.add-usage-data'>Add usage data</Text>
                    </div>
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields
                            stores={this.props.stores}
                            sections={sections}
                            holdingStore={usageStore}
                            objectName={'newUsage'}
                            isNew={isNew}
                            drawerLevel={drawerLevel}
                        />
                        <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                            <button type='button' class='btn btn-primary rounded-pill' onClick={this.addUsageData}>
                                <Text id='part.add-usage-data'>Add usage data</Text>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default AddUsageData;
