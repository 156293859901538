import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class ContactStore extends LocalModel {
    constructor() {
        super('contact', {
            namePlural: 'contacts',
            namePluralReal: 'contacts',
            sort: 'lastname',
            limit: 100,
            api: {
                search: {
                    url: '/api/contacts/',
                    params: {
                        limit: 50,
                        sort: 'lastname',
                    },
                },
                load: {
                    url: '/api/contacts/',
                    params: {},
                },
                save: {
                    url: '/api/contacts/',
                    params: {},
                },
                delete: {
                    url: '/api/contacts/',
                    params: {},
                },
            },
        });
    }

    @observable newContact = {};

    @observable contact = {};

    @observable contacts = [];

}

const store = new ContactStore();
export default store;

// Named export for the class itself
export { ContactStore };
