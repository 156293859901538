import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class InstallationStore extends LocalModel {
    constructor() {
        super('installation', {
            namePlural: 'installations',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/installations/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/installations/',
                    params: {},
                },
                save: {
                    url: '/api/installations/',
                    params: {},
                },
                delete: {
                    url: '/api/installations/',
                    params: {},
                },
            },
        });
    }

    @observable installationPart = {};

    @observable newInstallation = {};

    @observable installation = {};

    @observable installations = [];

    @observable requiredInspections = [];

    async addInspection({ id, newInspection }) {
        // const idx = this.notifications?.findIndex(e => e.id === id);
        // const obj = this.notifications[idx];
        const response = await util.fetchApi(`/api/installations/inspection/${id}`, {
            publish: true,
            method: 'POST',
        }, {
            id,
            ...newInspection,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 201:
                // obj.read = true;
                // this.updateObjectField('notifications', idx, obj);
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async editPart({ id, partId, data }) {
        const keys = Object.keys(data);
        const editData = {};
        keys.forEach(key => {
            this.updateFieldByName({
                namePlural: 'parts',
                name: 'singlePart',
                id: partId,
                field: key,
                value: data[key],
                findBy: 'id',
            });
            if (data[key]) {
                editData[`installationPart${key.charAt(0).toUpperCase() + key.slice(1)}`] = data[key];
            }
        });

        const response = await util.fetchApi(`/api/installations/${id}`, {
            publish: true,
            method: 'PATCH',
        }, {
            installationPartId: partId,
            ...editData,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                this.updateField(id, 'parts', response.data?.installation?.parts);
                return response;
            case 401:
                break;
        }
    }
}

const store = new InstallationStore();
export default store;
