import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';

const initialState = {};

@observer
class ScrollIntoView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
        };
        this.container = null;
    }

    componentDidMount() {
        const { horizontal, block = 'start' } = this.props;
        if (horizontal) {
            this.container.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center'
            });
            return;
        }

		this.container.scrollIntoView({
            behavior: 'smooth',
            block, // 'start', 'center', 'end', 'nearest'
        });
	}

    render() {
        return (
            <div class='w-100 mb-0' ref={c => this.container = c}></div>
        );
    }
}

export default ScrollIntoView;
