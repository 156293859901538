import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

@withText(props => ({
    titleText: <Text id='installation.title'>Installations</Text>,
}))
@observer
class InstallationList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewInstallation = (e, props) => {
        const { view, preCallback = () => {} } = this.props;
        preCallback();
        if (view) {
            return view(e);
        }
        const { id, title } = props ? props : e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        appState.toggleDrawer();
        route(`/installations/${id}`);
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0) {
            return '';
        }
        return (<>
            <div class='w-100 overflow-hidden'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.tags?.length > 0 && obj.tags.map(tag => {
                        return (<>
                            <span class='badge badge-pill badge-secondary mr-1 mt-1'>#{tag}</span>
                        </>);
                    })}
                    {obj.loans?.length > 0 && obj.loans.map(loan => {
                        if (loan.endDate) {
                            return '';
                        }
                        const endDate = loan.endDate ? new Date(loan.endDate) : new Date();
                        const {
                            seconds,
                            minutes,
                            hours,
                            days,
                            weeks,
                            months,
                            years,
                        } = util.dateDiff(loan.startDate, endDate);
                        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })

                        return (<>
                            <span class={`badge badge-pill badge-${loan.endDate ? 'success' : 'warning'} mr-1 mt-1`}>
                                Loaned out <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                            </span>

                            {/* <div class='tag'>{JSON.stringify(loan)}</div> */}
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    render() {
        const { appState, userStore, installationStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            installations,
            classNames = '',
            hideEmpty,
            addItem,
            title = titleText,
            wrapperProps,
            deleteElement,
            rentOutElement,
            moveElement,
            editElement,
            help,
            children,
        } = this.props;

        return (<>
            <GuiList
                key='installation-list'
                stores={this.props.stores}
                holdingStore={installationStore}
                classNames={classNames}
                view={this.viewInstallation}
                // elInfo1={this.viewTags}
                viewTags={this.viewTags}
                title={title}
                list={installations}
                elTitle={e => e.name}
                elImageStatus={e => <>
                    <InspectionStatus stores={this.props.stores} installation={e} />
                </>}
                // elInfo2={e => <>
                //     <span class='mr-2'>
                //         <i class='fa-solid fa-screwdriver-wrench' />
                //     </span>
                //     {e.parts.length}
                // </>}
                // elInfo2={e => <>
                //     <span class='mr-2'>
                //         <i class='fa-solid fa-image' />
                //     </span>
                //     {e.images.length}
                // </>}
                // elInfo3={e => <>
                //     <span class='mr-2'>
                //         <i class='fa-solid fa-file' />
                //     </span>
                //     {e.files.length}
                // </>}
                hideEmpty={hideEmpty}
                addItem={addItem}
                wrapperProps={wrapperProps}
                deleteElement={deleteElement}
                rentOutElement={rentOutElement}
                moveElement={moveElement}
                editElement={editElement}
                help={help || children}
            />
        </>);
    }
}

export default InstallationList;
