import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

@observer
class Versions extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    loadVersions = async () => {
        console.log('loadVersions');
        const { type, id } = this.props;
        const { versionStore } = this.props.stores;
        if (!type || !id) {
            return;
        }
        await versionStore.load({
            query: {
                type,
                documentId: id,
            },
            addData: ['users'],
        });
    }

    componentDidMount() {
        this.loadVersions();
    }

    render() {
        const { type, id } = this.props;
        const { versionStore } = this.props.stores;
        const { versions, users } = versionStore;

        return (<>
            <details>
                <summary><small class='text-muted font-weight-light'>Show version history</small></summary>
                <div class='w-100 d-flex flex-column mb-5 bg-lightmode rounded-lg px-3 py-2'>
                    <h5><Text id='input.versions'>Versions</Text></h5>
                    <div class='table-responsive'>
                        <table class='table table-sm table-hover table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th><Text id='input.createdDate'>Created Date</Text></th>
                                    <th><Text id='input.user'>user</Text></th>
                                    <th><Text id='input.type'>type</Text></th>
                                    <th><Text id='input.data'>data</Text></th>
                                </tr>
                            </thead>
                            <tbody>
                                {versions.map((version, idx) => {
                                    const user = users.find(e => e.id === version.user) || {};
                                    const data = JSON.parse(JSON.stringify(version.data));
                                    // delete data.id;
                                    // delete data.version;
                                    return (<tr key={idx}>
                                        <td>{util.isoDate(version.createdDate, true)}</td>
                                        <td>{user.firstname} {user.lastname}</td>
                                        <td>{version.type}</td>
                                        <td>
                                            {util.isObject(data) ? <>
                                                {Object.keys(data).map((key, idx) => {
                                                    return (<>
                                                        <div key={idx}>
                                                            <span class='badge badge-success mr-2'>{key}</span>
                                                            {util.isObject(data[key]) ? <>
                                                                <small><xmp>{JSON.stringify(data[key], null, 2)}</xmp></small>
                                                            </> : <>
                                                                {data[key]}
                                                            </>}
                                                        </div>
                                                    </>);
                                                })}
                                            </> : <>n/a</>}
                                        </td>
                                    </tr>);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </details>
        </>);
    }
}

export default Versions;
