import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalAnimalListMini from '../../components/dyrejournal/listAnimalMini';
import DyrejournalPrescriptionListMini from '../../components/dyrejournal/listPrescriptionMini';
import DyrejournalSalePrescription from '../../components/dyrejournal/salePrescription';

@observer
class ViewPrescription extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal', 'Journal basics'),
            sectionsMedical: fields.get('journal', 'Journal medical'),
            sectionsClinical: fields.get('journal', 'Journal clinical'),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id } = props;
        const { prescriptionStore } = this.props.stores;
        await this.loadPrescription(id);
        await prescriptionStore.loadVeterinaries();
    }

    loadPrescription = async id => {
        const { prescriptionStore, animalStore } = this.props.stores;
        await prescriptionStore.load(id);
        const { animal } = prescriptionStore;
        if (animal) {
            await animalStore.load(animal);
        }
    }

    onClickPrintPrescription = e => {
        const { prescriptionStore } = this.props.stores;
        const { prescription } = prescriptionStore;
        // console.log('onClickPrintPrescription', e);
        // Render Prescription component with data
        const prescriptionComponent = <DyrejournalSalePrescription stores={this.props.stores} prescription={prescription} />;
        // Append to document
        const printDiv = document.createElement('div');
        printDiv.className = 'printPrescription';
        document.body.appendChild(printDiv);
        render(prescriptionComponent, printDiv);
        // Trigger print dialog
        window.print();
        // Remove the appended div
        document.body.removeChild(printDiv);
        // Re-render the receipt component to reset it
        render(null, printDiv);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const {
            week,
            isNew,
            hideAdmin = true,
            drawerLevel = 1,
        } = this.props;
        const {
            sections,
            sectionsMedical,
            sectionsClinical,
        } = this.state;
        const { userStore, prescriptionStore, animalStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { prescription } = prescriptionStore;
        const { animal } = animalStore;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='font-weight-lighter px-3 box-header'>
                        <Text id='dyrejournal.prescription.view'>View Prescription</Text>
                    </div>

                    <DyrejournalPrescriptionListMini
                        classNames='mx-4'
                        stores={this.props.stores}
                        prescriptions={[prescription]}
                        saleIsLocked={true}
                        drawerLevel={drawerLevel}
                    />

                </div>

            </div>
        );
    }
}

export default ViewPrescription;
