import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class LoanStore extends LocalModel {
    constructor() {
        super('loan', {
            namePlural: 'loans',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/loans/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/loans/',
                    params: {},
                },
                save: {
                    url: '/api/loans/',
                    params: {},
                },
                delete: {
                    url: '/api/loans/',
                    params: {},
                },
            },
        });
    }

    @observable newLoan = {};

    @observable loan = {};

    @observable loans = [];

    @observable parts = [];

    @observable installations = [];

    @observable otherPartLoans = [];

    @observable otherLoans = [];

    async sendReminder(loanId) {
        const response = await util.fetchApi(`/api/loans/${loanId}/sendReminder`, {
            publish: true,
            method: 'GET',
        }, {});
        switch (response.status) {
            case 200:
                // obj.read = true;
                // this.updateObjectField('notifications', idx, obj);
                return response;
            case 401:
                break;
        }
    }
}

const store = new LoanStore();
export default store;
