import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import FileList from '../../components/fileViewer/list';
// import DyrejournalViewForm from '../../components/dyrejournal/viewForm';
import DyrejournalPrintForm from '../../components/dyrejournal/printForm';

import localUtil from '../../lib/util';

const MARKDOWN_OPTIONS = {
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
    },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};



@observer
class FileListTable extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
        this.mainContainer = null;
        this.calendar = null;
    }

    loadAll = async () => {
        const { fileStore } = this.props.stores;
        fileStore.load({
            query: {},
            skipUpdate: false,
        });
    }

    viewLog = (e) => {
        const { id } = e.target.closest('tr').dataset;
        const idInt = parseInt(id, 10);
        const { view } = this.state;
        this.setState({ view: view === idInt ? null : idInt });
    }

    onClickEditFile = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        // console.log('onClickEditFile', id, parseInt(id, 10));
        const { drawerLevel = 1 } = this.props;
		const { appState, fileStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editFile', {
			height: drawerHeightMedium,
            id,
            callback: file => {
                appState.toggleDrawer(false, drawerLevel + 1);
            },
		}, drawerLevel + 2);
    }

    onClickDeleteFile = async e => {
        e.preventDefault();
        e.stopPropagation();

        if (!confirm('Are you sure you want to delete this file?')) {
            return;
        }

        const { id } = e.target.closest('tr').dataset;
        const { fileStore } = this.props.stores;
        // console.log('onClickDeleteFile', id, parseInt(id, 10));
        await fileStore.delete(parseInt(id, 10));
        setTimeout(() => {
            this.loadAll(this.props, false);
        }, 1000);
    }

    onClickNewFile = e => {
        const { drawerLevel = 1 } = this.props;
		const { appState, filesStore } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('newFile', {
			height: drawerHeightMedium,
            callback: () => {
                this.loadAll();
            },
		}, drawerLevel + 2);
	}

    onClickPreviewForm = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, fileStore } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('viewForm', {
            id,
            height: drawerHeightMedium,
            callback: () => {
                this.loadAll();
            },
        }, drawerLevel + 2);
    }

    onClickViewForm = e => {
        // console.log('onClickPrintSale', e);
        const { id } = e.target.closest('tr').dataset;

        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { language = 'no' } = user;
        const languageDef = countryMap[language || 'default'];

        // Render Receipt component with data
        const receipt = <IntlProvider definition={languageDef}>
            <DyrejournalPrintForm stores={this.props.stores} id={id} />
        </IntlProvider>;

        // Append to document
        const printDiv = document.createElement('div');
        printDiv.className = 'viewForm';
        document.body.appendChild(printDiv);
        render(receipt, printDiv);
        // Trigger print dialog
        window.print();
        // Remove the appended div
        document.body.removeChild(printDiv);
        // Re-render the receipt component to reset it
        render(null, printDiv);
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { drawerLevel = 1 } = this.props;
        const { view } = this.state;
        const { appState, userStore, fileStore, saleStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { forms, newSale } = saleStore;
        const { files } = fileStore;

        const { visitors, animals, journals } = newSale;
        const currentVisitor = visitors && visitors.length > 0 ? visitors[0] : {};
        const currentAnimal = animals && animals.length > 0 ? animals[0] : {};
        const currentJournal = journals && journals.length > 0 ? journals[0] : {};
        const currentSale = newSale;

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center'>
                <div class='w-100 h-100 px-2 pt-3' style='padding-top: 0px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-column'>
                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                            <Text id='admin.forms-title'>Forms list</Text>
                        </div>
                        <div class={`d-flex flex-column mt-1 mx-3 px-3 py-3 mb-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <div class='table-responsive'>
                                <table class='table table-striped table-hover table-sm'>
                                    <thead>
                                        <tr>
                                            <th><Text id='admin.file-table-file'>File</Text></th>
                                            <th><Text id='admin.file-table-description'>Description</Text></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {forms && forms.map((form, idx) => {
                                            return (<>
                                                <tr data-id={form.id} onClick={this.onClickViewForm}>
                                                    <td><nobr>{form.name}</nobr></td>
                                                    <td class='font-weight-lighter'>{form.description}</td>
                                                    <td onClick={this.onClickPreviewForm}><i class='fa-solid fa-eye' /></td>
                                                </tr>
                                            </>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class='w-100 d-flex flex-column'>
                        <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                            <Text id='admin.file-title'>File list</Text>
                            <div>
                                <div class='float-right mb-1'>
                                    <button
                                        type='button'
                                        class='btn btn-sm btn-outline-secondary rounded-pill'
                                        onClick={this.onClickNewFile}
                                    >
                                        <i class='fa-solid fa-file text-muted' /> <Text id='form.file-new'>New File</Text>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class={`d-flex flex-column mt-1 mx-3 px-3 py-3 mb-4 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                            <div class='table-responsive'>
                                <table class='table table-striped table-hover table-sm'>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th><Text id='admin.file-table-date'>Date</Text></th>
                                            <th><Text id='admin.file-table-isPublic'>isPublic</Text></th>
                                            <th><Text id='admin.file-table-file'>File</Text></th>
                                            <th><Text id='admin.file-table-description'>Description</Text></th>
                                            <th>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {files && files.sort(localUtil.fieldSorter(['-isPublic', 'name'])).map((file, idx) => {
                                            return (<>
                                                <tr data-id={file.id}>
                                                    {file.customer === user.customer ? <>
                                                        <td class='d-none d-sm-table-cell' onClick={this.onClickEditFile} data-id={file.id}>
                                                            <i class='fa-solid fa-pen' />
                                                        </td>
                                                    </> : <>
                                                        <td class='d-none d-sm-table-cell'>&nbsp;</td>
                                                    </>}
                                                    <td><nobr>{util.isoDate(file.date)}</nobr></td>
                                                    <td align='center'><nobr>{file.isPublic ? <i class='fa-duotone fa-check' /> : ''}</nobr></td>
                                                    <td><nobr>{file.name}</nobr></td>
                                                    <td class='font-weight-lighter'>{file.description}</td>
                                                    <td class='font-weight-lighter'>
                                                        <FileList
                                                            files={file.files}
                                                            id={file.id}
                                                            stores={this.props.stores}
                                                            drawerLevel={drawerLevel}
                                                            currentVisitor={currentVisitor}
                                                            currentAnimal={currentAnimal}
                                                            currentJournal={currentJournal}
                                                            currentSale={currentSale}
                                                            saleId={newSale.id}
                                                        />
                                                    </td>
                                                    {file.customer === user.customer ? <>
                                                        <td class='d-none d-sm-table-cell' onClick={this.onClickDeleteFile} data-id={file.id}>
                                                            {file.isPublic ? <></> : <>
                                                                <i class='fa-solid fa-trash text-danger' />
                                                            </>}
                                                        </td>
                                                    </> : <>
                                                        <td class='d-none d-sm-table-cell'>&nbsp;</td>
                                                    </>}
                                                </tr>
                                            </>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>);
    }
}

export default FileListTable;
