import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class RaceResultStore extends LocalModel {
    constructor() {
        super('raceResult', {
            namePlural: 'raceResults',
            namePluralReal: 'raceResults',
            sort: 'timestamp',
            limit: 500,
            api: {
                search: {
                    url: '/api/raceresults/',
                    params: {
                        limit: 15,
                        sort: 'timestamp',
                    },
                },
                load: {
                    url: '/api/raceresults/',
                    params: {},
                },
                save: {
                    url: '/api/raceresults/',
                    params: {},
                },
                delete: {
                    url: '/api/raceresults/',
                    params: {},
                },
            },
        });
    }

    @observable reloadTimer = null;

    @observable newRaceResult = {};

    @observable raceResult = {};

    @observable nextResultToAdd = {};

    @observable raceResults = [];

    @observable adminRaces = [];

    @observable races = [];

    @observable events = [];

    @observable hasStarted = false;

    @action
    checkRaceResult = () => {
        try {
            if (new Date(this.raceResult?.startDate) < new Date()) {
                this.hasStarted = true;
            }
        } catch(e) {
            this.reportError(e);
        }
    }

    @action
    setReloadTimer = (timer) => {
        this.reloadTimer = timer;
    }

    @action
    deleteReloadTimer = () => {
        clearTimeout(this.reloadTimer);
    }
}

const store = new RaceResultStore();
export default store;
