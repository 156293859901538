import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import localUtil from '../../lib/util';

import RaceList from '../../components/race/list';
import RaceDetail from '../../components/race/detail';
import RaceClassTracking from '../../components/race/trackingMap';

@observer
class RaceResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    async loadRaceClassContestants(props = this.props) {
        const { raceStore, raceClassStore, raceClassContestantStore, userStore } = props.stores;
        const { raceId, raceClassId } = props;
        raceClassStore.updateKeyValue('raceClass', {});

        await raceStore.load(raceId, false, {
            url: '/api/races/public/',
        });

        let rcId = raceClassId;
        await raceClassStore.load({
            url: '/api/raceclasses/public/',
            query: {
                race: raceId,
            },
        });
        const { raceClasses } = raceClassStore;
        if (!raceClassId) {
            const currentRaceClass = raceClasses[0];
            rcId = currentRaceClass.id;
        }

        this.setState({
            raceClassId: rcId,
            raceClasses: [...raceClasses],
        });

        await raceClassStore.load(rcId, false, {
            url: '/api/raceclasses/public/',
            addData: ['files'],
        });

        const { race } = raceStore;
        await raceClassContestantStore.load({
            query: {
                race: race.id,
                raceClass: rcId,
                addResults: true,
            },
            url: '/api/raceclasscontestants/public/',
        });

        const { user } = userStore;
        raceStore.checkAllPositions(user);
    }

    loadAll = async (props = this.props) => {
        // console.log('loadAll', props);
        await this.loadRaceClassContestants(props);
        await this.loadYrWeather();
    }

    loadYrWeather = async () => {
        const { gpsPointStore, raceClassStore } = this.props.stores;
        const { raceClass } = raceClassStore;
        if (!raceClass.id) {
            return;
        }
        const checkpoints = raceClass.checkpoints;
        if (!checkpoints.length) {
            return;
        }
        for (let j = 0, k = checkpoints.length; j < k; j += 1) {
            const { lat, lng } = checkpoints[j];
            if (!lat || !lng) {
                continue;
            }
            const response = await gpsPointStore.getYrWeather({ lat, lon: lng });
            gpsPointStore.addWeather(checkpoints[j].md5, response.data);
        }
    }

    setRaceClass = async (e) => {
        const { callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        const idInt = parseInt(id, 10);
        // console.log('setRaceClass', idInt)
        callback(idInt);
        this.loadAll({ ...this.props, raceClassId: idInt });
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.id !== this.props.id) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { raceClassId, raceClasses } = this.state;
        const { id, isFixed, lat, lng, title } = this.props;
        const { userStore, raceStore, raceClassStore, raceClassContestantStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { race, isRaceAdmin } = raceStore;
        const { raceClass } = raceClassStore;
        const { raceClassContestants } = raceClassContestantStore;

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center'>
                <div class='w-100 h-100'>
                    {raceClassId && raceClass?.id ? <>
                        <RaceClassTracking
                            stores={this.props.stores}
                            race={race}
                            raceClass={raceClass}
                            raceClasses={raceClasses}
                            raceClassContestants={raceClassContestants.filter(rcc => rcc.raceClass === raceClass.id)}
                            setRaceClass={this.setRaceClass}
                            isFixed={isFixed}
                            inputMarkers={[{ lat, lng, title }]}
                        />
                    </> : <>
                        <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                            <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                                <div class='w-100 d-flex flex-row justify-content-center'>
                                    <div class='box-line-add'>
                                        <i class='fa-solid fa-circle-question' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </>);
    }
}

export default RaceResults;
