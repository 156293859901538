import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import localUtil from '../../lib/util';

@observer
class FileViewer extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            url,
        } = this.props;

        if (url.match(/\.(m4v|m4a|mp4)$/i)) {
            return (<>
                <div className='video-container'>
                    <video controls className='video-player'>
                        <source src={url} type='video/mp4' />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </>);
        }

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center align-items-center'>
                <iframe class='w-100 h-100' src={url} allowfullscreen></iframe>
            </div>
        </>);
    }
}

export default FileViewer;
