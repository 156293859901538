import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

function admissionForm() {
    return (<>
        <div class='d-flex flex-row'>
            <div class={`w-50 px-3 py-1 d-flex flex-column`}>
                <table class='table table-bordered'>
                    <tbody>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Inn kl:
                                </small>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Ut kl:
                                </small>
                                &nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Møter fastende:
                                </small>
                                <i class='fa-regular fa-square' /> Ja
                                <i class='fa-regular fa-square ml-3' /> Nei, spiste kl.:
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class={`w-50 px-3 py-1 d-flex flex-column`}>
                <table class='table table-bordered'>
                    <tbody>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Innleggelsen gjelder:
                                </small>
                                &nbsp;<br />
                                &nbsp;<br />
                                &nbsp;<br />
                                &nbsp;<br />
                                &nbsp;<br />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class='d-flex flex-row'>
            <div class={`w-50 px-3 py-1 d-flex flex-column`}>
                <small><strong>Eier opplysninger:</strong></small>
                <table class='table table-bordered'>
                    <tbody>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Vet du om dyret er allergisk mot noen medisiner?:
                                </small>
                                <i class='fa-regular fa-square' /> Ja
                                <i class='fa-regular fa-square ml-3' /> Nei
                                <span class='ml-3'>Hvis ja, hvilke:</span>
                                <br />
                                <br />
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Har dyret reagert unormalt på bedøvelse tidligere?:
                                </small>
                                <i class='fa-regular fa-square' /> Ja
                                <i class='fa-regular fa-square ml-3' /> Nei
                                <span class='ml-3'>Hvis ja, spesifiser:</span>
                                <br />
                                <br />
                                <br />
                            </td>
                        </tr>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Dersom veterinæren avdekker nye forhold underveis i behandlingen,
                                    slik at kostnaden kan øke, ønsker jeg å bli kontatker på overstående
                                    telefonnr:<br />
                                </small>
                                &nbsp;<br />
                                &nbsp;<br />
                                <i class='fa-regular fa-square' /> Ja
                                <i class='fa-regular fa-square ml-3' /> Nei
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class={`w-50 px-3 py-1 d-flex flex-column`}>
                <small><strong>Dersom aktuelt:</strong></small>
                <table class='table table-bordered'>
                    <tbody>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Kan det klippes pels på frambeinet for bedøvelse/blodprøve?:
                                </small>
                                &nbsp;<br />
                                <i class='fa-regular fa-square' /> Ja
                                <i class='fa-regular fa-square ml-3' /> Nei
                            </td>
                        </tr>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Ved fjerning av nydannelser/svulster ønskes biopsi?:
                                </small>
                                <i class='fa-regular fa-square' /> Ja
                                <i class='fa-regular fa-square ml-3' /> Nei
                            </td>
                        </tr>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Ønsker kloklipp?:
                                </small>
                                <i class='fa-regular fa-square' /> Ja
                                <i class='fa-regular fa-square ml-3' /> Nei
                            </td>
                        </tr>
                        <tr>
                            <td class='position-relative pt-3 pb-0'>
                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                    Annet aktuelt:
                                </small>
                                &nbsp;<br />
                                &nbsp;<br />
                                &nbsp;<br />
                                &nbsp;<br />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class='d-flex flex-row'>
            <div class={`w-50 px-3 py-1 d-flex flex-column`}>
                <small>
                    Dersom ikke annet er avtalt, forbeholder klinikken seg retten til å beholde dyr som pant
                    hvis ikke kunden betaler for behandling/tjenester som er utført/levert.
                </small>
            </div>
            <div class={`w-50 px-3 py-1 d-flex flex-column`}>
                <small>
                    Jeg er inneforstått med at bruk av anestesi og operasjon innebærer en viss risiko.<br />
                    &nbsp;<br />
                    &nbsp;<br />
                    .............................................................................<br />
                    Eiers underskrift<br />
                </small>
            </div>
        </div>
    </>);
}

function urineForm() {
    return (<>
        <div class='d-flex flex-row'>
            <div class={`w-100 px-3 py-1 d-flex flex-column`}>
                <small>
                    <strong>Stix:</strong>
                    <table class='table table-bordered table-sm'>
                        <tbody>
                            <tr>
                                <td>
                                    Lukt
                                </td>
                                <td class='position-relative pt-2 pb-0' colspan='10'>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Farge
                                </td>
                                <td class='position-relative pt-2 pb-0' colspan='10'>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Turbiditet
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Klar
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Uklar
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Fnokket
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Annet
                                    </small>
                                    &nbsp;
                                </td>
                                <td colspan='3'>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Spesifikk vekt
                                </td>
                                <td class='position-relative pt-2 pb-0' colspan='10'>
                                    &nbsp;
                                </td>
                            </tr>
                            {['Blod', 'Heomoglobin', 'Bilirubin', 'Keton', 'Glukose', 'Protein'].map((e, i) => {
                                return (<>
                                    <tr>
                                        <td>
                                            {e}
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                0
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                +
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                ++
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                +++
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                ++++
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                    </tr>
                                </>);
                            })}
                            <tr>
                                <td>
                                    pH
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        5
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        6
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        7
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        8
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        9
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Sediment
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Lite
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Moderat
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Mye
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Rikelig
                                    </small>
                                    &nbsp;
                                </td>
                                <td colspan='3'>
                                    &nbsp;
                                </td>
                            </tr>

                            <tr>
                                <td colspan='10'>
                                    Mikroskoppi: alle tall er pr. synsfelt/HPF<br />
                                    <small>
                                        Minst 3 synsfelt i nest største forstørring telles.
                                    </small>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Bakterier
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Nei
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Kokker
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Staver
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Få
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Mange
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            {['Røde blodlegmer', 'Granulocytter', 'Plate-epitel', 'Overgangsepitel', 'Caudate celler'].map((e, i) => {
                                return (<>
                                    <tr>
                                        <td>
                                            {e}
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                nei
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                1-10
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                10-20
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                20-50
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                        <td class='position-relative pt-2 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                &gt;50
                                            </small>
                                            &nbsp;
                                        </td>
                                        <td>
                                            &nbsp;
                                        </td>
                                    </tr>
                                </>);
                            })}
                            <tr>
                                <td>
                                    Sylindere
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Nei
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Leukocytter
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Amorfe
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Andre
                                    </small>
                                    &nbsp;
                                </td>
                                <td colspan='3'>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Krystaller
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Nei
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Oxalate
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Struvite
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Annet
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                                <td class='position-relative pt-2 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Antall
                                    </small>
                                    &nbsp;
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </small>
            </div>
        </div>
    </>)
}

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class ViewForm extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    getCurrentForm(id) {
        switch (id) {
            case 1:
                return admissionForm();
            case 2:
                return urineForm();
            default:
                return null;
        }
    }

    getOwner(owner, formId) {
        switch (formId) {
            case 1:
                return (<>
                    <table class='table table-bordered'>
                        <tbody>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Eier:
                                    </small>
                                    {owner.firstname} {owner.lastname}
                                </td>
                            </tr>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Addresse:
                                    </small>
                                    {owner.address}
                                </td>
                            </tr>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Postnr/Sted:
                                    </small>
                                    {fields.getNumberFixedWidth(owner.postalcode)} {owner.place}
                                </td>
                            </tr>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Telefon:
                                    </small>
                                    {owner.cellphone}
                                </td>
                            </tr>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        E-post:
                                    </small>
                                    {owner.email}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                        {/* <div class='d-flex flex-column justify-content-start'>
                            <div class=''>
                                {owner.firstname} {owner.lastname}
                            </div>
                            <div class=''>
                                {owner.address}
                            </div>
                            <div class=''>
                                {fields.getNumberFixedWidth(owner.postalcode)} {owner.place}
                            </div>
                            <div class=''>
                                <i class='fa-solid fa-envelope text-muted' /> {owner.email}
                            </div>
                            <div class=''>
                                <i class='fa-solid fa-phone text-muted' /> {owner.cellphone}
                            </div>
                        </div> */}
                </>);
            case 2:
                return (<>
                    <table class='table table-bordered'>
                        <tbody>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Eier:
                                    </small>
                                    {owner.firstname} {owner.lastname}<br />
                                    <span class='font-weight-light text-muted'>Mobil:</span> {owner.cellphone}
                                    <span class='font-weight-light text-muted ml-2'>E-post:</span> {owner.email}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>);
            default:
                return null;
        }
    }

    getAnimal(animal, formId) {
        switch (formId) {
            case 1:
                return (<>
                    <table class='table table-bordered table-sm'>
                        <tbody>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Dyrets navn:
                                    </small>
                                    {animal.name}
                                </td>
                            </tr>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Art/rase:
                                    </small>
                                    {animal.species} / {animal.breed}
                                </td>
                            </tr>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Født:
                                    </small>
                                    {util.formatDate(animal.birth, { locale: 'nb-NO' })} ({util.age(animal.birth)})
                                </td>
                            </tr>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Kjønn:
                                    </small>
                                    {animal.gender}
                                </td>
                            </tr>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Vekt:
                                    </small>
                                    {animal.weight ? <>{animal.weight} kg</> : <>&nbsp;</>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>);
            case 2:
                return (<>
                    <table class='table table-bordered table-sm'>
                        <tbody>
                            <tr>
                                <td class='position-relative pt-3 pb-0'>
                                    <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                        Dyr:
                                    </small>
                                    {animal.name} ({animal.gender}), {animal.species} / {animal.breed}<br />
                                    <span class='font-weight-light text-muted'>Født:</span> {util.formatDate(animal.birth, { locale: 'nb-NO' })} ({util.age(animal.birth)})
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>);
            default:
                return null;
        }
    }

    render() {
        const { week, isNew, drawerLevel, journals = [], id } = this.props;
        const { sections } = this.state;
        const { customerStore, userStore, saleStore, journalStore } = this.props.stores;
        const { customer } = userStore;
        const { user, isAdmin } = userStore;

        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const {
            saleTotal = 0,
            saleTax = 0,
            newSale = {},
            forms = [],
        } = saleStore;
        // const saleIsLocked = !!newSale.paymentMethod;
        // console.log({ newSale })

        const {
            visitors = [],
            animals = [],
        } = newSale;

        const currentForm = forms.find(e => e.id === parseInt(id, 10));

        if (currentForm.id > 100) {
            return (<>
                <div class='viewForm'>
                    <div class='viewForm'>

                        <div class='d-flex flex-row justify-content-between'>
                            <div>
                                <img src={currentForm.logo} class='img-fluid' style='max-height: 100px;' />
                            </div>
                            <div class='d-flex flex-column' style='font-size: 0.9em;'>
                                <span class='font-weight-bolder'>{currentForm.name}</span>
                                <span>{currentForm.address1}</span>
                                <span>{currentForm.address2}</span>
                                <span>{currentForm.postalcode} {currentForm.place}</span>
                                <span>Tlf: {currentForm.phone}</span>
                                <span>E-post: {currentForm.email}</span>
                                <span>{currentForm.url}</span>
                                <span>Orgnr: {currentForm.orgNum}</span>
                            </div>
                        </div>
                        <div class='d-flex flex-column align-items-end mt-3'>
                            <div class='d-flex flex-row font-weight-bold'><Text id='dyrejournal.date'>Date:</Text> {util.formatDate(new Date(), { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</div>
                        </div>

                        <div class='d-flex flex-row mt-3'>
                            <div class='d-flex flex-row w-50'>
                                <table class='table table-bordered table-sm'>
                                    <tbody>
                                        {visitors.map(owner => {
                                            return (<>
                                                <tr>
                                                    <td class='position-relative pt-3 pb-0'>
                                                        <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                            Eier:
                                                        </small>
                                                        {owner.firstname} {owner.lastname}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class='position-relative pt-3 pb-0'>
                                                        <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                            Adresse:
                                                        </small>
                                                        {owner.address}, {owner.postalcode} {owner.place}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class='position-relative pt-3 pb-0'>
                                                        <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                            E-post:
                                                        </small>
                                                        {owner.email}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class='position-relative pt-3 pb-0'>
                                                        <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                            Telefon:
                                                        </small>
                                                        {owner.cellphone}
                                                    </td>
                                                </tr>
                                            </>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div class='d-flex flex-row w-50'>
                                <table class='table table-bordered table-sm'>
                                    <tbody>
                                        <tr>
                                            <td class='position-relative pt-3 pb-0'>
                                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                    Klinikk:
                                                </small>
                                                {customer.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='position-relative pt-3 pb-0'>
                                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                    Adresse:
                                                </small>
                                                {customer.address}, {customer.postalcode} {customer.place}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='position-relative pt-3 pb-0'>
                                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                    E-post:
                                                </small>
                                                {customer.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='position-relative pt-3 pb-0'>
                                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                    Telefon:
                                                </small>
                                                {customer.phone}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class='d-flex flex-row mt-3'>
                            <table class='table table-bordered table-sm'>
                                <tbody>
                                    <tr>
                                        <td class='position-relative pt-3 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                Dyrets rase, navn og vekt:
                                            </small>
                                            {animals.map(animal => {
                                                return(<>
                                                    {animal.breed}, {animal.name}, {animal.weight || '______'} kg
                                                </>);
                                            })}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class='d-flex flex-row mt-3'>
                            <div class='d-flex flex-row w-75'>
                                <table class='table table-bordered table-sm'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                Felleskremering
                                            </td>
                                            <td class='position-relative pt-3 pb-0'>
                                                <i class='fa-regular fa-square' /> Ja
                                                <i class='fa-regular fa-square ml-3' /> Nei
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Separat kremering
                                            </td>
                                            <td class='position-relative pt-3 pb-0'>
                                                <i class='fa-regular fa-square' /> Ja
                                                <i class='fa-regular fa-square ml-3' /> Nei
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Eier ønsker aske tilbake i urne
                                            </td>
                                            <td class='position-relative pt-3 pb-0'>
                                                <i class='fa-regular fa-square' /> Ja
                                                <i class='fa-regular fa-square ml-3' /> Nei
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Eier ønsker aske tilbake i enkel eske
                                            </td>
                                            <td class='position-relative pt-3 pb-0'>
                                                <i class='fa-regular fa-square' /> Ja
                                                <i class='fa-regular fa-square ml-3' /> Nei
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Faktura sendes
                                            </td>
                                            <td class='position-relative pt-3 pb-0'>
                                                <i class='fa-regular fa-square' /> Eier
                                                <i class='fa-regular fa-square ml-3' /> Klinikk
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Urne/eske sendes
                                            </td>
                                            <td class='position-relative pt-3 pb-0'>
                                                <i class='fa-regular fa-square' /> Eier
                                                <i class='fa-regular fa-square ml-3' /> Klinikk
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class='d-flex flex-row w-25'>
                                <table class='table table-bordered table-sm'>
                                    <tbody>
                                        <tr>
                                            <td class='position-relative pt-3 pb-0'>
                                                &nbsp;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='position-relative pt-3 pb-0'>
                                                Tagg nr:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='position-relative pt-3 pb-0'>
                                                Urne nr:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class='position-relative pt-3 pb-0'>
                                                <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                    Merknader:
                                                </small>
                                                &nbsp;<br />
                                                &nbsp;<br />
                                                &nbsp;<br />
                                                &nbsp;<br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class='d-flex flex-row mt-3'>
                            <table class='table table-bordered'>
                                <tbody>
                                    <tr>
                                        <td class='position-relative pt-3 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                Dato:
                                            </small>
                                            {util.formatDate(new Date(), { locale: 'nb-NO' })}
                                        </td>
                                        <td class='position-relative pt-3 pb-0 w-75'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                Underskrift eier:
                                            </small>
                                            &nbsp;<br />
                                            &nbsp;<br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class='position-relative pt-3 pb-0'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                Dato:
                                            </small>
                                            {util.formatDate(new Date(), { locale: 'nb-NO' })}
                                        </td>
                                        <td class='position-relative pt-3 pb-0 w-75'>
                                            <small class='position-absolute text-muted' style='top: 0; left: 0;'>
                                                Underskrift veterinær/klinikk:
                                            </small>
                                            &nbsp;<br />
                                            &nbsp;<br />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class='d-flex flex-column mt-3'>
                            <small>
                                {currentForm.info1 && <Markdown markdown={currentForm.info1} markedOpts={localUtil.getMarkdownOptions()} />}
                                {currentForm.info2 && <Markdown markdown={currentForm.info2} markedOpts={localUtil.getMarkdownOptions()} />}
                            </small>
                        </div>

                    </div>
                </div>
            </>);
        }

        return (<>
            <div class='viewForm'>
                <div class='viewForm'>

                    <div class='row'>
                        <div class='col-2 text-center d-flex align-items-center'>
                            {customer.images && customer.images.length > 0 && <img src={localUtil.displayImg(customer.images[0], this.props, '80x80')} class='img-fluid' />}
                        </div>
                        <div class='col-10 d-flex align-items-center'>
                            <h3>{customer.name}</h3>
                        </div>
                    </div>
                    <div class='row mt-1'>
                        <div class='col-12 d-flex flex-column align-items-end'>
                            <div class='d-flex flex-row'><Text id='dyrejournal.date'>Date:</Text> {util.formatDate(new Date(), { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}</div>
                        </div>
                    </div>

                    <div class='d-flex flex-row justify-content-center'>
                        {id && currentForm && <>
                            <h5 class='m-0 p-0'>{currentForm.name}</h5>
                        </>}
                    </div>

                    <div class='d-flex flex-column'>
                        <div class='d-flex flex-row'>

                            <div class={`w-50 px-3 py-1 d-flex flex-column`}>
                                {animals.map(animal => {
                                    return this.getAnimal(animal, currentForm.id);
                                })}
                            </div>

                            <div class={`w-50 px-3 py-1 d-flex flex-column`}>
                                {visitors.map(owner => {
                                    return this.getOwner(owner, currentForm.id);
                                })}
                                {/* <xmp>{JSON.stringify(owners, null, 4)}</xmp> */}
                            </div>
                        </div>

                        {this.getCurrentForm(currentForm.id)}

                    </div>


                    <hr class='mt-5' />

                    <div class='row'>
                        <div class='col-3 d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.business-register'>The Business Register</Text></div>
                                <div>{customer.orgNumber}</div>
                                <div>{customer.name}</div>
                            </small>
                        </div>
                        <div class='col-3 d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.address'>Address</Text></div>
                                <div>{customer.address}</div>
                                <div>{customer.postalcode} {customer.postalplace}</div>
                            </small>
                        </div>
                        <div class='col-3 d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.phone-fax'>Phone/Fax</Text></div>
                                <div>{customer.phone}</div>
                                <div>{customer.fax}</div>
                            </small>
                        </div>
                        <div class='col-3 d-flex flex-column'>
                            <small>
                                <div class='mb-2 font-weight-bold'><Text id='dyrejournal.internett'>Internet</Text></div>
                                <div>{customer.url}</div>
                                <div>{customer.email}</div>
                            </small>
                        </div>
                    </div>

                </div>

            </div>
        </>);
    }
}

export default ViewForm;
