import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class SmsStore extends LocalModel {
    constructor() {
        super('sms', {
            namePlural: 'smsList',
            namePluralReal: 'sms',
            sort: '-createdDate',
            limit: 500,
            api: {
                search: {
                    url: '/api/sms/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/sms/',
                    params: {},
                },
                save: {
                    url: '/api/sms/',
                    params: {},
                },
                delete: {
                    url: '/api/sms/',
                    params: {},
                },
            },
        });
    }

    @observable newSms = {};

    @observable sms = {};

    @observable smsList = [];
}

const store = new SmsStore();
export default store;
