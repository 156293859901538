import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class InspectionStore extends LocalModel {
    constructor() {
        super('inspection', {
            namePlural: 'inspections',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/inspections/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/inspections/',
                    params: {},
                },
                save: {
                    url: '/api/inspections/',
                    params: {},
                },
                delete: {
                    url: '/api/inspections/',
                    params: {},
                },
            },
        });
    }

    @observable newInspection = {};

    @observable inspection = {};

    @observable inspections = [];

    @observable installations = [];
}

const store = new InspectionStore();
export default store;
