import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class FileStore extends LocalModel {
    constructor() {
        super('file', {
            namePlural: 'files',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/files/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/files/',
                    params: {},
                },
                save: {
                    url: '/api/files/',
                    params: {},
                },
                delete: {
                    url: '/api/files/',
                    params: {},
                },
            },
        });
    }

    @observable newFile = {};

    @observable file = {};

    @observable files = [];

    @observable animalFields = [];
    @observable visitorFields= [];
    @observable userFields= [];
    @observable customerFields= [];
    @observable journalFields= [];
    @observable saleFields= [];
    @observable veterinaryFields= [];


}

const store = new FileStore();
export default store;
