import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import GuiInfoBox from '../../components/gui/infoBox';

import localUtil from '../../lib/util';

@withText(props => ({
    amountText: <Text id='race-class.signup.amount-title'>Amount to pay</Text>,
}))
@observer
class PaymentPaypal extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    doPayment = async () => {
        this.setState({ paymentInProgress: true });
        const { type, amount, raceId } = this.props;
        const { raceClassContestantStore, userStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        const { user } = userStore;

        switch (type) {
            case 'fee':
                await raceClassContestantStore.paySignupFeeByPaypal(raceClassContestant.uuidv4);
                this.checkSignupFeePayment();
                break;
            case 'signup':
                await raceClassContestantStore.paySignupByPaypal(raceClassContestant.uuidv4);
                this.checkSignupPayment();
                break;
            case 'subscription':
                await userStore.paySubscriptionByPaypal(user.uuidv4, raceId, amount);
                this.checkSubscriptionPayment();
                break;
            default:
                break;
        }
    }

    paySignup = async () => {
        this.setState({ paymentInProgress: true });
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        await raceClassContestantStore.paySignupByPaypal(raceClassContestant.uuidv4);
        this.checkSignup();
    }

    checkSignupFeePayment = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        if (raceClassContestant.paidSignupfee || !raceClassContestant.paypalSignupfeeReference) {
            return;
        }
        const hasPaid = await raceClassContestantStore.checkSignupFeeByPaypal(raceClassContestant.uuidv4);
        setTimeout(() => {
            this.checkSignupFeePayment();
        }, 2000);
    }

    checkSignupPayment = async () => {
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        if (raceClassContestant.paidSignup || !raceClassContestant.paypalSignupReference) {
            return;
        }
        const hasPaid = await raceClassContestantStore.checkSignupByPaypal(raceClassContestant.uuidv4);
        setTimeout(() => {
            this.checkSignupPayment();
        }, 2000);
    }

    checkSubscriptionPayment = async () => {
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const userResponse = await userStore.load({ skipUpdate: true });
        if (userResponse.subscription) {
            userStore.updateObjectKeyValue('user', 'subscription', userResponse.subscription);
            userStore.updateObjectKeyValue('user', 'paidSubscription', userResponse.paidSubscription);
        }
        const { subscription = {} } = user;

        if (user.paidSubscription || !subscription.payPalSubscriptionId) {
            return;
        }
        const hasPaid = subscription.payPalPaidSubscription;
        setTimeout(() => {
            this.checkSubscriptionPayment();
        }, 2000);
    }

    payment = () => {
        const { type, amount } = this.props;
        const { paymentInProgress } = this.state;
        const { raceClassContestantStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { raceClassContestant } = raceClassContestantStore;

        // const paypalReference = type === 'fee' ? raceClassContestant.paypalSignupfeeReference : raceClassContestant.paypalSignupReference;
        // const paypalRedirectUrl = type === 'fee' ? raceClassContestant.paypalSignupfeeRedirectUrl : raceClassContestant.paypalSignupRedirectUrl;

        let paypalReference = null;
        let paypalRedirectUrl = null;
        switch (type) {
            case 'fee':
                paypalReference = raceClassContestant.paypalSignupfeeReference;
                paypalRedirectUrl = raceClassContestant.paypalSignupfeeObject?.redirectUrl;
                break;
            case 'signup':
                paypalReference = raceClassContestant.paypalSignupReference;
                paypalRedirectUrl = raceClassContestant.paypalSignupObject?.redirectUrl;
                break;
            case 'rest':
                paypalReference = raceClassContestant.paypalRestReference;
                paypalRedirectUrl = raceClassContestant.paypalRestObject?.redirectUrl;
            case 'subscription':
                paypalReference = user.subscription?.payPalSubscriptionId;
                paypalRedirectUrl = user.subscription?.payPalApproveLink;
            break;
        }

        return (<>
            {paypalReference ? <>
                <div class='d-flex flex-column'>
                    <div class='d-flex flex-row justify-content-center align-items-center mt-0'>
                        <span class='spinner-border spinner-border-sm mr-2' role='status' aria-hidden='true' />
                        <Text id='race-class.signup.waiting-for-paypal'>Waiting for Paypal...</Text>
                    </div>
                    <div class='d-flex flex-row justify-content-center align-items-center mt-3'>
                        {paypalRedirectUrl ? <>
                            <a href={paypalRedirectUrl} rel='noopener noreferrer' target='_blank' class='btn btn-primary rounded-pill mt-4'>
                                <Text id='race-class.signup.goto-paypal-page'>Go to Paypal to handle the payment</Text>
                            </a>
                        </> : <>
                            {/* <small class='text-muted'>
                                <Text id='race-class.signup.waiting-for-paypal-help'>Please check your Paypal app.</Text>
                            </small> */}
                        </>}
                    </div>
                </div>
            </> : <>
                {paymentInProgress ? <>
                    <div class='spinner-grow spinner-grow-sm' role='status'>
                        <span class='sr-only'>Loading...</span>
                    </div>
                </> : <>
                    <button type='button' class='btn btn-primary rounded-pill mt-4' onClick={this.doPayment}>Pay with Paypal</button>
                </>}
                {/* <button type='button' class='btn btn-primary mt-4' onClick={this.paySignup}>Pay signup </button> */}
            </>}
        </>);
    }

    receipt = () => {
        const { type } = this.props;
        const { raceClassContestantStore, userStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        const { user } = userStore;

        let amount = 0;
        let currency = '';
        let paidDate = null;
        switch (type) {
            case 'fee':
                amount = raceClassContestant.signupfeeAmount;
                paidDate = raceClassContestant.paidSignupfee;
                break;
            case 'signup':
                amount = raceClassContestant.signuppaymentAmount;
                paidDate = raceClassContestant.paidSignup;
                break;
            case 'rest':
                amount = raceClassContestant.restPaymentAmount;
                paidDate = raceClassContestant.paidRest;
                break;
            case 'subscription':
                amount = user.subscriptionPaymentAmount;
                currency = user.subscriptionCurrency;
                paidDate = user.paidSubscription;
                break;
            default:
                break;
        }

        return (<>
            <div class='d-flex flex-row justify-content-center align-items-center mt-0'>
                <div>
                    <i class='fa-regular fa-circle-check text-success' style='font-size: 4.0em;' />
                </div>
                <div class='d-flex flex-column ml-3'>
                    {/* paymentMethod: {raceClassContestant.paymentMethod}<br /> */}
                    {/* {util.formatDate(raceClassContestant.paidSignupfee, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}<br /> */}
                    {/* paypalSignupfeeReference: {raceClassContestant.paypalSignupfeeReference}<br /> */}
                    {/* paypalSignupfeeStatus: {raceClassContestant.paypalSignupfeeStatus}<br /> */}
                    {/* paypalSignupfeeAmountCurrency: {raceClassContestant.paypalSignupfeeAmountCurrency}<br /> */}
                    {/* paypalSignupfeeAmountValue: {util.format(raceClassContestant.paypalSignupfeeAmountValue / 100, 0)}<br /> */}

                    <GuiInfoBox
                        stores={this.props.stores}
                        nameMiddle={util.formatDate(paidDate, { hour12: false, hour: '2-digit', minute: '2-digit', locale: 'nb-NO' })}
                        value={util.format(amount, 0)}
                        postfix={currency}
                        styles={`font-size: 2.5em; line-height: 1.0em;`}
                    />
                </div>
            </div>
        </>);
    }

    render() {
        const { paymentInProgress } = this.state;
        const {
            amountText,
        } = this.props;
        const { type } = this.props;
        const { appState, userStore, raceClassContestantStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { raceClassContestant } = raceClassContestantStore;

        let amount = 0;
        let currency = '';
        let paidDate = null;
        switch (type) {
            case 'fee':
                amount = raceClassContestant.signupfeeAmount;
                paidDate = raceClassContestant.paidSignupfee;
                break;
            case 'signup':
                amount = raceClassContestant.signuppaymentAmount;
                paidDate = raceClassContestant.paidSignup;
                break;
            case 'rest':
                amount = raceClassContestant.restPaymentAmount;
                paidDate = raceClassContestant.paidRest;
                break;
            case 'subscription':
                amount = user.subscriptionPaymentAmount;
                paidDate = user.paidSubscription;
                break;
            default:
                break;
        }
        return (<>

            {paidDate ? <>
                {this.receipt()}
            </> : <>
                <div class='d-flex flex-row justify-content-center mt-0'>
                    <GuiInfoBox
                        stores={this.props.stores}
                        nameMiddle={amountText}
                        value={util.format(amount, 0)}
                        postfix={'NOK'}
                        styles={`font-size: 2.5em; line-height: 1.0em;`}
                    />
                </div>
                <div class='d-flex flex-row justify-content-center mt-0'>
                    {this.payment()}
                </div>
            </>}

        </>);
    }
}

export default PaymentPaypal;
