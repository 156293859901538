import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    installation: <Text id='input.installation-help'>installation</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewPrescription extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('prescription'),
        };
    }

    createPrescription = async () => {
        const { callback = () => {} } = this.props;
        const { prescriptionStore } = this.props.stores;
        const { newPrescription } = prescriptionStore;
        const response = await prescriptionStore.adminCreate(newPrescription);
        if (response.status === 201) {
            callback();
        }
    }

    componentDidMount() {
        const { idx, id, isNew } = this.props;
        const { prescriptionStore } = this.props.stores;
        if (!isNew) {
            // prescriptionStore.adminSetFromIdx(idx);
            prescriptionStore.adminSetFromId(id);
        }
    }

    render() {
        const { week, isNew, drawerLevel } = this.props;
        const { sections } = this.state;
        const { userStore, prescriptionStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newPrescription } = prescriptionStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <h5 class='ml-3 mb-3'>
                        {isNew ? <Text id='prescription.new'>New prescription</Text> : <Text id='prescription.edit'>Edit prescription</Text>}
                    </h5>
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <FormFields stores={this.props.stores} sections={sections} holdingStore={prescriptionStore} objectName={isNew ? 'newPrescription' : 'prescription'} isNew={isNew} drawerLevel={drawerLevel} isAdmin={true} />
                    </div>
                    {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createPrescription}>
                            {isNew ? <Text id='prescription.create'>Create prescription</Text> : <Text id='prescription.save'>Save prescription</Text>}
                        </button>
                    </div>}
                </div>
            </div>
        </>);
    }
}

export default NewPrescription;
