import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

import { MapContainer, TileLayer, Marker, Popup, Polyline, Polygon, useMap, GeoJSON, latLngBounds } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

// import isoCountries from 'iso-countries';

import markerSvg from '../assets/mapicons/marker.svg';
import markerCircleSvg from '../assets/mapicons/circle-marker.svg';
import markerDogSvg from '../assets/mapicons/dog-duotone.svg';

import localUtil from '../lib/util';

configure({ enforceActions: 'always' });

// Function to calculate offset positions in a circular pattern
function getCirclePoints(center, initialRadius, count, radiusIncrement) {
    var points = [];
    var skewAngle = 45 * Math.PI / 180; // Convert 45 degrees to radians

    for (var i = 0; i < count; i++) {
        var radius = initialRadius + (radiusIncrement * i); // Increase radius for each point
        var angle = (i / count) * (2 * Math.PI) + skewAngle; // Add skewAngle to rotate
        // console.log({ angle });
        var dx = radius * Math.cos(angle);
        var dy = radius * Math.sin(angle);
        // console.log({ center });
        points.push([center.lat + dy, center.long + dx]);
    }
    return points;
}

function areMarkersClose(marker1, marker2, proximityInMeters) {
    const earthRadiusInMeters = 6371000;

    const dLat = degreesToRadians(marker2.lat - marker1.lat);
    const dLon = degreesToRadians(marker2.long - marker1.long);

    const lat1 = degreesToRadians(marker1.lat);
    const lat2 = degreesToRadians(marker2.lat);

    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = earthRadiusInMeters * c;

    return distance <= proximityInMeters;
}

function degreesToRadians(degrees) {
    return degrees * Math.PI / 180;
}

function isMarker1FurtherNorth(marker1, marker2) {
    if (marker1 && marker2) {
        return marker1.lat > marker2.lat;
    }
}

function getRotationDegree(tIdx = 1) {
    const idx = tIdx + 1; // Add 1 to avoid 0
    // Define the range and step
    const range = 20; // -30 to +30 is a range of 60
    const step = 3;  // Change in degrees for each step

    // Calculate the number of steps within the range
    const stepsInRange = range / step;

    // Use modulo operator to cycle through steps within the range
    const normalizedIndex = tIdx % stepsInRange;

    // Calculate the rotation degree
    const rotationDegree = normalizedIndex * step;

    return rotationDegree;
}

class GpsPointStore extends LocalModel {
    constructor() {
        super('gpsPoint', {
            namePlural: 'gpsPoints',
            namePluralReal: 'gpsPoints',
            sort: 'timestamp',
            limit: 500,
            api: {
                search: {
                    url: '/api/gpspoints/',
                    params: {
                        limit: 15,
                        sort: 'timestamp',
                    },
                },
                load: {
                    url: '/api/gpspoints/',
                    params: {},
                },
                save: {
                    url: '/api/gpspoints/',
                    params: {},
                },
                delete: {
                    url: '/api/gpspoints/',
                    params: {},
                },
            },
        });
    }

    @observable newGpsPoint = {};

    @observable gpsPoint = {};

    @observable lastPoint = { lat: 62.573821, lng: 11.3857662 };

    @observable gpsPoints = [];

    @observable races = [];

    @observable polyline = [];

    @observable trackers = [];

    @observable bounds = [];

    @observable markers = [];

    @observable lines = [];

    @observable allGpsDevices = [];

    @observable weather = {};

    @action
    addWeather = (key, value) => {
        this.weather[key] = value;
    }

    @action
    setLastPoint() {
        const { gpsPoints } = this;
        if (gpsPoints.length > 0) {
            const lastPoint = gpsPoints[gpsPoints.length - 1];
            this.lastPoint = lastPoint;
        }
    }

    @action
    setPolyline() {
        const { gpsPoints } = this;
        if (gpsPoints.length > 0) {
            const polyline = gpsPoints.map(point => [point.lat, point.lng]);
            this.polyline = polyline;
        }
    }

    @action
    setBounds() {
        const { trackers } = this;
        this.bounds = [];
        if (trackers.length > 0) {
            trackers.forEach(tracker => {
                // Get last point from each tracker
                // const lastPoint = tracker.points[tracker.points.length - 1];
                // this.bounds.push([lastPoint[0], lastPoint[1]]);
                // const lastPoint = tracker.point;
                const lastPoint = [tracker.lat, tracker.long];
                // this.bounds.push([lastPoint[1], lastPoint[0]]);
                this.bounds.push([lastPoint[0], lastPoint[1]]);
                // tracker.points.forEach(point => {
                    // this.bounds.push([point[0], point[1]]);
                // });
            });
        }
    }

    @action
    setMarkers() {
        const { trackers } = this;
        this.markers = [];
        this.lines = [];
        const processedTrackers = new Set(); // To keep track of processed trackers

        if (trackers.length > 0) {
            trackers.forEach((tracker, tIdx) => {
                if (processedTrackers.has(tracker.trackerId)) {
                    return;
                }

                const currentTracker = { ...tracker };
                const currentContestant = currentTracker.contestant || { firstname: 'trackerId', lastname: currentTracker.trackerId };
                const lastPoint = [currentTracker.lat, currentTracker.long];

                // // Check for overlapping markers
                // const overlappingMarkers = trackers.filter(m =>  {
                //     return m.lat === currentTracker.lat && m.long === currentTracker.long;
                // });

                // Check for overlapping markers
                const overlappingMarkers = trackers.filter((m, mIdx) => {
                    if (processedTrackers.has(m.trackerId)) {
                        return false;
                    }
                    return m.trackerId !== currentTracker.trackerId && areMarkersClose(m, currentTracker, 30);
                });
                const closeMarkers = trackers.filter((m, mIdx) => {
                    if (processedTrackers.has(m.trackerId)) {
                        return false;
                    }
                    return m.trackerId !== currentTracker.trackerId && areMarkersClose(m, currentTracker, 400);
                });
                currentTracker.closeMarkers = closeMarkers;

                // Constants
                const minOpacity = 0.2; // Define your minimum opacity
                const maxTime = 3600 * 3; // 3 hours in seconds
                const fadeStartTime = 3600; // 1 hour in seconds
                const lineHeight = '1.1em';
                const iconSize = [150,30];
                const iconLabelWidth = 120;
                const iconAnchorRight = [3,15];
                const iconAnchorLeft = [147, 15];

                if (overlappingMarkers.length > 0) {
                    const circlePoints = getCirclePoints(currentTracker, 0.0004, overlappingMarkers.length, 0.00002); // Adjust radius as needed
                    // console.log({ circlePoints })
                    circlePoints.forEach((point, idx) => {
                        const overlappingMarker = overlappingMarkers[idx];
                        const overlappingContestant = overlappingMarker.contestant || { firstname: 'trackerId', lastname: overlappingMarker.trackerId };

                        // Calculate time since last GPS point in seconds
                        const timeSinceLastGpsPoint = parseInt((new Date().getTime() - new Date(overlappingMarker.timestamp).getTime()) / 1000, 10);

                        // Calculate opacity
                        let opacity;
                        if (timeSinceLastGpsPoint <= fadeStartTime) {
                            opacity = 1;
                        } else if (timeSinceLastGpsPoint > maxTime) {
                            opacity = minOpacity;
                        } else {
                            opacity = minOpacity + (1 - minOpacity) * ((maxTime - timeSinceLastGpsPoint) / (maxTime - fadeStartTime));
                        }

                        // Create offset marker
                        const offsetMarker = new L.divIcon({
                            iconUrl: markerDogSvg,
                            iconRetinaUrl: markerDogSvg,
                            popupAnchor:  [0, -15],
                            iconAnchor: [3,9],
                            iconSize,
                            html: `
                            <div
                                class='d-flex flex-row justify-content-start align-items-center'
                                style='opacity: ${opacity};'
                            >
                                <div class='rounded-circle mr-1' style='width: 7px; height: 7px; background-color: ${currentTracker.bgColor || '#ff0000'};'></div>
                                <div class='bg-white rounded-pill px-2' style='font-size: 11px;'>
                                    <nobr>
                                        ${localUtil.getFlagSvgString(overlappingContestant.country, 15, true) || ''}
                                        <span class='font-weight-light text-muted mx-1'>${overlappingContestant.bib}</span>
                                        ${overlappingContestant.lastname}
                                    </nobr>
                                </div>
                            </div>`,
                        });
                        overlappingMarker.icon = offsetMarker;
                        overlappingMarker.isSpread = true;
                        this.markers.push([point[0], point[1], overlappingMarker]);
                        processedTrackers.add(overlappingMarker.trackerId);
                        const iconMrk = new L.divIcon({
                            iconUrl: markerSvg,
                            iconRetinaUrl: markerSvg,
                            popupAnchor:  [0, -15],
                            iconAnchor: [8,8],
                            iconSize: [15,15],
                            html: `
                            <div
                                class='d-flex flex-row justify-content-start align-items-center'
                            >
                                <div class='rounded-circle' style='width: 17px; height: 17px; background-color: ${currentTracker.bgColor || '#ff0000'}; border: 2px solid #ffffff;'></div>
                            </div>`,
                        });
                        currentTracker.icon = iconMrk;
                        // Draw line
                        // var line = L.polyline([[data.lat, data.lng], [point[0], point[1]]], { color: 'red' });
                        this.lines.push([currentTracker.lat, currentTracker.long, point[0], point[1]]);
                    });
                    this.markers.push([lastPoint[0], lastPoint[1], currentTracker]);
                    processedTrackers.add(currentTracker.trackerId);
                } else {
                    const isNorth = isMarker1FurtherNorth(currentTracker, closeMarkers[0]);
                    let rotateDeg = getRotationDegree(tIdx);

                    // console.log(currentTracker);
                    let iconPosition = 'right';
                    const bearing = currentTracker.bearing || 0;
                    if (bearing > 270) {
                        iconPosition = 'left';
                    } else if (bearing > 0 && bearing < 90) {
                        iconPosition = 'left';
                    }

                    // Calculate time since last GPS point in seconds
                    const timeSinceLastGpsPoint = parseInt((new Date().getTime() - new Date(currentTracker.timestamp).getTime()) / 1000, 10);

                    // Calculate opacity
                    let opacity;
                    if (timeSinceLastGpsPoint <= fadeStartTime) {
                        opacity = 1;
                    } else if (timeSinceLastGpsPoint > maxTime) {
                        opacity = minOpacity;
                    } else {
                        opacity = minOpacity + (1 - minOpacity) * ((maxTime - timeSinceLastGpsPoint) / (maxTime - fadeStartTime));
                    }

                    // Choose icon based on time since last gps point
                    let lastUpdateIcon;
                    if (timeSinceLastGpsPoint > 3600) {
                        lastUpdateIcon = `fa-duotone fa-wifi-exclamation text-danger`;
                    } else if (timeSinceLastGpsPoint > 1800) {
                        lastUpdateIcon = `fa-duotone fa-wifi-exclamation text-warning`;
                    }

                    // Choose type icon based on currentTracker.type
                    let typeIcon;
                    if (currentTracker.type === 'gsm') {
                        typeIcon = `fa-solid fa-phone text-white`;
                    } else if (currentTracker.type === 'sat') {
                        typeIcon = `fa-solid fa-satellite-dish text-white`;
                    }

                    const iconMrk = new L.divIcon({
                        iconUrl: markerSvg,
                        iconRetinaUrl: markerSvg,
                        popupAnchor:  [0, -15],
                        iconAnchor: iconPosition === 'right' ? iconAnchorRight : iconAnchorLeft,
                        iconSize,
                        html: iconPosition === 'right' ? `
                            <div
                                class='d-flex flex-row justify-content-start align-items-center w-100'
                                style='${closeMarkers && closeMarkers.length > 0 ? `transform-origin: left bottom; transform: rotate(${isNorth ? -rotateDeg : rotateDeg}deg);` : ''} opacity: ${opacity};'
                            >
                                <div
                                    class='d-flex align-items-center justify-content-center overflow-hidden rounded-circle mr-1'
                                    style='width: 15px !important; min-width: 15px; height: 15px; background-color: ${currentTracker.bgColor || '#ff0000'}; border: 2px solid #ffffff; font-size: 7px;'
                                >
                                    ${typeIcon ? `<i class='${typeIcon}'></i>` : ''}
                                </div>
                                <div class='bg-white rounded-pill px-2 border' style='font-size: 11px;'>
                                    <div class='d-flex flex-row align-items-center'>
                                        <div class='d-flex flex-column justify-content-center align-items-center mr-1'>
                                            ${currentTracker.bearing || lastUpdateIcon ? `<small class='font-weight-light' style='line-height: ${lineHeight};'>
                                                <i
                                                    class='${lastUpdateIcon ? lastUpdateIcon : 'fa-duotone fa-arrow-up'}'
                                                    style='${!lastUpdateIcon ? `transform: rotate(${currentTracker.bearing}deg);` : ''}'
                                                ></i>
                                            </small>` : ''}
                                            ${currentTracker.avgSpeed > 0.5 && !lastUpdateIcon ? `<small class='font-weight-light text-nowrap'  style='line-height: ${lineHeight};'>${currentTracker.avgSpeed.toFixed(0)} km/t</small>` : ''}
                                        </div>
                                        <div class='d-flex flex-row text-truncate align-items-center overflow-hidden ml-1' style='max-width: ${iconLabelWidth}px;'>
                                            ${currentContestant.country ? localUtil.getFlagSvgString(currentContestant.country, 15, true) || '' : ''}
                                            <span class='font-weight-light text-muted mx-1'>${currentContestant.bib}</span>
                                            ${currentContestant.lastname}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ` : `
                            <div
                                class='d-flex flex-row justify-content-end align-items-center w-100'
                                style='${closeMarkers && closeMarkers.length > 0 ? `transform-origin: right bottom; transform: rotate(${isNorth ? -rotateDeg : rotateDeg}deg);` : ''} opacity: ${opacity};'
                            >
                                <div class='bg-white rounded-pill px-2 border' style='font-size: 11px;'>
                                    <div class='d-flex flex-row align-items-center'>
                                        <div class='d-flex flex-row text-truncate align-items-center overflow-hidden mr-1' style='max-width: ${iconLabelWidth}px;'>
                                            <span class='font-weight-light text-muted mr-1'>${currentContestant.bib}</span>
                                            <span class='mr-1'>${currentContestant.lastname}</span>
                                            ${currentContestant.country ? localUtil.getFlagSvgString(currentContestant.country, 15, true) || '' : ''}
                                        </div>
                                        <div class='d-flex flex-column justify-content-center align-items-center ml-1'>
                                            ${currentTracker.bearing || lastUpdateIcon ? `<small class='font-weight-light' style='line-height: ${lineHeight};'>
                                                <i
                                                    class='${lastUpdateIcon ? lastUpdateIcon : 'fa-duotone fa-arrow-up'}'
                                                    style='${!lastUpdateIcon ? `transform: rotate(${currentTracker.bearing}deg);` : ''}'
                                                ></i>
                                            </small>` : ''}
                                            ${currentTracker.avgSpeed > 0.5 && !lastUpdateIcon ? `<small class='font-weight-light text-nowrap' style='line-height: ${lineHeight};'>${currentTracker.avgSpeed.toFixed(0)} km/t</small>` : ''}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class='d-flex align-items-center justify-content-center overflow-hidden rounded-circle ml-1'
                                    style='width: 15px !important; min-width: 15px; height: 15px; background-color: ${currentTracker.bgColor || '#ff0000'}; border: 2px solid #ffffff; font-size: 7px;'
                                >
                                    ${typeIcon ? `<i class='${typeIcon}'></i>` : ''}
                                </div>
                            </div>

                        `,
                    });
                    currentTracker.icon = iconMrk;
                    // Get last point from each tracker
                    // const lastPoint = tracker.points[tracker.points.length - 1];
                    // const lastPoint = tracker.point;
                    // this.markers.push([lastPoint[1], lastPoint[0], tracker]);
                    this.markers.push([lastPoint[0], lastPoint[1], currentTracker]);
                    processedTrackers.add(currentTracker.trackerId);
                }
            });
            // console.log({ processedTrackers });
        }
    }

    async getGpsPoints({ race, raceClass, cutoffDate = '' }) {
        const response = await util.fetchApi(`/api/gpspoints/group?race=${race}&raceClass=${raceClass}&cutoffDate=${cutoffDate}`, {  method: 'GET' }, { });
        switch (response.status) {
            case 200:
                this.updateKeyValue('trackers', response.data);
                this.setBounds();
                this.setMarkers();
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    async getGpsDeviceStatus({ cutoffDate = '' }) {
        const response = await util.fetchApi(`/api/gpspoints/status?cutoffDate=${cutoffDate}`, {  method: 'GET' }, { });
        switch (response.status) {
            case 200:
                this.updateKeyValue('allGpsDevices', response.data);
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    async getYrWeather({ lat, lon, altitude = 500 }) {
        if (lat && lon) {
            const result = await util.fetchApi('/api/yr/public', { publish: true, method: 'GET' }, { lat, lon, altitude });
            return result;
        }
        return null;
    }
}

const store = new GpsPointStore();
export default store;
