import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';
import GuiListLarge from '../../components/gui/listLarge';

const MARKDOWN_OPTIONS = {
    pedantic: false,
    gfm: true,
    breaks: true,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: true,
highlight: function(code, lang) {
    const hljs = require('highlight.js');
    const language = hljs.getLanguage(lang) ? lang : 'plaintext';
    return hljs.highlight(code, { language }).value;
  },
langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    titleText: <Text id='news.title'>News</Text>,
}))
@observer
class NewsList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            offset: 0,
            localList: [],
        };
    }

    viewNews = e => {
        const { view, showHeader, tag = 'frontpage' } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewNews', { id });
        appState.openDrawer('viewNews', {
            id,
            tag,
            height: drawerHeightMedium,
            // callback: () => {
            //     appState.toggleDrawer();
            //     // this.loadAll();
            // },
        }, drawerLevel + 1);
    }

    viewTags = obj => {
        if (obj.tags?.length === 0 && obj.loans?.length === 0 && !obj.ownedByOther && !obj.url && !obj.parents?.length > 0 && !obj.files?.length > 0) {
            return '';
        }
        const { userStore } = this.props.stores;
        const { user = {} } = userStore;
        const darkmode = util.getNestedValue(user, 'settings.darkmode');
        const tagClass = darkmode ? 'dark' : 'light';

        return (<>
            <div class='w-100 overflow-auto'>
                <div class='box-line-tags d-flex flex-nowrap'>
                    {obj.ownedByOther && <>
                        <span class={`badge badge-pill badge-danger mr-1 mt-1`}>
                            <span class='font-weight-lighter'>Owner:</span> {obj.ownerFirstname} {obj.ownerLastname}
                        </span>
                    </>}
                    {obj.parents?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-code-branch' />
                        </span>
                    </>}
                    {obj.url && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-link' />
                        </span>
                    </>}
                    {/* {obj.files?.length > 0 && <>
                        <span class={`badge badge-pill badge-light mr-1 mt-1`}>
                            <i class='fa-duotone fa-file' /> <span class='font-weight-lighter'> x {obj.files.length}</span>
                        </span>
                    </>} */}
                    {obj.tags?.length > 0 && obj.tags.slice(0,3).map(tag => {
                        return (<>
                            <span
                                class={`badge badge-pill badge-${tagClass} mr-1 mt-1`}
                                onClick={this.toggleTagFilter}
                                data-tag={tag.name}
                            >#{tag.name}</span>
                        </>);
                    })}
                    {obj.loans?.length > 0 && obj.loans.map(loan => {
                        if (loan.endDate) {
                            return '';
                        }
                        const endDate = loan.endDate ? new Date(loan.endDate) : new Date();
                        const {
                            seconds,
                            minutes,
                            hours,
                            days,
                            weeks,
                            months,
                            years,
                        } = util.dateDiff(loan.startDate, endDate);
                        // console.log('viewTags', obj.startDate, { obj, seconds, minutes, hours, days, weeks, months, years })

                        return (<>
                            <span class={`badge badge-pill badge-${loan.endDate ? 'success' : 'warning'} mr-1 mt-1`}>
                                Loaned out <span class='font-weight-lighter'>({days > 0 ? <>{days}d</> : <>{hours}h</>})</span>
                            </span>

                            {/* <div class='tag'>{JSON.stringify(loan)}</div> */}
                        </>);
                    })}
                </div>
            </div>
        </>);
    }

    onClickMenu = e => {
        const { id } = e.target.closest('.list-menu').dataset;
        const idInt = parseInt(id, 10);
        const { newsStore } = this.props.stores;
        const { newsList } = newsStore;
        const news = newsList.find(n => n.id === idInt);

        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightSmall } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('newsMenu', {
            id,
            height: drawerHeightSmall,
            news,
            // callback: () => {
            //     appState.toggleDrawer();
            //     // this.loadAll();
            // },
        }, drawerLevel + 1);
    }

    loadAll = async (props = this.props, append, emptyFirst = true) => {
        // console.log('news.list.loadAll', { props, append, emptyFirst });
        const { limit, offset } = this.state;
        const { raceId, tag, skipUpdate, limit: propsLimit } = props;
        const { newsStore, raceStore, userStore } = this.props.stores;
        const { isAdmin } = userStore;
        const { isRaceAdmin, isMediaWorker } = raceStore;
        const isWorkingWithMedia = isAdmin || isRaceAdmin || isMediaWorker;

        if (emptyFirst && !skipUpdate) {
            newsStore.updateKeyValue('newsList', {});
        }
        if (raceId || tag) {
            newsStore.deleteReloadTimer();
            const localList = await newsStore.load({
                query: { race: raceId, tag },
                url: isWorkingWithMedia ? null : '/api/news/public/',
                limit: propsLimit || limit,
                offset,
                skipUpdate: skipUpdate,
            }, append);
    // console.log('loadAll', { raceId, tag, skipUpdate, limit, propsLimit, offset, localList })
            if (localList && localList.length > 0) {
                this.setState({ localList });
                // newsStore.updateKeyValue('newsListAlt', localList);
            }

            if (!skipUpdate && !append && offset === 0) {
                newsStore.setReloadTimer(setTimeout(() => {
                    this.loadAll(props, false, false);
                }, 1000 * 60));
            } else {
                newsStore.deleteReloadTimer();
            }
        }
    }

    setLoadMore = () => {
        const { newsStore } = this.props.stores;
        const { skipUpdate } = this.props;
        if (skipUpdate) {
            newsStore.addRefreshPageFunction(() => {
                this.loadAll(this.props, false, false);
            }, this.props.tag);
            return false;
        }
        newsStore.setLoadMore(() => {
            // console.log('load more');
            newsStore.deleteReloadTimer();
            let { limit, offset } = this.state;
            offset += limit;
            this.setState({ offset }, () => {
                this.loadAll(this.props, true, false);
            });
        });

        newsStore.setRefreshPage(() => {
            // console.log('refresh page');
            newsStore.deleteReloadTimer();
            let { limit, offset } = this.state;
            offset = 0;
            this.setState({ offset }, () => {
                this.loadAll(this.props, false, false);
            });
        });
    }

    unsetLoadMore = () => {
        const { skipUpdate } = this.props;
        if (skipUpdate) {
            return false;
        }
        const { newsStore } = this.props.stores;
        newsStore.setLoadMore(() => {});
        newsStore.setRefreshPage(() => {});
    }

    onClickNewNews = e => {
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { tag } = e.target.closest('button').dataset;

		appState.openDrawer('newsNew', {
			height: drawerHeightMediumLarge,
            isNew: true,
            tag,
            callback: () => {
                this.loadAll(this.props, false, false);
            },
		}, drawerLevel + 2);
	}

    onClickViewAllNews = e => {
        const { drawerLevel = 1 } = this.props;
		const { appState, contactsStore } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
        const { tag } = e.target.closest('button').dataset;

		appState.openDrawer('adminListNews', {
			height: drawerHeightMediumLarge,
            skipUpdate: true,
            tag,
            callback: () => {
                this.loadAll(this.props, false, false);
            },
		}, drawerLevel + 2);
	}

    componentDidMount() {
        this.loadAll();
        this.setLoadMore();
    }

    componentWillUnmount() {
        const { newsStore } = this.props.stores;
        newsStore.deleteReloadTimer();
        this.unsetLoadMore();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.raceId !== this.props.raceId) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { appState, userStore, newsStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { newsList } = newsStore;
        const { titleText, showHeader, skipUpdate, key, keyName } = this.props;
        const {
            classNames = '',
            hideEmpty,
            title = titleText,
            editElement,
            help,
            children,
            largeList,
            newsList: list,
            raceId,
            tag = 'frontpage',
        } = this.props;

        const { localList } = this.state;
        const finalList = skipUpdate ? localList : list || newsList;

        if (largeList) {
            return (<>
                <GuiListLarge
                    key={`news-list-large-${tag}-${raceId}-${keyName || 'default'}`}
                    classNames={classNames}
                    stores={this.props.stores}
                    holdingStore={newsStore}
                    view={this.viewNews}
                    title={showHeader ? title : undefined}
                    showPadding={showHeader}
                    list={finalList}
                    elTitle={e => <>
                        <h5 class='mb-0 mt-2 py-0'>{e.title}</h5>
                        <div class='font-weight-lighter mt-0'>
                            <small>
                                {util.formatDate(e.date, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                            </small>
                        </div>
                    </>}
                    elDescription={e => <>
                        {e.ingress && <div class='font-weight-bold mt-2'><Markdown markdown={e.ingress} markedOpts={MARKDOWN_OPTIONS} /></div>}
                        {e.body && <div class='font-weight-normal mt-3 mb-2'><Markdown markdown={e.body} markedOpts={MARKDOWN_OPTIONS} /></div>}
                        {e.files && e.files.length > 0 && <>
                            <div class='d-flex mt-3'>
                                {e.files.map(file => {
                                    return (<>
                                        <a href={file.s3Link} class='badge badge-pill badge-light border mr-2' target='_blank' rel='noopener noreferrer'>{file.name} <span class='font-weight-lighter'>({localUtil.formatFileSize(file.size)})</span></a>
                                    </>);
                                })}
                            </div>
                        </>}
                    </>}
                    elInfo1={e => ''}
                    elInfo2={e => ''}
                    elInfo3={e => ''}
                    hideEmpty={hideEmpty}
                    editElement={editElement}
                    help={help || children}
                    showFilterBox={false}
                    maxHeight={'60vh'}
                    showMenu={this.onClickMenu}
                    viewTags={this.viewTags}
                    loadMore={newsStore.loadMore}
                >
                    {isAdmin && <>
                        <div class='position-absolute' style='top: 10px; right: 10px; z-index: 1000;'>
                            <button
                                type='button'
                                class='btn btn-sm btn-primary rounded-pill mr-1'
                                data-tag={tag}
                                onClick={this.onClickViewAllNews}
                            >
                                {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                                <i class='fa-solid fa-list' />
                            </button>
                            <button
                                type='button'
                                class='btn btn-sm btn-primary rounded-pill'
                                data-tag={tag}
                                onClick={this.onClickNewNews}
                            >
                                {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                                <i class='fa-solid fa-plus' />
                            </button>
                        </div>
                    </>}
                </GuiListLarge>
            </>);
        }

        return (<>
            <GuiList
                key={`news-list-${tag}-${raceId}-${keyName || 'default'}`}
                classNames={classNames}
                stores={this.props.stores}
                holdingStore={newsStore}
                view={this.viewNews}
                title={showHeader ? title : undefined}
                list={finalList}
                elTitle={e => e.title}
                elInfo1={e => util.isoDate(e.publishDate || e.date)}
                elInfo2={e => ''}
                elInfo3={e => ''}
                hideEmpty={hideEmpty}
                editElement={editElement}
                help={help || children}
                showFilterBox={false}
                maxHeight={'90vh'}
                viewTags={this.viewTags}
            >
                {isAdmin ? <>
                    <div class='position-absolute' style='top: 10px; right: 10px; z-index: 1000;'>
                        <button
                            type='button'
                            class='btn btn-sm btn-primary rounded-pill mr-1'
                            data-tag={tag}
                            onClick={this.onClickViewAllNews}
                        >
                            {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                            <i class='fa-solid fa-list' />
                        </button>
                        <button
                            type='button'
                            class='btn btn-sm btn-primary rounded-pill'
                            data-tag={tag}
                            onClick={this.onClickNewNews}
                        >
                            {/* <i class='fa-solid fa-list text-muted' /> <Text id='dyrejournal.product.all'>All Products</Text> */}
                            <i class='fa-solid fa-plus' />
                        </button>
                    </div>
                </> : <></>}
            </GuiList>
        </>);

    }
}

export default NewsList;
