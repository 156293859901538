import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

@observer
class ViewImage extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            image,
        } = this.props;
        const { userStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 45px; padding-bottom: 100px;'>
                    <img src={image} class='img-fluid' />
                </div>
            </div>
        );
    }
}

export default ViewImage;
