import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import HelpText from '../../components/gui/helpText';
import FileList from '../../components/fileViewer/list';
import Input from '../../components/form/input';

import localUtil from '../../lib/util';

const MARKDOWN_OPTIONS = {
	pedantic: false,
	gfm: true,
	breaks: true,
	sanitize: false,
	smartLists: true,
	smartypants: true,
	xhtml: true,
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
    langPrefix: 'hljs language-', // highlight.js css expects a top-level 'hljs' class.
};

function textTemplateImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3LargeLink}`;
    return imgSrc;
}

@observer
class TextTemplateView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { id } = props;
        const { textTemplateStore } = this.props.stores;
        textTemplateStore.updateKeyValue('textTemplate', {});
        await textTemplateStore.load(id, false, { addData: ['tags'] });
    }

    // editTextTemplate = () => {
	// 	const { id, drawerLevel } = this.props;
	// 	const { appState } = this.props.stores;
	// 	const { drawerHeightMedium } = appState;
	// 	appState.openDrawer('editTextTemplate', {
	// 		height: drawerHeightMedium,
	// 		id,
	// 	}, drawerLevel + 1);
	// }

    addTag = e => {
        const { tagInput = '' } = this.state;
        const { textTemplateStore } = this.props.stores;
        const value = {
            name: tagInput.toLowerCase().trim(),
            date: new Date(),
        };
        value.md5 = md5(JSON.stringify(value)).toString();

        const field = 'tags';
        const object = textTemplateStore.textTemplate;
        const tagExists = object[field] && object[field].find(tag => tag.name === value.name);
        if (!tagExists) {
            const localValue = object[field] ? [...object[field]] : [];
            textTemplateStore.saveField(object.id, field, value);
            textTemplateStore.updateField(object.id, field, [...localValue, value]);
        }
        this.setState({ tagInput: '' });
    }

    checkForEnter = (e) => {
        if (e.key === 'Enter') {
            this.addTag();
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
        } = this.props;
        const {
            sessionid,
            showMenu,
            tagInput,
        } = this.state;
        const { appState, userStore, textTemplateStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { textTemplate } = textTemplateStore;

        const linkTextClass = `${darkmode ? 'text-white' : 'text-dark'}`;
		const linkTextStyle = `line-height: 24px; font-size: 21px; font-weight: 400;`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class={`d-flex justify-content-start flex-row w-100`}>
                        <div class='flex-fill'>
                            {/* <h5 class='ml-3 mb-3'>{textTemplate.title}</h5> */}
                        </div>
                        {/* <div class='' style='margin-right: 65px;'>
                            <span onClick={this.editTextTemplate} class={`text-primary ${linkTextClass}`} style={`${linkTextStyle}`}>
                                <i class={`fa-regular fa-pen ml-3 mr-1`} />
                            </span>
                        </div> */}
                    </div>

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class='d-flex'>
                                {/* {JSON.stringify(textTemplate)} */}
                                <ImageScroller images={textTemplate.images} stores={this.props.stores} showImg={img => textTemplateImg(img, this.props, '1920x')} showImageInfo={true} />
                            </div>

                            {textTemplate.files?.length > 0 && textTemplate.files.map(file => {
                                console.log({ file });
                                if (file.s3Link.match(/\.(m4v|m4a|mp4)$/i)) {
                                    return (<>
                                        <div className='video-container rounded-lg'>
                                            <video controls className='video-player'>
                                                <source src={file.s3Link} type='video/mp4' />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <div class='video-info d-flex flex-row justify-content-center'>
                                            <span class='text-muted'>size:</span> {localUtil.formatFileSize(file.size)}
                                            <span class='text-muted ml-3 mr-1'>encoding:</span> {file.encoding}
                                            <span class='text-muted ml-3 mr-1'>mimetype:</span> {file.mimetype}
                                            <span class='text-muted ml-3 mr-1'>ext:</span> {file.ext}
                                            <span class='text-muted ml-3 mr-1'>s3Link:</span> <a href={file.s3Link} target='_blank' rel='noopener noreferrer'>download</a>
                                        </div>
                                    </>);
                                }
                            })}


                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3 mt-3`}>
                                {[
                                    'title',
                                    'body',
                                    'whoIsInTheTextTemplate',
                                    'whatIsInTheTextTemplate',
                                    'whatIsTheStory',
                                    'whereIsTheTextTemplateRecorded',
                                    'whenIsTheTextTemplateRecorded',
                                    'preferredUse',
                                    'bib',
                                    'attribution',
                                    'licenseType',
                                    'userAgreement',
                                ].map(key => {
                                    if (util.isUndefined(textTemplate[key])) {
                                        return '';
                                    }
                                    const field = fields.getField('textTemplate', key);
                                    if (!field) {
                                        console.log('Missing field:', key);
                                    }
                                    const displayValue = field.displayValue || (value => value);
                                    return (<>
                                        {(textTemplate[key] || textTemplate[key] === false) && <div class='d-flex flex-row'>
                                            <span class='text-muted font-weight-light'>
                                                {field.title}
                                            </span>
                                            {field.type === 'textarea' ? <>
                                                <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                    <Markdown markdown={displayValue(textTemplate[key])} markedOpts={MARKDOWN_OPTIONS} />
                                                </div>
                                            </> : <>
                                                <span class='ml-2'>
                                                    {field.type === 'toggle' ? <>
                                                        {textTemplate[key] ? <>
                                                            <i class='fa-solid fa-check text-success' />
                                                        </> : <>
                                                            <i class='fa-solid fa-times text-danger' />
                                                        </>}
                                                    </> : <>
                                                        {displayValue(textTemplate[key])}
                                                    </>}
                                                </span>
                                            </>}
                                        </div>}
                                    </>);
                                })}
                            </div>

                            <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                {textTemplate.files?.length > 0 && <div class='d-flex flex-column'>
                                    <span class='font-weight-lighter text-muted'>
                                        <Text id='textTemplate.files'>Files</Text>
                                    </span>
                                    <div class={`d-flex flex-column`}>
                                        <FileList files={textTemplate.files} stores={this.props.stores} />
                                    </div>
                                </div>}

                                <Input
                                    stores={this.props.stores}
                                    holdingStore={textTemplateStore}
                                    field='tags'
                                    object={textTemplate}
                                    objectName='textTemplate'
                                    type='tags'
                                    lookupArray={'tags'}
                                />

                            </div>
                            <HelpText>
                                <Text id='textTemplate.tag-help'>Add tags to this textTemplate to make it easier to find and organize. Tip: Use general tags like: photo, fishing, car, boat...</Text>
                            </HelpText>

                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3 mt-3`}>
                                {[
                                    'publishedToWeb',
                                    'publishedToFacebook',
                                    'publishedToInstagram',
                                    'publishedToOther',
                                    'done',
                                ].map(key => {
                                    const field = fields.getField('textTemplate', key);
                                    if (!field) {
                                        console.log('Missing field:', key);
                                    }
                                    const displayValue = field.displayValue || (value => value);
                                    return (<>
                                        {textTemplate[key] > 0 && <div class='d-flex flex-row'>
                                            <span class='text-muted font-weight-light'>
                                                {key}
                                            </span>
                                            {field.type === 'textarea' ? <>
                                                <div class='ml-2  mb-4 text-wrap' style='max-height: 20vh; overflow: auto;'>
                                                    <Markdown markdown={displayValue(textTemplate[key])} markedOpts={MARKDOWN_OPTIONS} />
                                                </div>
                                            </> : <>
                                                <span class='ml-2'>
                                                    {displayValue(textTemplate[key])}
                                                </span>
                                            </>}
                                        </div>}
                                    </>);
                                })}
                            </div>

                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default TextTemplateView;
