import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import Tooltip from '../../components/gui/tooltip';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalReportPrint from '../../components/dyrejournal/reportPrint';
import DyrejournalJournals from '../../components/dyrejournal/journals';

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const MONTH_NAMES = [
    'jan', 'feb', 'mar', 'apr', 'mai', 'jun',
    'jul', 'aug', 'sep', 'okt', 'nov', 'des',
];

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

const ACCOUNT_MAP = {
    '1200': 'Aktiverte kostn. gruppe d',
    '1201': 'Aktiverte kostn. gruppe a',
    '1202': 'Aktiverte kostn. leide lokaler',
    '1460': 'Lagerbeholdning salgsvarer',
    '1480': 'Forskudd til leverandører',
    '1510': 'Kunder',
    '1511': 'Avsetning for kundetap',
    '1525': 'Annen kundefordring',
    '1530': 'Annen kundefordring2',
    '1610': 'Fordring forsikring Agria',
    '1612': 'Fordring forsikring Codan',
    '1614': 'Fordring forsikring DnB NOR',
    '1616': 'Fordring forsikring Enter',
    '1618': 'Fordring forsikring Gjensidige',
    '1620': 'Fordring forsikring IF',
    '1622': 'Fordring forsikring Jernbanepersonalets',
    '1623': 'Fordring forsikring Nemi',
    '1624': 'Fordring forsikring Sparebank1',
    '1626': 'Fordring forsikring Storebrand',
    '1628': 'Fordring forsikring Tennant',
    '1630': 'Fordring forsikring Terra',
    '1632': 'Fordring forsikring Tryg Vesta',
    '1634': 'Fordring forsikring Frende',
    '1636': 'Fordring forsikring - annet selskap',
    '1910': 'Kasse',
    '1913': 'Bank kort (fordring)',
    '1915': 'Visa (fordring)',
    '1916': 'Eurocard (fordring)',
    '1917': 'Diners (fordring)',
    '1925': 'Fording på inkassoselskap kundeinnbetalinger',
    '1935': 'Bank plasseringskonto',
    '1939': 'Bank folio',
    '1940': 'Bank skattetrekk',
    '2000': 'Aksjekapital',
    '2020': 'Overkursfond',
    '2050': 'Egenkapital fri',
    '2114': 'Skyldig konsulent avg.fritt',
    '2410': 'Leverandører',
    '2412': 'Skyldig konsulent',
    '2500': 'Skyldig skatt utsatt',
    '2510': 'Skyldig skatt betalbar',
    '2610': 'Skyldig Skattetrekk',
    '2710': 'Utgående mva',
    '2720': 'Inngående mva',
    '2730': 'Investeringsavgift',
    '2750': 'Fylkesskattesjefen',
    '2780': 'Skyldig arbeidsgiveravgift',
    '2781': 'Skyldig arbeidsg.avg feriep',
    '2782': 'Gjeld bank',
    '2800': 'Skyldig utbytte',
    '2910': 'Skyldig lønn, feriepenger etc',
    '2920': 'Skyldig Feriepenger',
    '2930': 'Gjeld til ansatte og aksjonærer',
    '2960': 'Påløpne kostnader',
    '2965': 'Annen kortsiktig gjeld',
    '3001': 'Medisinsk utstyr',
    '3010': 'Poliklinikk',
    '3011': 'Fôrsalg',
    '3012': 'Rekvisitasalg',
    '3015': 'Varesalg avgiftsfritt',
    '3019': 'Ande salgsinntekter',
    '3030': 'Tenner',
    '3032': 'Røntgen/Ultralyd',
    '3034': 'Pelsstell',
    '3036': 'Vaksiner',
    '3038': 'Andre klinikkinntekter',
    '3040': 'Operasjon vanlig',
    '3042': 'Operasjon Spesialist',
    '3044': 'Lab eksternt',
    '3046': 'Lab internt',
    '3069': 'Fysio/kiro/rehab',
    '3620': 'Leieinntekter klinikk',
    '3900': 'Andre inntekter',
    '4006': 'Varekjøp - vaksiner',
    '4008': 'Varekjøp - medisin',
    '4010': 'Varekjøp - utstyr (vet) forbr.',
    '4012': 'Varekjøp - salgsrekvisita',
    '4013': 'Varekjøp - fôr',
    '4019': 'Varekjøp - annet',
    '4090': 'Beholdningsendring varer',
    '4110': 'Varekjøp - avgiftsfritt',
    '4510': 'Andre fremmede tjenester',
    '4511': 'Konsulent',
    '4512': 'Analyser, kremering',
    '5010': 'Lønn',
    '5130': 'Lønn u.feriep.gr.lag',
    '5190': 'Feriepenger',
    '5410': 'Arbeidsgiveravgift',
    '5411': 'Arbeidsgiveravgift på feriepenger',
    '5960': 'Sosiale kostnader',
    '5990': 'Andre godtgjørelser',
    '6010': 'Ordinære avskrivinger',
    '6290': 'Leiekostnader mva-fritt',
    '6300': 'Leiekostnader mva pliktig',
    '6340': 'Strøm',
    '6540': 'Utstyr, inventer (uaktivert)',
    '6700': 'Regnskap, revisjon mv',
    '6730': 'Inkassokostnader',
    '6800': 'Kontorrekvisita, trykksaker mv',
    '6840': 'Aviser og Tidsskrifter',
    '6850': 'Faglitteratur',
    '6860': 'Kurs (inkl. reise og diett)',
    '6900': 'Telefon',
    '6940': 'Porto',
    '7100': 'Bilgodtgjørelse',
    '7140': 'Andre reise og diettkostn.',
    '7320': 'Reklame Div',
    '7322': 'Reklame Telekatalog',
    '7323': 'Reklame Internett',
    '7410': 'Kontingenter og gaver',
    '7500': 'Forsikringer',
    '7600': 'Patentkostnader',
    '7790': 'Andre driftskostnader',
    '7830': 'Tap på fordringer',
    '7832': 'Ettergitt',
    '8050': 'Renteinntekter',
    '8150': 'Renteutgifter',
    '8190': 'Andre finanskostnader',
    '8192': 'Andre ekstraordinære kostnader',
    '8600': 'Skattekostnad betalbar',
    '8620': 'Skattekostnad utsatt',
    '8800': 'Årsoppgjør',
    '8920': 'Utbytte',
    '8931': 'Privatkonto uttak kontanter',
    '8939': 'Privatkonto uttak annet',
    '8950': 'Tilførte midler',
    '3013': 'Medisinering',
    '3050': 'Varer som takst',
    '1920': 'Vipps',
    '1950': 'Ansatt rabatt',
    '1700': 'Kreditt egenandel ansatte (lønn)',
};

function getReportDate(inputDate, reportType, onlyFromDate) {
    const reportDate = new Date(`${inputDate}T00:00:00`);
    // console.log({ inputDate, reportDate})
    // console.log({ reportType })
    const reportDay = reportDate.getDate();
    const reportMonth = reportDate.getMonth();
    const reportYear = reportDate.getFullYear();

    if (reportType === 'day') {
        return `${reportDay}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
    } else if (reportType === 'month') {
        return `${onlyFromDate ? reportDay : 1} - ${util.daysInMonth(reportMonth + 1, reportYear)}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
    } else if (reportType === 'year') {
        return `1.jan - 31.des ${reportYear}`;
    }
    return '';//`${reportDay}.${MONTH_NAMES[reportMonth]} ${reportYear}`;
}


@withText(props => ({
    productTitle: <Text id='dyrejournal.product.search-title'>Search for product</Text>,
}))
@observer
class AllProducts extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal'),
            reportDate: util.isoDate(new Date(), false, false, true),
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { productStore, saleStore, saleReportStore, appState } = this.props.stores;
        // await productStore.load();
        // await saleStore.loadDailyReport();
        // await saleStore.loadMonthlyReport();
        const { view = {} } = appState;
        if (view.report === 'day') {
            await saleStore.loadDailyReport();
        } else if (view.report === 'month') {
            await saleStore.loadMonthlyReport();
        } else if (view.report === 'year') {
            await saleStore.loadYearlyReport();
        }
        this.setupSaleReport();
        saleReportStore.load();
    }

    setupSaleReport = () => {
        const { appState, saleStore } = this.props.stores;
        const { aggSaleTotal, aggSaleTotalDiscount, aggSaleTotalNew, aggSalePersons, aggSalePersonsAccounts, aggSaleTotalAccounts, aggSaleWithJournals } = saleStore;
        const { view = {} } = appState;
        const totalForPeriode = aggSaleTotal?.price;
        const saleReport = {
            reportType: view.report,
            amountCash:       saleStore.getPaymentMethodTotalNew('cash'),
            amountCard:       saleStore.getPaymentMethodTotalNew('card'),
            amountVipps:      saleStore.getPaymentMethodTotalNew('vipps'),
            amountInvoice:    saleStore.getPaymentMethodTotal('invoice'),
            amountAccount:    saleStore.getPaymentMethodTotalNew('account'),
            amountCreditNote: saleStore.getPaymentMethodTotal('creditNote'),
            // amountUnpaid:     saleStore.getPaymentMethodTotal('unpaid'),
            amountTotal: totalForPeriode,
            amountDiscount: aggSaleTotalDiscount?.price,
            // amountTotal: (view.report === 'day' ?
            //     (
            //         saleStore.getPaymentMethodTotalNew('cash') +
            //         saleStore.getPaymentMethodTotalNew('card') +
            //         saleStore.getPaymentMethodTotalNew('vipps') +
            //         saleStore.getPaymentMethodTotalNew('invoice') +
            //         saleStore.getPaymentMethodTotalNew('account')
            //     ) :
            //     (
            //         saleStore.getPaymentMethodTotalNew('cash') +
            //         saleStore.getPaymentMethodTotalNew('card') +
            //         saleStore.getPaymentMethodTotalNew('vipps') +
            //         saleStore.getPaymentMethodTotal('invoice') +
            //         saleStore.getPaymentMethodTotalNew('account') +
            //         saleStore.getPaymentMethodTotalNew('creditNote')
            //     )
            // ),
            amountTax: totalForPeriode / 1.25 * 0.25,
            // amountTax: (view.report === 'day' ?
            //     (
            //         saleStore.getPaymentMethodTotalNew('cash') +
            //         saleStore.getPaymentMethodTotalNew('card') +
            //         saleStore.getPaymentMethodTotalNew('vipps') +
            //         saleStore.getPaymentMethodTotalNew('invoice') +
            //         saleStore.getPaymentMethodTotalNew('account')
            //     ) / 1.25 * 0.25 :
            //     (
            //         saleStore.getPaymentMethodTotalNew('cash') +
            //         saleStore.getPaymentMethodTotalNew('card') +
            //         saleStore.getPaymentMethodTotalNew('vipps') +
            //         saleStore.getPaymentMethodTotalNew('invoice') +
            //         saleStore.getPaymentMethodTotalNew('account') +
            //         saleStore.getPaymentMethodTotalNew('creditNote')
            //     ) / 1.25 * 0.25
            // ),
            salesPersons: aggSalePersons,
            salePersonsAccounts: aggSalePersonsAccounts,
            saleAccounts: aggSaleTotalAccounts,
        };
        this.setState({ saleReport });
    }

    // onClickChooseProduct = e => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     const { id } = e.target.closest('tr').dataset;
    //     const { drawerLevel = 1 } = this.props;
	// 	const { appState, saleStore, productStore } = this.props.stores;
    //     const { newSale } = saleStore;
    //     const { products = [] } = newSale;
    //     const { products: allProducts } = productStore;
    //     const selectedItem = allProducts.find(p => p.id === parseInt(id, 10));
    //     if (selectedItem) {
    //         saleStore.updateObjectKeyValue('newSale', 'products', [...products, selectedItem]);
    //         appState.toggleDrawerRight(false, drawerLevel);
    //     }
    // }

    // onClickEditProduct = e => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     const { id } = e.target.closest('tr').dataset;
    //     const { drawerLevel = 1 } = this.props;
	// 	const { appState, saleStore } = this.props.stores;
	// 	const { drawerHeightMedium } = appState;
	// 	appState.openDrawer('editProduct', {
	// 		height: drawerHeightMedium,
    //         hideAdmin: true,
    //         id,
    //         callback: product => {
    //             appState.toggleDrawer(false, drawerLevel + 1);
    //             if (product) {
    //                 const { newSale, productResults } = saleStore;
    //                 const { products } = newSale;
    //                 saleStore.updateObjectKeyValue('newSale', 'products', [...products, { ...product, qty: 1 }]);
    //                 setTimeout(() => {
    //                     this.loadAll(this.props, false);
    //                 }, 1000);
    //             }
    //         },
	// 	}, drawerLevel + 1);
    // }

    // searchProductCallback = selectedItem => {
    //     const { appState, saleStore, productStore } = this.props.stores;
    //     const { drawerLevel = 1 } = this.props;
    //     const { newSale } = saleStore;
    //     const { products = [] } = newSale;
    //     saleStore.updateObjectKeyValue('newSale', 'products', [...products, selectedItem]);
    //     appState.toggleDrawerRight(false, drawerLevel);
    // }

    onClickPrintReport = async e => {
        const { userStore, saleReportStore } = this.props.stores;
        const { id } = e.target.closest('button').dataset;
        await saleReportStore.load(id);

        // console.log('onClickPrintSale', e);
        const { user } = userStore;
        const { language = 'no' } = user;
        const languageDef = countryMap[language || 'default'];

        // Render Receipt component with data
        const receipt = <IntlProvider definition={languageDef}>
            <DyrejournalReportPrint stores={this.props.stores} id={id} />
        </IntlProvider>;

        // Append to document
        const printDiv = document.createElement('div');
        printDiv.className = 'receipt';
        document.body.appendChild(printDiv);
        render(receipt, printDiv);
        // Trigger print dialog
        window.print();
        // Remove the appended div
        document.body.removeChild(printDiv);
        // Re-render the receipt component to reset it
        render(null, printDiv);
    }

    onClickSaveReport = async e => {
        const { saleReportStore } = this.props.stores;
        const { saleReport, reportDate } = this.state;
        this.setState({ saveReportInserting: true });
        const response = await saleReportStore.insert({
            ...saleReport,
            reportDate: util.isoDate(reportDate),
            calledFrom: `reports.onClickSaveReport`,
        });
        // Update current newSale object
        const newSaleReport = response?.data?.saleReport;
        saleReportStore.updateKeyValue('saleReport', newSaleReport);
        this.setState({ saveReportInserting: false });

        setTimeout(() => {
            saleReportStore.load();
        }, 1000);
    }

    onClickUpdateList = async e => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ saveReportUpdating: true });
        const { saleReportStore } = this.props.stores;
        await saleReportStore.load();
        this.setState({ saveReportUpdating: false });
    }

    onClickDeleteSaleReport = async e => {
        e.preventDefault();
        e.stopPropagation();

        if (!confirm('Are you sure you want to delete this saleReport?')) {
            return;
        }

        const { id } = e.target.closest('td').dataset;
        const { saleReportStore } = this.props.stores;
        await saleReportStore.delete(parseInt(id, 10));
        setTimeout(() => {
            this.loadAll(this.props, false);
        }, 1000);
    }

    changeReportDate = async e => {
        const { onlyFromDate } = this.state;
        const { name } = e.target.dataset;
        const { value } = e.target;
        this.setState({ [name]: value });

        const { saleStore, appState } = this.props.stores;
        const { view = {} } = appState;
        if (view.report === 'day') {
            await saleStore.loadDailyReport(value);
        } else if (view.report === 'month') {
            await saleStore.loadMonthlyReport(value, onlyFromDate);
        } else if (view.report === 'year') {
            await saleStore.loadYearlyReport(value, onlyFromDate);
        }
        this.setupSaleReport();
    }

    toggleOnlyFromDate = async e => {
        const { checked: onlyFromDate } = e.target;
        this.setState({ onlyFromDate });
        const { saleStore, appState } = this.props.stores;
        const { view = {} } = appState;
        const { reportDate } = this.state;
        if (view.report === 'day') {
            await saleStore.loadDailyReport(reportDate);
        } else if (view.report === 'month') {
            await saleStore.loadMonthlyReport(reportDate, onlyFromDate);
        } else if (view.report === 'year') {
            await saleStore.loadYearlyReport(reportDate, onlyFromDate);
        }
        this.setupSaleReport();
    }

    selectView = (e) => {
        const { appState } = this.props.stores;
        const { type } = e.target.closest('button').dataset;
        appState.setViewKey('report', type);
        this.loadAll();
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { reportDate, saleReport = {}, saveReportInserting, saveReportUpdating, onlyFromDate } = this.state;
        const {
            productTitle,
        } = this.props;
        const { userStore, saleStore, saleReportStore, appState } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { view = {} } = appState;

        const noViewSelected = !view.report;

        const {
            totalNumberOfSales,
            aggSaleTotal,
            aggSaleTotalNew,
            aggSaleTotalPaymentMethods,
            aggSaleTotalTax,
            aggSalePersons,
            aggSalePersonsAccounts,
            aggSaleTotalAccounts,
            aggSaleWithJournals,
            aggSaleNewVisitors,
            aggSaleActiveVisitors,
            reportStatus,
        } = saleStore;

        const { saleReports } = saleReportStore;

        let totalForAccounts = 0;
        if (aggSaleTotalAccounts) {
            totalForAccounts = aggSaleTotalAccounts.map(account => account.price).reduce((a, b) => a + b, 0);
        }

        const apiServer = util.getApiServer();
        const jwtToken = util.getJwtToken();

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 0px; padding-bottom: 100px;'>

                    {reportStatus ? <>
                        <div class='alert alert-danger' role='alert'>
                            <Text id='dyrejournal.report.error'>Error: {reportStatus}</Text>
                        </div>
                    </> : <></>}

                    <div class='w-100 d-flex flex-column'>
                        <div class='w-100 d-flex flex-column mt-1'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <div class='d-flex flex-nowrap'>
                                    <button type='button' class={`btn btn-${view.report === 'day' ? 'primary' : 'outline-secondary'} rounded-pill-left`} onClick={this.selectView} data-type={'day'}>
                                        <Text id='dyrejournal.report.daily'>Daily</Text>
                                    </button>
                                    <button type='button' class={`btn btn-${view.report === 'month' ? 'primary' : 'outline-secondary'} rounded-none`} onClick={this.selectView} data-type={'month'}>
                                        <Text id='dyrejournal.report.monthly'>Monthly</Text>
                                    </button>
                                    <button type='button' class={`btn btn-${view.report === 'year' ? 'primary' : 'outline-secondary'} rounded-pill-right`} onClick={this.selectView} data-type={'year'}>
                                        <Text id='dyrejournal.report.yearly'>Yearly</Text>
                                    </button>
                                </div>

                                <div class='d-flex flex-column'>
                                    <div>
                                        <input
                                            class='form-control rounded-pill'
                                            type='date'
                                            onInput={this.changeReportDate}
                                            value={reportDate}
                                            data-name={'reportDate'}
                                        />
                                    </div>
                                </div>
                            </div>

                            {(view.report === 'month' || view.report === 'year') && <>
                                <div class='d-flex flex-row justify-content-end px-3'>
                                    <div class='form-group form-check'>
                                        <input type='checkbox' class='form-check-input' id='fromDate' onInput={this.toggleOnlyFromDate} />
                                        <label class='form-check-label' for='fromDate'>Kun fra valgt dato</label>
                                    </div>
                                </div>
                            </>}


                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                {noViewSelected && <>
                                    <div class='alert alert-danger' role='alert'>
                                        <Text id='dyrejournal.report.no-view-selected'>No view selected. Please select daily, monthly or yearly above.</Text>
                                    </div>
                                </>}

                                {view.report === 'day' && <>
                                    <div class='table-responsive'>
                                        <table class='table table-sm table-hover table-striped' style='font-size: 0.9em; line-height: 1.0em;'>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.cashregister'>Cashregister</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.cashregister-help'>Cashregister is all actual payments with payment method Cash.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.bankcard'>Bankcard</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.bankcard-help'>Card is all actual payments with payment method Card.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.vipps'>Vipps</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.vipps-help'>Vipps is all actual payments with payment method Vipps.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.invoice'>Invoice</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.invoice-help'>Invoice is all actual payments with payment method Invoice.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    {/* <th style='text-align: right;'><Text id='dyrejournal.report.invoice'>Invoice</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.account'>Account</Text></th> */}
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.creditNote'>creditNote</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.creditNote-help'>Creditnote is a negative sum canceling out a previous sale.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    {/* <th style='text-align: right;'><Text id='dyrejournal.report.unpaid'>unpaid</Text></th> */}
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.turnover'>turnover</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />} left={-150} width={230}>
                                                            <Text id='dyrejournal.report.turnover-help'>Turnover is total sale for this periode. Payment is not taken into account. This is your total for this periode.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.discount'>Discount</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />} left={-150} width={230}>
                                                            <Text id='dyrejournal.report.discount-help'>Discount is the total discount including tax for this periode. Payment is not taken into account. This is your total discount for this periode.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.tax'>Tax inc</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />} left={-180} width={200}>
                                                            <Text id='dyrejournal.report.tax-help'>Tax included in the total sale.</Text>
                                                        </Tooltip>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.outgoing-last-report'>Outgoing last report</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    {/* <td style='text-align: right;'>0</td> */}
                                                    {/* <td style='text-align: right;'>0</td> */}
                                                    <td style='text-align: right;'>0</td>
                                                    {/* <td style='text-align: right;'>0</td> */}
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'>0</td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>+ <Text id='dyrejournal.report.current-report'>Today</Text></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountCash, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountCard, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountVipps, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountInvoice, 2)}</nobr></td>
                                                    {/* <td style='text-align: right;'><nobr>({util.format(saleReport.amountInvoice, 2)})</nobr></td> */}
                                                    {/* <td style='text-align: right;'><nobr>{util.format(saleReport.amountAccount, 2)}</nobr></td> */}
                                                    <td style='text-align: right;'><nobr>{(util.format(saleReport.amountCreditNote, 2))}</nobr></td>
                                                    {/* <td style='text-align: right;'><nobr>({util.format(saleReport.amountUnpaid, 2)})</nobr></td> */}
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountTotal, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountDiscount, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountTax, 2)}</nobr></td>
                                                </tr>
                                                <tr style='border-top: 1px solid #808080;'>
                                                    <td style='text-align: left;'>= <Text id='dyrejournal.report.outgoing-inventory'>Outgoing inventory</Text></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountCash, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountCard, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountVipps, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountInvoice, 2)}</nobr></td>
                                                    {/* <td style='text-align: right;'><nobr>({util.format(saleReport.amountInvoice, 2)})</nobr></td> */}
                                                    {/* <td style='text-align: right;'><nobr>{util.format(saleReport.amountAccount, 2)}</nobr></td> */}
                                                    <td style='text-align: right;'><nobr>{(util.format(saleReport.amountCreditNote, 2))}</nobr></td>
                                                    {/* <td style='text-align: right;'><nobr>({util.format(saleReport.amountUnpaid, 2)})</nobr></td> */}
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountTotal, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountDiscount, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountTax, 2)}</nobr></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>+ <Text id='dyrejournal.report.added-change'>Added change</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>- <Text id='dyrejournal.report.bank-withdrawal'>Bank withdrawal</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'>= <Text id='dyrejournal.report.outgoing-cash'>Outgoing cash</Text></td>
                                                    <td style='text-align: right;'>0</td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.counted-cash'>Counted cash</Text></td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.countedCash')} />
                                                    </td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.deviation'>Deviation</Text></td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationCash')} />
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationCard')} />
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationVipps')} />
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationInvoice')} />
                                                    </td> */}
                                                    {/* <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationAccount')} />
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationCreditNote')} />
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationUnpaid')} />
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationTotal')} />
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationDiscount')} />
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='number' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationTax')} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.comment'>Deviation Comment</Text></td>
                                                    <td style='text-align: left;' colspan='7'>
                                                        <textarea class='form-control rounded-pill' placeholder='Comment' onInput={linkstate(this, 'saleReport.comment')} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>}

                                {(view.report === 'month' || view.report === 'year') && <>
                                    <div class='table-responsive'>
                                        <table class='table table-sm table-hover table-striped' style='font-size: 0.9em; line-height: 1.0em;'>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.cashregister'>Cashregister</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.cashregister-help'>Cashregister is all actual payments with payment method Cash.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.bankcard'>Bankcard</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.bankcard-help'>Card is all actual payments with payment method Card.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.vipps'>Vipps</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.vipps-help'>Vipps is all actual payments with payment method Vipps.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.invoice'>Invoice</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.invoice-help'>Invoice is all actual payments with payment method Invoice.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    {/* <th style='text-align: right;'><Text id='dyrejournal.report.invoice'>Invoice</Text></th>
                                                    <th style='text-align: right;'><Text id='dyrejournal.report.account'>Account</Text></th> */}
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.creditNote'>creditNote</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />}>
                                                            <Text id='dyrejournal.report.creditNote-help'>Creditnote is a negative sum canceling out a previous sale.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    {/* <th style='text-align: right;'><Text id='dyrejournal.report.unpaid'>unpaid</Text></th> */}
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.turnover'>turnover</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />} left={-150} width={230}>
                                                            <Text id='dyrejournal.report.turnover-help'>Turnover is total sale for this periode. Payment is not taken into account. This is your total for this periode.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.discount'>Discount</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />} left={-150} width={230}>
                                                            <Text id='dyrejournal.report.discount-help'>Discount is the total discount including tax for this periode. Payment is not taken into account. This is your total discount for this periode.</Text>
                                                        </Tooltip>
                                                    </th>
                                                    <th style='text-align: right;'>
                                                        <Text id='dyrejournal.report.tax'>Tax inc</Text>
                                                        <Tooltip text={<i class='fa-duotone fa-circle-info ml-2' />} left={-180} width={200}>
                                                            <Text id='dyrejournal.report.tax-help'>Tax included in the total sale.</Text>
                                                        </Tooltip>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><Text id='dyrejournal.report.totalsales'>Total sales</Text></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountCash, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountCard, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountVipps, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountInvoice, 2)}</nobr></td>
                                                    {/* <td style='text-align: right;'><nobr>{util.format(saleReport.amountInvoice, 2)}</nobr></td> */}
                                                    {/* <td style='text-align: right;'><nobr>{util.format(saleReport.amountAccount, 2)}</nobr></td> */}
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountCreditNote, 2)}</nobr></td>
                                                    {/* <td style='text-align: right;'><nobr>({util.format(saleReport.amountUnpaid, 2)})</nobr></td> */}
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountTotal, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountDiscount, 2)}</nobr></td>
                                                    <td style='text-align: right;'><nobr>{util.format(saleReport.amountTax, 2)}</nobr></td>

                                                    {/* <td style='text-align: right;'><nobr>{util.format(aggSaleTotalNew.price + saleStore.getPaymentMethodTotal('unpaid') + saleStore.getPaymentMethodTotal('invoice') || 0, 2)}</nobr></td> */}
                                                    {/* <td style='text-align: right;'><nobr>{util.format(aggSaleTotal.price * (1 + aggSaleTotal.vat / 100), 2)}</nobr></td> */}
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.counted-cash'>Counted cash</Text></td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.countedCash')} />
                                                    </td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    {/* <td style='text-align: right;'></td> */}
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                    <td style='text-align: right;'></td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.deviation'>Deviation</Text></td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationCash')} />
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationCard')} />
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationVipps')} />
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationInvoice')} />
                                                    </td> */}
                                                    {/* <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationAccount')} />
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationCreditNote')} />
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationUnpaid')} />
                                                    </td> */}
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationTotal')} />
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationDiscount')} />
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <input size='4' type='text' class='form-control rounded-pill text-right' placeholder='Beløp' onInput={linkstate(this, 'saleReport.deviationTax')} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style='text-align: left;'><Text id='dyrejournal.report.comment'>Deviation Comment</Text></td>
                                                    <td style='text-align: left;' colspan='7'>
                                                        <textarea class='form-control rounded-pill' placeholder='Comment' onInput={linkstate(this, 'saleReport.comment')} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </>}

                                <div class='w-100 d-flex flex-row justify-content-center'>
                                    {/* <button type='button' class='btn btn-primary rounded-pill mx-2' onClick={this.onClickPrintReport}>
                                        <i class='fa-solid fa-print' /> <Text id='dyrejournal.report.print'>Print report</Text>
                                    </button> */}
                                    <button
                                        type='button'
                                        class={`btn btn-danger rounded-pill mx-2`}
                                        onClick={this.onClickSaveReport}
                                        disabled={saveReportInserting}
                                    >
                                        <i class='fa-solid fa-floppy-disk' /> <Text id='dyrejournal.report.create-salereport'>Create sale report</Text> - {getReportDate(reportDate, view.report, onlyFromDate)}
                                        {saveReportInserting && <i class='fa-solid fa-spinner-third fa-spin' />}
                                    </button>
                                </div>

{/* <xmp>{JSON.stringify(saleReport, null, 4)}</xmp> */}
{/* <xmp>{JSON.stringify(saleReports, null, 4)}</xmp> */}

                                {/* <div class='table-responsive w-50'>
                                    <table class='table table-sm table-hover table-striped'>
                                        <tbody>
                                            <tr>
                                                <th><Text id='dyrejournal.report.total-sales'>Total sales</Text></th>
                                                <td style='text-align: right;'>{totalNumberOfSales}</td>
                                            </tr>
                                            <tr>
                                                <th><Text id='dyrejournal.report.total-amount-wo-tax'>Total amount wo/tax</Text></th>
                                                <td style='text-align: right;'>{util.format(saleStore.lineTotal(aggSaleTotal, true, 1), 2)}</td>
                                            </tr>
                                            <tr>
                                                <th><Text id='dyrejournal.report.total-tax'>Total tax</Text></th>
                                                <td style='text-align: right;'>{util.format(aggSaleTotal.vat, 2)}</td>
                                            </tr>
                                            <tr>
                                                <th><Text id='dyrejournal.report.total-number-of-products-services'>Total number of products/services</Text></th>
                                                <td style='text-align: right;'>{aggSaleTotal.count}</td>
                                            </tr>
                                            {aggSaleTotalPaymentMethods && aggSaleTotalPaymentMethods.map((item, idx) => {
                                                return (<>
                                                    <tr>
                                                        <th><Text id='dyrejournal.report.total-amount-wo-tax'>Total amount wo/tax for</Text> {item.paymentMethod}</th>
                                                        <td style='text-align: right;'>{util.format(item.total, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><Text id='dyrejournal.report.total-tax'>Total tax for</Text> {item.paymentMethod}</th>
                                                        <td style='text-align: right;'>{util.format(item.tax, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th><Text id='dyrejournal.report.total-number-of-products-services'>Total number of products/services for</Text> {item.paymentMethod}</th>
                                                        <td style='text-align: right;'>{item.count}</td>
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div> */}

                                {/* Report for today<br />
                                Total number of sales: {totalNumberOfSales}<br />
                                Total amount wo/tax: {util.format(aggSaleTotal.total, 2)}<br />
                                Total tax: {util.format(aggSaleTotal.tax, 2)}<br />
                                Total number of products/services: {aggSaleTotal.count}<br />

                                {aggSaleTotalPaymentMethods && aggSaleTotalPaymentMethods.map((item, idx) => {
                                    return (<>
                                        Total amount wo/tax for {item.paymentMethod}: {util.format(item.total, 2)}<br />
                                        Total tax for {item.paymentMethod}: {util.format(item.tax, 2)}<br />
                                        Total number of products/services for {item.paymentMethod}: {item.count}<br />
                                    </>);
                                })} */}
                                {/* Total appointments: 0<br />
                                Total journals: 0<br />
                                Total prescriptions: 0<br />
                                Total animals: 0<br /> */}

                                {/* <xmp>{JSON.stringify(aggSaleTotal, null, 2)}</xmp>
                                <xmp>{JSON.stringify(aggSaleTotalPaymentMethods, null, 2)}</xmp>
                                <xmp>{JSON.stringify(aggSaleTotalTax, null, 2)}</xmp> */}
                            </div>
                        </div>
                    </div>

                    <div class='w-100 d-flex flex-sm-row flex-column mt-3'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='d-flex flex-row justify-content-between'>
                                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                    <Text id='dyrejournal.report.user-sales'>Sales by user</Text>
                                </div>
                                <div class='d-flex flex-row'>
                                    <a
                                        class='btn btn-sm btn-outline-primary rounded-pill mx-2'
                                        href={`${apiServer}/api/sales/report/user/csv?jwtToken=${jwtToken}&reportDate=${reportDate}&type=${view.report}&onlyFromDate=${onlyFromDate}`}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        <i class='fa-duotone fa-download' /> <Text id='dyrejournal.report.download'>Download</Text>
                                    </a>
                                </div>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class='table-responsive'>

                                    <table class='table table-sm table-hover table-striped' style='font-size: 0.9em; line-height: 1.0em;'>
                                        <thead>
                                            <tr>
                                                <th><Text id='dyrejournal.report.user'>User</Text></th>
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sales'>Sales</Text></th>
                                                {aggSalePersonsAccounts && aggSalePersonsAccounts.sort().map(account => {
                                                    return (<>
                                                        <th style='text-align: right;'>{ACCOUNT_MAP[account] || account || 'Ingen konto'}</th>
                                                    </>);
                                                })}
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sum'>Sum</Text></th>
                                                {/* <th style='text-align: right;'><Text id='dyrejournal.report.tax'>Tax inc</Text></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aggSalePersons && aggSalePersons.map((userSale, idx) => {
                                                const totalSales = userSale.accounts?.reduce((acc, item) => acc + item.saleCount, 0);
                                                return (<>
                                                    <tr>
                                                        <td>{userSale.user?.firstname} {userSale.user?.lastname}</td>
                                                        <td style='text-align: right;'>{util.format(totalSales, 0)}</td>
                                                        {aggSalePersonsAccounts && aggSalePersonsAccounts.sort().map(account => {
                                                            const accountSale = userSale.accounts?.find(item => item.account === account);
                                                            return (<>
                                                                <td style='text-align: right;'>
                                                                    <nobr>
                                                                        {accountSale ? <>
                                                                            {util.format(accountSale.price, 2)} <span class='font-weight-lighter'>({accountSale.saleCount})</span>
                                                                        </> : <></>}
                                                                    </nobr>
                                                                </td>
                                                            </>);
                                                        })}
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(userSale.price, 2)}
                                                            </nobr>
                                                        </td>
                                                        {/* <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(userSale.price / 1.25 * 0.25, 2)}
                                                            </nobr>
                                                        </td> */}
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class='w-100 d-flex flex-row justify-content-center'>
                                <small class='text-muted'>
                                    <Text id='dyrejournal.report.user-sales-help'>Sales by user. The sum is the total sale for this user without rounding price to nearest NOK. Tax is NOT included in the sum.</Text>
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class='w-100 d-flex flex-sm-row flex-column mt-3'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.account-sales'>Sales by account</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class='table-responsive w-100'>

                                    <table class='table table-sm table-hover table-striped' style='font-size: 0.9em; line-height: 1.0em;'>
                                        <thead>
                                            <tr>
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sales'>Sales</Text></th>
                                                <th><Text id='dyrejournal.report-account'>Konto</Text></th>
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sum'>Sum</Text></th>
                                                {/* <th style='text-align: right;'><Text id='dyrejournal.report.tax'>Tax inc</Text></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aggSaleTotalAccounts && aggSaleTotalAccounts.map(account => <>
                                                <tr>
                                                    <td style='text-align: right;'>
                                                        <nobr>
                                                            {util.format(account.saleCount, 0)}
                                                        </nobr>
                                                    </td>
                                                    <td style='text-align: left;'>
                                                        <nobr>
                                                            {ACCOUNT_MAP[account.account] || account.account || 'Ingen konto'}
                                                        </nobr>
                                                    </td>

                                                    <td style='text-align: right;'>
                                                        <nobr>
                                                            {util.format(account.price, 2)}
                                                        </nobr>
                                                    </td>
                                                    {/* <td style='text-align: right;'>
                                                        <nobr>
                                                            {util.format(account.price / 1.25 * 0.25, 2)}
                                                        </nobr>
                                                    </td> */}
                                                </tr>
                                            </>)}
                                            <tr>
                                                <td class='font-weight-bold' style='text-align: right;'>{aggSaleTotalAccounts.reduce((acc, item) => acc + item.saleCount, 0)}</td>
                                                <td class='font-weight-bold'><Text id='dyrejournal.report.total-ex-tax'>Total without tax</Text></td>
                                                <td class='font-weight-bold' style='text-align: right;'>{util.format(totalForAccounts, 2)}</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td class='font-weight-bold'><Text id='dyrejournal.report.total-inc-tax'>Total with tax</Text></td>
                                                <td class='font-weight-bold' style='text-align: right;'>{util.format(totalForAccounts * 1.25, 2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class='w-100 d-flex flex-row justify-content-center px-3'>
                                <small class='text-muted'>
                                    <Text id='dyrejournal.report.account-sales-help'>Sales by account. The sum is the total sale for this account without rounding price to nearest NOK. Tax is NOT included in the sum.</Text>
                                </small>
                            </div>
                        </div>

                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.journal-sales'>Sales with journals</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class='table-responsive w-100'>

                                    <table class='table table-sm table-hover table-striped' style='font-size: 0.9em; line-height: 1.0em;'>
                                        <thead>
                                            <tr>
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sales'>Sales</Text></th>
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sum'>Sum</Text></th>
                                                {/* <th style='text-align: right;'><Text id='dyrejournal.report.tax'>Tax inc</Text></th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aggSaleWithJournals && aggSaleWithJournals.saleCount && <>
                                                <tr>
                                                    <td style='text-align: right;'>
                                                        <nobr>
                                                            {util.format(aggSaleWithJournals.saleCount, 0)}
                                                        </nobr>
                                                    </td>
                                                    <td style='text-align: right;'>
                                                        <nobr>
                                                            {util.format(aggSaleWithJournals.price, 2)}
                                                        </nobr>
                                                    </td>
                                                </tr>
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class='w-100 d-flex flex-row justify-content-center px-3'>
                                <small class='text-muted'>
                                    <Text id='dyrejournal.report.journal-sales-help'>Sales with journals. The sum is the total sale without rounding price to nearest NOK. Tax is NOT included in the sum.</Text>
                                </small>
                            </div>
                        </div>

                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.visitors'>Visitors</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class='table-responsive w-100'>

                                    <table class='table table-sm table-hover table-striped' style='font-size: 0.9em; line-height: 1.0em;'>
                                        <thead>
                                            <tr>
                                                <th style='text-align: right;'><Text id='dyrejournal.report.type'>Type</Text></th>
                                                <th style='text-align: right;'><Text id='dyrejournal.report.sum'>Sum</Text></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style='text-align: right;'><Text id='dyrejournal.report.new-visitors'>New visitors</Text></td>
                                                <td class='text-right'>{aggSaleNewVisitors}</td>
                                            </tr>
                                            <tr>
                                                <td style='text-align: right;'><Text id='dyrejournal.report.active-visitors'>Active visitors</Text></td>
                                                <td class='text-right'>{aggSaleActiveVisitors}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class='w-100 d-flex flex-row justify-content-center px-3'>
                                <small class='text-muted'>
                                    <Text id='dyrejournal.report.journal-newvisitors-help'>Visitors statistics for this periode.</Text>
                                </small>
                            </div>
                        </div>

                    </div>

                    <div class='w-100 d-flex flex-sm-row flex-column mt-3'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.list'>Report list</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class='table-responsive'>

                                    <table class='table table-sm table-hover table-striped' style='font-size: 0.9em; line-height: 1.0em;'>
                                        <thead>
                                            <tr>
                                                <th><Text id='dyrejournal.report.date'>Date</Text></th>
                                                <th><Text id='dyrejournal.report.reportdate'>Report Date</Text></th>
                                                <th><Text id='dyrejournal.report.reportType'>Type</Text></th>
                                                <th class='text-center'><Text id='dyrejournal.report.counted-cash'>Counted cash</Text></th>
                                                <th class='text-center'><Text id='dyrejournal.report.cash'>Cash</Text></th>
                                                <th class='text-center'><Text id='dyrejournal.report.bankcard'>Bankcard</Text></th>
                                                <th class='text-center'><Text id='dyrejournal.report.vipps'>Vipps</Text></th>
                                                <th><Text id='dyrejournal.report.invoice'>Invoice</Text></th>
                                                {/* <th><Text id='dyrejournal.report.account'>Account</Text></th> */}
                                                <th class='text-center'><Text id='dyrejournal.report.creditNote'>creditNote</Text></th>
                                                {/* <th><Text id='dyrejournal.report.unpaid'>unpaid</Text></th> */}
                                                <th class='text-center'><Text id='dyrejournal.report.turnover'>turnover</Text></th>
                                                <th class='text-center'><Text id='dyrejournal.report.discount'>Discount</Text></th>
                                                <th class='text-center'><Text id='dyrejournal.report.tax'>Tax</Text></th>
                                                <th><Text id='dyrejournal.report.comment'>Comment</Text></th>
                                                <th>&nbsp;</th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {saleReports && saleReports.map((item, idx) => {
                                                return (<>
                                                    <tr>
                                                        {/* <td>{util.formatDate(item.reportDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</td> */}
                                                        <td>
                                                            <nobr class='text-muted'>{util.formatDate(item.createdDate)}</nobr>
                                                        </td>
                                                        <td class='text-right'>
                                                            <nobr>
                                                                {getReportDate(util.isoDate(item.reportDate, false, false, true), item.reportType)}
                                                            </nobr>
                                                        </td>
                                                        <td class='text-center'>
                                                            {item.reportType === 'day' && <>
                                                                <Text id='dyrejournal.report.daily'>Daily</Text>
                                                            </>}
                                                            {item.reportType === 'month' && <>
                                                                <Text id='dyrejournal.report.monthly'>Monthly</Text>
                                                            </>}
                                                            {item.reportType === 'year' && <>
                                                                <Text id='dyrejournal.report.yearly'>Yearly</Text>
                                                            </>}
                                                        </td>
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.countedCash, 2)}
                                                            </nobr>
                                                        </td>
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountCash, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationCash !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationCash, 2)})</span>
                                                            </nobr>
                                                        </td>
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountCard, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationCard !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationCard, 2)})</span>
                                                            </nobr>
                                                        </td>
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountVipps, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationVipps !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationVipps, 2)})</span>
                                                            </nobr>
                                                        </td>
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountInvoice, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationInvoice !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationInvoice, 2)})</span>
                                                            </nobr>
                                                        </td>
                                                        {/* <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountAccount, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationAccount !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationAccount, 2)})</span>
                                                            </nobr>
                                                        </td> */}
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountCreditNote, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationCreditNote !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationCreditNote, 2)})</span>
                                                            </nobr>
                                                        </td>
                                                        {/* <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountUnpaid, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationUnpaid !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationUnpaid, 2)})</span>
                                                            </nobr>
                                                        </td> */}
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountTotal, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationTotal !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationTotal, 2)})</span>
                                                            </nobr>
                                                        </td>
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountDiscount, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationDiscount !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationDiscount, 2)})</span>
                                                            </nobr>
                                                        </td>
                                                        <td style='text-align: right;'>
                                                            <nobr>
                                                                {util.format(item.amountTax, 2)}
                                                                <span class={`font-weight-lighter ${item.deviationTax !== 0 ? 'text-danger' : ''} ml-2`}>({util.format(item.deviationTax, 2)})</span>
                                                            </nobr>
                                                        </td>
                                                        <td>{item.comment}</td>
                                                        <td>
                                                            <button type='button' class='btn btn-sm btn-primary rounded-pill mx-2' onClick={this.onClickPrintReport} data-id={item.id}>
                                                                <nobr><i class='fa-solid fa-print' /> <Text id='dyrejournal.report.print'>Print report</Text></nobr>
                                                            </button>
                                                        </td>
                                                        <td class='d-none d-sm-table-cell' onClick={this.onClickDeleteSaleReport} data-id={item.id}>
                                                            <i class='fa-solid fa-trash text-danger' />
                                                        </td>
                                                    </tr>
                                                </>);
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div class='w-100 d-flex flex-row justify-content-center'>
                                    <button
                                        type='button'
                                        class={`btn btn-secondary rounded-pill mx-2`}
                                        onClick={this.onClickUpdateList}
                                        disabled={saveReportUpdating}
                                    >
                                        <i class='fa-solid fa-sync' /> <Text id='dyrejournal.report.update-list'>Update list</Text>
                                        {saveReportUpdating && <i class='fa-solid fa-spinner-third fa-spin' />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div class='w-100 d-flex flex-sm-row flex-column mt-3'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.appointments'>Appointments</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                Report for today's appointments
                            </div>
                        </div>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.prescriptions'>Prescriptions</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                Report for today's prescriptions
                            </div>
                        </div>
                    </div> */}

                    {/* <div class='w-100 d-flex flex-sm-row flex-column mt-3'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.invoices'>Invoices</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                Report for today's invoices
                            </div>
                        </div>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.appointments'>Drugs</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-3 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                Report for today's drugs
                            </div>
                        </div>
                    </div> */}

                </div>
            </div>
        );
    }
}

export default AllProducts;
