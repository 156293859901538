import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';

@withText(props => ({
    titleText: <Text id='customer.title'>Customers</Text>,
}))
@observer
class CustomerList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewCustomer = e => {
        const { view } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewCustomer', {
            id,
            // title,
            height: drawerHeightMedium,
            callback: () => {
                appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    render() {
        const { appState, userStore, customerStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            customers,
            classNames = '',
            title = titleText,
            editElement,
            help,
        } = this.props;

        return (<>
            <GuiList
                stores={this.props.stores}
                holdingStore={customerStore}
                classNames={classNames}
                view={this.viewCustomer}
                title={title}
                list={customers}
                elTitle={e => e.name}
                elImageStatus={e => ''}
                elInfo1={e => <>
                    {e.postalplace} {e.country}
                </>}
                elInfo2={e => ''}
                elInfo3={e => ''}
                editElement={editElement}
                help={help}
            />
        </>);
    }
}

export default CustomerList;
