import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import md5 from 'crypto-js/md5'

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import GuiInfoBox from '../../components/gui/infoBox';
import GuiList from '../../components/gui/list';
import ScrollIntoView from '../../components/gui/scrollIntoView';
import AddToCalendar from '../../components/actions/addToCalendar';

import RaceClassContestantViewProfile from '../../components/raceClassContestant/viewProfile';

const checkAllRequiredFields = (signupObj, signupData) => {
    // console.log({ signupObj, signupData });
    return signupData.every(signup => {
        const { fields, hasAllRequiredFields } = signup;
        if (!hasAllRequiredFields) {
            return true;
        }
        return hasAllRequiredFields(signupObj, fields);
    });
};

const getMissingRequiredFields = (signupObj, signupData) => {
    let missingFields = [];
    signupData.forEach(signup => {
        const requiredFields = signup?.fields?.filter(f => f.isRequired);
        if (!requiredFields) {
            return;
        }
        const missing = requiredFields
            .filter(f => !signupObj[f.name])  // Check if the field's value is missing
            .map(f => `${signup.key} / ${f.name}`);  // Get the field name

        missingFields = [...missingFields, ...missing];
    });
    return missingFields;
};

function viewProfile(user) {
    const image = localUtil.getImage({ user, size: 's3MediumLink', lastImage: true });
    const name = localUtil.displayName(user, true);
    const { firstname, lastname, sportsClub, kennel, email, cellphone } = user;

    return (<>
        <div
            class={`text-center border rounded-circle imageRounded imageRoundedLarge`}
            style={`${image ? `background-image: url('${image}'); background-size: cover;` : ''}`}
        >
            {!image && <i class='fas fa-user text-muted mt-2' style='font-size: 33px;' />}
        </div>
        <div class='my-3 d-flex flex-column'>
            <strong class='text-center'>{firstname} {lastname}</strong>
            <span class='text-center'>{sportsClub}</span>
            <span class='text-center'>{kennel}</span>
            {email && <span class='text-center'><i class='fa-duotone fa-envelope' /> {email}</span>}
            {cellphone && <span class='text-center'><i class='fa-duotone fa-mobile' /> {cellphone}</span>}
        </div>
    </>);
}

@withText(props => ({
    approvedText: <Text id='race-class.signup.approved'>Approved</Text>,
    bibText: <Text id='race-class.signup.bib'>Bib</Text>,
    startTimeText: <Text id='race-class.signup.startTime'>Start time</Text>,
    paidSignupfeeText: <Text id='race-class.signup.pay-signupfee'>Pay signup fee</Text>,
    paidSignupText: <Text id='race-class.signup.pay-signup'>Pay starting fee</Text>,
    paidRestText: <Text id='race-class.signup.pay-rest'>Pay rest remaining</Text>,
    paidSeedingText: <Text id='race-class.signup.pay-seeding'>Pay seeding fee</Text>,
    termsAndAgreementText: <Text id='race-class.signup.termsAndAgreement'>Terms & agreement</Text>,
    declarationDateText: <Text id='race-class.signup.declarationDate'>Declaration</Text>,
    licenseText: <Text id='race-class.signup.license'>License</Text>,
    preCheckText: <Text id='race-class.signup.preCheck'>Pre Check</Text>,

    signupApprovedText: <Text id='race-class.signup.approved-approved'>Your signup is approved...</Text>,
    signupNotApprovedText: <Text id='race-class.signup.approved-not-approved'>Your signup is not approved yet...</Text>,
}))
@observer
class RaceClassSignup extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: props.isNew ? [...fields.get('raceClassSignup'), { type: 'submit' }] : fields.get('raceClassSignup'),
            imageIdx: 0,
            infoList: [],
        };
        this.imageScrollerRef;
    }

    scrollImages = (e) => {
        const { scrollLeft, scrollWidth, clientWidth } = e.target;
        const imageStep = clientWidth;
        const imageIdx = scrollLeft / imageStep;
        // console.log({ e, imageStep, imageIdx, scrollLeft, scrollWidth, clientWidth });

        const nearestInt = Math.round(imageIdx);
        const diff = Math.abs(nearestInt - imageIdx);
        // console.log({ nearestInt, diff });
        if (Number.isInteger(imageIdx) || diff < 0.1) {
            this.setState({
                imageIdx: nearestInt,
            });
        }
    }

    scrollToImage = e => {
        const { idx } = e.target.dataset;
        this.setState({
            selectedIdx: parseInt(idx, 10),
        });
    }

    onClickScrollLeft = (e) => {
        const el = this.imageScrollerRef;
        const width = 0 - el.clientWidth;
        el.scrollBy({
            top: 0,
            left: width,
            behavior: 'smooth'
        });
    }

    onClickScrollRight = (e) => {
        const el = this.imageScrollerRef;
        const width = el.clientWidth;
        el.scrollBy({
            top: 0,
            left: width,
            behavior: 'smooth'
        });
    }

    async loadRaceClass(props = this.props) {
		const { raceStore, raceClassStore, userStore } = props.stores;
        const { raceClassId } = props;
		await raceClassStore.load(raceClassId);
        const { raceClass } = raceClassStore;
        if (raceClass?.race) {
            // console.log('load race', raceClass.race);
            await raceStore.load(raceClass.race, false, { addData: ['customers'] });
        }
    }

    viewPay = async (paymentType, paymentTitle, amount, isPaid) => {
        const { drawerLevel = 1 } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMediumLarge } = appState;
		appState.openDrawer('payGeneric', {
            paymentType,
            paymentTitle,
            amount,
            isPaid,
			height: drawerHeightMediumLarge,
            callback: () => {
                this.loadRaceClassContestant(this.props, false);
            },
		}, drawerLevel + 2);
    }

    async loadRaceClassContestant(props = this.props, emptyFirst = true) {
        const {
            approvedText,
            bibText,
            startTimeText,
            paidSignupfeeText,
            paidSignupText,
            paidRestText,
            paidSeedingText,
            declarationDateText,
            termsAndAgreementText,
            licenseText,
            preCheckText,

            signupApprovedText,
            signupNotApprovedText,

        } = this.props;
		const { raceClassContestantStore, userStore, raceStore } = props.stores;
        const { raceClassId } = props;
        const { user } = userStore;
        const { race } = raceStore;
        const { raceClassContestant } = raceClassContestantStore;
        if (user && user.id) {
            const contestant = await raceClassContestantStore.loadContestants({ raceClass: raceClassId, user: user.id, emptyFirst });
            const { raceClassContestant } = raceClassContestantStore;
            // approved: {user.approved}<br />
            // bib: {user.bib}<br />
            // startTime: {user.startTime}<br />
            // paidSignupfee: {user.paidSignupfee}<br />
            // paidSignup: {user.paidSignup}<br />
            // declarationDate: {user.declarationDate}<br />
            // termsAndAgreement: {user.termsAndAgreement}<br />
            // license: {user.license}<br />
            // preCheck: {user.preCheck}<br />
            if (raceClassContestant && raceClassContestant.id) {
                this.setState({
                    infoList: [
                        {
                            id: 4,
                            // icon: 'fa-duotone fa-right-to-bracket',
                            title: paidSignupfeeText,
                            icon: raceClassContestant.paidSignupfee ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                            images: [],
                            // body: raceClassContestant.paidSignupfee ? `Signup fee paid` : `Signup fee not paid
                            //     ${raceClassContestant.vippsSignupfeeStatus}
                            //     ${raceClassContestant.vippsSignupfeeAmountCurrency}
                            //     ${raceClassContestant.vippsSignupfeeAmountValue}
                            // `,
                            // buttonClass: 'btn btn-danger',
                            // // buttonText: 'Pay signup fee',
                            // buttonText: 'Check signup fee',
                            // // buttonFunc: this.paySignupfee,
                            // buttonFunc: this.checkSignupfee,
                            viewElement: () => this.viewPay('signupfee', 'Signup fee', raceClassContestant.signupfeeAmount, raceClassContestant.paidSignupfee),
                            hideElement: raceClassContestant.signupfeeAmount ? false : true,
                        },
                        {
                            id: 5,
                            // icon: 'fa-duotone fa-right-to-bracket',
                            title: paidSignupText,
                            icon: contestant.paidSignup ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                            images: [],
                            // body: contestant.paidSignup ? 'Signup paid' : 'Signup not paid',
                            // buttonClass: 'btn btn-danger',
                            // buttonText: 'Pay signup',
                            // buttonFunc: this.paySignup,
                            viewElement: () => this.viewPay('signup', 'Signup', raceClassContestant.signuppaymentAmount, contestant.paidSignup),
                            hideElement: raceClassContestant.signuppaymentAmount ? false : true,
                        },
                        {
                            id: 10,
                            // icon: 'fa-duotone fa-right-to-bracket',
                            title: paidRestText,
                            icon: contestant.paidRest ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                            images: [],
                            // body: contestant.paidSignup ? 'Signup paid' : 'Signup not paid',
                            // buttonClass: 'btn btn-danger',
                            // buttonText: 'Pay signup',
                            // buttonFunc: this.paySignup,
                            viewElement: () => this.viewPay('rest', 'Signup rest', raceClassContestant.restPaymentAmount, contestant.paidRest),
                            hideElement: raceClassContestant.restPaymentAmount ? false : true,
                        },
                        {
                            id: 10,
                            // icon: 'fa-duotone fa-right-to-bracket',
                            title: paidSeedingText,
                            icon: contestant.paidSeeding ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                            images: [],
                            // body: contestant.paidSignup ? 'Signup paid' : 'Signup not paid',
                            // buttonClass: 'btn btn-danger',
                            // buttonText: 'Pay signup',
                            // buttonFunc: this.paySignup,
                            viewElement: () => this.viewPay('seeding', 'Seeding fee', raceClassContestant.seedingPaymentAmount, contestant.paidSeeding),
                            hideElement: raceClassContestant.seedingPaymentAmount ? false : true,
                        },
                        // {
                        //     id: 1,
                        //     // icon: 'fa-duotone fa-check-circle',
                        //     title: approvedText,
                        //     icon: contestant.approved ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                        //     body: contestant.approved ? signupApprovedText : signupNotApprovedText,
                        //     images: [],
                        //     buttonClass: 'btn btn-primary',
                        //     buttonIcon: 'fa-duotone fa-check',
                        //     buttonText: 'Check approved',
                        //     buttonFunc: this.checkApproved,
                        // },
                        // {
                        //     id: 2,
                        //     // icon: 'fa-duotone fa-circle-1',
                        //     title: bibText,
                        //     icon: contestant.bib ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                        //     body: contestant.bib,
                        //     images: [],
                        //     hideElement: false,
                        // },
                        // {
                        //     id: 3,
                        //     // icon: 'fa-duotone fa-clock',
                        //     title: startTimeText,
                        //     icon: contestant.startTime ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                        //     body: contestant.startTime,
                        //     images: [],
                        // },
                        {
                            id: 6,
                            // icon: 'fa-duotone fa-signature',
                            title: declarationDateText,
                            icon: contestant.declarationDate ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                            body: contestant.declarationDate,
                            images: [],
                            hideElement: race.declarationText ? false : true,
                        },
                        {
                            id: 7,
                            // icon: 'fa-duotone fa-signature',
                            title: termsAndAgreementText,
                            icon: contestant.termsAndAgreement ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                            body: contestant.termsAndAgreement,
                            images: [],
                            hideElement: race.termsAndAgreementText ? false : true,
                        },
                        {
                            id: 8,
                            // icon: 'fa-duotone fa-id-card',
                            title: licenseText,
                            icon: contestant.license ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                            body: contestant.license,
                            images: [],
                            hideElement: race.licenseText ? false : true,
                        },
                        {
                            id: 9,
                            // icon: 'fa-duotone fa-circle-check',
                            title: preCheckText,
                            icon: contestant.preCheck ? 'fa-duotone fa-circle-check text-success' : 'fa-duotone fa-circle-question text-danger',
                            body: contestant.preCheck,
                            images: [],
                            hideElement: race.preCheckText ? false : true,
                        },
                    ],
                });
            }
        }
    }

    loadAll = async (props = this.props) => {
        await this.loadRaceClass(props);
        await this.loadRaceClassContestant(props, props.editSignup ? false : true);
        const { isNew } = this.props;
        const { userStore, raceStore, raceClassStore, raceClassContestantStore } = this.props.stores;
        const { race } = raceStore;
        const { raceClass } = raceClassStore;
        const { raceClassContestant, hasSignedUp } = raceClassContestantStore;
        const { user } = userStore;

        if (!hasSignedUp) {
            raceClassContestantStore.updateKeyValue('newRaceClassContestant', {
                race: race.id,
                raceClass: raceClass.id,
                user: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                birth: user.birth,
                cellphone: user.cellphone,
                address: user.address,
                postalcode: user.postalcode,
                postalplace: user.postalplace,
                country: user.country,
                sportsClub: user.sportsClub,
                licenseNumber: user.licenseNumber,
                kennel: user.kennel,

                signuppaymentAmount: raceClass.signuppayment,
                signupfeeAmount: raceClass.signupfee,
                seedingPaymentAmount: raceClass.seedingPaymentAmount,

                facebook: user.facebook,
                instagram: user.instagram,

                qualifying: user.qualifying,
                localNewsPaper: user.localNewsPaper,
                okToShareInfoWithMedia: user.okToShareInfoWithMedia,
                rnb: user.rnb,

                occupation: user.occupation,
                sponsors: user.sponsors,
                leadDogs: user.leadDogs,
                oldMerits: user.oldMerits,
                hobbies: user.hobbies,
                funFacts:user.funFacts,
                ambitions: user.ambitions,

                images: user.images[0] ? [user.images[0]] : [],
            });
        }

    }

    createraceClassContestant = async () => {
        const { callback = () => {} } = this.props;
        const { raceClassStore, raceClassContestantStore, userStore } = this.props.stores;
        const { user } = userStore;
        const { raceClass } = raceClassStore;
        const { newRaceClassContestant } = raceClassContestantStore;

        // Check if raceClassContestant exists before creating it:
        let existingRaceClassContestant = await raceClassContestantStore.loadContestants({ raceClass: raceClass.id, user: user.id });

        let raceClassContestant = {};
        if (existingRaceClassContestant?.length > 0) {
            raceClassContestant = existingRaceClassContestant[0];
            const response = await raceClassContestantStore.save({
                id: raceClassContestant.id,
                ...newRaceClassContestant,
            });
        } else {
            const response = await raceClassContestantStore.insert(newRaceClassContestant);
            raceClassContestant = response?.data?.raceClassContestant;
            if (raceClassContestant && raceClassContestant.id) {
                raceClassContestantStore.updateKeyValue('hasSignedUp', true);
                raceClassContestantStore.updateKeyValue('raceClassContestant', raceClassContestant);
                raceClassContestantStore.updateKeyValue('newRaceClassContestant', {});
                this.setState({
                    sections: [{ type: 'submit' }],
                });
                await this.loadRaceClassContestant();
                const userUpdate = {};
                const userFields = [
                    'firstname', 'lastname', 'email', 'birth', 'cellphone', 'address',
                    'postalcode', 'postalplace', 'country', 'sportsClub', 'licenseNumber', 'kennel', 'facebook', 'instagram',
                    'qualifying', 'localNewsPaper', 'okToShareInfoWithMedia', 'rnb', 'occupation',
                    'sponsors', 'leadDogs', 'oldMerits', 'hobbies', 'funFacts', 'ambitions', 'images',
                    'handler1Firstname', 'handler1Lastname', 'handler1Cellphone',
                    'handler2Firstname', 'handler2Lastname', 'handler2Cellphone',
                ];
                userFields.forEach(field => {
                    // if (raceClassContestant[field] && !user[field]) {
                    if (raceClassContestant[field]) {
                        userUpdate[field] = newRaceClassContestant[field];
                        userStore.updateObjectKeyValue('user', field, newRaceClassContestant[field]);
                    }
                });
                await userStore.save({
                    ...userUpdate,
                    id: user.id,
                });
            }
        }
        callback();
        // this.viewPaySignupfee();
    }

    saveChanges = () => {

    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { raceClassContestantStore } = this.props.stores;
        const { foundList } = raceClassContestantStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        raceClassContestantStore.updateKeyValue('newRaceClassContestant', {
            ...item,
        });
        raceClassContestantStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    viewText = e => {
        // console.log('viewText');
        const { drawerLevel = 1 } = this.props;
        const { raceClassContestantStore } = this.props.stores;
        const { raceClassContestant } = raceClassContestantStore;
        const { id } = e.target.closest('.box-line').dataset;
        // const { id } = e.target.closest('').dataset;

        const { infoList } = this.state;
        const info = infoList.find(i => i.id === parseInt(id, 10));
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('viewText', {
			height: drawerHeightMedium,
			title: info.title,
            body: info.body,
            images: info.images,
            buttonClass: info.buttonClass,
            buttonText: info.buttonText,
            buttonIcon: info.buttonIcon,
            buttonFunc: info.buttonFunc,
            // checksum: md5(`${raceClassContestant.paidSignupfee}-${raceClassContestant.vippsSignupfeeStatus}`).toString(),
		}, drawerLevel + 2);
	}

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { isNew, drawerLevel, lookupArray, editSignup } = this.props;
        const { sections, imageIdx, selectedIdx, infoList } = this.state;
        const { userStore, raceStore, raceClassStore, raceClassContestantStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { race } = raceStore;
        const { raceClass = {}, events = [] } = raceClassStore;
        const { newRaceClassContestant, raceClassContestant } = raceClassContestantStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { language = 'en' } = user;

        const hasPrev = imageIdx > 0;
        const hasNext = imageIdx < sections.length - 1;

        const totalMushers = raceClass.totalContestants;
        const { hasSignedUp } = raceClassContestantStore;

        const hasAllRequiredFields = checkAllRequiredFields(isNew ? newRaceClassContestant : raceClassContestant, sections);
        const missingRequiredFields = getMissingRequiredFields(isNew ? newRaceClassContestant : raceClassContestant, sections);

        return (<>
            {/* hasAllRequiredFields: {hasAllRequiredFields ? 'true' : 'false'}<br />
            {missingRequiredFields.map(field => <>
                <div class='my-2'>  {field} </div>
            </>)} */}

            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100' style='max-width: 960px; padding-top: 35px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(installation, null, 4)}</xmp> */}
                    {/* maxMushers: {raceClass.maxMushers}<br />
                        totalMushers: {totalMushers}<br /> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='race-class.signup.new'>New signup</Text> : <Text id='race-class.signup.edit'>Edit signup</Text>}
                    </div>
                    {raceClass?.id && <>
                        <GuiInfoBox
                            stores={this.props.stores}
                            nameMiddle={race.title}
                            value={raceClass.title}
                            // postfix={util.formatDate(race.endDate, { hour12: false, hour: '2-digit', minute: '2-digit' })}
                            styles={'font-size: 1.5em;'}
                            classesContainer={'flex-fill mx-3'}
                        />
                    </>}

                    <div class='d-flex flex-column justify-content-start mx-3 mt-3 row'>
                        <div
                            class='overflow-hidden position-relative'
                            style='
                                flex: 0 0 100%;
                                max-width: 100%;
                                // height: 60vh;
                                // min-height: 60vh;
                                padding-bottom: 50px;
                            '
                        >
                            {(hasSignedUp && !editSignup) ? <>
                                <div class={`box-container d-flex flex-column justify-content-center align-items-center flex-wrap py-3  ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <div class='my-3'>
                                        <h5><Text id='race-class.signup.title'>You are signed up!</Text></h5>
                                    </div>
                                    <RaceClassContestantViewProfile stores={this.props.stores} user={raceClassContestant} />
                                </div>

                                {(!raceClassContestant.paidSignupfee && raceClassContestant.signupfeeAmount > 0) ? <>
                                    <div class={`box-container d-flex flex-column justify-content-center align-items-center flex-wrap py-3 mt-4 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                        <div class='w-100 d-flex flex-column justify-content-center text-center px-4 my-2'>
                                            <Text id='race-class.signup.signupfee-not-paid'>Next step is to pay the signup fee! Click this button to get started.</Text>
                                            <button type='button' class='btn btn-danger rounded-pill mt-2' onClick={() => this.viewPay('signupfee', 'Signup fee', raceClassContestant.signupfeeAmount, raceClassContestant.paidSignupfee)}>
                                                <i class='fa-duotone fa-credit-card mr-2' />
                                                <Text id='race-class.signup.signupfee-payment'>Signup fee payment</Text>
                                            </button>
                                        </div>
                                    </div>
                                </> : <>
                                    {(!raceClassContestant.paidSignup && raceClassContestant.signuppaymentAmount > 0) && <>
                                        <div class={`box-container d-flex flex-column justify-content-center align-items-center flex-wrap py-3 mt-4 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                            <div class='w-100 d-flex flex-column justify-content-center text-center px-4 my-2'>
                                                <Text id='race-class.signup.signup-not-paid'>Next step is to pay the signup! Click this button to get started.</Text>
                                                <button type='button' class='btn btn-danger rounded-pill mt-2' onClick={() => this.viewPay('signup', 'Signup', raceClassContestant.signuppaymentAmount, raceClassContestant.paidSignup)}>
                                                    <i class='fa-duotone fa-credit-card mr-2' />
                                                    <Text id='race-class.signup.signup-payment'>Signup payment</Text>
                                                </button>
                                            </div>
                                        </div>
                                    </>}
                                </>}
                                {/* <xmp>{JSON.stringify(raceClassContestant, null, 4)}</xmp> */}

                                <div class={`box-container d-flex flex-column justify-content-center align-items-center flex-wrap py-3 mt-4 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                    <GuiList
                                        stores={this.props.stores}
                                        holdingStore={raceClassStore}
                                        view={this.viewText}
                                        list={infoList.filter(e => !e.hideElement)}
                                        elTitle={e => e.title}
                                        elInfo1={e => ''}
                                        elInfo2={e => ''}
                                        elInfo3={e => ''}
                                        hideEmpty={true}
                                        showEmptyImage={false}
                                        showFilterBox={false}
                                        showCevron={true}
                                        showRightIcon={e => e.rightIcon}
                                        // checksum={md5(`${raceClassContestant.paidSignupfee}-${raceClassContestant.vippsSignupfeeStatus}`).toString()}
                                        // editElement={editElement}
                                    />
                                </div>

                            </> : <>
                                <div
                                    class='d-flex flex-row flex-nowrap no-scrollbar h-100'
                                    style='overflow: auto; scroll-snap-type: x mandatory;'
                                    onScroll={this.scrollImages}
                                    ref={c => this.imageScrollerRef = c}
                                >
                                    {sections.map((section, idx) => <>
                                        <div class={`col-12 clearfix p-0 position-relative ${idx >= 1 ? 'ml-2' : ''}`}>
                                            <div
                                                class={`w-100 h-100`}
                                                style={`
                                                    flex-wrap: wrap;
                                                    overflow-y: hidden;
                                                    scroll-snap-align: start;
                                                `}
                                                onTouchstart={(e) => { e.stopPropagation(); }}
                                                onTouchend={(e) => { e.stopPropagation(); }}
                                                onTouchmove={(e) => { e.stopPropagation(); }}
                                            >
                                                {section.type === 'submit' ? <>
                                                    <div
                                                        class={`box-container d-flex flex-column justify-content-center align-items-center flex-wrap py-3 px-4 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}
                                                    >
                                                        {hasAllRequiredFields ? <>
                                                            <div class='my-3 w-100'>
                                                                <h5><Text id='race-class.signup.ready'>Ready to sign up</Text></h5>
                                                                {raceClass.readyToSignup && <>
                                                                    <Markdown markdown={localUtil.replaceImages(raceClass.readyToSignup, raceClass.images, language, false)} markedOpts={localUtil.getMarkdownOptions()} />
                                                                </>}
                                                            </div>
                                                            {viewProfile(newRaceClassContestant)}

                                                            {editSignup ? <>
                                                                <button type='button' class={`btn btn-block btn-lg btn-primary rounded-pill`} onClick={this.saveChanges}>
                                                                    <i class='fa-duotone fa-user-plus' /> <Text id='race-class.signup.save'>Save changes</Text> - {raceClass.title}
                                                                </button>
                                                            </> : <>
                                                                <button type='button' class={`btn btn-block btn-lg btn-danger rounded-pill`} onClick={this.createraceClassContestant}>
                                                                    <i class='fa-duotone fa-user-plus' /> <Text id='race-class.signup.create'>Signup</Text> - {raceClass.title}
                                                                </button>
                                                            </>}
                                                        </> : <>
                                                            <div class='my-3 w-100'>
                                                                <h5><Text id='race-class.signup.missing-fields'>Missing fields</Text></h5>
                                                                {missingRequiredFields.map(field => <>
                                                                    <div class='my-2'>  {field} </div>
                                                                </>)}
                                                            </div>
                                                        </>}

                                                    </div>
                                                </> : <>
                                                    <FormFields
                                                        stores={this.props.stores}
                                                        sections={sections.filter(e => e.name === section.name)}
                                                        holdingStore={raceClassContestantStore}
                                                        objectName={isNew ? 'newRaceClassContestant' : 'raceClassContestant'}
                                                        isNew={isNew}
                                                        drawerLevel={drawerLevel}
                                                        isAdmin={false}
                                                        headerMargin={'mx-1'}
                                                        sectionMargin={''}
                                                        // filter={e => e.name === section.name}
                                                    />
                                                </>}
                                                <div class='my-2 d-flex flex-row justify-content-between'>
                                                    <div>
                                                        {hasPrev && <>
                                                            <button type='button' class={`btn btn-link text-muted rounded-pill`} onClick={this.onClickScrollLeft}>
                                                                <i class='fas fa-arrow-left' /> <Text id='race-class.signup.prev-section'>Prev</Text>
                                                            </button>
                                                        </>}
                                                    </div>
                                                    <div>
                                                        {hasNext && <>
                                                            <button type='button' class={`btn ${isNew ? 'btn-outline-primary' : 'btn-link text-muted'} rounded-pill`} onClick={this.onClickScrollRight}>
                                                                <Text id='race-class.signup.next-section'>Next</Text> <i class='fas fa-arrow-right' />
                                                            </button>
                                                        </>}
                                                    </div>
                                                </div>
                                                {selectedIdx === idx && <ScrollIntoView />}
                                            </div>
                                        </div>
                                    </>)}
                                </div>

                                {sections && sections.length > 1 && <div
                                    class='w-100 d-flex flex-row justify-content-center align-items-center position-absolute'
                                    style='
                                        bottom: 20px; right: 0px;
                                    '
                                >
                                    {sections.map((section, idx) => <>
                                        {/* <i class={`${idx === imageIdx ? 'fas' : 'far'} fa-circle mr-1`} /> */}
                                        <div
                                            class={`d-flex flex-column justify-content-center align-items-center rounded-circle text-center mx-1 px-2 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} ${idx === imageIdx ? 'border font-weight-bold' : 'text-muted'}`}
                                            style='width: 35px; height: 35px;'
                                            onClick={this.scrollToImage}
                                            data-idx={idx}
                                        >
                                            {idx + 1}
                                        </div>
                                    </>)}
                                </div>}
                            </>}

                        </div>
                    </div>

                    {(hasSignedUp && !editSignup) && <>
                        <AddToCalendar events={events} stores={this.props.stores} classNames={'mx-0'} />
                    </>}

                </div>

            </div>
        </>);
    }
}

export default RaceClassSignup;
