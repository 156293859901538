import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    commentText: <Text id='input.comment-help'>comment</Text>,
}))
@observer
class NewSaleComment extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('saleComment'),
        };
    }

    createSaleComment = async () => {
        const { comment } = this.state;
        const { callback = () => {} } = this.props;

        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;
        const { comments = [] } = newSale;

        const newComment = {
            md5: md5(`${`comment`}${new Date().getTime()}`).toString(),
            comment,
            date: new Date(),
        };

        saleStore.updateObjectKeyValue('newSale', 'comments', [...comments, { ...newComment }]);

        await saleStore.saveSale({ calledFrom: 'newSaleComment.createSaleComment', fieldsToSave: ['comments'] });
        callback(comment);
    }

    render() {
        const { comment } = this.state;
        const { commentText } = this.props;
        const { id } = this.props;
        const { userStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        <Text id='saleComment.new'>New saleComment</Text>
                    </div>
                    <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                        <textarea
                            class='form-control'
                            rows={5}
                            placeholder={commentText}
                            value={comment}
                            onInput={linkstate(this, 'comment')}
                        />
                    </div>
                    <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                        <button type='button' class='btn btn-primary rounded-pill' onClick={this.createSaleComment}>
                            <Text id='saleComment.add'>Add saleComment</Text>
                        </button>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default NewSaleComment;
